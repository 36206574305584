import crud from "libs/api/client";
import { MarketCategory } from "../entity/types";
import { APIList } from "../types";
import { Lead, LeadInterest } from "./types";

const AccountAPI = {
  getMarketCategories: (params?: Record<string, string>): Promise<APIList<MarketCategory>> =>
    crud("list", "/marketcategories/", params),

  getOrCreateLead: (params: { email: string; website?: string }): Promise<Lead> =>
    crud("list", "/product_application/application_lead/get_active/", params),

  updateLeadInterest: (lead: number, products: LeadInterest): Promise<Lead> =>
    crud("update", `/product_application/application_lead/${lead}/update_products_of_interest/`, products),

  setLeadConverted: (lead: number): Promise<void> =>
    crud("update", `/product_application/application_lead/${lead}/set_lead_converted/`),
};

export default AccountAPI;
