import { useQuery } from "react-query";
import { CURRENT_USER_QUERY_KEY } from "constants/common";
import { AuthAPI } from "@ampla/api";
import { AuthInternalContext } from "../types";

const useCurrentUser = ({
  userIsLoggedIn,
  setShowLoader,
}: Pick<AuthInternalContext, "userIsLoggedIn" | "setShowLoader">) => {
  const { data: user, ...userQuery } = useQuery(CURRENT_USER_QUERY_KEY, () => AuthAPI.get_current_user(true), {
    enabled: userIsLoggedIn,
    // In case we're showing the loader we want to stop doing that
    // once we successfully fetch the current user
    onSuccess: () => setShowLoader(false),
  });

  return {
    // When logging out the query for the current user is removed
    // however that does not trigger a rerender hence why we need this
    user: userIsLoggedIn ? user : undefined,
    userQuery,
  };
};

export default useCurrentUser;
