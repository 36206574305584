import { Children, cloneElement, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Tabs as MuiTabs } from "@mui/material";
import classnames from "classnames";

import { useStyles } from "libs/components/components/Tabs/Tabs.styles";
import TabsOverflowMenu from "libs/components/components/Tabs/TabsOverflowMenu";
import { TabsProps } from "./Tabs.types";

// Width of the button that opens the overflow menu
const OVERFLOW_MENU_BUTTON_WIDTH = 80;

const Tabs = forwardRef(
  ({ variant = "primary", color, collapsible = false, className, children, ...tabProps }: TabsProps, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [minWidth, setMinWidth] = useState(0);
    const [hiddenIndexes, setHiddenIndexes] = useState<number[]>([]);
    useImperativeHandle(ref, () => containerRef.current);

    // Set a min-width of the tabs based on the longest tab label
    const handleRender = (tabRef: HTMLButtonElement) => {
      if (tabRef) {
        const { width } = tabRef.getBoundingClientRect();
        if (width > minWidth) {
          setMinWidth(width);
        }
      }
    };
    const classes = useStyles();

    // Make a list of tabs that should be hidden according to what is selected and the max
    // number of tabs that will fit on the screen using the minWidth
    const filterTabs = () => {
      if (collapsible && containerRef.current && minWidth) {
        const { clientWidth } = containerRef.current;
        const maxTabs = (clientWidth - OVERFLOW_MENU_BUTTON_WIDTH) / minWidth;
        const tabs = Children.toArray(children);
        const selectedIndex = tabs.findIndex((child: any) => child.props.value === tabProps.value);
        const hidden = tabs.reduce((acc: any, child: any, index: number) => {
          if (index !== selectedIndex && index >= maxTabs - (index > selectedIndex ? 1 : 2)) {
            return [...acc, index];
          }
          return acc;
        }, []);
        setHiddenIndexes(hidden);
      }
    };

    // Create children for tab list that will report their ref to calculate minWidth
    const tabChildren = Children.map(children, (child: any, index: number) => {
      if (!child) {
        return null;
      }
      return cloneElement(child, {
        ...child.props,
        className: classnames(child.props.className, { hidden: hiddenIndexes.includes(index) }),
        variant,
        color,
        ref: handleRender,
        style: collapsible ? { minWidth } : {},
      });
    });

    // Create children for the overflow menu
    const menuChildren = Children.map(children, (child: any, index: number) => {
      if (!child) {
        return null;
      }
      return cloneElement(child, {
        ...child.props,
        color: "dark",
        className: classnames(child.props.className, { hidden: !hiddenIndexes.includes(index) }),
      });
    });

    useEffect(() => {
      filterTabs();
    }, [containerRef, minWidth, tabProps.value]);

    useEffect(() => {
      if (collapsible) {
        window.addEventListener("resize", filterTabs);
        return () => window.removeEventListener("resize", filterTabs);
      }
    }, [tabProps]);

    return (
      <div className={classnames(classes.root, className)} ref={containerRef}>
        <div className={classnames(classes.container, variant)}>
          <MuiTabs {...tabProps} className={variant}>
            {tabChildren}
          </MuiTabs>
          {!!hiddenIndexes.length && (
            <TabsOverflowMenu color={color} variant={variant} onChange={tabProps.onChange}>
              {menuChildren}
            </TabsOverflowMenu>
          )}
        </div>
      </div>
    );
  },
);

export default Tabs;
