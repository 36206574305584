import { Fade } from "@mui/material";
import {
  SnackbarProvider as NotistackSnackbarProvider,
  SnackbarProviderProps as SnackbarProviderPropsNotistack,
} from "notistack";

// FIXME: Move the utility functions from the component library
// to a shared `@ampla/utils` lib to avoid the circular dependency
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CheckCircleIcon, ErrorIcon, InfoIcon } from "@ampla/icons";

import { useStyles } from "./SnackbarProvider.styles";

export type SnackbarProviderProps = Omit<
  SnackbarProviderPropsNotistack,
  "anchorOrigin" | "classes" | "iconVariant" | "TransitionComponent" | "maxSnack"
>;

export const SnackbarProvider = ({ children, ...props }: SnackbarProviderProps) => {
  const classes = useStyles();

  return (
    <NotistackSnackbarProvider
      {...props}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{
        root: classes.root,
      }}
      iconVariant={{
        success: <CheckCircleIcon height={24} width={24} />,
        warning: <InfoIcon height={24} width={24} />,
        error: <ErrorIcon height={24} width={24} />,
      }}
      TransitionComponent={Fade}
      maxSnack={3}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};
