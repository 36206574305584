import moment from "moment";
import numeral from "numeral";

export const pluralize = (count: number, noun: string, suffix = "s") => `${noun}${count !== 1 ? suffix : ""}`;

export const unslugify = (input?: string, delimiterInput?: string) => {
  if (!input) {
    return "";
  }

  const delimiter = delimiterInput || "_";

  const output = input.split(delimiter).join(" ");
  return output.charAt(0).toUpperCase() + output.slice(1);
};

export const numberWithCommas = (x: number | string) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

type MoneyStrType = (
  x: number | undefined | null,
  withDecimal?: boolean,
  moneySymbolIfNull?: boolean,
  hideDollarSign?: boolean,
) => string;

export const moneyStr: MoneyStrType = (x, withDecimal = false, moneySymbolIfNull = true, hideDollarSign = false) => {
  if (moneySymbolIfNull) {
    if (x === undefined || x === null) return withDecimal ? "$--.--" : "$--";
  } else if (x === undefined || x === null) return "--";

  const roundedNum = Math.round(x * 100) / 100;
  const cleanNum = Math.abs(roundedNum); // ensures removing infinite precision, and negative value
  let output = "";

  if (withDecimal) {
    let decStr = "";
    const splitNum = cleanNum.toString().split(".");
    // 1) no decimal
    // if num has '.' but no decimals, splitNum !== 2 and/or decimal string is empty, then no decimal, so do nothing
    if (cleanNum.toString().endsWith(".") || splitNum.length !== 2 || splitNum[1].length === 0) {
      decStr = ".00";
    } else {
      // 2) decimal is 032
      // TODO: if 3 or more digits, maybe round to 2 digits?
      const truncStr = splitNum[1].slice(0, 2); // ensure decimal string is only 2 characters or less

      // 3) decimal is 0
      // 4) decimal is 3
      decStr = truncStr.length === 1 ? `${truncStr}0` : truncStr; // ensure decimal string is exactly 2 chars

      // 5) decimal is 00
      // 6) decimal is 30
      // 7) decimal is 03
      decStr = `.${decStr}`;
    }

    output = numberWithCommas(splitNum[0]) + decStr;
  } else {
    output = numberWithCommas(Math.round(cleanNum));
  }
  return hideDollarSign ? (roundedNum < 0 ? `-${output}` : `${output}`) : roundedNum < 0 ? `-$${output}` : `$${output}`;
};

// Formats number like this:
// 100000 => $100k
// $3,000,000 => $3m
export const moneyAbbrevStr = (x: number) => {
  if (x > 1000000 || x < 1000) {
    const formattedNum = numeral(x).format("$0.00a");

    if (formattedNum.includes(".00")) {
      return numeral(x).format("$0a");
    }

    return formattedNum;
  }

  const formattedNum = numeral(x).format("$0.0a");

  if (formattedNum.includes(".0")) {
    return numeral(x).format("$0a");
  }

  return formattedNum;
};

export const percentageStr = (x: number, round: number | undefined) => {
  const useRound = typeof round === "number" ? round : 2;
  const output = Math.abs((x * 10000) / 100).toFixed(useRound);
  if (x < 0) {
    return `-${output}%`;
  }
  return `${output}%`;
};

export const titleCase = (str: string | undefined) => {
  if (typeof str === "string") {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join(" ");
  }
  return "";
};

export const absMoneyStr = (s: string) => s.replace(/^-\$/g, "$");

export const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const moneyStrToNumber = (moneyString = "") => Number(moneyString.replace("$", "").replaceAll(",", ""));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getFlagEmoji(countryCode: string) {
  // TODO: Need to return empty string if country code emoji is not found, on Windows results in a char emoji instead
  return "";

  // return countryCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)));
}
export const formatAccountNumberLastFourDots = (accountNumerLastFour?: string) =>
  accountNumerLastFour ? `...${accountNumerLastFour}` : null;

export const formatAccountNumberLastFour = (accountNumerLastFour?: string) =>
  accountNumerLastFour ? `●●●●●●${accountNumerLastFour} ` : null;

export const formatCardNumberLastFour = (cardNumerLastFour?: string) =>
  cardNumerLastFour ? `...${cardNumerLastFour}` : null;

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const getDueString = (dueString: string) => {
  const dueDate = moment(dueString);
  const daysBetween = dueDate.diff(moment(), "days");

  if (daysBetween === 1) return "Due tomorrow";
  if (daysBetween === -1) return "Due yesterday";
  if (daysBetween === 0) return "Due today";
  if (daysBetween > 0) return `Due in ${daysBetween} days`;
  if (daysBetween < 0) return `Due ${Math.abs(daysBetween)} days ago`;
};

export const isOverdue = (dueString: string) => moment(dueString).diff(moment()) < 0;
