import { useQuery } from "react-query";
import { ProductApplicationAPI } from "@ampla/api";
import { APPLICATION_STATUSES_QUERY_KEY } from "constants/queryKeys";
import { AuthInternalContext } from "../types";

const useApplicationStatuses = ({ userIsLoggedIn }: Pick<AuthInternalContext, "userIsLoggedIn">) => {
  const { data: applicationStatuses, ...applicationStatusesQuery } = useQuery(
    APPLICATION_STATUSES_QUERY_KEY,
    () => ProductApplicationAPI.statuses(),
    {
      enabled: userIsLoggedIn,
    },
  );

  return {
    // When logging out the query for the current user is removed
    // however that does not trigger a rerender hence why we need this
    applicationStatuses: userIsLoggedIn ? applicationStatuses : undefined,
    applicationStatusesQuery,
  };
};

export default useApplicationStatuses;
