const styles = {
  button: {
    svg: { color: "ampla.gray.400" },

    "@media (max-width:767px)": {
      display: "none",
    },
  },
  popover: {
    borderRadius: "4px",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "ampla.gray.100",
  },
};

export default styles;
