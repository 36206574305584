import { PaletteColorOptions } from "@mui/material";

import colors from "./colors";
import { AmplaPaletteOptions } from "./types";

export const palette: AmplaPaletteOptions = {
  primary: {
    light: colors.gray[400],
    main: colors.teal[700],
    dark: colors.teal[700],
    contrastText: colors.white,
  },
  secondary: {
    light: colors.gray[300],
    main: colors.gray[400],
    dark: colors.teal[900],
    contrastText: colors.white,
  },
  error: colors.red as PaletteColorOptions,
  success: colors.green as PaletteColorOptions,
  ampla: colors,
  text: {
    secondary: colors.gray[300],
  },
};

export default palette;
