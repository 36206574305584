import { Components } from "@mui/material/styles";

import colors from "../palette/colors";

const MuiSvgIcon: Components["MuiSvgIcon"] = {
  styleOverrides: {
    colorError: {
      color: colors.red[600],
    },
  },
};

export default MuiSvgIcon;
