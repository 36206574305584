import { SVGProps } from "react";

import withIconWrapper from "libs/icons/components/withIconWrapper";

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.0002 2C6.47616 2 1.99902 6.47714 1.99902 12.0001C1.99889 13.3135 2.25748 14.6141 2.76004 15.8276C3.26259 17.041 3.99927 18.1436 4.92799 19.0723C5.85671 20.001 6.95928 20.7377 8.17274 21.2403C9.3862 21.7428 10.6868 22.0014 12.0002 22.0013C17.5221 22.0013 22.0003 17.5241 22.0003 12.0001C22.0003 6.47714 17.5221 2 12.0002 2ZM12.9335 5.61046C13.9085 5.61046 14.195 6.17609 14.195 6.82297C14.195 7.63028 13.5492 8.37716 12.446 8.37716C11.5231 8.37716 11.0835 7.91361 11.1106 7.14589C11.1106 6.49901 11.6512 5.61046 12.9335 5.61046ZM10.4356 17.9898C9.76891 17.9898 9.28244 17.5846 9.74807 15.8085L10.5116 12.6574C10.6439 12.1532 10.6658 11.9512 10.5116 11.9512C10.3127 11.9512 9.44703 12.2991 8.93661 12.6428L8.60431 12.098C10.2241 10.7449 12.0866 9.95114 12.8835 9.95114C13.5502 9.95114 13.6606 10.7386 13.3283 11.9512L12.4533 15.2637C12.2981 15.8491 12.3648 16.0512 12.52 16.0512C12.72 16.0512 13.3742 15.8095 14.0179 15.3022L14.395 15.8085C12.8189 17.3835 11.1012 17.9898 10.4356 17.9898Z"
      fill="inherit"
    />
  </svg>
);

export default withIconWrapper(InfoIcon);
