import { makeStyles } from "libs/components/styles";
import {
  getVariantAltFontStyleDefinition,
  getVariantBaseStyleDefinition,
} from "libs/components/theme/ampla-theme/helpers/typography";
import { AMPLA_COMPONENT_NAMESPACE_SELECTOR } from "libs/components/theme/constants";

const useStyles = makeStyles((theme) => ({
  jumboCurrencyField: {
    [`&${AMPLA_COMPONENT_NAMESPACE_SELECTOR}`]: {
      "& .MuiFormLabel-root": { display: "none" },

      "& .MuiInputBase-root": {
        padding: 0,
        border: 0,
        borderRadius: 0,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.ampla.gray[400],
        backgroundColor: "unset",

        "&:focus-within": {
          borderBottomColor: theme.palette.ampla.teal[400],
        },

        "& .MuiInputAdornment-root": {
          marginLeft: 8,

          p: {
            color: theme.palette.ampla.teal[900],
            ...getVariantBaseStyleDefinition({ variant: "h3" }),
            ...getVariantAltFontStyleDefinition({ variant: "h3" }),
            textTransform: "unset",
          },
        },

        input: {
          ...getVariantBaseStyleDefinition({ variant: "h1" }),
          ...getVariantAltFontStyleDefinition({ variant: "h1" }),
          textTransform: "unset",
          paddingLeft: 0,

          "&::placeholder": {
            color: theme.palette.ampla.gray[400],
          },
        },
      },
    },
  },
}));

export default useStyles;
