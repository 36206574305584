import { makeStyles } from "libs/components/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    transition: "all .25s ease",
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: `${theme.palette.ampla.gray[100]}`,
    },
    "&:active": {
      backgroundColor: `${theme.palette.ampla.gray[200]}`,
    },
  },
  checked: {
    "& .MuiSvgIcon-root": {
      fill: theme.palette.ampla.teal[400],
    },
    "&.Mui-disabled .MuiSvgIcon-root": {
      fill: theme.palette.ampla.gray[200],
    },
  },
  indeterminate: {
    "& .MuiSvgIcon-root": {
      fill: theme.palette.ampla.teal[400],
    },
    "&.Mui-disabled .MuiSvgIcon-root": {
      fill: theme.palette.ampla.gray[200],
    },
  },
  error: {
    "& .MuiSvgIcon-root": {
      fill: theme.palette.ampla.red[500],
    },
  },
  sizeSmall: {
    width: "32px",
    height: "32px",
  },
  sizeMedium: {
    width: "38px",
    height: "38px",
  },
}));
