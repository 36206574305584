export const AP_AUTOMATION_STATE_MACHINE_STATUS = "ap-automation-state-machine-status";
export const AP_AUTOMATION_PRODUCTS_STATE = "ap-automation-products-state";
export const AP_AUTOMATION_EXPENSES_LIST = "ap-expenses-list";
export const AP_AUTOMATION_EXPENSES_CONFIG_LIST = "ap-automation-expenses-config-list";
export const AP_AUTOMATION_VENDORS_LIST = "ap-automation-vendors-list";
export const AP_AUTOMATION_CUSTOMERS_LIST = "ap-automation-customers-list";
export const AP_AUTOMATION_AMPLA_CATEGORIES_LIST = "ap-automation-ampla-categories-list";
export const AP_AUTOMATION_ENTITY_AMPLA_CATEGORIES_LIST = "ap-automation-entity-ampla-categories-list";
export const AP_AUTOMATION_SETTINGS_LIST = "ap-automation-settings-list";
export const AP_AUTOMATION_MERGE_CATEGORIES_MUTATE_KEY = "ap-automation-merge-categories";
export const AP_AUTOMATION_UPDATE_CATEGORIES_MUTATE_KEY = "ap-automation-update-categories";
export const AP_AUTOMATION_UPDATE_EXPENSES_SETTINGS_MUTATE_KEY = "ap-automation-update-expenses-settings";
export const AP_AUTOMATION_CREATE_SETUP_CUSTOMER_MUTATE_KEY = "ap-automation-create-setup-customer";
export const AP_AUTOMATION_CREATE_SETUP_SUPPLIER_MUTATE_KEY = "ap-automation-create-setup-supplier";
export const AP_AUTOMATION_CREATE_SETUP_ACCOUNT_MUTATE_KEY = "ap-automation-create-setup-account";
export const AP_AUTOMATION_UPDATE_EXPENSES_CONFIG_MUTATE_KEY = "ap-automation-update-expenses-config";
export const AP_AUTOMATION_EXPENSES_LIST_QUERY_KEY = "ap-expenses-list";
export const AP_AUTOMATION_EXPENSES_DETAIL_QUERY_KEY = "ap-expenses-detail";
export const AP_AUTOMATION_UNREAD_EMAILS_NUMBER_QUERY_KEY = "ap-automation-unread-emails-number";
export const AP_AUTOMATION_EMAILS_LIST_QUERY_KEY = "ap-automation-emails-list";
export const AP_AUTOMATION_EMAILS_ALL_LIST_QUERY_KEY = "ap-automation-emails-all-list";
export const ADDRESSES_QUERY_KEY = "addresses";
export const AGREEMENT_CONSENT_PDF_DOWNLOAD = "agreement-consent-pdf-download";
export const AGREEMENT_CONSENTS_QUERY_KEY = "agreement-consents";
export const AGREEMENT_DETAIL_QUERY_KEY = "agreement";
export const BANCO_AIRWALLEX_AUTHORIZE_EMBEDDED = "banco_airwallex_authorize_embedded";
export const APPLICATION_STATUSES_QUERY_KEY = "application-statuses";
export const BANCO_ACCOUNT_CHARGE_CARDS_QUERY_KEY = "banco-account-charge-cards";
export const BANCO_ACCOUNT_GROWTH_CARDS_QUERY_KEY = "banco-account-growth-cards";
export const BANCO_ACCOUNT_CHECKING_DEPOSIT_QUERY_KEY = "banco-account-checking-deposit";
export const BANCO_ACCOUNT_LIST_QUERY_KEY = "banco-account-list";
export const BANCO_ACCOUNT_CHECKING_RESERVE_QUERY_KEY = "banco-account-checking-reserve";
export const BANCO_ACCOUNT_LIST_FOR_REWARDS_QUERY_KEY = "banco-account-for-rewards-list";
export const BANCO_ACCOUNT_OPERATING_KEY = "banco-account-operating";
export const BANCO_ACCOUNT_RESERVE_KEY = "banco-account-reserve";
export const BANCO_ACCOUNT_DETAILS_KEY = "banco-account-details";
export const BANCO_ACCOUNT_NUMBER_QUERY_KEY = "account_number";
export const BANCO_ACCOUNT_START_THREAD_MIGRATION_QUERY_KEY = "banco-account-start-thread-migration";
export const BANCO_AUTHORIZATIONS_LIST_QUERY_KEY = "banco-authorizations";
export const BANCO_CARD_AUTHORIZATIONS_LIST_QUERY_KEY = "banco-card-authorizations-list";
export const BANCO_CARD_DETAIL_QUERY_KEY = "banco-card-detail";
export const BANCO_CARD_LIST_QUERY_KEY = "banco-card-list";
export const CREDIT_CARD_LIST_QUERY_KEY = "credit-card-list";
export const CREDIT_CARD_DETAIL_QUERY_KEY = "credit-card-detail";
export const CREDIT_CARD_SENSITIVE_DETAIL_QUERY_KEY = "credit-card-sensitive-detail";
export const CREDIT_CARD_FILTERS_QUERY_KEY = "credit-card-filters";
export const CREDIT_ACCOUNT_LIST_QUERY_KEY = "credit-account-list";
export const CREDIT_TRANSACTION_LIST_QUERY_KEY = "credit-transactions";
export const CREDIT_AUTH_PENDING_TRANSACTION_LIST_QUERY_KEY = "credit-auth-pending-transactions";
export const BANCO_CARD_FILTERS_QUERY_KEY = "banco-card-filters";
export const STATEMENTS_QUERY_KEY = "statements-list";
export const BANCO_COUNTERPARTY_CONTACT_LIST_QUERY_KEY = "banco-counterparty-contact-list";
export const BANCO_COUNTERPARTY_DELETE_QUERY_KEY = "delete-banco-counterparty";
export const BANCO_COUNTERPARTY_DETAIL_QUERY_KEY = "banco-counterparty-detail";
export const BANCO_COUNTERPARTY_LIST_QUERY_KEY = "banco-counterparty-list";
export const BANCO_COUNTERPARTY_PAYMENT_METHOD_ACCOUNT_NUMBER_QUERY_KEY =
  "banco-counterparty-payment-method-account-number";
export const BANCO_COUNTERPARTY_PAYMENT_METHOD_DETAIL_QUERY_KEY = "banco-counterparty-payment-method-detail";
export const BANCO_COUNTERPARTY_PAYMENT_METHOD_LIST_QUERY_KEY = "banco-counterparty-payment-method-list";
export const BANCO_COUNTERPARTY_PREVIEW_QUERY_KEY = "banco-counterparty-preview";
export const BANCO_COUNTERPARTY_BULK_UPLOAD_KEY = "banco-counterparty-bulk_upload";
export const BANCO_COUNTERPARTY_BULK_UPLOAD_CSV_TEMPLATE_KEY = "banco-counterparty-bulk_upload_csv_template";
export const BANCO_DAILY_BALANCES_QUERY_KEY = "banco-daily-balances";
export const BANCO_DASHBOARD_CALC_DATA_QUERY_KEY = "calc-data";
export const BANCO_DOWNLOAD_STATEMENT_PDF_QUERY_KEY = "banco-download-statement-pdf";
export const BANCO_ENTITY_DASHBOARD_QUERY_KEY = "banco-entity-dashboard";
export const BANCO_INSTRUCTION_DETAIL_QUERY_KEY = "banco-instruction-detail";
export const BANCO_INSTRUCTION_LIST_QUERY_KEY = "banco-instructions";
export const BANCO_INSTRUCTION_PENDING_LIST_QUERY_KEY = "pending-banco-instructions";
export const BANCO_INSTRUCTION_NEXT_VALID_SCHEDULE_DATA_QUERY_KEY = "banco-instruction-next-valid-schedule-date";
export const BANCO_INSTRUCTION_ESTIMATED_ARRIVAL_QUERY_KEY = "banco-estimated-arrival-date-from-scheduled-date";
export const BANCO_MONEY_IN_MONEY_OUT_QUERY_KEY = "banco-money-in-money-out";
export const BANCO_MONTHLY_STATEMENTS_LIST_QUERY_KEY = "banco-monthly-statements-list";
export const BANCO_RECURRING_PAYMENT_KEY = "banco-recurring-payment";
export const BANCO_REPAYMENT_ACCOUNT_LIST_QUERY_KEY = "banco-repayment-account-list";
export const BANCO_SIGNED_BANK_VERIFICATION_LETTER = "signed-bank-verification-letter";
export const BANCO_CHECKING_ACCOUNT_VOID_CHECK = "void-check";
export const BANCO_TRANSACTION_ADVANCE_QUERY_KEY = "banco-transaction-advance";
export const BANCO_TRANSACTION_BUYERPAYMENT_QUERY_KEY = "banco-transaction-buyerpayment";
export const BANCO_TRANSACTION_DETAIL_QUERY_KEY = "banco-transaction-detail";
export const BANCO_USER_TOKEN_VALID_QUERY_KEY = "user-token-valid";
export const BANCO_MONEY_MOVEMENT_SNAPSHOT = "banco-money-movement-snapshot";
export const CARD_DAILY_SPEND_QUERY_KEY = "card-daily-spend";
export const CARD_DETAIL_QUERY_KEY = "card-detail";
export const CARD_LIST_QUERY_KEY = "card-list";
export const CARD_PAYMENT_DETAIL_QUERY_KEY = "card-payment-detail";
export const CARD_SPEND_REPORT_QUERY_KEY = "card-payment-spend-report";
export const CODAT_ENABLED_INTEGRATIONS_BY_TYPE_QUERY_KEY = "codat-enabled-integrations-by-type";
export const CODAT_GET_COMPANY_LINK_URL_QUERY_KEY = "codat-get-company-link-url";
export const CODAT_COMPANY_LINKED_INTEGRATIONS_QUERY_KEY = "company-linked-integrations";
export const CODAT_SHOPIFY_INTEGRATION_QUERY_KEY = "codat-shopify-integration";
export const ENTITY_DETAIL_QUERY_KEY = "entity-detail";
export const ENTITY_MEMBERSHIPS_QUERY_KEY = "entity-memberships";
export const ENTITY_HOME_DASHBOARD_QUERY_KEY = "entity-home-dashboard";
export const ENTITY_ADVANCE_PAYDOWN_SUMMARY_QUERY_KEY = "entity-advance-paydown-summary";
export const FIVETRAN_LIST_QUERY_KEY = "fivetran-list";
export const FIVETRAN_INTEGRATIONS_QUERY_KEY = "fivetran-integrations";
export const FUNDING_APPLICATION_DETAIL_QUERY_KEY = "funding-application-detail";
export const MARKET_CATEGORY_QUERY_KEY = "market-categories";
export const PRODUCTION_RUN_TRANSACTION_DETAIL_QUERY_KEY = "production-run-transaction-detail";
export const USER_MANAGEMENT_ADD_USER_MUTATION_KEY = "user-management-add-user";
export const USER_MANAGEMENT_LIST_QUERY_KEY = "user-management-list";
export const USER_MANAGEMENT_ACTIVE_USERS_LIST_QUERY_KEY = "user-management-list-active-users";
export const USER_MANAGEMENT_ROLES_LIST_QUERY_KEY = "user-management-list-roles";
export const CASHBACK_REWARDS_QUERY_KEY = "cashback-rewards";

export const CHARGE_ACCOUNT_TOTAL_SPENT_QUERY_KEY = "charge-account-total-spent";

export const DOCUMENT_OF_TYPE_LIST_QUERY_KEY = "document-of-type-list";
export const DATA_SNAPSHOT_LIST_QUERY_KEY = "data-snapshot-list";

export const MONEY_ACCOUNT_DETAIL_QUERY_KEY = "money-account";

export const BILL_PAY_LIST_QUERY_KEY = "bill-pay-list";
export const BILL_PAYMENTS_LIST = "bill-payments-list";
export const BILL_PAYMENTS_PREVIEW = "bill-payments-preview";
export const BILL_PAY_DETAIL = "bill-pay-detail";
export const BILL_CHART_OF_ACCOUNTS_LIST = "bill-chart-of-accounts-list";
export const BILL_CATEGORY_LIST = "bill-pay-category-list";
export const BILL_PAY_SETTINGS_LIST = "bill-pay-settings-list";
export const BILL_PAY_CHECKLIST = "bill-pay-checklist";
export const BILL_PAY_FETCH_CODAT_INTEGRATIONS = "bill-pay-fetch-codat-integrations";
export const BILL_PAY_DELETE = "bill-pay-delete";
export const BILL_PAY_AMPLA_CODAT_COUNTERPARTY_LIST_QUERY_KEY = "bill-pay-ampla-codat-counterparty-list";
export const BILL_PAY_CODAT_COUNTERPARTY_LIST_QUERY_KEY = "bill-pay-codat-counterparty-list";
export const BILL_PAY_FETCH_INITIAL_SYNC_STATUS = "bill-pay-fetch-initial-sync-status";
export const BILL_PAY_MERGE_COUNTERPARTIES_MUTATE_KEY = "bill-pay-merge-counterparties";

export const EXPENSES_SETTINGS_LIST = "expenses-settings-list";

export const TRANSACTION_EVENT_LIST_QUERY_KEY = "transaction-event-list";
export const TRANSACTION_EVENT_STATS_QUERY_KEY = "transaction-event-stats";
export const TRANSACTION_EVENT_COUNTS_QUERY_KEY = "transaction-event-counts";
export const TRANSACTION_PR_DETAILS_QUERY_KEY = "transaction-pr-details";
export const TRANSACTION_EVENT_DASHBOARD_QUERY_KEY = "transaction-event-dashboard";
export const TRANSACTION_EVENT_TRANSACTION_AMOUNTS_QUERY_KEY = "transaction-event-transaction-amounts";

export const PAY_LATER_APPLICATION_GET_ACTIVE_KEY = "pay-later-product-application-active";
export const PAY_LATER_SETTINGS_LIST_QUERY_KEY = "pay-later-settings-list";
export const PAY_LATER_REPAYMENT_ACCOUNT = "pay-later-repayment-account";
export const PAY_LATER_SETTINGS_LIST = "pay-later-settings-list";
export const PAY_LATER_REPAYMENT_DATE = "pay-later-repayment-date";
export const PAY_LATER_ADVANCE_ID_QUERY_KEY = "pay-later-advance-id";
export const PAY_LATER_GET_FEE_VALUES_QUERY_KEY = "pay-later-get-fee-values";
export const PAY_LATER_PAYMENT_SUMMARY = "pay-later-payment-summary";
export const BILL_PAYMENT_INSTRUCTIONS = "bill-payment-instructions";
export const BILL_PAYMENT_DETAILS = "bill-payment-details";

export const INTEGRATION_ACCOUNTING_CONNECTIONS_LIST = "integration-accounting-connections-list";
export const INTEGRATION_BANK_INSTITUTIONS_LIST = "integration-bank-institutions-list";
export const INTEGRATION_BANK_CONNECTIONS_LIST = "integration-bank-connections-list";
export const INTEGRATION_AMPLA_KLAVIYO_CONNECTIONS_LIST = "integration-ampla-klaviyo-connections-list";
export const PLAID_ITEMS_LIST = "plaid-items-list";
export const PLAID_ACCOUNT_LIST = "plaid-accounts-list";
export const PLAID_LINK_FETCH = "plaid-link-fetch";

export const INTEGRATION_LIST_QUERY_KEY = "integration-list";
export const INTEGRATION_LIST_SHOPIFY_QUERY_KEY = "integration-list-legacy-shopify";

export const CHARGE_CARDS_APPLICATION_GET_ACTIVE_KEY = "charge-cards-product-application-active";

export const NET_TERMS_WHITELABEL_KEY = "net-terms-whitelabel";
export const NET_TERMS_BRAND_INVOICE_LIST_KEY = "net-terms-brand-invoice-list";
export const NET_TERMS_BRAND_INVOICE_DETAIL_KEY = "net-terms-brand-invoice-detail";
export const NET_TERMS_BRAND_LOANS_LIST_KEY = "net-terms-brand-loans-list";
export const NET_TERMS_BRAND_LOAN_DETAIL_KEY = "net-terms-brand-loan-detail";
export const NET_TERMS_BRAND_LOAN_SUMMARY_KEY = "net-terms-brand-loan-summary";
export const NET_TERMS_BRAND_CONSENT_KEY = "net-terms-brand-consent";
export const NET_TERMS_BRAND_INVITE_KEY = "net-terms-brand-invite";
export const NET_TERMS_VENDOR_CONSENT_KEY = "net-terms-vendor-consent";
export const NET_TERMS_VENDOR_APPLICATION_KEY = "net-terms-vendor-application";
export const NET_TERMS_VENDOR_BANK_ACCOUNT_KEY = "net-terms-vendor-bank-account";
export const NET_TERMS_VENDOR_BRANDS_KEY = "net-terms-vendor-brands";
export const NET_TERMS_VENDOR_CODAT_SYNC_STATUS = "net-terms-vendor-sync-status";
export const NET_TERMS_VENDOR_INVOICE_KEY = "net-terms-vendor-invoice";
export const NET_TERMS_VENDOR_INVOICE_DETAIL_KEY = "net-terms-vendor-invoice-detail";
export const NET_TERMS_VENDOR_INVOICE_AMPLA_PDF_KEY = "net-terms-vendor-invoice-ampla-pdf";
export const NET_TERMS_VENDOR_INVOICE_CODAT_PDF_KEY = "net-terms-vendor-invoice-codat-pdf";
export const NET_TERMS_VENDOR_ACCOUNTING_ACCOUNTS = "net-terms-vendor-accounting-accounts";
export const NET_TERMS_VENDOR_GET_CONFIGURATION = "net-terms-vendor-get-configuration";

export const PORTAL_REMINDERS_LIST_QUERY_KEY = "portal-reminders-list";

export const LEAD_QUERY_KEY = "lead-query-key";

export const CAPITAL_GROWTH_EXPEDITE_FEE = "capital-growth-expedite-fee";
export const CAPITAL_GROWTH_STATEMENTS = "capital-growth-statements";

export const FINANCIAL_REPORTING_TASKS = "financial-reporting-tasks";
export const FINANCIAL_REPORTING_COVENANTS = "financial-reporting-covenants";
export const FINANCIAL_REPORTING_BY_CATEGORY = "financial-reporting-by-category";
export const FINANCIAL_REPORTING_BANK_ACCOUNTS = "financial-reporting-bank-accounts";
export const FINANCIAL_REPORTING_OTHER_DOCUMENTS = "financial-reporting-other-documents";

export const NOTIFICATIONS_LIST = "notifications-list";

export const LOOM_VIDEO_OEMBED_QUERY_KEY = "loom-video-oembed";

export const CREDIT_ACCESS_KEY = "credit-access";
