import crud from "libs/api/client";
import {
  AcceptChargeCardsApprovalParams,
  ApplicationStatuses,
  ChargeCardsApplication,
  PayLaterApplication,
} from "./types";

const ProductApplicationAPI = {
  getActiveChargeCardsApplication: (): Promise<ChargeCardsApplication> =>
    crud("list", "/product_application/charge_cards/get_active/"),

  transitionChargeCardsApplication: (id: string): Promise<ChargeCardsApplication> =>
    crud("create", `/product_application/charge_cards/${id}/transition/`),

  getActivePayLaterApplication: (): Promise<PayLaterApplication> =>
    crud("list", "/product_application/paylater/get_active/"),

  submitPayLaterApplication: (id: string): Promise<PayLaterApplication> =>
    crud("create", `/product_application/paylater/${id}/submit/`),

  finishPayLaterApplication: (id: string): Promise<PayLaterApplication> =>
    crud("create", `/product_application/paylater/${id}/finish/`),

  acceptChargeCardOnboarding: ({ id, approval }: AcceptChargeCardsApprovalParams): Promise<ChargeCardsApplication> =>
    crud("create", `/product_application/charge_cards/${id}/accept_onboarding/`, approval),

  statuses: (): Promise<ApplicationStatuses> => crud("list", "/product_application/statuses/"),

  completeChargeCardsApplicationSteps: (id: string): Promise<ChargeCardsApplication> =>
    crud("update", `/product_application/charge_cards/${id}/complete_get_started_steps/`),
};

export default ProductApplicationAPI;
