import { ForwardedRef, forwardRef, ReactElement, useMemo } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import classNames from "classnames";

// FIXME: Move the utility functions from the component library
// to a shared `@ampla/utils` lib to avoid the circular dependency
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CheckCircleIcon, ErrorIcon, Info, PlaceholderIcon } from "@ampla/icons";

import { Tooltip } from "libs/components/components/Tooltip";
import { useStyles } from "./Banner.styles";
import { BannerProps, BannerVariant } from "./Banner.types";

const getDefaultStartIconForVariant = (variant: BannerVariant) =>
  variant === "error" ? (
    <ErrorIcon />
  ) : variant === "warning" || variant === "info" ? (
    <Info />
  ) : variant === "success" ? (
    <CheckCircleIcon />
  ) : variant === "insights" ? (
    <PlaceholderIcon />
  ) : undefined;

const Banner = forwardRef(
  (
    {
      children,
      variant = "info",
      position = "unset",
      startIcon: startIconProp = true,
      ctaLabel,
      ctaUrl,
      ctaOnClick,
      ctaProps,
      onClose,
      onCloseTooltipProps,
    }: BannerProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const classes = useStyles();

    const showStartIcon = !!startIconProp;
    const showCTAButton = !!ctaLabel;
    const showCloseButton = !!onClose;
    const showOnCloseTooltip = !!onCloseTooltipProps;

    const startIcon: ReactElement | undefined = useMemo(
      () => (typeof startIconProp === "boolean" ? getDefaultStartIconForVariant(variant) : startIconProp),
      [variant, startIconProp],
    );

    return (
      <div ref={ref} className={classNames(classes.root, variant, position)}>
        {showStartIcon && <div className="start-icon-container">{startIcon}</div>}
        <div className="content">{children}</div>
        {showCTAButton && (
          <Button variant="text" className="cta-button" href={ctaUrl} onClick={ctaOnClick} {...ctaProps}>
            {ctaLabel}
          </Button>
        )}
        {showCloseButton &&
          (showOnCloseTooltip ? (
            <Tooltip {...onCloseTooltipProps}>
              <IconButton className="close-button" size="small" onClick={onClose}>
                <CloseIcon className="close-icon" />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton className="close-button" size="small" onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          ))}
      </div>
    );
  },
);

export default Banner;
