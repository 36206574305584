import { makeStyles } from "libs/components/styles";
import { getVariantBaseStyleDefinition } from "../../theme/ampla-theme/helpers/typography";

export const useStyles = makeStyles((theme) => ({
  clearButton: {
    cursor: "pointer",
    outline: "inherit",
    background: "none",
    border: "none",
    padding: 0,
    margin: 0,
  },
  link: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
  },
  inline: {
    display: "inline-flex",
  },
  icon: {
    display: "inline-flex",
    "& svg": {
      fontSize: "1em",
    },
  },
  disabled: {
    pointerEvents: "none",
  },
  backgroundLight: {
    color: theme.palette.ampla.teal[700],
    "&:hover": {
      color: theme.palette.ampla.teal[800],
    },
    "&:active": {
      color: theme.palette.ampla.teal[600],
    },
    "&$disabled": {
      color: theme.palette.ampla.gray[300],
    },
  },
  backgroundDark: {
    color: "white",
    "&:hover": {
      color: theme.palette.ampla.teal[300],
    },
    "&:active": {
      color: theme.palette.ampla.teal[200],
    },
    "&$disabled": {
      color: theme.palette.ampla.gray[400],
    },
  },
  backgroundColorful: {
    color: theme.palette.ampla.teal[900],
    "&:hover": {
      color: theme.palette.ampla.teal[900],
    },
    "&:active": {
      color: theme.palette.ampla.teal[900],
    },
    "&$disabled": {
      color: theme.palette.ampla.gray[300],
    },
  },
  linkSizeSmall: { ...getVariantBaseStyleDefinition({ variant: "body3" }) },
  linkSizeMedium: { ...getVariantBaseStyleDefinition({ variant: "body2" }) },
  linkSizeLarge: { ...getVariantBaseStyleDefinition({ variant: "body1" }) },
}));
