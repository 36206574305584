import { Banner } from "@ampla/ui-components";
import { useState } from "react";
import { useLocation } from "react-router";

const ShopifyMigrationBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const location = useLocation();

  return showBanner ? (
    <Banner
      variant="warning"
      ctaLabel={location.pathname.includes("/settings") ? "Re-Link Shopify Stores" : "Go to Integration Settings Page"}
      ctaUrl="/settings?migrateShopify=true"
      onClose={() => setShowBanner(false)}
    >
      Please relink your Shopify stores so that Ampla can continuously evaluate your business for credit and give you
      insights into cash flow and financial performance.
    </Banner>
  ) : null;
};

export default ShopifyMigrationBanner;
