import React, { ForwardedRef, forwardRef } from "react";
import { Close } from "@mui/icons-material";
import { Box, Dialog, useMediaQuery, useTheme } from "@mui/material";

import Button from "../Button/Button";
import { IconButton } from "../IconButton";
import Typography from "../Typography/Typography";
import { ModalProps, ModalSize } from "./Modal.types";

const modalSizeMap = {
  small: "520px",
  medium: "696px",
  large: "1040px",
};

const Modal = forwardRef(
  (
    { children, title, onClose, showCloseButton = true, size = "large", open, HeaderProps, ...props }: ModalProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const handleClose = () => onClose?.();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isFullScreen = isMobile && size !== "small";
    const childProps = { ...props, size };

    return (
      <Dialog
        ref={ref}
        open={open}
        onClose={handleClose}
        fullWidth
        fullScreen={isFullScreen}
        PaperProps={{
          sx: {
            maxWidth: modalSizeMap[size],
            maxHeight: isFullScreen ? "100%" : "min(776px, calc(100% - 64px))",
          },
        }}
        {...props}
      >
        {(title || showCloseButton) && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding={title ? "24px 32px" : "24px 32px 0 0"}
            {...HeaderProps}
          >
            <Typography altFont variant="h4">
              {title}
            </Typography>
            {showCloseButton && (
              <IconButton sx={{ minWidth: 0 }} onClick={handleClose}>
                <Close />
              </IconButton>
            )}
          </Box>
        )}
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child) && ["ModalBody", "ModalFooter"].includes(child.props.__TYPE)) {
            return React.cloneElement(child, childProps);
          }
          return child;
        })}
      </Dialog>
    );
  },
);

export default Modal;
