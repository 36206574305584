import { CssBaseline } from "@ampla/ui-components";
import "apps/portal/helpers/webfonts";
import "apps/portal/helpers/yupValidators";
import "react-loading-skeleton/dist/skeleton.css";
import Routes from "routes/Routes";
import { AppShell } from "./components/AppShell";
import { Providers } from "./components/context/Providers";

export default function App() {
  return (
    <Providers>
      <CssBaseline />
      <div className="App">
        <AppShell>
          <Routes />
        </AppShell>
      </div>
    </Providers>
  );
}
