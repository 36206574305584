import { makeStyles } from "libs/components/styles";

export const useStyles = makeStyles(() => ({
  textContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    height: 140,
    width: "100%",
  },
}));
