import { BRAND_CONFIG, Brand, BrandConfigItem } from "constants/brands";
import { SESSION_WIDGET_SOURCE_KEY, SESSION_WIDGET_RETURN_URL_KEY } from "constants/storageKeys";

export const getWidgetReturnUrl = () => {
  const source = sessionStorage.getItem(SESSION_WIDGET_SOURCE_KEY);

  if (source) {
    const redirectUrlFromSession = sessionStorage.getItem(SESSION_WIDGET_RETURN_URL_KEY);
    const brandConfig: BrandConfigItem = BRAND_CONFIG[source as Brand] || BRAND_CONFIG.default;

    return redirectUrlFromSession || `${brandConfig.url}?ref=ampla`;
  }
};

export const getPartnerBrandSettings = () => {
  const source = sessionStorage.getItem(SESSION_WIDGET_SOURCE_KEY);
  if (source) {
    const brandConfig: BrandConfigItem = BRAND_CONFIG[source as Brand] || BRAND_CONFIG.default;
    return brandConfig;
  }
  return null;
};
