import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { usePrevious } from "@react-hookz/web";
import camelcaseKeys from "camelcase-keys";
import PropTypes from "prop-types";

import { BrandRoles, FeatureFlags } from "@ampla/api";
import { useMediaQuery } from "@ampla/ui-components";
import { isDemoEnv } from "@ampla/utils";

import { useAuthState } from "components/context/AuthContextProvider";
import { getDaysBetweenNowAndDate } from "helpers/date";
import { isLegacyShopifyIntegration } from "helpers/integrations";
import { isPathMatchOfAny } from "helpers/url";
import {
  activeEntityHasNetTermsSellerInEnabledState,
  activeEntityHasProductBankingInActiveState,
  activeEntityHasProductBankingInApplyingState,
  activeEntityHasProductChargeCardsInApplyingState,
  activeEntityHasProductFundingInActiveState,
  activeEntityHasProductFundingInApplyingState,
  userHasFeatureFlag,
  userIsStaff,
} from "routes/helpers/validators";

const APP_LAYOUT_FREE_ROUTES = [
  /^\/signup*/,
  /^\/application*/,
  /^\/connect-integration*/,
  /^\/connect-ads-integration*/,
  /^\/connect-shopify-integration*/,
  /^\/apply\/*/,
  /^\/account\/activate*/,
  /^\/account\/\w*_result\b/,
  /^\/send-money*/,
  /^\/send-payment*/,
  /^\/international-wire*/,
  /^\/rewards\/redeem-cashback\/*/,
  /^\/banking\/[A-Za-z0-9]*\/cards\/create*/,
  /^\/banking\/[A-Za-z0-9]*\/cards\/[^/]+\/confirm*/,
  /^\/cards\/create*/,
  /^\/cards\/[^/]+\/confirm*/,
  /^\/add-a-bill*/,
  /^\/add-funds*/,
  /^\/agreement*/,
  /^\/recipients\/add*/,
  /^\/recipients\/[A-Za-z0-9]/,
  /^\/bill-add*/,
  /^\/bill-pay\/onboarding*/,
  /^\/bill-schedule*/,
  /^\/bill-pay\/edit\/[A-Za-z0-9]*/,
  /^\/bill-pay\/extend-payment-terms*/,
  /^\/corporate-card\/make-payment\/*/,
  /^\/corporate-card\/recurring-payment\/*/,
  /^\/corporate-card\/onboarding\/*/,
  /^\/corporate-card\/cards\/create*/,
  /^\/corporate-card\/cards\/[^/]+\/confirm*/,
  /^\/corporate-card\/expenses-onboarding\/*/,
  /^\/line-of-credit\/cards\/create*/,
  /^\/line-of-credit\/cards\/[^/]+\/confirm*/,
  /^\/thread-agreements*/,
  /^\/line-of-credit\/setup-recurring-advance/,
  "/not-authorized",
  "/phone-verification",
  "/pay-later/beta",
  /^\/banking\/additional-documentation\/*/,
  /^\/(banking|corporate\-card)\/documents-request\/?.*/,
  /^\/settings\/financial-integration*/,
  /^\/pay-later-setup*/,
  /^\/ach-transfer*/,
  /^\/ach-transfer\/amount*/,
  /^\/ach-transfer\/review*/,
  /^\/deposit-money*/,
  /^\/deposit-money\/details*/,
  /^\/deposit-money\/review*/,
  /^\/corporate-card\/about*/,
  /^\/ars\/vendor\/signup.*/,
  /^\/ars\/vendor\/onboarding.*/,
  /^\/ars\/vendor\/setup.*/,
  /^\/ars\/brand\/invoices\/.*\/payment.*/,
  /^\/ars\/brand\/signup\/.*/,
  /^\/ars\/brand\/setup.*/,
  "/international-payments-onboarding",
  "/international-payments-onboarding-rfi",
  "/transfer-between-accounts",
];

const APP_HEADER_FREE_ROUTES = [...APP_LAYOUT_FREE_ROUTES];

const APP_MENU_FREE_ROUTES = [...APP_LAYOUT_FREE_ROUTES];

const APP_FOOTER_FREE_ROUTES = [...APP_LAYOUT_FREE_ROUTES, "/self-reporting"];

const MIN_DAYS_OUTDATED_PROFILE_BANNER = 30;

const LayoutContext = createContext({
  isMenuOpen: false,
  isSettingsMenuOpen: false,
  isProfileMenuOpen: false,
});

export const LayoutContextProvider = ({ children, ...props }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);

  const isMobileLayout = useMediaQuery("(max-width:707px)");
  const isDesktopLayout = useMediaQuery("(min-width:708px)");

  const { user, userIsLoggedIn } = useAuthState();

  const userIsBrandOwner = user?.brand_role === BrandRoles.BrandOwner;

  const hasBankingEnabled = activeEntityHasProductBankingInActiveState({ user });

  const EXTRA_FREE_ROUTES = hasBankingEnabled ? [/^\/request-funding*/] : [];

  const userLoggedIn = userIsLoggedIn && user;

  useEffect(() => {
    if (!userLoggedIn) {
      setIsMenuOpen(false);
      setIsNotificationMenuOpen(false);
      setIsProfileMenuOpen(false);
      setIsAccountMenuOpen(false);
      setIsAdminMenuOpen(false);
      setIsNotificationMenuOpen(false);
    }
  }, [userLoggedIn]);

  const isSmallViewport = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    // Close menu when viewport changes to small
    if (isSmallViewport) {
      setIsMenuOpen(false);
    }
  }, [isSmallViewport]);

  const pathSplit = location.pathname.split("/");
  const previousPath = usePrevious(pathSplit[1]);

  useEffect(() => {
    // Scroll to top when user navigates to a new page
    if (previousPath !== pathSplit[1]) document.body.scrollTo(0, 0);
  }, [location]);

  const showAppHeader =
    userLoggedIn && !isPathMatchOfAny(window.location.pathname, [...APP_HEADER_FREE_ROUTES, ...EXTRA_FREE_ROUTES]);

  const showAppMenu =
    userLoggedIn && !isPathMatchOfAny(window.location.pathname, [...APP_MENU_FREE_ROUTES, ...EXTRA_FREE_ROUTES]);

  const showBankingApplicationBanner =
    userLoggedIn &&
    userIsBrandOwner &&
    activeEntityHasProductBankingInApplyingState({ user }) &&
    !activeEntityHasProductChargeCardsInApplyingState({ user }) &&
    !activeEntityHasProductFundingInApplyingState({ user }) &&
    !activeEntityHasNetTermsSellerInEnabledState({ user }) &&
    !isPathMatchOfAny(window.location.pathname, [...APP_MENU_FREE_ROUTES, ...EXTRA_FREE_ROUTES]);

  const showFundingApplicationBanner =
    userLoggedIn &&
    userIsBrandOwner &&
    activeEntityHasProductFundingInApplyingState({ user }) &&
    !isPathMatchOfAny(window.location.pathname, [...APP_MENU_FREE_ROUTES, ...EXTRA_FREE_ROUTES]);

  const showChargeCardApplicationBanner =
    userLoggedIn &&
    userIsBrandOwner &&
    !activeEntityHasProductFundingInApplyingState({ user }) &&
    activeEntityHasProductChargeCardsInApplyingState({ user }) &&
    !isPathMatchOfAny(window.location.pathname, [...APP_MENU_FREE_ROUTES, ...EXTRA_FREE_ROUTES]);

  const showStaffAccountBanner = userLoggedIn && userIsStaff({ user });

  const showOutdatedProfileBanner =
    isDemoEnv() && userLoggedIn && getDaysBetweenNowAndDate(user.date_joined) >= MIN_DAYS_OUTDATED_PROFILE_BANNER;

  const showAppFooter =
    userLoggedIn && !isPathMatchOfAny(window.location.pathname, [...APP_FOOTER_FREE_ROUTES, ...EXTRA_FREE_ROUTES]);

  const showBrandSwitcher = !isDemoEnv() && userLoggedIn;

  const showMigrateShopifyBanner = useMemo(() => {
    const userActiveIntegrations = camelcaseKeys(user?.active_entity_integrations ?? [], { deep: true });
    return (
      userLoggedIn &&
      userHasFeatureFlag(user, FeatureFlags.ShopifyMigrationBanner) &&
      activeEntityHasProductFundingInActiveState({ user }) &&
      userActiveIntegrations.some((integration) => isLegacyShopifyIntegration(integration))
    );
  }, [user, userLoggedIn]);

  return (
    <LayoutContext.Provider
      value={{
        isMenuOpen,
        setIsMenuOpen,
        isSettingsMenuOpen,
        setIsSettingsMenuOpen,
        isProfileMenuOpen,
        setIsProfileMenuOpen,
        isAccountMenuOpen,
        setIsAccountMenuOpen,
        isAdminMenuOpen,
        setIsAdminMenuOpen,
        isNotificationMenuOpen,
        setIsNotificationMenuOpen,
        showAppHeader,
        showAppMenu,
        showBankingApplicationBanner,
        showFundingApplicationBanner,
        showChargeCardApplicationBanner,
        showStaffAccountBanner,
        showOutdatedProfileBanner,
        showMigrateShopifyBanner,
        showAppFooter,
        showBrandSwitcher,
        isMobileLayout,
        isDesktopLayout,
      }}
      {...props}
    >
      {children}
    </LayoutContext.Provider>
  );
};

LayoutContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useLayoutState = () => {
  const {
    isMenuOpen,
    setIsMenuOpen,
    isSettingsMenuOpen,
    setIsSettingsMenuOpen,
    isProfileMenuOpen,
    setIsProfileMenuOpen,
    isAccountMenuOpen,
    setIsAccountMenuOpen,
    isAdminMenuOpen,
    setIsAdminMenuOpen,
    isNotificationMenuOpen,
    setIsNotificationMenuOpen,
    showAppHeader,
    showAppMenu,
    showBankingApplicationBanner,
    showFundingApplicationBanner,
    showChargeCardApplicationBanner,
    showStaffAccountBanner,
    showOutdatedProfileBanner,
    showMigrateShopifyBanner,
    showAppFooter,
    showBrandSwitcher,
    isMobileLayout,
    isDesktopLayout,
  } = useContext(LayoutContext);
  return {
    isMenuOpen,
    setIsMenuOpen,
    isSettingsMenuOpen,
    setIsSettingsMenuOpen,
    isProfileMenuOpen,
    setIsProfileMenuOpen,
    isAccountMenuOpen,
    setIsAccountMenuOpen,
    isAdminMenuOpen,
    setIsAdminMenuOpen,
    isNotificationMenuOpen,
    setIsNotificationMenuOpen,
    showAppHeader,
    showAppMenu,
    showBankingApplicationBanner,
    showFundingApplicationBanner,
    showChargeCardApplicationBanner,
    showStaffAccountBanner,
    showOutdatedProfileBanner,
    showMigrateShopifyBanner,
    showAppFooter,
    showBrandSwitcher,
    isMobileLayout,
    isDesktopLayout,
  };
};
