import { ForwardedRef, forwardRef } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import { Box } from "@mui/material";

import { IconButton } from "libs/components/components/IconButton";
import { Typography } from "libs/components/components/Typography";
import styles from "./PanelHeader.styles";
import { PanelHeaderProps } from "./PanelHeader.types";

const PanelHeader = forwardRef(
  ({ title, badge, startIcon, endIcon, onClose, sx }: PanelHeaderProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Box ref={ref} sx={{ ...styles.root, ...sx }}>
        <Box sx={styles.leftAlign}>
          {endIcon && <Box sx={styles.headerIconContainer}>{endIcon}</Box>}
          <Typography variant="h4" altFont>
            {title}
          </Typography>
          {startIcon && <Box sx={styles.headerIconContainer}>{startIcon}</Box>}
          {badge}
        </Box>
        {onClose && (
          <IconButton onClick={onClose} size="medium">
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    );
  },
);

export default PanelHeader;
