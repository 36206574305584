import { AuthAPI } from "@ampla/api";
import { LoginResponse, LoginRequest, MagicAuthLoginRequest } from "@ampla/api";
import { useMutation } from "react-query";
import { AuthInternalContext } from "../types";

const useLogin = ({ user, setUserIsLoggedIn }: Pick<AuthInternalContext, "user" | "setUserIsLoggedIn">) => {
  const { mutate: login, ...loginMutation } = useMutation<LoginResponse, any, LoginRequest & MagicAuthLoginRequest>(
    ({ email, password, token }) => {
      if (token) return AuthAPI.magic_auth_login({ token });
      return AuthAPI.login({ email, password });
    },
    {
      onSuccess: () => {
        setUserIsLoggedIn(true);
      },
    },
  );

  return {
    login,
    loginMutation: {
      ...loginMutation,
      isLoading: loginMutation.isLoading,
    },
  };
};

export default useLogin;
