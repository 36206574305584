import { useTheme } from "@ampla/ui-components";

type SpacerProps = {
  size?: number;
  className?: string;
};

const Spacer = ({ size = 1, ...otherProps }: SpacerProps) => {
  const theme = useTheme();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div style={{ height: theme.spacing(size), width: "100%", flexShrink: 0 }} {...otherProps} />;
};

export default Spacer;
