import { useContext } from "react";
import { AuthContext } from "../AuthContextProvider";
import { AuthContextType } from "../types";

const useAuthState = () => {
  const context = useContext(AuthContext) as AuthContextType;
  return context;
};

export default useAuthState;
