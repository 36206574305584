import * as React from "react";
import { MouseEvent, ReactNode, useState } from "react";
import { MoreVert } from "@mui/icons-material";
import { Tabs as MuiTabs, Popover } from "@mui/material";
import classnames from "classnames";

import { Typography } from "libs/components/components/Typography";
import { useStyles } from "./TabsOverflowMenu.styles";

interface TabsOverflowMenu {
  disabled?: boolean;
  children?: ReactNode;
  color?: "light" | "dark";
  variant?: "primary" | "secondary";
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
}

const TabsOverflowMenu = (props: TabsOverflowMenu) => {
  const { disabled, children, color, variant, onChange } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleButtonClick = (event: MouseEvent<HTMLParagraphElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSelect = (event: React.ChangeEvent<{}>, value: any) => {
    handleCloseMenu();
    onChange?.(event, value);
  };

  return (
    <>
      <Typography
        variant="label2"
        onClick={handleButtonClick}
        className={classnames(classes.root, "TabsOverflowMenu", variant, color, { [classes.disabled]: disabled })}
      >
        More <MoreVert />
      </Typography>

      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ paper: classes.paper }}
        elevation={1}
      >
        <MuiTabs value={false} orientation="vertical" onChange={handleSelect}>
          {children}
        </MuiTabs>
      </Popover>
    </>
  );
};

export default TabsOverflowMenu;
