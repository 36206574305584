import { SVGProps } from "react";

import withIconWrapper from "./withIconWrapper";

const AmplaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 132 133" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M81.2001 0H50.8801L0.410156 132.85H25.5502L87.3701 87.08V87.19L100.08 77.78L90.9001 97.7L102.7 132.85H131.62L81.2001 0ZM37.0001 99.82L66.0001 22.36L84.7701 79.25L37.0001 99.82Z" />
  </svg>
);

export default withIconWrapper(AmplaIcon);
