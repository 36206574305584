import { Components } from "@mui/material/styles";

const MuiTabs: Components["MuiTabs"] = {
  styleOverrides: {
    root: {
      minHeight: "34px",
      alignItems: "center",

      "& .MuiTabs-indicator": {
        display: "none",
      },

      "& .hidden": {
        display: "none",
      },
    },
  },
};

export default MuiTabs;
