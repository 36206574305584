export const CREDIT_LIMIT_REACHED_WARNING_SHOWN_KEY = "credit-limit-reached-warning-shown";

export const COLLECTION_PERCENTAGE_INCREASE_WARNING_SHOWN_KEY = "collection-percentage-increase-warning-shown";

export const ANALYTICS_TAB_CLICKED = "analytics-tab-clicked";

export const USER_SIGNUP_FROM_PAID_CHANNEL = "user-signup-from-paid-channel";

export const PORTAL_LOGIN_SUCCESS = "portal_login_success";

export const PORTAL_SUPPORT_BUTTON_CLICKED = "portal_support_button_clicked";

export const PORTAL_AUTOMATIC_REDIRECTION = "portal_automatic_redirection";

export const PORTAL_HOME_FUND_ACCOUNT_BUTTON_CLICKED = "portal_home_fund_account_button_clicked";

export const PORTAL_HOME_BANKING_TAB_CLICKED = "portal_home_banking_tab_clicked";

export const PORTAL_MENU_ACCOUNTS_OPTION_CLICKED = "portal_menu_accounts_option_clicked";

export const ACCOUNTS_SELECT_ACCOUNT_DROP_DOWN_CLICKED = "accounts_select_account_drop_down_clicked";

export const APPLICATION_LETS_GO_BUTTON_CLICKED = "application_lets_go_button_clicked";

export const PORTAL_MENU_TRANSACTIONS_OPTION_CLICKED = "portal_menu_transactions_option_clicked";

// Signup

export const SIGNUP_CREATE_LEAD_SUBMIT_SUCCESS = "signup_create_lead_submit_success";

export const SIGNUP_CREATE_ACCOUNT_SUBMIT_BUTTON_CLICKED = "signup_create_account_submit_button_clicked";

export const SIGNUP_CREATE_ACCOUNT_SUBMIT_ERROR = "signup_create_account_submit_error";

export const SIGNUP_CREATE_ACCOUNT_SUBMIT_SUCCESS = "signup_create_account_submit_success";

export const SIGNUP_BUSINESS_PROFILE_WEBSITE_INPUT_FILLED = "signup_business_profile_website_input_filled";

export const SIGNUP_BUSINESS_PROFILE_WEBSITE_INPUT_INVALID = "signup_business_profile_website_input_invalid";

export const SIGNUP_BUSINESS_PROFILE_SUBMIT_BUTTON_CLICKED = "signup_business_profile_submit_button_clicked";

export const SIGNUP_BUSINESS_PROFILE_SUBMIT_ERROR = "signup_business_profile_submit_error";

export const SIGNUP_BUSINESS_PROFILE_SUBMIT_SUCCESS = "signup_business_profile_submit_success";

export const SIGNUP_BUSINESS_ADDRESS_SUBTMIT_SUCCESS = "signup_business_address_submit_success";

export const SIGNUP_PRODUCT_SELECTION_SUBMIT = "signup_product_selection_submit";

// Pay Later Application
export const PAY_LATER_APPLICATION_MISSING_REQUIREMENT = "pay_later_application_missing_requirement";

export const PAY_LATER_APPLICATION_CLOSE_BUTTON_CLICKED = "pay_later_application_close_button_clicked";

export const PAY_LATER_APPLICATION_SUBMIT_BUTTON_CLICKED = "pay_later_application_submit_button_clicked";

export const PAY_LATER_APPLICATION_SUBMIT_ERROR = "pay_later_application_submit_error";

export const PAY_LATER_APPLICATION_SUBMIT_SUCCESS = "pay_later_application_submit_success";

export const PAY_LATER_BETA_SEND_CODE_SUBMIT_BUTTON_CLICKED = "pay_later_beta_send_code_submit_button_clicked";

// Phone verification

export const PHONE_NUMBER_VERIFICATION_SEND_CODE_SUBMIT_BUTTON_CLICKED =
  "phone_number_verification_send_code_submit_button_clicked";

export const PHONE_NUMBER_VERIFICATION_VERIFY_RESEND_CODE_BUTTON_CLICKED =
  "phone_number_verification_verify_resend_code_button_clicked";

export const PHONE_NUMBER_VERIFICATION_VERIFY_SUBMIT_BUTTON_CLICKED =
  "phone_number_verification_verify_submit_button_clicked";

// Transactions page

export const TRANSACTIONS_TRANSACTION_TABLE_ROW_CLICKED = "transactions_transaction_table_row_clicked";

export const TRANSACTIONS_TRANSACTION_DETAILS_RECIPIENT_CLICKED = "transactions_transaction_details_recipient_clicked";

export const TRANSACTIONS_EXPORT_TRANSACTIONS_BUTTON_CLICKED = "transactions_export_transactions_button_clicked";

// Send money

export const SEND_MONEY_RECIPIENT_NEXT_CLICK = "send_money_recipient_next_click";

export const SEND_MONEY_PAYMENT_METHOD_NEXT_CLICK = "send_money_payment_method_next_click";

export const SEND_MONEY_ROUTING_NUMBER_INPUT = "send_money_routing_number_input";

export const SEND_MONEY_ROUTING_NUMBER_ERROR = "send_money_routing_number_error";

export const SEND_MONEY_ACCOUNT_NUMBER_INPUT = "send_money_account_number_input";

export const SEND_MONEY_ACCOUNT_NUMBER_ERROR = "send_money_account_number_error";

export const SEND_MONEY_RECIPIENT_DETAILS_NEXT_CLICK = "send_money_recipient_details_next_click";

export const SEND_MONEY_AMOUNT_INPUT_CHANGED = "send_money_amount_input_changed";

export const SEND_MONEY_AMOUNT_ERROR = "send_money_amount_error";

export const SEND_MONEY_MEMO_INPUT_CHANGED = "send_money_memo_input_changed";

export const SEND_MONEY_MEMO_ERROR = "send_money_memo_error";

export const SEND_MONEY_AMOUNT_NEXT_CLICK = "send_money_amount_next_click";

export const SEND_MONEY_SEND_CLICK = "send_money_send_click";

export const SEND_MONEY_CLOSE_CLICK = "send_money_close_click";

export const PODSCRIBE_SIGNUP_VISITED = "signup";

// Shopify Migration

export const MIGRATE_SHOPIFY_CONNECTION_BUTTON_CLICK = "migrate_shopify_connection_button_click";

export const NEW_SHOPIFY_CONNECTION_BUTTON_CLICK = "new_shopify_connection_button_click";

// Application

export const APPLICATION_CLOSE_BUTTON_CLICKED = "application_close_button_clicked";

export const APPLICATION_SUBMIT_BUTTON_CLICKED = "application_submit_button_clicked";

export const APPLICATION_SUBMIT_SUCCESS = "application_submit_success";

export const APPLICATION_SUBMIT_ERROR = "application_submit_error";

export const BANCO_APPLICATION_BENEFICIAL_OWNERS_OWNER_UPDATED = "banco_application_beneficial_owners_owner_updated"; // ToDo: Implement

export const BANCO_APPLICATION_BENEFICIAL_OWNER_SHARE_BUTTON_CLICKED =
  "banco_application_beneficial_owner_share_button_clicked";

export const BANCO_APPLICATION_BENEFICIAL_OWNER_SHARE_LINK_COPIED =
  "banco_application_beneficial_owner_share_link_copied";

export const BANCO_APPLICATION_ADDITIONAL_DOCUMENTS_DOCUMENT_UPLOADED =
  "banco_application_additional_documents_document_uploaded";

export const BANCO_APPLICATION_ADDITIONAL_DOCUMENTS_CLOSE_BUTTON_CLICKED =
  "banco_application_additional_documents_close_button_clicked";
