import { lazy } from "react";
import {
  activeEntityHasBillPayInActiveState,
  activeEntityHasBillPayInEnabledState,
  activeEntityHasInsightsV1Enabled,
  activeEntityHasInsightsV2Enabled,
  activeEntityHasInsightsV3Enabled,
  activeEntityHasProductBankingInActiveState,
  activeEntityHasProductBankingInApplyingState,
  activeEntityHasProductBankingInDisabledState,
  activeEntityHasProductBankingInOnboardingState,
  activeEntityHasProductBankingInReviewingState,
  activeEntityHasProductPayLaterInOnboardingOrActiveState,
  everyValidator,
  negateValidator,
  oneOfValidator,
  userBancoApplicationHasBeenDenied,
  userHasAddBillPermission,
  userHasAddPaymentPermission,
  userHasBillPaySetupPermission,
  userHasChangeBillPermission,
  userHasPayLaterSetupPermission,
  userHasViewBillPermission,
} from "./helpers/validators";

import { RouteDeclaration } from "./types";

const BillForm = lazy(() => import("pages/BillForm"));
const BillPay = lazy(() => import("pages/BillPay"));
const BillPayOnboarding = lazy(() => import("pages/BillPayOnboarding"));
const BillSchedule = lazy(() => import("pages/BillSchedule/BillSchedule"));
const PayLaterSetup = lazy(() => import("pages/PayLaterSetup"));
const PayLaterExtendPaymentsTermsPage = lazy(() => import("pages/PayLaterExtendPaymentsTermsPage"));

export const BILL_PAY: RouteDeclaration = {
  name: "Bill Pay",
  path: "/bill-pay",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    oneOfValidator(
      everyValidator(
        activeEntityHasBillPayInEnabledState,
        activeEntityHasProductBankingInActiveState,
        userHasViewBillPermission,
      ),
      everyValidator(activeEntityHasProductBankingInReviewingState, negateValidator(userBancoApplicationHasBeenDenied)),
      activeEntityHasProductBankingInOnboardingState,
      activeEntityHasProductBankingInApplyingState,
      everyValidator(
        activeEntityHasProductBankingInDisabledState,
        oneOfValidator(
          activeEntityHasInsightsV1Enabled,
          activeEntityHasInsightsV2Enabled,
          activeEntityHasInsightsV3Enabled,
        ),
        negateValidator(userBancoApplicationHasBeenDenied),
      ),
    ),
  ],
  PageComponent: BillPay,
};

export const BILL_PAY_ONBOARDING: RouteDeclaration = {
  name: "Bill Pay Onboarding",
  path: "/bill-pay/onboarding",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasBillPayInEnabledState, userHasBillPaySetupPermission],
  PageComponent: BillPayOnboarding,
};

export const BILL_PAY_INBOX: RouteDeclaration = {
  name: "Bill Pay",
  path: "/bill-pay/inbox",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasBillPayInEnabledState, userHasViewBillPermission],
  PageComponent: BillPay,
};

export const BILL_PAY_PAYMENTS: RouteDeclaration = {
  name: "Bill Pay",
  path: "/bill-pay/payments",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasBillPayInEnabledState, userHasViewBillPermission],
  PageComponent: BillPay,
};

export const BILL_PAY_DETAIL: RouteDeclaration = {
  name: "Bill Detail",
  path: "/bill-pay/bill/:id",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [activeEntityHasBillPayInActiveState, userHasViewBillPermission],
  PageComponent: BillPay,
};

export const BILL_PAY_ADD: RouteDeclaration = {
  name: "Add Bill",
  path: "/bill-add",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [activeEntityHasBillPayInActiveState, userHasAddBillPermission],
  PageComponent: BillForm,
};

export const BILL_PAY_EDIT: RouteDeclaration = {
  name: "Edit Bill",
  path: "/bill-pay/edit/:id",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [activeEntityHasBillPayInActiveState, userHasChangeBillPermission],
  PageComponent: BillForm,
};

export const BILL_PAY_SCHEDULE: RouteDeclaration = {
  name: "Schedule Payments",
  path: "/bill-schedule",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [activeEntityHasBillPayInActiveState, userHasAddPaymentPermission],
  PageComponent: BillSchedule,
};

export const PAY_LATER_SETUP: RouteDeclaration = {
  name: "Pay Later Setup",
  path: "/pay-later-setup",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasProductPayLaterInOnboardingOrActiveState, userHasPayLaterSetupPermission],
  PageComponent: PayLaterSetup,
};

export const PAY_LATER_EXTEND_PAYMENT_TERMS_PAGE: RouteDeclaration = {
  name: "Extend Payment Terms",
  path: "/bill-pay/extend-payment-terms",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: PayLaterExtendPaymentsTermsPage,
};

const BILL_PAY_ROUTES = [
  BILL_PAY,
  BILL_PAY_ONBOARDING,
  BILL_PAY_INBOX,
  BILL_PAY_ADD,
  BILL_PAY_EDIT,
  BILL_PAY_DETAIL,
  BILL_PAY_SCHEDULE,
  PAY_LATER_SETUP,
  BILL_PAY_PAYMENTS,
  PAY_LATER_EXTEND_PAYMENT_TERMS_PAGE,
];

export default BILL_PAY_ROUTES;
