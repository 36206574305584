import { makeStyles } from "libs/components/styles";
import { getVariantBaseStyleDefinition } from "libs/components/theme/ampla-theme/helpers/typography";
import colors from "libs/components/theme/ampla-theme/palette/colors";

const getVariantStyleDefinition = (borderColor: string, backgroundColor: string, iconColor: string) => ({
  borderColor,
  backgroundColor,

  "& .start-icon-container svg": {
    fill: iconColor,
  },
});

export const useStyles = makeStyles(() => ({
  root: {
    color: colors.black,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBlock: 14,
    paddingInline: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    fontSize: 14,

    "&.error": getVariantStyleDefinition(colors.red[600], colors.red[100], colors.red[500]),
    "&.warning": getVariantStyleDefinition(colors.orange[500], colors.orange[100], colors.orange[500]),
    "&.success": getVariantStyleDefinition(colors.green[600], colors.green[100], colors.green[400]),
    "&.insights": getVariantStyleDefinition(colors.purple[600], colors.purple[100], colors.purple[600]),
    "&.info": getVariantStyleDefinition(colors.blue[600], colors.blue[50], colors.blue[600]),

    "& .content": {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      minHeight: 24,
    },

    "& .start-icon-container": {
      display: "flex",
      alignItems: "center",
      marginRight: 8,
      height: 24,

      "& svg": {
        width: 20,
        height: 20,
      },
    },

    "& .close-button": {
      height: 24,
      marginLeft: 8,
    },

    "& .close-icon": {
      width: `16px !important`,
      height: `16px !important`,
    },

    "& .cta-button": {
      ...getVariantBaseStyleDefinition({ variant: "body2" }),
      color: colors.teal[900],
      height: "unset",
      padding: 0,
      marginLeft: 0,
      marginRight: 0,
      textDecoration: "underline !important",
      textTransform: "unset",

      "&:hover": {
        color: colors.teal[700],
      },

      "&:focus": {
        color: colors.teal[500],
      },
    },

    "&.fixed-top, &.fixed-bottom": {
      borderWidth: 0,
      borderRadius: 0,
      paddingBlock: 18,
      paddingInline: 58,

      "& .start-icon-container": {
        marginRight: 18,
      },
    },
    "&.fixed-top": {
      borderBottomWidth: 1,
    },
    "&.fixed-bottom": {
      borderTopWidth: 1,
    },
  },
}));
