import { Components } from "@mui/material/styles";
import { isObject, reduce } from "lodash";

import { AMPLA_COMPONENT_NAMESPACE_SELECTOR } from "libs/components/theme/constants";
import {
  getVariantAltFontStyleDefinition,
  getVariantBaseStyleDefinition,
  getVariantWeightStyleDefinition,
} from "../helpers/typography";
import colors from "../palette/colors";

const MuiTypography: Components["MuiTypography"] = {
  styleOverrides: {
    // Legacy overrides. TODO: Remove once all components are replaced
    h1: {
      fontSize: "3rem",
      lineHeight: "3.625rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.125rem",
      lineHeight: "2.563rem",
      fontWeight: 700,
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: "2.125rem",
      fontWeight: 700,
    },
    h4: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
    },
    h6: {
      fontSize: "1.25rem",
      lineHeight: "1.875rem",
    },
    subtitle1: {
      letterSpacing: "0.2em",
      textTransform: "uppercase",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.375rem",
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    },
    caption: {
      fontSize: "0.75rem",
      lineHeight: "1.063rem",

      letterSpacing: "0.1em",
      textTransform: "uppercase",
      color: colors.gray[300],
    },

    // Custom component overrides (custom variants)
    root: {
      [AMPLA_COMPONENT_NAMESPACE_SELECTOR]: {
        "&.label1": getVariantBaseStyleDefinition({ variant: "label1" }),
        "&.label2": getVariantBaseStyleDefinition({ variant: "label2" }),
        "&.label3": getVariantBaseStyleDefinition({ variant: "label3" }),
        "&.overline1": getVariantBaseStyleDefinition({ variant: "overline1" }),
        "&.overline2": getVariantBaseStyleDefinition({ variant: "overline2" }),
        "&.body1": {
          ...getVariantBaseStyleDefinition({ variant: "body1" }),
          "&.medium": getVariantWeightStyleDefinition({ variant: "body1", weight: "medium" }),
          "&.bold": getVariantWeightStyleDefinition({ variant: "body1", weight: "bold" }),
          "&.underline": {
            textDecoration: "underline",
          },
        },
        "&.body2": {
          ...getVariantBaseStyleDefinition({ variant: "body2" }),
          "&.medium": getVariantWeightStyleDefinition({ variant: "body2", weight: "medium" }),
          "&.bold": getVariantWeightStyleDefinition({ variant: "body2", weight: "bold" }),
          "&.underline": {
            textDecoration: "underline",
          },
        },
        "&.body3": {
          ...getVariantBaseStyleDefinition({ variant: "body3" }),
          "&.medium": getVariantWeightStyleDefinition({ variant: "body3", weight: "medium" }),
          "&.bold": getVariantWeightStyleDefinition({ variant: "body3", weight: "bold" }),
          "&.underline": {
            textDecoration: "underline",
          },
        },
        "&.h1": {
          ...getVariantBaseStyleDefinition({ variant: "h1" }),
          "&.altfont": getVariantAltFontStyleDefinition({ variant: "h1" }),
        },
        "&.h2": {
          ...getVariantBaseStyleDefinition({ variant: "h2" }),
          "&.altfont": getVariantAltFontStyleDefinition({ variant: "h2" }),
        },
        "&.h3": {
          ...getVariantBaseStyleDefinition({ variant: "h3" }),
          "&.altfont": getVariantAltFontStyleDefinition({ variant: "h3" }),
        },
        "&.h4": {
          ...getVariantBaseStyleDefinition({ variant: "h4" }),
          "&.altfont": getVariantAltFontStyleDefinition({ variant: "h4" }),
        },
        "&.h5": {
          ...getVariantBaseStyleDefinition({ variant: "h5" }),
          "&.altfont": getVariantAltFontStyleDefinition({ variant: "h5" }),
        },
        "&.h6": {
          ...getVariantBaseStyleDefinition({ variant: "h6" }),
          "&.altfont": getVariantAltFontStyleDefinition({ variant: "h6" }),
        },
        "&.h7": {
          ...getVariantBaseStyleDefinition({ variant: "h7" }),
          "&.altfont": getVariantAltFontStyleDefinition({ variant: "h7" }),
        },

        // Create a color variation class for each palette color e.g. &.teal-700
        ...reduce(
          colors,
          (acc, color, colorName) => {
            return {
              ...acc,
              ...(isObject(color)
                ? reduce(
                    color,
                    (acc, shade, shadeName) => {
                      return {
                        ...acc,
                        [`&.${colorName}-${shadeName}`]: {
                          color: shade,
                        },
                      };
                    },
                    {},
                  )
                : {
                    [`&.${colorName}`]: {
                      color,
                    },
                  }),
            };
          },
          {},
        ),
      },
    },
  },
};

export default MuiTypography;
