import { SESSION_USER_KEY, SESSION_USER_LOGGED_IN_KEY } from "constants/storageKeys";
import { GoogleToken } from "types";
import { identifyUserInHeap, getTrackingUserProperties } from "./heap";
import { parseJwt } from "./strings";
import { AMPLA_DOMAIN } from "constants/common";
import { User } from "@ampla/api";

export function identifyTrackedUser(user: User) {
  /* Make sure the user has not already been identified */
  if (window.isIdentifiedInHeap) return;

  window.isIdentifiedInHeap = true;
  const identity_label = `${user.active_entity_name}:${user.id}  ${user?.contact?.first_name || ""}`;

  /*  Full Story  */
  const FS = (window as any).FS || null;
  if (typeof FS === "function") {
    if (user.is_staff) {
      console.log("disabling full story for staff");
      FS.shutdown();
    } else {
      console.log("identifying with full story");
      FS.identify(user.id, {
        displayName: identity_label,
        entity: user.active_entity,
        entityName: user.active_entity_name,
        entityType: user.active_entity_type,
        isStaff: user?.is_staff?.toString(),
      });
    }
  }

  /*  Heap  */
  identifyUserInHeap(user);

  /*  Clearbit  */
  const clearbit = (window as any).clearbit || null;
  if (typeof clearbit === "object" && clearbit !== null)
    if (typeof clearbit.identify === "function" && user.id) {
      clearbit.identify(user.id.toString(), getTrackingUserProperties(user));
      if (user.active_entity) {
        const entity_props: any = Object.fromEntries(
          Object.entries(user.active_entity_products || {}).map(([key, value]) => ["product_status_" + key, value]),
        );
        entity_props["entity_name"] = user.active_entity_name;
        entity_props["entity_id"] = user.active_entity;
        clearbit.group(user.active_entity?.toString(), entity_props);
      }
    }
}

export const isGoogleTokenExpired = (parsedToken: GoogleToken) => {
  const timeInSeconds = new Date().getTime() / 1000;
  const tokenExpiration = parsedToken.exp ?? 0;

  return timeInSeconds > tokenExpiration;
};

export const validateGoogleOAuthToken = (token: string | null) => {
  if (!token) throw Error("You must login with Google to access this page");

  const decodedToken: GoogleToken | null = parseJwt(token);

  if (!decodedToken) throw Error("Invalid token, please login with Google again");

  if (decodedToken?.hd !== AMPLA_DOMAIN) throw Error("You must login with an Ampla Google account to access this page");

  if (isGoogleTokenExpired(decodedToken)) throw Error("Your token has expired, please login with Google again");
};
