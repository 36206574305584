interface Serializable {
  [key: string]: string | number | boolean | string[] | number[] | boolean[] | null;
}

export const serializeToParams = (obj: Serializable): string => {
  if (obj) {
    const urlParams = Object.keys(obj)
      .reduce((acc: string[], key: string) => {
        const value = obj[key];
        if (value !== undefined && value !== null) {
          if (Array.isArray(value)) {
            (value as string[]).forEach((item) => {
              acc.push(`${key}=${encodeURIComponent(item.toString())}`);
            });
          } else {
            acc.push(`${key}=${encodeURIComponent(value.toString())}`);
          }
        }
        return acc;
      }, [])
      .join("&");

    return urlParams && urlParams.length ? `?${urlParams}` : "";
  }
  return "";
};
