import { SESSION_GOOGLE_OAUTH_KEY } from "storage-keys.json";

import { getEnvVariables, isDemoEnv, isLoggedIn, isStagingEnv } from "@ampla/utils";

import { BASE_HEADERS } from "./constants";

export const getHeaders = (origin = "api"): HeadersInit => {
  const googleToken = sessionStorage.getItem(SESSION_GOOGLE_OAUTH_KEY);

  if (origin === "unit") {
    return { Authorization: `Bearer ${getEnvVariables()?.REACT_APP_UNIT_KEY}` };
  }

  // The persona endpoints require a Google token, if the frontend has it available,
  // we pass it to the backend
  if (!isLoggedIn() && (isDemoEnv() || isStagingEnv()) && googleToken && origin === "api") {
    return { Authorization: `Bearer ${googleToken}`, ...BASE_HEADERS };
  }

  return BASE_HEADERS;
};

export const b64toBlob = (b64Data: string, contentType: string, sliceSize = 512): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
