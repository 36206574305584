import { forwardRef } from "react";
import { LabelDisplayedRowsArgs, TablePagination as MUITablePagination } from "@mui/material";
import classNames from "classnames";

import { useStyles } from "./TablePagination.styles";
import { TablePaginationProps } from "./TablePagination.types";

const defaultLabelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
  const numberFormatter = new Intl.NumberFormat();
  return `${from}–${to} of ${count !== -1 ? numberFormatter.format(count) : `more than ${to}`}`;
};

const TablePagination = forwardRef((props: TablePaginationProps, ref) => {
  const classes = useStyles();

  return (
    <MUITablePagination
      ref={ref}
      component="div"
      labelDisplayedRows={defaultLabelDisplayedRows}
      {...props}
      backIconButtonProps={{
        ...props.backIconButtonProps,
        size: "large",
        className: classNames(classes.iconButton, props.backIconButtonProps?.className),
      }}
      nextIconButtonProps={{
        ...props.nextIconButtonProps,
        size: "large",
        className: classNames(classes.iconButton, props.nextIconButtonProps?.className),
      }}
    />
  );
});

export default TablePagination;
