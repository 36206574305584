import { ForwardedRef, forwardRef } from "react";
import { Menu as MenuIcon, NotificationsNone as NotificationsNoneIcon } from "@mui/icons-material";
import { Badge, Box } from "@mui/material";
import { sumBy } from "lodash";

import { IconButton } from "libs/components/components/IconButton";
import { AmplaLogo } from "../AmplaLogo";
import { Typography } from "../Typography";
import styles from "./AppHeader.styles";
import { AppHeaderProps } from "./AppHeader.types";
import { NotificationDrawer } from "./components/NotificationDrawer";
import { ProfileDropdown } from "./components/ProfileDropdown";
import { SettingsDropdown } from "./components/SettingsDropdown";

const AppHeader = forwardRef(
  (
    {
      user,
      SettingsDropdownProps,
      ProfileDropdownProps,
      NotificationDrawerProps,
      onNotificationsButtonClick,
      onMenuButtonClick,
    }: AppHeaderProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const notificationCount = sumBy(NotificationDrawerProps?.notifications, ({ read }) => (read ? 0 : 1));

    return (
      <Box ref={ref} sx={styles.root}>
        <AmplaLogo style={styles.logo} />
        <Box sx={styles.contentRight}>
          {!!SettingsDropdownProps && <SettingsDropdown {...SettingsDropdownProps} />}
          {onNotificationsButtonClick && (
            <IconButton sx={styles.iconButton} onClick={onNotificationsButtonClick}>
              <Badge
                badgeContent={notificationCount && <Typography variant="overline3">{notificationCount}</Typography>}
                color="error"
              >
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>
          )}
          {!!ProfileDropdownProps && <ProfileDropdown user={user} {...ProfileDropdownProps} />}
          {!!onMenuButtonClick && (
            <IconButton color="secondary" sx={styles.menuButton} onClick={onMenuButtonClick}>
              <MenuIcon />
            </IconButton>
          )}
          {NotificationDrawerProps && <NotificationDrawer {...NotificationDrawerProps} />}
        </Box>
      </Box>
    );
  },
);

export default AppHeader;
