const styles = {
  content: {
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: 3,
    height: "100%",
    overflowY: "auto",
  },
  drawerWithoutBackdrop: {
    position: "unset",
  },
  paper: {
    backgroundColor: "ampla.white",
    dropShadow: "default",
    boxShadow: "0px 4px 20px 0px rgba(8, 93, 106, 0.10)",
  },
  narrow: {
    width: 544,

    "@media (max-width: 544px)": {
      width: "100%",
      maxWidth: 544,
      pl: 3,
      pr: 3,
    },

    "@media (max-width: 375px)": {
      pl: 2,
      pr: 2,
    },
  },
  wide: {
    width: 640,

    "@media (max-width: 640px)": {
      pl: 3,
      pr: 3,
    },

    "@media (max-width: 375px)": {
      pl: 2,
      pr: 2,
    },
  },
};

export default styles;
