export enum AuthErrorCode {
  MissingToken = "token_missing",
  NotAuthenticated = "not_authenticated",
}

export enum EnabledProducts {
  ProductionFunding = "production_funding",
  Banking = "banking",
  DebitCards = "debit_cards",
  GrowthCards = "growth_cards",
  ChargeCards = "charge_cards",
  BillPay = "bill_pay",
  PayLater = "pay_later",
  NetTermsSeller = "net_terms_seller",
  NetTermsPayer = "net_terms_payer",
  InsightsEmbedded = "insights_embedded",
  InsightsV3 = "insights_v3",
  ReconciliationAccounts = "reconciliation_accounts",
  CashbackRewards = "rewards",
  ReserveAccount = "reserve_account",
  InvoicePaymentProcessingPayer = "invoice_payment_processing_payer",
  InvoicePaymentProcessingSeller = "invoice_payment_processing_seller",
  Expenses = "expenses",
  InternationalPayouts = "international_payouts",
  Spins = "spins",
}

export enum ProductState {
  Disabled = "disabled", // Not enabled by config
  NoApplication = "no_application", // Product enabled but not application created yet
  Applying = "applying", // User needs to complete the product application
  Reviewing = "reviewing", // Product application is being reviewed
  Active = "active", // User has been approved and able to use this product
  Onboarding = "onboarding", // User needs to complete the onboarding process,
  Locked = "locked", // User has product enabled but is locked out
  PreApproved = "preapproved", // User has been pre-approved for this product
  PreApprovedAccepted = "preapproved_accepted", // User has accepted pre-approval
}

export enum FeatureFlags {
  // Legacy feature flag system
  ChargeCardsIntegrationsOptional = "charge_cards_integrations_optional",
  InternationalPayouts = "international_payouts",

  // Migrated to new feature flag system
  ShopifyMigrationBanner = "shopify_migration_banner",
  RecurringAdvance = "recurring_advance",
  FinancialReporting = "financial_reporting",
  AirwallexOnboarding = "airwallex_onboarding",
  InternationalPayoutsFromAmplaCheckingAccount = "international_payouts_from_ampla_checking_account",
  InsightsRetailDashboard = "insights_retail_dashboard",
  InsightsWildeCustomerDataAccess = "insights_wilde_customer_data_access",
  InsightsWildeDataProcessing = "insights_wilde_data_processing",
  InsightsEmbeddedBI = "insights_embedded_bi",
  InsightsMarketingEfficiency = "insights_marketing_efficiency",
  InsightsNewMarketingEfficiency = "insights_new_marketing_efficiency",
  InsightsProfitAndLossDashboard = "insights_profit_and_loss_dashboard",
  AlternateApplicationUploadDocuments = "alternate_application_upload_documents",
  ShowAppNotifications = "show_app_notifications",
  GLOCStatements = "gloc_statements",
  CustomizableRoles = "customizable_roles",
  TransferBetweenAmplaAccounts = "transfer_between_ampla_accounts",
  CorporateCardsI2CMigrationInfo = "corporate_cards_i2c_migration_info",
  CorporateCardsI2CMigrationFlow = "corporate_cards_i2c_migration_flow",
  ThreadMigration = "thread_migration",
  ThreadDirect = "thread_direct",
}

export enum TimePeriod {
  Year = "year",
  HalfYear = "half_year",
  YearToDate = "ytd",
  Quarter = "quarter",
  Month = "month",
  MonthToDate = "mtd",
}
