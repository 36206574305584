import { Theme } from "@mui/material";

import { makeStyles } from "libs/components/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
    borderRadius: "2px",
    paddingLeft: "8px",
    height: "20px",
    color: theme.palette.ampla.gray[500],

    "&:hover": {
      backgroundColor: theme.palette.ampla.gray[100],
    },
    "&:active": {
      backgroundColor: theme.palette.ampla.gray[200],
    },

    "& .MuiSvgIcon-root": {
      height: "18px",
    },

    "&.secondary": {
      height: "34px",
      paddingLeft: "14px",
      marginLeft: "14px",
    },

    "&.light": {
      color: theme.palette.ampla.white,

      "&:hover": {
        backgroundColor: theme.palette.ampla.teal[600],
      },
      "&:active": {
        backgroundColor: theme.palette.ampla.teal[500],
      },
    },
  },
  disabled: {
    opacity: 0,
  },
  paper: {
    borderRadius: "4px",

    "& .MuiTab-root": {
      display: "flex",
      border: "none",
      padding: "14px 16px",
      minWidth: "200px",
      margin: 0,
      justifyContent: "flex-start",

      "&:hover": {
        backgroundColor: theme.palette.ampla.gray[100],
      },
      "& .MuiTab-wrapper": {
        justifyContent: "flex-start",
      },
      "&.hidden": {
        display: "none",
      },
    },
  },
}));
