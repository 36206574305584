import { Components } from "@mui/material/styles";

const MuiAppBar: Components["MuiAppBar"] = {
  styleOverrides: {
    root: {
      background: "transparent",
    },
    colorDefault: {
      background: "transparent",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
};

export default MuiAppBar;
