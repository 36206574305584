import { forwardRef, useImperativeHandle, useRef } from "react";
import { Popover } from "@mui/material";
import classNames from "classnames";

import { FilterButton } from "libs/components/components/FilterButton";
import { useStyles } from "./Filter.styles";
import { FilterProps } from "./Filter.types";

const Filter = forwardRef(
  ({ label, open, onClick, onClose, children, active, ButtonProps = {}, PopoverProps = {} }: FilterProps, ref) => {
    const classes = useStyles();

    const anchorEl = useRef<HTMLButtonElement | null>(null);

    useImperativeHandle(ref, () => anchorEl.current);

    return (
      <>
        <FilterButton ref={anchorEl} label={label} open={open} active={active} onClick={onClick} {...ButtonProps} />
        <Popover
          {...PopoverProps}
          open={open}
          anchorEl={anchorEl.current}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
            ...PopoverProps.anchorOrigin,
          }}
          PaperProps={{
            ...PopoverProps.PaperProps,
            className: classNames(classes.paper, PopoverProps.PaperProps?.className),
          }}
        >
          {children}
        </Popover>
      </>
    );
  },
);

export default Filter;
