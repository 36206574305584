import { lazy } from "react";

import { RouteDeclaration } from "./types";
import {
  activeEntityHasInvoicePaymentProcessingPayerInActiveState,
  activeEntityHasInvoicePaymentProcessingSellerInEnabledState,
  activeEntityHasNetTermsPayerInActiveState,
  activeEntityHasNetTermsSellerInEnabledState,
  oneOfValidator,
  userIsNetTermsPayerSignup,
  userIsNetTermsSellerSignup,
} from "./helpers/validators";
import { NetTermsPaths } from "routes/paths";

const NetTermsVendorInvoiceDetail = lazy(() => import("pages/NetTerms/NetTermsVendorInvoiceDetail"));
const NetTermsBrandSignup = lazy(() => import("pages/NetTerms/NetTermsBrandSignup"));
const NetTermsBrandInvoices = lazy(() => import("pages/NetTerms/NetTermsBrandInvoices"));
const NetTermsBrandSetup = lazy(() => import("pages/NetTerms/NetTermsBrandSetup"));
const NetTermsBrandPending = lazy(() => import("pages/NetTerms/NetTermsBrandPending"));

const NetTermsVendorCustomers = lazy(() => import("pages/NetTerms/NetTermsVendorInvoices/NetTermsVendorInvoices"));
const NetTermsVendorCustomerDetail = lazy(() => import("pages/NetTerms/NetTermsVendorCustomerDetail"));
const NetTermsVendorRegister = lazy(() => import("pages/NetTerms/NetTermsVendorRegister"));
const NetTermsVendorProfile = lazy(() => import("pages/NetTerms/NetTermsVendorProfile"));
const NetTermsBrandInvoiceDetail = lazy(() => import("pages/NetTerms/NetTermsBrandInvoiceDetail"));
const NetTermsBrandSchedulePayment = lazy(() => import("pages/NetTerms/NetTermsBrandSchedulePayment"));
const NetTermsBrandPaymentSignup = lazy(() => import("pages/NetTerms/NetTermsBrandPaymentSignup"));
const NetTermsVendorOnboarding = lazy(() => import("pages/NetTerms/NetTermsVendorOnboarding"));
const NetTermsARSync = lazy(() => import("pages/NetTerms/NetTermsARSync"));

const hasActivePayerProduct = oneOfValidator(
  activeEntityHasNetTermsPayerInActiveState,
  activeEntityHasInvoicePaymentProcessingPayerInActiveState,
);

const hasSellerProduct = oneOfValidator(
  activeEntityHasNetTermsSellerInEnabledState,
  activeEntityHasInvoicePaymentProcessingSellerInEnabledState,
);

export const NET_TERMS_PAYMENT_SIGNUP: RouteDeclaration = {
  name: "Signup",
  path: NetTermsPaths.NET_TERMS_BRAND_PAYMENT_SIGNUP,
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: NetTermsBrandPaymentSignup,
};

export const NET_TERMS_PAYMENT_SETUP: RouteDeclaration = {
  name: "Signup",
  path: NetTermsPaths.NET_TERMS_BRAND_PAYMENT_SETUP,
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: NetTermsBrandPaymentSignup,
};

export const NET_TERMS_BRAND_SIGNUP_UNSECURED: RouteDeclaration = {
  name: "Signup",
  path: NetTermsPaths.NET_TERMS_BRAND_SIGNUP_UNSECURED,
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: NetTermsBrandSignup,
};

export const NET_TERMS_BRAND_SIGNUP_SECURED: RouteDeclaration = {
  name: "Signup",
  path: NetTermsPaths.NET_TERMS_BRAND_SIGNUP_SECURED,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [userIsNetTermsPayerSignup],
  PageComponent: NetTermsBrandSignup,
};

export const NET_TERMS_BRAND_INVITATION_SIGNUP: RouteDeclaration = {
  name: "Signup",
  path: NetTermsPaths.NET_TERMS_BRAND_INVITATION_SIGNUP,
  pathIsExact: false,
  pathIsPrivate: false,
  PageComponent: NetTermsBrandSignup,
};

export const NET_TERMS_BRAND_INVOICES_OPEN: RouteDeclaration = {
  name: "Vendor Bills",
  path: NetTermsPaths.NET_TERMS_BRAND_INVOICES_OPEN,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [hasActivePayerProduct],
  PageComponent: NetTermsBrandInvoices,
};

export const NET_TERMS_BRAND_INVOICES_PAID: RouteDeclaration = {
  name: "Vendor Bills",
  path: NetTermsPaths.NET_TERMS_BRAND_INVOICES_PAID,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [hasActivePayerProduct],
  PageComponent: NetTermsBrandInvoices,
};

export const NET_TERMS_BRAND_INVOICE_DETAIL: RouteDeclaration = {
  name: "Invoice",
  path: NetTermsPaths.NET_TERMS_BRAND_INVOICE_DETAIL,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [hasActivePayerProduct],
  PageComponent: NetTermsBrandInvoiceDetail,
};

export const NET_TERMS_BRAND_SCHEDULE_PAYMENT: RouteDeclaration = {
  name: "Invoice",
  path: NetTermsPaths.NET_TERMS_BRAND_SCHEDULE_PAYMENT,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasInvoicePaymentProcessingPayerInActiveState],
  PageComponent: NetTermsBrandSchedulePayment,
};

export const NET_TERMS_BRAND_SETUP: RouteDeclaration = {
  name: "Net Terms Setup",
  path: NetTermsPaths.NET_TERMS_BRAND_SETUP,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasNetTermsPayerInActiveState],
  PageComponent: NetTermsBrandSetup,
};

export const NET_TERMS_BRAND_PENDING: RouteDeclaration = {
  name: "Net Terms Pending",
  path: NetTermsPaths.NET_TERMS_BRAND_PENDING,
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: NetTermsBrandPending,
};

export const NET_TERMS_VENDOR_CUSTOMERS_SUMMARY: RouteDeclaration = {
  name: "Invoices",
  path: NetTermsPaths.NET_TERMS_VENDOR_CUSTOMERS_SUMMARY,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [hasSellerProduct],
  PageComponent: NetTermsVendorCustomers,
};

export const NET_TERMS_VENDOR_INVOICES: RouteDeclaration = {
  name: "Invoices",
  path: NetTermsPaths.NET_TERMS_VENDOR_INVOICES,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [hasSellerProduct],
  PageComponent: NetTermsVendorCustomers,
};

export const NET_TERMS_VENDOR_INVOICE_DETAIL: RouteDeclaration = {
  name: "Payments",
  path: NetTermsPaths.NET_TERMS_VENDOR_INVOICE_DETAIL,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [hasSellerProduct],
  PageComponent: NetTermsVendorInvoiceDetail,
};

export const NET_TERMS_VENDOR_CUSTOMERS: RouteDeclaration = {
  name: "Invoices",
  path: NetTermsPaths.NET_TERMS_VENDOR_CUSTOMERS,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [hasSellerProduct],
  PageComponent: NetTermsVendorCustomers,
};

export const NET_TERMS_VENDOR_CUSTOMER_DETAIL: RouteDeclaration = {
  name: "Invoices",
  path: NetTermsPaths.NET_TERMS_VENDOR_CUSTOMER_DETAIL,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [hasSellerProduct],
  PageComponent: NetTermsVendorCustomerDetail,
};

export const NET_TERMS_VENDOR_PROFILE: RouteDeclaration = {
  name: "Profile",
  path: NetTermsPaths.NET_TERMS_VENDOR_PROFILE,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasNetTermsSellerInEnabledState],
  PageComponent: NetTermsVendorProfile,
};

export const NET_TERMS_VENDOR_UNSECURED_SIGNUP: RouteDeclaration = {
  name: "Signup",
  path: NetTermsPaths.NET_TERMS_VENDOR_UNSECURED_SIGNUP,
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: NetTermsVendorRegister,
};

export const NET_TERMS_VENDOR_SECURED_SIGNUP: RouteDeclaration = {
  name: "Signup",
  path: NetTermsPaths.NET_TERMS_VENDOR_SECURED_SIGNUP,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [userIsNetTermsSellerSignup],
  PageComponent: NetTermsVendorRegister,
};

export const NET_TERMS_VENDOR_ONBOARDING: RouteDeclaration = {
  name: "Onboarding",
  path: NetTermsPaths.NET_TERMS_VENDOR_ONBOARDING,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasNetTermsSellerInEnabledState],
  PageComponent: NetTermsVendorOnboarding,
};

export const NET_TERMS_VENDOR_SETUP_AR_SYNC: RouteDeclaration = {
  name: "Sync Accounts",
  path: NetTermsPaths.NET_TERMS_VENDOR_SETUP_AR_SYNC,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [hasSellerProduct],
  PageComponent: NetTermsARSync,
};

const NET_TERMS_ROUTES = [
  NET_TERMS_BRAND_SIGNUP_UNSECURED,
  NET_TERMS_BRAND_SIGNUP_SECURED,
  NET_TERMS_BRAND_INVOICES_OPEN,
  NET_TERMS_BRAND_INVOICES_PAID,
  NET_TERMS_BRAND_INVOICE_DETAIL,
  NET_TERMS_BRAND_SCHEDULE_PAYMENT,
  NET_TERMS_BRAND_SETUP,
  NET_TERMS_PAYMENT_SETUP,
  NET_TERMS_PAYMENT_SIGNUP,
  NET_TERMS_BRAND_PENDING,
  NET_TERMS_BRAND_INVITATION_SIGNUP,
  NET_TERMS_VENDOR_CUSTOMERS,
  NET_TERMS_VENDOR_CUSTOMER_DETAIL,
  NET_TERMS_VENDOR_CUSTOMERS_SUMMARY,
  NET_TERMS_VENDOR_INVOICES,
  NET_TERMS_VENDOR_INVOICE_DETAIL,
  NET_TERMS_VENDOR_UNSECURED_SIGNUP,
  NET_TERMS_VENDOR_SECURED_SIGNUP,
  NET_TERMS_VENDOR_PROFILE,
  NET_TERMS_VENDOR_ONBOARDING,
  NET_TERMS_VENDOR_SETUP_AR_SYNC,
];

export default NET_TERMS_ROUTES;
