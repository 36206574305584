import { Components } from "@mui/material/styles";

import colors from "../palette/colors";

const MuiTablePagination: Components["MuiTablePagination"] = {
  styleOverrides: {
    displayedRows: {
      color: colors.gray[500],
    },
    actions: {
      marginLeft: 8,
    },
  },
};

export default MuiTablePagination;
