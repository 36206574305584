import { withStyles, WithStyles, createStyles, Theme, AmplaLogo } from "@ampla/ui-components";
import { ReactNode } from "react";
import RoundedPaper from "./Common/RoundedPaper";
import SerifTypography from "./Common/SerifTypography";
import Spacer from "./Common/Spacer";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.ampla.teal[900],
      zIndex: 1200,
    },
    logo: {
      position: "fixed",
      top: theme.spacing(2),
      left: theme.spacing(4),
      zIndex: 1400,
      margin: 0,
      width: "unset",
    },
    paper: {
      padding: theme.spacing(3),
    },
  });

type ErrorPageProps = {
  title?: string;
  children: ReactNode;
};

const ErrorPage = ({ title = "We are sorry...", children, classes }: ErrorPageProps & WithStyles<typeof styles>) => (
  <div className={classes.root}>
    <div className={classes.logo}>
      <AmplaLogo size="small" color="white" />
    </div>
    <RoundedPaper className={classes.paper} borderless>
      {title && (
        <>
          <SerifTypography variant="h2" align="center">
            {title}
          </SerifTypography>
          <Spacer size={3} />
        </>
      )}
      {children}
    </RoundedPaper>
  </div>
);

export default withStyles(styles)(ErrorPage);
