import { ChevronRight as ChevronRightIcon, ErrorOutline as ErrorOutlineIcon, Close as CloseIcon } from "@ampla/icons";

import { MUIIconButton, MUIButton, Banner } from "@ampla/ui-components";
import withDemoWrapper from "components/withDemoWrapper";
import { useState } from "react";
import { useStyles } from "./OutdatedProfileBanner.styles";
import { useAuthState } from "components/context/AuthContextProvider";

const OutdatedProfileBanner = () => {
  const classes = useStyles();

  const [isBannerOpen, setOpenBanner] = useState(true);

  const { logout } = useAuthState();

  const handleCreateNewProfile = () => logout({ nextUrl: "/new-profile" });

  return isBannerOpen ? (
    <Banner startIcon={<ErrorOutlineIcon className={classes.leftIcon} />}>
      <>
        <span>
          This profile is out of date. Please create a new user profile for the most up to date portal experience.
        </span>
        <div>
          <MUIButton endIcon={<ChevronRightIcon />} onClick={handleCreateNewProfile}>
            <u>Create new profile</u>
          </MUIButton>
          <MUIIconButton aria-label="close" onClick={() => setOpenBanner(false)}>
            <CloseIcon />
          </MUIIconButton>
        </div>
      </>
    </Banner>
  ) : null;
};

export default withDemoWrapper(OutdatedProfileBanner);
