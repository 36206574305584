import { ForwardedRef, forwardRef } from "react";
import { FormControlLabel, Switch as MUISwitch } from "@mui/material";
import classnames from "classnames";

import styles from "./Switch.styles";
import { SwitchProps } from "./Switch.types";

const Switch = forwardRef(({ label, sx, className, disabled, ...props }: SwitchProps, ref: ForwardedRef<any>) => {
  const withLabel = !!label;

  const control = (
    <MUISwitch
      {...props}
      sx={{ ...styles.root, ...sx }}
      className={classnames(className, { "Mui-disabled": disabled })}
      ref={withLabel ? undefined : ref}
      disabled={disabled}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    />
  );

  return withLabel ? (
    <FormControlLabel
      ref={ref}
      label={label}
      componentsProps={{ typography: { sx: styles.formControlLabel } }}
      control={control}
      sx={{ margin: 0 }}
    />
  ) : (
    control
  );
});

export default Switch;
