import React, { ReactNode } from "react";
import { isArray, isEqual } from "lodash";

/**
 * Renders the selected value(s) of a select component.
 *
 * @param value - The selected value(s).
 * @param children - The options of the select component.
 * @param multiple - Indicates if the select component allows multiple selections.
 * @returns The rendered selected value(s).
 */
export const renderSelectValue = (value: any, children: ReactNode, multiple: boolean) => {
  const displayMultiple: Array<any> = [];
  let displaySingle = "";

  React.Children.toArray(children).map((child) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    let selected;

    if (multiple) {
      if (!isArray(value)) {
        throw new Error("MUI: The `value` prop must be an array when using the `Select` component with `multiple`.");
      }

      selected = value.some((v) => isEqual(v, child.props.value));
      if (selected) {
        displayMultiple.push(child.props.children);
      }
    } else {
      selected = isEqual(value, child.props.value);
      if (selected) {
        displaySingle = child.props.children;
      }
    }
  });

  if (multiple) {
    if (displayMultiple.length === 0) {
      return null;
    } else {
      return displayMultiple.reduce((output, child, index) => {
        output.push(child);
        if (index < displayMultiple.length - 1) {
          output.push(", ");
        }
        return output;
      }, []);
    }
  } else {
    return displaySingle;
  }
};
