import { EnabledProducts } from "@ampla/api";

export enum ExtendedSignup {
  MultiProducts = "MultiProducts",
}

export type SignupProduct = ExtendedSignup | EnabledProducts;

export enum SignupAccess {
  ProductionFunding = "production_funding",
  Banking = "banking",
  PayLater = "pay_later",
  FundingAndBanking = "funding_and_banking",
  CorporateCard = "corporate_card",
}

export enum MoreMenuOptions {
  Activate = "Resend activation email",
  Login = "Login as user",
  Remove = "Remove user",
  Reset = "Reset password",
}
