import { SendMoneyStep } from "./types";

export const SEND_PAYMENT_BASE_URL = "/send-payment";

const RECIPIENTS: SendMoneyStep = {
  name: "Source & recipient",
  title: "Source & recipient",
  path: `${SEND_PAYMENT_BASE_URL}/recipient/`,
  pathPatterns: [/\/send-payment\/recipient/],
  showSidebar: true,
  showOnSidebar: true,
  showHeaderTitle: false,
};

const PAYMENT_DETAILS: SendMoneyStep = {
  name: "Payment details",
  title: "Payment details",
  path: `${SEND_PAYMENT_BASE_URL}/payment-details/`,
  pathPatterns: [/\/send-payment\/payment-details/],
  showSidebar: true,
  showOnSidebar: true,
  showHeaderTitle: false,
};

const REVIEW: SendMoneyStep = {
  name: "Review",
  title: "Review",
  path: `${SEND_PAYMENT_BASE_URL}/review/`,
  pathPatterns: [/\/send-payment\/review/],
  showSidebar: true,
  showOnSidebar: true,
  showHeaderTitle: false,
};

const CONFIRMATION: SendMoneyStep = {
  name: "Confirmation",
  title: "Confirmation",
  path: `${SEND_PAYMENT_BASE_URL}/confirmation/`,
  pathPatterns: [/\/send-payment\/confirmation/],
  showSidebar: false,
  showOnSidebar: false,
  showHeaderTitle: false,
};

export const SEND_MONEY_STEPS = [RECIPIENTS, PAYMENT_DETAILS, REVIEW, CONFIRMATION];
