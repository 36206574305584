import { WithRequired } from "@ampla/utils";

import crud from "libs/api/client";
import { PermissionSet } from "../auth";
import { APIList } from "../types";
import { Role, UserManagement, UserManagementList, UserManagementWrite } from "./types";

// TODO: to be removed after integration with BE
const mockedRoles: Role[] = [
  {
    brand_role: "brand_owner",
    permissions: [
      // Ampla account management
      {
        name: PermissionSet.AcceptFinancialAgreements,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageRolesAndPermissions,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageIntegrations,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.MoveMoney,
        enabled: true,
        checked: true,
      },
      // Manage users
      // {
      //   name: PermissionSet.ManageOwner,
      //   enabled: true,
      //   checked: true,
      // },
      {
        name: PermissionSet.ManageAdmins,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageAccountingManagers,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageEmployees,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageCardholders,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageBookkeepers,
        enabled: true,
        checked: true,
      },
      // Banking
      {
        name: PermissionSet.ViewBankingHomepage,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.BankingViewExportTransactions,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageDebitCards,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.CreateReserveAndReconciliationAccounts,
        enabled: true,
        checked: true,
      },
      // GLOC
      {
        name: PermissionSet.ViewGLOCHomepage,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.GLOCViewExportTransactions,
        enabled: true,
        checked: false,
      },
      // Corporate card
      {
        name: PermissionSet.ViewCorporateCardHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportCardTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewCardsByUser,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.FreezeCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ReportCardsAsStolen,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CloseCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AccessRewards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.RedeemRewards,
        enabled: true,
        checked: true,
      },
      // bills
      {
        name: PermissionSet.ViewBillpayHomepage,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ViewExportBills,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.AddEditBills,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageRecipients,
        enabled: true,
        checked: true,
      },
      // expenses
      {
        name: PermissionSet.ExpensesViewExportTransactions,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.EditExpenses,
        enabled: true,
        checked: true,
      },
      // insights
      {
        name: PermissionSet.ViewInsights,
        enabled: true,
        checked: true,
      },
    ],
    users: ["Demo Owner22"],
  },
  {
    brand_role: "brand_admin",
    permissions: [
      // Ampla account management
      {
        name: PermissionSet.AcceptFinancialAgreements,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageRolesAndPermissions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageIntegrations,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.MoveMoney,
        enabled: true,
        checked: false,
      },
      // Manage users
      // {
      //   name: PermissionSet.ManageOwner,
      //   enabled: true,
      //   checked: true,
      // },
      {
        name: PermissionSet.ManageAdmins,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageAccountingManagers,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageEmployees,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageCardholders,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageBookkeepers,
        enabled: true,
        checked: true,
      },
      // Banking
      {
        name: PermissionSet.ViewBankingHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.BankingViewExportTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageDebitCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CreateReserveAndReconciliationAccounts,
        enabled: true,
        checked: false,
      },
      // GLOC
      {
        name: PermissionSet.ViewGLOCHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.GLOCViewExportTransactions,
        enabled: true,
        checked: false,
      },
      // Corporate card
      {
        name: PermissionSet.ViewCorporateCardHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportCardTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewCardsByUser,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.FreezeCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ReportCardsAsStolen,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CloseCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AccessRewards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.RedeemRewards,
        enabled: true,
        checked: true,
      },
      // bills
      {
        name: PermissionSet.ViewBillpayHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportBills,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AddEditBills,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageRecipients,
        enabled: true,
        checked: false,
      },
      // expenses
      {
        name: PermissionSet.ExpensesViewExportTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.EditExpenses,
        enabled: true,
        checked: false,
      },
      // insights
      {
        name: PermissionSet.ViewInsights,
        enabled: true,
        checked: false,
      },
    ],
    users: ["Admin Test"],
  },
  {
    brand_role: "brand_accounting_manager",
    permissions: [
      // Ampla account management
      {
        name: PermissionSet.AcceptFinancialAgreements,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageRolesAndPermissions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageIntegrations,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.MoveMoney,
        enabled: true,
        checked: false,
      },
      // Manage users
      // {
      //   name: PermissionSet.ManageOwner,
      //   enabled: true,
      //   checked: true,
      // },
      {
        name: PermissionSet.ManageAdmins,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageAccountingManagers,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageEmployees,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageCardholders,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageBookkeepers,
        enabled: true,
        checked: true,
      },
      // Banking
      {
        name: PermissionSet.ViewBankingHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.BankingViewExportTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageDebitCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CreateReserveAndReconciliationAccounts,
        enabled: true,
        checked: false,
      },
      // GLOC
      {
        name: PermissionSet.ViewGLOCHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.GLOCViewExportTransactions,
        enabled: true,
        checked: false,
      },
      // Corporate card
      {
        name: PermissionSet.ViewCorporateCardHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportCardTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewCardsByUser,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.FreezeCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ReportCardsAsStolen,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CloseCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AccessRewards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.RedeemRewards,
        enabled: true,
        checked: true,
      },
      // bills
      {
        name: PermissionSet.ViewBillpayHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportBills,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AddEditBills,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageRecipients,
        enabled: true,
        checked: false,
      },
      // expenses
      {
        name: PermissionSet.ExpensesViewExportTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.EditExpenses,
        enabled: true,
        checked: false,
      },
      // insights
      {
        name: PermissionSet.ViewInsights,
        enabled: true,
        checked: false,
      },
    ],
    users: [],
  },
  {
    brand_role: "brand_employee",
    permissions: [
      // Ampla account management
      {
        name: PermissionSet.AcceptFinancialAgreements,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageRolesAndPermissions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageIntegrations,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.MoveMoney,
        enabled: true,
        checked: false,
      },
      // Manage users
      // {
      //   name: PermissionSet.ManageOwner,
      //   enabled: true,
      //   checked: true,
      // },
      {
        name: PermissionSet.ManageAdmins,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageAccountingManagers,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageEmployees,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageCardholders,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageBookkeepers,
        enabled: true,
        checked: true,
      },
      // Banking
      {
        name: PermissionSet.ViewBankingHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.BankingViewExportTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageDebitCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CreateReserveAndReconciliationAccounts,
        enabled: true,
        checked: false,
      },
      // GLOC
      {
        name: PermissionSet.ViewGLOCHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.GLOCViewExportTransactions,
        enabled: true,
        checked: false,
      },
      // Corporate card
      {
        name: PermissionSet.ViewCorporateCardHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportCardTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewCardsByUser,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.FreezeCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ReportCardsAsStolen,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CloseCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AccessRewards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.RedeemRewards,
        enabled: true,
        checked: true,
      },
      // bills
      {
        name: PermissionSet.ViewBillpayHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportBills,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AddEditBills,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageRecipients,
        enabled: true,
        checked: false,
      },
      // expenses
      {
        name: PermissionSet.ExpensesViewExportTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.EditExpenses,
        enabled: true,
        checked: false,
      },
      // insights
      {
        name: PermissionSet.ViewInsights,
        enabled: true,
        checked: false,
      },
    ],
    users: [],
  },
  {
    brand_role: "brand_bookkeeper",
    permissions: [
      // Ampla account management
      {
        name: PermissionSet.AcceptFinancialAgreements,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageRolesAndPermissions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageIntegrations,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.MoveMoney,
        enabled: true,
        checked: false,
      },
      // Manage users
      // {
      //   name: PermissionSet.ManageOwner,
      //   enabled: true,
      //   checked: true,
      // },
      {
        name: PermissionSet.ManageAdmins,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageAccountingManagers,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageEmployees,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageCardholders,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageBookkeepers,
        enabled: true,
        checked: true,
      },
      // Banking
      {
        name: PermissionSet.ViewBankingHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.BankingViewExportTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageDebitCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CreateReserveAndReconciliationAccounts,
        enabled: true,
        checked: false,
      },
      // GLOC
      {
        name: PermissionSet.ViewGLOCHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.GLOCViewExportTransactions,
        enabled: true,
        checked: false,
      },
      // Corporate card
      {
        name: PermissionSet.ViewCorporateCardHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportCardTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewCardsByUser,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.FreezeCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ReportCardsAsStolen,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CloseCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AccessRewards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.RedeemRewards,
        enabled: true,
        checked: true,
      },
      // bills
      {
        name: PermissionSet.ViewBillpayHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportBills,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AddEditBills,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageRecipients,
        enabled: true,
        checked: false,
      },
      // expenses
      {
        name: PermissionSet.ExpensesViewExportTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.EditExpenses,
        enabled: true,
        checked: false,
      },
      // insights
      {
        name: PermissionSet.ViewInsights,
        enabled: true,
        checked: false,
      },
    ],
    users: [],
  },
  {
    brand_role: "brand_cardholder",
    permissions: [
      // Ampla account management
      {
        name: PermissionSet.AcceptFinancialAgreements,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageRolesAndPermissions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageIntegrations,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.MoveMoney,
        enabled: true,
        checked: false,
      },
      // Manage users
      // {
      //   name: PermissionSet.ManageOwner,
      //   enabled: true,
      //   checked: true,
      // },
      {
        name: PermissionSet.ManageAdmins,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageAccountingManagers,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageEmployees,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageCardholders,
        enabled: true,
        checked: true,
      },
      {
        name: PermissionSet.ManageBookkeepers,
        enabled: true,
        checked: true,
      },
      // Banking
      {
        name: PermissionSet.ViewBankingHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.BankingViewExportTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageDebitCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CreateReserveAndReconciliationAccounts,
        enabled: true,
        checked: false,
      },
      // GLOC
      {
        name: PermissionSet.ViewGLOCHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.GLOCViewExportTransactions,
        enabled: true,
        checked: false,
      },
      // Corporate card
      {
        name: PermissionSet.ViewCorporateCardHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportCardTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewCardsByUser,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.FreezeCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ReportCardsAsStolen,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.CloseCards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AccessRewards,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.RedeemRewards,
        enabled: true,
        checked: true,
      },
      // bills
      {
        name: PermissionSet.ViewBillpayHomepage,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ViewExportBills,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.AddEditBills,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.ManageRecipients,
        enabled: true,
        checked: false,
      },
      // expenses
      {
        name: PermissionSet.ExpensesViewExportTransactions,
        enabled: true,
        checked: false,
      },
      {
        name: PermissionSet.EditExpenses,
        enabled: true,
        checked: false,
      },
      // insights
      {
        name: PermissionSet.ViewInsights,
        enabled: true,
        checked: false,
      },
    ],
    users: ["Bookkeeper Test", "Holder Test"],
  },
];

const UserManagementAPI = {
  create: async (data: UserManagementWrite): Promise<UserManagement> => crud("create", "/user_management/", data),

  update: async (id: number, data: Partial<UserManagement>): Promise<UserManagement> =>
    crud("update", `/user_management/${id}/`, data),

  list: async (data?: UserManagementList): Promise<APIList<WithRequired<UserManagement, "id" | "email">>> =>
    crud("list", "/user_management/", data),

  send_activation_email: async (id: number) => crud("detail", `/user_management/${id}/send_activation_email/`),

  // list_roles: async (): Promise<Role[]> => crud("detail", "/user_management/roles/"),
  list_roles: async (): Promise<Role[]> => Promise.resolve(mockedRoles),
};

export default UserManagementAPI;
