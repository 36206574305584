import { useEffect, useState } from "react";
import camelcaseKeys from "camelcase-keys";
import { useMutation, useQuery, useQueryClient } from "react-query";
import snakecaseKeys from "snakecase-keys";

import { AccountAPI, CamelCasedLeadInterest, EnabledProducts, ProductState } from "@ampla/api";

import { activeEntityHasProductInSomeState } from "apps/portal/routes/helpers/validators";
import { useAuthState } from "components/context/AuthContextProvider";
import { CURRENT_USER_QUERY_KEY } from "constants/common";
import { LEAD_QUERY_KEY } from "constants/queryKeys";
import { ExtendedSignup } from "enums";
import { identifyLeadInHeap } from "helpers/heap";
import { identifyLeadInPendo } from "helpers/pendo";

const SUBMITTED_PRODUCT_STATES = [ProductState.Active, ProductState.Onboarding, ProductState.Reviewing];

export const getApplyingProduct = (productsOfInterest: EnabledProducts[]) => {
  if (!productsOfInterest.length) return;
  return productsOfInterest.length === 1 ? productsOfInterest[0] : ExtendedSignup.MultiProducts;
};

const useLead = () => {
  const queryClient = useQueryClient();
  const { user } = useAuthState() || {};
  const [leadEmail, setLeadEmail] = useState(user?.email);

  useEffect(() => {
    if (user?.email) setLeadEmail(user?.email);
  }, [user]);

  const {
    data: lead,
    isFetching: isFetchingLead,
    refetch: refetchLead,
  } = useQuery({
    queryKey: [LEAD_QUERY_KEY, leadEmail],
    queryFn: () => AccountAPI.getOrCreateLead({ email: leadEmail! }),
    enabled: !!leadEmail,
    refetchOnMount: false,
    select: (lead) => ({
      ...camelcaseKeys(lead, { deep: true }),
      applyingProduct: getApplyingProduct(lead.products_of_interest),
    }),
    onSuccess: (lead) => {
      if (lead.leadConverted !== user?.lead_converted) queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
    },
  });

  const {
    mutateAsync: createLead,
    isLoading: isCreatingLead,
    error,
  } = useMutation({
    mutationFn: (params: { email: string; website: string }) => AccountAPI.getOrCreateLead(params),
    onSuccess: (lead) => {
      setLeadEmail(lead.email);
      identifyLeadInHeap(lead.email);
      identifyLeadInPendo(lead.email);
    },
  });

  const { mutateAsync: updateLeadInterest, isLoading: isUpdatingLead } = useMutation({
    mutationFn: (interest: CamelCasedLeadInterest) => AccountAPI.updateLeadInterest(lead?.id!, snakecaseKeys(interest)),
    onSuccess: () => {
      queryClient.invalidateQueries(LEAD_QUERY_KEY);
      queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
    },
  });

  const { mutateAsync: setLeadConverted, isLoading: isSettingLead } = useMutation({
    mutationFn: () => AccountAPI.setLeadConverted(lead?.id!),
    onSuccess: () => queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY),
  });

  const getProductsOfInterestForHeap = (productsOfInterest?: EnabledProducts[]) => ({
    productsOfInterest: productsOfInterest?.join("; "),
  });

  const productsOfInterestForHeap = getProductsOfInterestForHeap(lead?.productsOfInterest);

  const isLoading = isFetchingLead || isUpdatingLead || isSettingLead || isCreatingLead;

  useEffect(() => {
    if (user && lead && !lead.leadConverted) {
      const bankingSubmitted = activeEntityHasProductInSomeState(
        EnabledProducts.Banking,
        SUBMITTED_PRODUCT_STATES,
      )({ user });
      const fundingSubmitted = activeEntityHasProductInSomeState(
        EnabledProducts.ProductionFunding,
        SUBMITTED_PRODUCT_STATES,
      )({ user });

      if (bankingSubmitted || fundingSubmitted) {
        setLeadConverted();
      }
    }
  }, [user, lead]);

  return {
    lead,
    refetchLead,
    updateLeadInterest,
    setLeadConverted,
    createLead,
    error,
    isLoading,
    getProductsOfInterestForHeap,
    productsOfInterestForHeap,
  };
};

export default useLead;
