import { Components } from "@mui/material/styles";

const MuiCssBaseline: Components["MuiCssBaseline"] = {
  styleOverrides: {
    "@global": {
      body: {
        margin: 0,
        boxSizing: "border-box",
      },
    },
  },
};

export default MuiCssBaseline;
