import { lazy } from "react";

import { RouteDeclaration } from "./types";

const Disclosure = lazy(() => import("pages/Disclosure"));

export const DISCLOSURE: RouteDeclaration = {
  name: "Disclosure",
  path: "/disclosure",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: Disclosure,
};

const DISCLOSURE_ROUTES = [DISCLOSURE];

export default DISCLOSURE_ROUTES;
