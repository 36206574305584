import { Work as WorkIcon } from "@ampla/icons";
import { MUILink, Banner } from "@ampla/ui-components";
import { Link as RouterLink } from "react-router-dom";
import { useStyles } from "./StaffAccountBanner.styles";
import { LOCAL_STORAGE_HIDE_STAFF_BANNER_FLAG } from "constants/storageKeys";
import { useLocalStorageValue } from "@react-hookz/web";

const StaffAccountBanner = () => {
  const classes = useStyles();

  const { value: hideBanner, set: setHideBanner } = useLocalStorageValue(LOCAL_STORAGE_HIDE_STAFF_BANNER_FLAG, {
    defaultValue: false,
  });

  const handleCloseBannerClick = () => setHideBanner(true);

  if (hideBanner) return null;

  return (
    <Banner
      variant="warning"
      startIcon={
        <div className={classes.productIconContainer}>
          <WorkIcon />
        </div>
      }
      onClose={handleCloseBannerClick}
      onCloseTooltipProps={{ title: "Hide banner", placement: "left" }}
    >
      <span>
        Some functionality is unavailable while you&apos;re logged into a staff account. To access all functionality,
        log in as brand owner user on the{" "}
        <MUILink component={RouterLink} to="/settings/team" underline="always">
          team management page
        </MUILink>
        .
      </span>
    </Banner>
  );
};

export default StaffAccountBanner;
