import { ForwardedRef, forwardRef, useMemo } from "react";
import classnames from "classnames";

import { Typography } from "libs/components/components/Typography";
import { TypographyVariant } from "libs/components/components/Typography/Typography.types";
import { AMPLA_COMPONENT_NAMESPACE } from "libs/components/theme/constants";
import { NumberTypographyProps, NumberTypographyVariant } from "./NumberTypography.types";

// Use minimumFractionDigits and maximumFractionDigits at max level to prevent rounding
// until `roundingMode` is supported by Intl.NumberFormat
const formatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 20,
  maximumFractionDigits: 20,
});

function getSymbolVariant(variant: NumberTypographyVariant): string {
  switch (variant) {
    case "h1":
      return "h3";
    case "h2":
      return "h4";
    case "h3":
      return "h6";
    case "h4":
      return "h7";
    default:
      return variant;
  }
}

function getFractionVariant(variant: NumberTypographyVariant, unit: string): string {
  if (unit === "currency") {
    return getSymbolVariant(variant);
  }
  return variant;
}

function getPrefix(unit: string, value: number | undefined): string {
  const symbol = unit === "currency" ? "$" : "";

  if (value && isFinite(value) && value < 0) {
    return `-${symbol}`;
  }
  return symbol;
}

function getSuffix(unit: string): string {
  if (unit === "percent") {
    return "%";
  }
  return "";
}

const NumberTypography = forwardRef((props: NumberTypographyProps, ref: ForwardedRef<HTMLSpanElement>) => {
  const { variant = "h1", unit = "number", className, color, value, precision = 2, ...baseProps } = props;
  const symbolVariant = getSymbolVariant(variant) as TypographyVariant;
  const fractionVariant = getFractionVariant(variant, unit) as TypographyVariant;

  const { prefix, whole, fraction, suffix } = useMemo(() => {
    const prefix = getPrefix(unit, value);
    const suffix = getSuffix(unit);

    // Placeholder for missing value
    if (typeof value !== "number" || !isFinite(value)) {
      return { prefix, whole: "--", suffix };
    }

    const numString = formatter.format(Math.abs(value));
    const [whole, fraction = ""] = numString.split(".");
    const truncatedFraction = fraction.substring(0, precision);

    return { prefix, whole, fraction: truncatedFraction ? `.${truncatedFraction}` : "", suffix };
  }, [value, unit, precision]);

  return (
    <span ref={ref} className={classnames(AMPLA_COMPONENT_NAMESPACE, variant, className)} {...baseProps}>
      <Typography altFont component="span" variant={symbolVariant} color={color}>
        {prefix}
      </Typography>
      <Typography altFont component="span" variant={variant} color={color}>
        {whole}
      </Typography>
      <Typography altFont component="span" variant={fractionVariant} color={color}>
        {fraction}
      </Typography>
      <Typography altFont component="span" variant={symbolVariant} color={color}>
        {suffix}
      </Typography>
    </span>
  );
});

export default NumberTypography;
