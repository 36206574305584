import { get, includes } from "lodash";

import {
  BancoApplicationStatus,
  BrandRole,
  BrandRoleAction,
  BrandRoles,
  ChargeCardsProductApplicationState,
  EnabledProducts,
  FeatureFlags,
  FundingApplicationStatus,
  ProductState,
  SignupSource,
  User,
  UserPermissions,
} from "@ampla/api";

import { getAllBrandRolePerms, getBrandRolePerm } from "apps/portal/helpers/roles";
import { isMoreThan90Days } from "helpers/date";
import { GetProductStateFunction } from "routes/types";
import { ValidatorFunction, ValidatorParams } from "types";

// Utils 🛠

export const everyValidator =
  (...fns: Array<ValidatorFunction>) =>
  (args: ValidatorParams) =>
    !!fns.every((fn) => fn(args));

export const oneOfValidator =
  (...fns: Array<ValidatorFunction>) =>
  (args: ValidatorParams) =>
    !!fns.find((fn) => fn(args));

export const negateValidator = (fn: ValidatorFunction) => (args: ValidatorParams) => !fn(args);

export const userHasFeatureFlag = (user: User | undefined, featureFlag: FeatureFlags) => {
  return user?.feature_flags?.[featureFlag] ?? false;
};

export const featureFlagValidator =
  (featureFlag: FeatureFlags): ValidatorFunction =>
  ({ user }) =>
    user?.feature_flags?.[featureFlag] ?? false;

// Entity validators 🏢

export const activeEntityIsAmpla: ValidatorFunction = ({ user }) => user?.active_entity_type === "gg";

export const activeEntityIsNotAmpla: ValidatorFunction = negateValidator(activeEntityIsAmpla);

export const activeEntityHasEnabledFeatureFlag =
  (featureFlag: FeatureFlags): ValidatorFunction =>
  ({ user }) =>
    !!user?.active_entity_feature_flags?.includes(featureFlag);

export const activeEntityHasChargeCardsOptionalIntegrations = activeEntityHasEnabledFeatureFlag(
  FeatureFlags.ChargeCardsIntegrationsOptional,
);

// Entity enabled products check
export const activeEntityHasProductEnabled =
  (product: EnabledProducts): ValidatorFunction =>
  ({ user }) =>
    user?.active_entity_settings ? includes(user?.active_entity_settings.enabled_products, product) : false;

export const activeEntityHasProductChargeCardsEnabled = activeEntityHasProductEnabled(EnabledProducts.ChargeCards);

// Entity enabled product validators 🏢

export const activeEntityHasProductInState =
  (product: EnabledProducts, state: ProductState): ValidatorFunction =>
  ({ user }) =>
    user?.active_entity_products ? user?.active_entity_products[product] === state : false;

export const activeEntityHasProductInSomeState =
  (product: EnabledProducts, states: ProductState[]): ValidatorFunction =>
  ({ user }) =>
    user?.active_entity_products ? states.includes(user?.active_entity_products[product] as ProductState) : false;

export const activeEntityHasSomeProductInState =
  (products: EnabledProducts[], state: ProductState): ValidatorFunction =>
  ({ user }) =>
    products.some((product) => user?.active_entity_products?.[product] === state);

export const getActiveEntityProductState =
  (product: EnabledProducts): GetProductStateFunction =>
  ({ user }) =>
    user?.active_entity_products?.[product];

export const getActiveEntityPayLaterProductState = getActiveEntityProductState(EnabledProducts.PayLater);
export const getActiveEntityBankingProductState = getActiveEntityProductState(EnabledProducts.Banking);
export const getActiveEntityCorporateCardProductState = getActiveEntityProductState(EnabledProducts.ChargeCards);

export const activeEntityHasBillPayInActiveState = activeEntityHasProductInState(
  EnabledProducts.BillPay,
  ProductState.Active,
);

export const activeEntityHasBillPayInEnabledState = activeEntityHasProductInSomeState(EnabledProducts.BillPay, [
  ProductState.Active,
  ProductState.Onboarding,
]);

export const activeEntityHasProductBankingInApplyingState = activeEntityHasProductInState(
  EnabledProducts.Banking,
  ProductState.Applying,
);

export const activeEntityHasProductBankingInReviewingState = activeEntityHasProductInState(
  EnabledProducts.Banking,
  ProductState.Reviewing,
);

export const activeEntityHasProductInternationalPayoutsInActiveState = activeEntityHasProductInState(
  EnabledProducts.InternationalPayouts,
  ProductState.Active,
);

export const activeEntityHasProductBankingInActiveState = activeEntityHasProductInState(
  EnabledProducts.Banking,
  ProductState.Active,
);

export const activeEntityHasProductBankingInDisabledState = activeEntityHasProductInState(
  EnabledProducts.Banking,
  ProductState.Disabled,
);

export const activeEntityHasProductBankingInNoApplicationState = activeEntityHasProductInState(
  EnabledProducts.Banking,
  ProductState.NoApplication,
);

export const activeEntityHasProductBankingInOnboardingState = activeEntityHasProductInState(
  EnabledProducts.Banking,
  ProductState.Onboarding,
);

export const activeEntityHasProductFundingInApplyingState = activeEntityHasProductInState(
  EnabledProducts.ProductionFunding,
  ProductState.Applying,
);

export const activeEntityHasProductFundingInReviewingState = activeEntityHasProductInState(
  EnabledProducts.ProductionFunding,
  ProductState.Reviewing,
);

export const activeEntityHasProductFundingInActiveState = activeEntityHasProductInState(
  EnabledProducts.ProductionFunding,
  ProductState.Active,
);

export const activeEntityHasProductFundingInDisabledState = activeEntityHasProductInState(
  EnabledProducts.ProductionFunding,
  ProductState.Disabled,
);

export const activeEntityHasProductFundingInNoApplicationState = activeEntityHasProductInState(
  EnabledProducts.ProductionFunding,
  ProductState.NoApplication,
);

export const activeEntityHasProductFundingInOnboardingState = activeEntityHasProductInState(
  EnabledProducts.ProductionFunding,
  ProductState.Onboarding,
);

export const activeEntityHasProductDebitCardsInActiveState = activeEntityHasProductInState(
  EnabledProducts.DebitCards,
  ProductState.Active,
);

export const activeEntityHasProductDebitCardsInDisabledState = activeEntityHasProductInState(
  EnabledProducts.DebitCards,
  ProductState.Disabled,
);

export const activeEntityHasProductCashbackRewardsInActiveState = activeEntityHasProductInState(
  EnabledProducts.CashbackRewards,
  ProductState.Active,
);

export const activeEntityHasProductCashbackRewardsInLockedState = activeEntityHasProductInState(
  EnabledProducts.CashbackRewards,
  ProductState.Locked,
);

export const activeEntityHasProductGrowthCardsInReviewingState = activeEntityHasProductInState(
  EnabledProducts.GrowthCards,
  ProductState.Reviewing,
);

export const activeEntityHasProductGrowthCardsInOnboardingState = activeEntityHasProductInState(
  EnabledProducts.GrowthCards,
  ProductState.Onboarding,
);

export const activeEntityHasProductGrowthCardsInActiveState = activeEntityHasProductInState(
  EnabledProducts.GrowthCards,
  ProductState.Active,
);

export const activeEntityHasProductGrowthCardsInDisabledState = activeEntityHasProductInState(
  EnabledProducts.GrowthCards,
  ProductState.Disabled,
);

export const activeEntityHasProductChargeCardsInDisabledState = activeEntityHasProductInState(
  EnabledProducts.ChargeCards,
  ProductState.Disabled,
);

export const activeEntityHasProductChargeCardsInApplyingState = activeEntityHasProductInState(
  EnabledProducts.ChargeCards,
  ProductState.Applying,
);

export const activeEntityHasProductChargeCardsInReviewingState = activeEntityHasProductInState(
  EnabledProducts.ChargeCards,
  ProductState.Reviewing,
);

export const activeEntityHasProductChargeCardsInOnboardingState = activeEntityHasProductInState(
  EnabledProducts.ChargeCards,
  ProductState.Onboarding,
);

export const activeEntityHasProductChargeCardsInActiveState = activeEntityHasProductInState(
  EnabledProducts.ChargeCards,
  ProductState.Active,
);

export const activeEntityHasProductChargeCardsInNotApplicationState = activeEntityHasProductInState(
  EnabledProducts.ChargeCards,
  ProductState.NoApplication,
);

export const activeEntityHasProductChargeCardsInPreApprovedState = activeEntityHasProductInState(
  EnabledProducts.ChargeCards,
  ProductState.PreApproved,
);

export const activeEntityHasProductPayLaterInActiveState = activeEntityHasProductInState(
  EnabledProducts.PayLater,
  ProductState.Active,
);

export const activeEntityHasProductChargeCardsInLockedState = activeEntityHasProductInState(
  EnabledProducts.ChargeCards,
  ProductState.Locked,
);

export const activeEntityHasProductPayLaterInApplyingState = activeEntityHasProductInState(
  EnabledProducts.PayLater,
  ProductState.Applying,
);

export const activeEntityHasProductPayLaterInOnboardingState = activeEntityHasProductInState(
  EnabledProducts.PayLater,
  ProductState.Onboarding,
);

export const activeEntityHasProductPayLaterInReviewingState = activeEntityHasProductInState(
  EnabledProducts.PayLater,
  ProductState.Reviewing,
);

export const activeEntityHasProductPayLaterInOnboardingOrActiveState = activeEntityHasProductInSomeState(
  EnabledProducts.PayLater,
  [ProductState.Onboarding, ProductState.Active],
);

export const activeEntityHasProductPayLaterInDisabledState = activeEntityHasProductInState(
  EnabledProducts.PayLater,
  ProductState.Disabled,
);

export const activeEntityHasProductPayLaterInNoApplicationState = activeEntityHasProductInState(
  EnabledProducts.PayLater,
  ProductState.NoApplication,
);

export const activeEntityHasInsightsEmbeddedInActiveState = activeEntityHasProductInState(
  EnabledProducts.InsightsEmbedded,
  ProductState.Active,
);

export const activeEntityHasInsightsEmbeddedInDisabledState = activeEntityHasProductInState(
  EnabledProducts.InsightsEmbedded,
  ProductState.Disabled,
);

export const activeEntityHasNetTermsPayerInDisabledState = activeEntityHasProductInState(
  EnabledProducts.NetTermsPayer,
  ProductState.Disabled,
);

export const activeEntityHasNetTermsPayerInActiveState = activeEntityHasProductInState(
  EnabledProducts.NetTermsPayer,
  ProductState.Active,
);

export const activeEntityHasInvoicePaymentProcessingPayerInActiveState = activeEntityHasProductInState(
  EnabledProducts.InvoicePaymentProcessingPayer,
  ProductState.Active,
);
export const activeEntityHasInvoicePaymentProcessingPayerInDisabledState = activeEntityHasProductInState(
  EnabledProducts.InvoicePaymentProcessingPayer,
  ProductState.Disabled,
);

export const activeEntityHasInvoicePaymentProcessingSellerInActiveState = activeEntityHasProductInState(
  EnabledProducts.InvoicePaymentProcessingSeller,
  ProductState.Active,
);
export const activeEntityHasInvoicePaymentProcessingSellerInReviewState = activeEntityHasProductInState(
  EnabledProducts.InvoicePaymentProcessingSeller,
  ProductState.Reviewing,
);
export const activeEntityHasInvoicePaymentProcessingSellerInDisabledState = activeEntityHasProductInState(
  EnabledProducts.InvoicePaymentProcessingSeller,
  ProductState.Disabled,
);
export const activeEntityHasInvoicePaymentProcessingSellerInEnabledState = activeEntityHasProductInSomeState(
  EnabledProducts.InvoicePaymentProcessingSeller,
  [ProductState.Active, ProductState.NoApplication, ProductState.Applying, ProductState.Reviewing],
);

export const activeEntityHasNetTermsSellerInDisabledState = activeEntityHasProductInState(
  EnabledProducts.NetTermsSeller,
  ProductState.Disabled,
);

export const activeEntityHasNetTermsSellerInActiveState = activeEntityHasProductInState(
  EnabledProducts.NetTermsSeller,
  ProductState.Active,
);

export const activeEntityHasNetTermsSellerInReviewingState = activeEntityHasProductInState(
  EnabledProducts.NetTermsSeller,
  ProductState.Reviewing,
);

export const activeEntityHasNetTermsSellerInApplyingState = activeEntityHasProductInState(
  EnabledProducts.NetTermsSeller,
  ProductState.Applying,
);

export const activeEntityHasNetTermsPayerInReviewingState = activeEntityHasProductInState(
  EnabledProducts.NetTermsPayer,
  ProductState.Reviewing,
);

export const activeEntityHasNetTermsSellerInEnabledState = activeEntityHasProductInSomeState(
  EnabledProducts.NetTermsSeller,
  [ProductState.Active, ProductState.NoApplication, ProductState.Applying, ProductState.Reviewing],
);

export const activeEntityHasReconciliationAccountsInActiveState = activeEntityHasProductInState(
  EnabledProducts.ReconciliationAccounts,
  ProductState.Active,
);

export const activeEntityHasReserveAccountInActiveState = activeEntityHasProductInState(
  EnabledProducts.ReserveAccount,
  ProductState.Active,
);

export const activeEntityHasSomeCardProductInActiveState = oneOfValidator(
  activeEntityHasProductDebitCardsInActiveState,
  activeEntityHasProductChargeCardsInActiveState,
  activeEntityHasProductGrowthCardsInActiveState,
);

export const activeEntityHasExpensesInActiveState = activeEntityHasProductInState(
  EnabledProducts.Expenses,
  ProductState.Active,
);

export const activeEntityHasInsightsV3InActiveState = activeEntityHasProductInSomeState(EnabledProducts.InsightsV3, [
  ProductState.Active,
  ProductState.Onboarding,
]);

export const activeEntityHasSpinsInActiveState = activeEntityHasProductInState(
  EnabledProducts.Spins,
  ProductState.Active,
);

// Group-based User validators 👨‍💻👩‍👩‍👧‍👧

export const userCanApplyForProduct = (product: EnabledProducts): ValidatorFunction => {
  return ({ user }) => {
    if (product == EnabledProducts.ChargeCards) {
      return userHasAddChargeCardsProductApplication({ user });
    }
    // FIXME: Update with right permissions when it gets available
    return user?.brand_role == BrandRoles.BrandOwner;
  };
};

export const userCanSendInternationalWires: ValidatorFunction = ({ user }) => {
  return user?.brand_role == BrandRoles.BrandOwner || user?.brand_role == BrandRoles.BrandAdmin;
};

export const userBancoApplicationHasBeenDenied: ValidatorFunction = ({ applicationStatuses }) =>
  get(applicationStatuses, ["banco_application", "status"]) === BancoApplicationStatus.Denied;

export const userChargeCardsApplicationHasBeenDenied: ValidatorFunction = ({ applicationStatuses }) =>
  get(applicationStatuses, ["charge_cards_application", "status"]) === ChargeCardsProductApplicationState.Denied &&
  !isMoreThan90Days(applicationStatuses?.charge_cards_application?.updated_at);

export const userFundingApplicationHasBeenDenied: ValidatorFunction = ({ applicationStatuses }) =>
  includes(get(applicationStatuses, ["funding_application", "status"]), [
    FundingApplicationStatus.Passed,
    // FundingApplicationStatus.Lost,
    // FundingApplicationStatus.SuspectedFraud,
    // FundingApplicationStatus.Ignore,
  ]) && !isMoreThan90Days(applicationStatuses?.charge_cards_application?.updated_at);

export const userHasVerifiedPhone: ValidatorFunction = ({ user }) => get(user, "contact.verified_phone_number", false);

export const userIsStaff: ValidatorFunction = ({ user }) => !!user?.is_staff;

export const userHasVendorRelationship: ValidatorFunction = ({ user }) => !!user?.referral_entity;

// Permission-based User validators 👨‍💻🔐

const userHasAnyOfPermissions =
  (anyOfPermissions: UserPermissions[]): ValidatorFunction =>
  ({ user }) =>
    !!anyOfPermissions.find((permission) => user?.permissions?.[permission]);

const userIsStaffOrHasAnyOfPermissions =
  (anyOfPermissions: UserPermissions[]): ValidatorFunction =>
  ({ user }) =>
    user?.is_staff || userHasAnyOfPermissions(anyOfPermissions)({ user });

const userIsStaffAndHasAnyOfPermissions =
  (anyOfPermissions: UserPermissions[]): ValidatorFunction =>
  ({ user }) =>
    !!(user?.is_staff && userHasAnyOfPermissions(anyOfPermissions)({ user }));

// User Management

export const userHasCreateUserPermission = userIsStaffOrHasAnyOfPermissions(getAllBrandRolePerms(BrandRoleAction.Add));

export const userHasTeamPagePermission = userIsStaffOrHasAnyOfPermissions([
  ...getAllBrandRolePerms(BrandRoleAction.Add),
  ...getAllBrandRolePerms(BrandRoleAction.Change),
]);

export const userHasBrandRoleActionPermission = (brandRole: BrandRole, action: BrandRoleAction): ValidatorFunction =>
  userIsStaffOrHasAnyOfPermissions([getBrandRolePerm(brandRole, action)].filter(Boolean) as UserPermissions[]);

// Bill Pay

export const userHasBillPaySetupPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddBillPaySettings,
  UserPermissions.ChangeBillPaySettings,
]);

export const userHasAddBillPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.AddBill]);
export const userHasChangeBillPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ChangeBill]);
export const userHasDeleteBillPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.DeleteBill]);
export const userHasAddPaymentPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.AddPayment]);
export const userHasDeletePaymentPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.DeletePayment]);
export const userHasEditBillPayAccountingIntegrationPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.EditBillPayAccountingIntegration,
]);
export const userHasViewBillPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewBill]);
export const userHasViewBillPaymentPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewPayment]);
export const userHasViewBillPayCheckListPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewBillPayCheckList,
]);

// Pay Later
export const userHasPayLaterSetupPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddPayLaterSettings,
  UserPermissions.ChangePayLaterSettings,
  // TODO - also trigger this api but i don't have a permission for it yet => crud("create", "/banco/repayment_account/", data)
]);

export const userHasAddPayLaterPaymentPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddPayLaterPayment,
]);

export const userHasDeletePayLaterPaymentPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.DeletePayLaterPayment,
]);

// Banco Instruction & Transactions Permission Validators
export const userHasAddBancoInstructionPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddBancoInstruction,
]);

export const userHasCancelBancoInstructionPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.CancelBancoInstruction,
]);

export const userHasViewBancoInstructionPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewBancoInstruction,
]);

export const userHasViewCardTransactionsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewOwnCardTransactions,
  UserPermissions.ViewAllCardTransactions,
  UserPermissions.ViewBancoInstruction,
]);

export const userHasViewBancoTransactionPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewBancoTransaction,
]);

// Cards
export const userHasAddCardPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddBancoCard,
  UserPermissions.AddCreditCard,
]);
export const userHasViewOwnCardPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewOwnBancoCards,
  UserPermissions.ViewOwnCreditCards,
]);
export const userHasViewAllCardPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewAllBancoCards,
  UserPermissions.ViewAllCreditCards,
]);
export const userHasViewCardPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewAllBancoCards,
  UserPermissions.ViewOwnBancoCards,
  UserPermissions.ViewAllCreditCards,
  UserPermissions.ViewOwnCreditCards,
]);
export const userHasViewAllCardsSensitiveDataPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewAllBancoCardsSensitiveData,
  UserPermissions.ViewAllCreditCardsSensitiveData,
]);
export const userHasChangeCardPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ChangeBancoCard,
  UserPermissions.ChangeCreditCard,
]);
export const userHasDeleteCardPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.DeleteBancoCard,
  UserPermissions.DeleteCreditCard,
]);
export const userHasUseCardPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.UseBancoCard,
  UserPermissions.UseCreditCard,
]);
export const userHasFreezeCardPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.FreezeBancoCard,
  UserPermissions.FreezeCreditCard,
]);
export const userHasFreezeAllCardsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.FreezeAllBancoCards,
  UserPermissions.FreezeAllCreditCards,
]);
export const userHasUnfreezeAllCardsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.UnfreezeAllBancoCards,
  UserPermissions.UnfreezeAllCreditCards,
]);
export const userHasCorporateCardsV2Enabled: ValidatorFunction = ({ user }) => !!user?.corporate_card_v2;
export const userHasCorporateCardsMigrationInfoEnabled: ValidatorFunction = ({ user }) =>
  userHasFeatureFlag(user, FeatureFlags.CorporateCardsI2CMigrationInfo);
export const userHasCorporateCardsMigrationFlowEnabled: ValidatorFunction = ({ user }) =>
  userHasFeatureFlag(user, FeatureFlags.CorporateCardsI2CMigrationFlow);
export const userHasOnlyOwnCardAccess = everyValidator(
  oneOfValidator(userHasViewOwnCardPermission, userHasUseCardPermission),
  negateValidator(userHasViewAllCardPermission),
);
export const userHasViewChargeCardSummaryPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewChargeCardSummary,
]);
export const userHasExportBancoInstructionsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ExportBancoInstructions,
]);
export const userHasExportExpensesPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ExportExpenses]);

// Corporate Card Application
export const userHasAddChargeCardsProductApplication = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddChargeCardsProductApplication,
]);
export const userHasSubmitChargeCardsProductApplication = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.SubmitChargeCardsProductApplication,
]);

// Banco Repayment Account
export const userHasAddProductRepaymentBancoAccountPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddProductRepaymentBancoAccount,
]);
export const userHasDeleteProductRepaymentBancoAccountPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.DeleteProductRepaymentBancoAccount,
]);
export const userHasViewProductRepaymentBancoAccountPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewProductRepaymentBancoAccount,
]);

// GLOC
export const userHasAddGLOCPaymentPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.AddGLOCPayment]);

// Banking Accounts Permission Validators
export const userHasAddBancoAccountPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.AddBancoAccount]);
export const userHasViewBancoAccountPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewBancoAccount]);
export const userHasChangeBancoAccountPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ChangeBancoAccount,
]);
export const userHasDeletePaymentMethodPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.DeletePaymentMethod,
]);
export const userHasViewSensitiveBankInfoPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewSensitiveBankInfo,
]);

// Funding Request Permission Validators
export const userHasRequestFundingPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.RequestFunding]);

// Money Acount
export const userHasAddMoneyAccountPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.AddMoneyAccount]);
export const userHasChangeMoneyAccountPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ChangeMoneyAccount,
]);
export const userHasViewMoneyAccountPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewMoneyAccount]);
export const userHasViewPlaidAccountPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewPlaidAccount]);
export const userHasAddPlaidItemPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.AddPlaidItem]);
export const userHasChangePlaidItemPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ChangePlaidItem]);
export const userHasViewPlaidItemPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewPlaidItem]);
export const userHasAddBankConnectionPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.AddBankConnection]);
export const userHasChangeBankConnectionPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ChangeBankConnection,
]);
export const userHasViewBankConnectionPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewBankConnection,
]);

// Integrations
export const userHasAddIntegrationPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.AddIntegration]);
export const userHasChangeIntegrationPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ChangeIntegration]);
export const userHasViewIntegrationPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewIntegration]);

// Counterparties Permission Validators
export const userHasAddBancoCounterpartyPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddBancoCounterparty,
]);

export const userHasChangeBancoCounterpartyPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ChangeBancoCounterparty,
]);

export const userHasViewBancoCounterpartyPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewBancoCounterparty,
]);

export const userHasRequestPayLaterCounterpartyApprovalPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.RequestPayLaterCounterpartyApproval,
]);

// Agreement Consents
export const userHasAddAgreementConsentPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddAgreementConsent,
]);

// Transaction Events
export const userHasViewTransactionEventPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewTransactionEvent,
]);

export const userHasExportTransactionEventsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ExportTransactionEvents,
]);

// Self reporting
export const userHasAccessSelfReportingPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AccessSelfReporting,
]);
export const userHasAddCovenantReportingFilePermission = userHasAnyOfPermissions([
  UserPermissions.AddContractCovenantReportingFile,
]);
export const userHasDeleteCovenantReportingFilePermission = userHasAnyOfPermissions([
  UserPermissions.DeleteContractCovenantReportingFile,
]);

// Rewards
export const userHasViewRewardsPermission = userHasAnyOfPermissions([UserPermissions.ViewRewardsSummary]);
export const userHasRedeemRewardsPermission = userHasAnyOfPermissions([UserPermissions.RedeemRewards]);

// Insights

export const userHasViewBasicInsightsPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewBasicInsights]);

export const activeEntityHasInsightsV3Enabled = everyValidator(
  activeEntityHasInsightsV3InActiveState,
  negateValidator(userHasOnlyOwnCardAccess),
);

export const activeEntityHasInsightsV2Enabled = everyValidator(
  // ToDo: We probably need a permission to control what user roles can view insights,
  // once we have that we should no longer check for card only users.
  activeEntityHasInsightsEmbeddedInActiveState,
  negateValidator(userHasOnlyOwnCardAccess),
  negateValidator(activeEntityHasInsightsV3Enabled),
);

export const activeEntityHasInsightsV1Enabled = everyValidator(
  // ToDo: We probably need a permission to control what user roles can view insights,
  // once we have that we should no longer check for card only users.
  negateValidator(userHasOnlyOwnCardAccess),
  negateValidator(activeEntityHasInsightsV2Enabled),
  negateValidator(activeEntityHasInsightsV3Enabled),
);

export const activeEntityHasInsightsEnabled = oneOfValidator(
  activeEntityHasInsightsV1Enabled,
  activeEntityHasInsightsV2Enabled,
  activeEntityHasInsightsV3Enabled,
);

// Net Terms
export const userIsNetTermsPayerSignup: ValidatorFunction = (params) => {
  const { user } = params;
  const isNetTermsPayerSignup = [
    SignupSource.NET_TERMS_BRAND_REFERRAL,
    SignupSource.NET_TERMS_BRAND_INVITATION,
  ].includes(user?.signup_source ?? ("" as SignupSource));
  return isNetTermsPayerSignup && (!user?.active_entity || !user?.active_entity_address || !user?.connected_plaid);
};

export const userIsNetTermsSellerSignup: ValidatorFunction = (params) => {
  const { user } = params;
  const isNetTermsSellerSignup = user?.signup_source === SignupSource.NET_TERMS_VENDOR;
  return (
    isNetTermsSellerSignup &&
    (!user?.active_entity || !user?.active_entity_address || !user.contact?.verified_phone_number)
  );
};

// AP Automation
export const userHasAddAutomatedProductsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddAutomatedProducts,
]);
export const userHasViewAutomatedProductsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewAutomatedProducts,
]);
export const userHasChangeAutomatedProductsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ChangeAutomatedProducts,
]);
export const userHasAddEntityAmplaCategoryPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.AddEntityAmplaCategory,
]);
export const userHasViewEntityAmplaCategoryPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewEntityAmplaCategory,
]);
export const useHasChangeEntityAmplaCategoryPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ChangeEntityAmplaCategory,
]);
export const useHasViewAPAutomationSettingsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewAPAutomationSettings,
]);
export const userHasViewAmplaCategoryPermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewAmplaCategory]);

// Expenses
export const userHasViewExpensePermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewExpense]);
export const userHasViewAllExpensePermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewAllExpense]);
export const userHasViewUserNeedsReviewExpensePermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewUserNeedsReviewExpense,
]);
export const userHasChangeExpensePermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ChangeExpense]);
export const userHasViewExpensesSettingsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ViewExpensesSettings,
]);
export const userHasChangeExpensesSettingsPermission = userIsStaffOrHasAnyOfPermissions([
  UserPermissions.ChangeExpensesSettings,
]);

// Home Page
export const userHasViewFullHomePagePermission = userIsStaffOrHasAnyOfPermissions([UserPermissions.ViewFullHomePage]);

// INTERNAL
export const userHasAccessAllBrandsPermission = userIsStaffAndHasAnyOfPermissions([UserPermissions.AccessAllBrands]);

export const userHasLoginAsBrandUserPermission = userIsStaffAndHasAnyOfPermissions([UserPermissions.LoginAsBrandUser]);
