export const AMPLA_COMPANY_NAME = "Ampla";
export const AMPLA_DOMAIN = "getampla.com";

export const COMPANY_NAME = AMPLA_COMPANY_NAME;
export const COMPANY_HOMEPAGE_DOMAIN = AMPLA_DOMAIN;
export const COMPANY_HOMEPAGE_URL = `https://${AMPLA_DOMAIN}/`;
export const BANK_PROVIDER_NAME = "Blue Ridge Bank, N.A.";
export const BANK_PROVIDER_ADDRESS_LINE_1 = "17 West Main Street";
export const BANK_PROVIDER_ADDRESS_LINE_2 = "Luray, Virginia 22835";
export const COMPANY_RECEIVING_ADDRESS_LINE_1 = "7813 Office Park Blvd";
export const COMPANY_RECEIVING_ADDRESS_LINE_2 = "Baton Rouge, LA 70809";
export const COMPANY_SWIFT_CODE = "FRNAUS44XXX";
export const COMPANY_DEPOSIT_ADDRESS_LINE_1 = "PO Box 81338";
export const COMPANY_DEPOSIT_ADDRESS_LINE_2 = "Woburn, MA 01813-1338";
export const companyDepositPaylableTo = (company: string) => `Payable to: ${company}`;
export const GLOC_CONTRACT_EARLY_TERM_FEE_CUTOFF_DATE = "2023-06-05";
export const EFFR_MORE_INFO_URL = "https://www.newyorkfed.org/markets/reference-rates/effr";

export const SIDEBAR_WIDTH = 250;

// ToDo: Move this to queryKeys file
export const CURRENT_USER_QUERY_KEY = "current-user";

export const OPS_CONTACT_EMAILS = {
  Admin: `contact@${COMPANY_HOMEPAGE_DOMAIN}`,
  Support: `support@${COMPANY_HOMEPAGE_DOMAIN}`,
};

export const TERMS_AGREEMENT_VERSION_SLUG = "platform-agreement-2023-12-21";
export const PRIVACY_AGREEMENT_VERSION_SLUG = "privacy-2023-12-21";
export const EDISCLOSURE_AGREEMENT_VERSION_SLUG = "edisclosure-2023-12-21";
export const DEPOSIT_AGREEMENT_VERSION_SLUG = "deposit-thread-2024-04-05";
export const CARDHOLDER_AGREEMENT_VERSION_SLUG = "ampla-visa-debit-cardholder-agreement-09-26-2023";
export const CHARGE_CARDS_PROGRAM_TERMS_AGREEMENT_VERSION_SLUG = "corporate-card-terms-2023-09-01";
export const CARDS_REWARD_PROGRAM_TERMS_AGREEMENT_VERSION_SLUG = "card-rewards-terms-2023-05-08";
export const CALIFORNIA_DISCLOSURE_GROWTH_LINE_OF_CREDIT_VERSION_SLUG = "california-disclosure-2022-12-08";
export const NEW_YORK_DISCLOSURE_GROWTH_LINE_OF_CREDIT_VERSION_SLUG = "new-york-disclosure-2023-08-23";
export const NEW_YORK_DISCLOSURE_GROWTH_LINE_OF_CREDIT_VERSION_SLUG_NO_EARLY_TERM =
  "new-york-disclosure-no-term-fees-2023-09-20";
export const NET_TERMS_BRAND_AGREEMENT_VERSION_SLUG = "net-terms-brand";
export const PAY_LATER_ADVANCE_SHEET_VERSION_SLUG = "paylater-advance-sheet-2023-01-03-final";
export const PAY_LATER_AGREEMENT_VERSION_SLUG = "paylater-2023-03-23-final";
export const PAY_LATER_DISCLOSURE_VERSION_SLUG = "paylater-disclosure-2023-01-01";
export const CALIFORNIA_DISCLOSURE_PAY_LATER_VERSION_SLUG = "california-disclosure-paylater-2023-02-06";
export const CREDIT_I2C_AGREEMENT_SLUG = "credit-i2c-agreement-slug";

export const NICKNAME_TOOLTIP_TEXT = "Name used for their DBA, FKA or an internal identifier";

export const FILE_UPLOAD_DOCUMENT_TYPES = {
  Invoice: "invoice",
};

export const FILE_UPLOAD_DOCUMENT_STATUS = {
  Draft: "draft",
  Temporary: "temporary",
  Discarded: "discarded",
  Reviewing: "reviewing",
  Signing: "signing",
  Executed: "executed",
};

export const EXTENSION_MEDIA_TYPES = {
  AAC: ["audio/aac"],
  ABW: ["application/x-abiword"],
  ARC: ["application/x-freearc"],
  AVI: ["video/x-msvideo"],
  AZW: ["application/vnd.amazon.ebook"],
  BIN: ["application/octet-stream"],
  BMP: ["image/bmp"],
  BZ: ["application/x-bzip"],
  BZ2: ["application/x-bzip2"],
  CDA: ["application/x-cdf"],
  CSH: ["application/x-csh"],
  CSS: ["text/css"],
  CSV: ["text/csv"],
  DOC: ["application/msword"],
  DOCX: ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
  EOT: ["application/vnd.ms-fontobject"],
  EPUB: ["application/epub+zip"],
  GIF: ["image/gif"],
  GZ: ["application/gzip"],
  HTM: ["text/html"],
  HTML: ["text/html"],
  ICO: ["image/vnd.microsoft.icon"],
  ICS: ["text/calendar"],
  JAR: ["application/java-archive"],
  JPEG: ["image/jpeg", "image/pjpeg"],
  JPG: ["image/jpeg", "image/pjpeg"],
  JS: ["text/javascript"],
  JSON: ["application/json"],
  JSONLD: ["application/ld+json"],
  MID: ["audio/midi audio/x-midi"],
  MIDI: ["audio/midi audio/x-midi"],
  MJS: ["text/javascript"],
  MP3: ["audio/mpeg"],
  MP4: ["video/mp4"],
  MPEG: ["video/mpeg"],
  MPKG: ["application/vnd.apple.installer+xml"],
  ODP: ["application/vnd.oasis.opendocument.presentation"],
  ODS: ["application/vnd.oasis.opendocument.spreadsheet"],
  ODT: ["application/vnd.oasis.opendocument.text"],
  OGA: ["audio/ogg"],
  OGV: ["video/ogg"],
  OGX: ["application/ogg"],
  OPUS: ["audio/opus"],
  OTF: ["font/otf"],
  PDF: ["application/pdf"],
  PHP: ["application/x-httpd-php"],
  PNG: ["image/png"],
  PPT: ["application/vnd.ms-powerpoint"],
  PPTX: ["application/vnd.openxmlformats-officedocument.presentationml.presentation"],
  RAR: ["application/vnd.rar"],
  RTF: ["application/rtf"],
  SH: ["application/x-sh"],
  SVG: ["image/svg+xml"],
  SWF: ["application/x-shockwave-flash"],
  TAR: ["application/x-tar"],
  TIF: ["image/tiff"],
  TIFF: ["image/tiff"],
  TS: ["video/mp2t"],
  TTF: ["font/ttf"],
  TXT: ["text/plain"],
  VSD: ["application/vnd.visio"],
  WAV: ["audio/wav"],
  WEBA: ["audio/webm"],
  WEBM: ["video/webm"],
  WEBP: ["image/webp"],
  WOFF: ["font/woff"],
  WOFF2: ["font/woff2"],
  XHTML: ["application/xhtml+xml"],
  XLS: ["application/vnd.ms-excel"],
  XLSX: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  XLSM: ["application/vnd.ms-excel.sheet.macroEnabled.12"],
  XML: ["application/xml"],
  XUL: ["application/vnd.mozilla.xul+xml"],
  ZIP: ["application/zip"],
};

export const ENTITY_TYPES = {
  ApplyingBrand: "brand_applying",
  ApprovedVendor: "approved_vendor",
  Bank: "bank",
  Brand: "brand",
  Buyer: "buyer",
  Copacker: "copacker",
  DisabledBrand: "brand_disabled",
  GourmetGrowth: "gg",
  InactiveBrand: "brand_inactive",
  Manufacturer: "manufacturer",
  PaymentProcessor: "payment_processor",
  Shipper: "shipper",
  UnapprovedVendor: "unapproved_vendor",
  Warehouse: "warehouse",
  NetTermsVendor: "net_terms_vendor",
};

export const DOCUMENT_ID_MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const COUNTRIES = [
  {
    name: "Afghanistan",
    iso2: "AF",
    iso3: "AFG",
  },
  {
    name: "Åland Islands",
    iso2: "AX",
    iso3: "ALA",
  },
  {
    name: "Albania",
    iso2: "AL",
    iso3: "ALB",
  },
  {
    name: "Algeria",
    iso2: "DZ",
    iso3: "DZA",
  },
  {
    name: "American Samoa",
    iso2: "AS",
    iso3: "ASM",
  },
  {
    name: "Andorra",
    iso2: "AD",
    iso3: "AND",
  },
  {
    name: "Angola",
    iso2: "AO",
    iso3: "AGO",
  },
  {
    name: "Anguilla",
    iso2: "AI",
    iso3: "AIA",
  },
  {
    name: "Antarctica",
    iso2: "AQ",
    iso3: "ATA",
  },
  {
    name: "Antigua and Barbuda",
    iso2: "AG",
    iso3: "ATG",
  },
  {
    name: "Argentina",
    iso2: "AR",
    iso3: "ARG",
  },
  {
    name: "Armenia",
    iso2: "AM",
    iso3: "ARM",
  },
  {
    name: "Aruba",
    iso2: "AW",
    iso3: "ABW",
  },
  {
    name: "Australia",
    iso2: "AU",
    iso3: "AUS",
  },
  {
    name: "Austria",
    iso2: "AT",
    iso3: "AUT",
  },
  {
    name: "Azerbaijan",
    iso2: "AZ",
    iso3: "AZE",
  },
  {
    name: "Bahamas",
    iso2: "BS",
    iso3: "BHS",
  },
  {
    name: "Bahrain",
    iso2: "BH",
    iso3: "BHR",
  },
  {
    name: "Bangladesh",
    iso2: "BD",
    iso3: "BGD",
  },
  {
    name: "Barbados",
    iso2: "BB",
    iso3: "BRB",
  },
  {
    name: "Belarus",
    iso2: "BY",
    iso3: "BLR",
  },
  {
    name: "Belgium",
    iso2: "BE",
    iso3: "BEL",
  },
  {
    name: "Belize",
    iso2: "BZ",
    iso3: "BLZ",
  },
  {
    name: "Benin",
    iso2: "BJ",
    iso3: "BEN",
  },
  {
    name: "Bermuda",
    iso2: "BM",
    iso3: "BMU",
  },
  {
    name: "Bhutan",
    iso2: "BT",
    iso3: "BTN",
  },
  {
    name: "Bolivia (Plurinational State of)",
    iso2: "BO",
    iso3: "BOL",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    iso2: "BQ",
    iso3: "BES",
  },
  {
    name: "Bosnia and Herzegovina",
    iso2: "BA",
    iso3: "BIH",
  },
  {
    name: "Botswana",
    iso2: "BW",
    iso3: "BWA",
  },
  {
    name: "Bouvet Island",
    iso2: "BV",
    iso3: "BVT",
  },
  {
    name: "Brazil",
    iso2: "BR",
    iso3: "BRA",
  },
  {
    name: "British Indian Ocean Territory",
    iso2: "IO",
    iso3: "IOT",
  },
  {
    name: "Brunei Darussalam",
    iso2: "BN",
    iso3: "BRN",
  },
  {
    name: "Bulgaria",
    iso2: "BG",
    iso3: "BGR",
  },
  {
    name: "Burkina Faso",
    iso2: "BF",
    iso3: "BFA",
  },
  {
    name: "Burundi",
    iso2: "BI",
    iso3: "BDI",
  },
  {
    name: "Cabo Verde",
    iso2: "CV",
    iso3: "CPV",
  },
  {
    name: "Cambodia",
    iso2: "KH",
    iso3: "KHM",
  },
  {
    name: "Cameroon",
    iso2: "CM",
    iso3: "CMR",
  },
  {
    name: "Canada",
    iso2: "CA",
    iso3: "CAN",
  },
  {
    name: "Cayman Islands",
    iso2: "KY",
    iso3: "CYM",
  },
  {
    name: "Central African Republic",
    iso2: "CF",
    iso3: "CAF",
  },
  {
    name: "Chad",
    iso2: "TD",
    iso3: "TCD",
  },
  {
    name: "Chile",
    iso2: "CL",
    iso3: "CHL",
  },
  {
    name: "China",
    iso2: "CN",
    iso3: "CHN",
  },
  {
    name: "Christmas Island",
    iso2: "CX",
    iso3: "CXR",
  },
  {
    name: "Cocos (Keeling) Islands",
    iso2: "CC",
    iso3: "CCK",
  },
  {
    name: "Colombia",
    iso2: "CO",
    iso3: "COL",
  },
  {
    name: "Comoros",
    iso2: "KM",
    iso3: "COM",
  },
  {
    name: "Congo",
    iso2: "CG",
    iso3: "COG",
  },
  {
    name: "Congo (Democratic Republic of the)",
    iso2: "CD",
    iso3: "COD",
  },
  {
    name: "Cook Islands",
    iso2: "CK",
    iso3: "COK",
  },
  {
    name: "Costa Rica",
    iso2: "CR",
    iso3: "CRI",
  },
  {
    name: "Côte d'Ivoire",
    iso2: "CI",
    iso3: "CIV",
  },
  {
    name: "Croatia",
    iso2: "HR",
    iso3: "HRV",
  },
  {
    name: "Cuba",
    iso2: "CU",
    iso3: "CUB",
  },
  {
    name: "Curaçao",
    iso2: "CW",
    iso3: "CUW",
  },
  {
    name: "Cyprus",
    iso2: "CY",
    iso3: "CYP",
  },
  {
    name: "Czechia",
    iso2: "CZ",
    iso3: "CZE",
  },
  {
    name: "Denmark",
    iso2: "DK",
    iso3: "DNK",
  },
  {
    name: "Djibouti",
    iso2: "DJ",
    iso3: "DJI",
  },
  {
    name: "Dominica",
    iso2: "DM",
    iso3: "DMA",
  },
  {
    name: "Dominican Republic",
    iso2: "DO",
    iso3: "DOM",
  },
  {
    name: "Ecuador",
    iso2: "EC",
    iso3: "ECU",
  },
  {
    name: "Egypt",
    iso2: "EG",
    iso3: "EGY",
  },
  {
    name: "El Salvador",
    iso2: "SV",
    iso3: "SLV",
  },
  {
    name: "Equatorial Guinea",
    iso2: "GQ",
    iso3: "GNQ",
  },
  {
    name: "Eritrea",
    iso2: "ER",
    iso3: "ERI",
  },
  {
    name: "Estonia",
    iso2: "EE",
    iso3: "EST",
  },
  {
    name: "Eswatini",
    iso2: "SZ",
    iso3: "SWZ",
  },
  {
    name: "Ethiopia",
    iso2: "ET",
    iso3: "ETH",
  },
  {
    name: "Falkland Islands (Malvinas)",
    iso2: "FK",
    iso3: "FLK",
  },
  {
    name: "Faroe Islands",
    iso2: "FO",
    iso3: "FRO",
  },
  {
    name: "Fiji",
    iso2: "FJ",
    iso3: "FJI",
  },
  {
    name: "Finland",
    iso2: "FI",
    iso3: "FIN",
  },
  {
    name: "France",
    iso2: "FR",
    iso3: "FRA",
  },
  {
    name: "French Guiana",
    iso2: "GF",
    iso3: "GUF",
  },
  {
    name: "French Polynesia",
    iso2: "PF",
    iso3: "PYF",
  },
  {
    name: "French Southern Territories",
    iso2: "TF",
    iso3: "ATF",
  },
  {
    name: "Gabon",
    iso2: "GA",
    iso3: "GAB",
  },
  {
    name: "Gambia",
    iso2: "GM",
    iso3: "GMB",
  },
  {
    name: "Georgia",
    iso2: "GE",
    iso3: "GEO",
  },
  {
    name: "Germany",
    iso2: "DE",
    iso3: "DEU",
  },
  {
    name: "Ghana",
    iso2: "GH",
    iso3: "GHA",
  },
  {
    name: "Gibraltar",
    iso2: "GI",
    iso3: "GIB",
  },
  {
    name: "Greece",
    iso2: "GR",
    iso3: "GRC",
  },
  {
    name: "Greenland",
    iso2: "GL",
    iso3: "GRL",
  },
  {
    name: "Grenada",
    iso2: "GD",
    iso3: "GRD",
  },
  {
    name: "Guadeloupe",
    iso2: "GP",
    iso3: "GLP",
  },
  {
    name: "Guam",
    iso2: "GU",
    iso3: "GUM",
  },
  {
    name: "Guatemala",
    iso2: "GT",
    iso3: "GTM",
  },
  {
    name: "Guernsey",
    iso2: "GG",
    iso3: "GGY",
  },
  {
    name: "Guinea",
    iso2: "GN",
    iso3: "GIN",
  },
  {
    name: "Guinea-Bissau",
    iso2: "GW",
    iso3: "GNB",
  },
  {
    name: "Guyana",
    iso2: "GY",
    iso3: "GUY",
  },
  {
    name: "Haiti",
    iso2: "HT",
    iso3: "HTI",
  },
  {
    name: "Heard Island and McDonald Islands",
    iso2: "HM",
    iso3: "HMD",
  },
  {
    name: "Holy See",
    iso2: "VA",
    iso3: "VAT",
  },
  {
    name: "Honduras",
    iso2: "HN",
    iso3: "HND",
  },
  {
    name: "Hong Kong",
    iso2: "HK",
    iso3: "HKG",
  },
  {
    name: "Hungary",
    iso2: "HU",
    iso3: "HUN",
  },
  {
    name: "Iceland",
    iso2: "IS",
    iso3: "ISL",
  },
  {
    name: "India",
    iso2: "IN",
    iso3: "IND",
  },
  {
    name: "Indonesia",
    iso2: "ID",
    iso3: "IDN",
  },
  {
    name: "Iran (Islamic Republic of)",
    iso2: "IR",
    iso3: "IRN",
  },
  {
    name: "Iraq",
    iso2: "IQ",
    iso3: "IRQ",
  },
  {
    name: "Ireland",
    iso2: "IE",
    iso3: "IRL",
  },
  {
    name: "Isle of Man",
    iso2: "IM",
    iso3: "IMN",
  },
  {
    name: "Israel",
    iso2: "IL",
    iso3: "ISR",
  },
  {
    name: "Italy",
    iso2: "IT",
    iso3: "ITA",
  },
  {
    name: "Jamaica",
    iso2: "JM",
    iso3: "JAM",
  },
  {
    name: "Japan",
    iso2: "JP",
    iso3: "JPN",
  },
  {
    name: "Jersey",
    iso2: "JE",
    iso3: "JEY",
  },
  {
    name: "Jordan",
    iso2: "JO",
    iso3: "JOR",
  },
  {
    name: "Kazakhstan",
    iso2: "KZ",
    iso3: "KAZ",
  },
  {
    name: "Kenya",
    iso2: "KE",
    iso3: "KEN",
  },
  {
    name: "Kiribati",
    iso2: "KI",
    iso3: "KIR",
  },
  {
    name: "North Korea (Democratic People's Republic of)",
    iso2: "KP",
    iso3: "PRK",
  },
  {
    name: "South Korea (Republic of)",
    iso2: "KR",
    iso3: "KOR",
  },
  {
    name: "Kuwait",
    iso2: "KW",
    iso3: "KWT",
  },
  {
    name: "Kyrgyzstan",
    iso2: "KG",
    iso3: "KGZ",
  },
  {
    name: "Laos (People's Democratic Republic)",
    iso2: "LA",
    iso3: "LAO",
  },
  {
    name: "Latvia",
    iso2: "LV",
    iso3: "LVA",
  },
  {
    name: "Lebanon",
    iso2: "LB",
    iso3: "LBN",
  },
  {
    name: "Lesotho",
    iso2: "LS",
    iso3: "LSO",
  },
  {
    name: "Liberia",
    iso2: "LR",
    iso3: "LBR",
  },
  {
    name: "Libya",
    iso2: "LY",
    iso3: "LBY",
  },
  {
    name: "Liechtenstein",
    iso2: "LI",
    iso3: "LIE",
  },
  {
    name: "Lithuania",
    iso2: "LT",
    iso3: "LTU",
  },
  {
    name: "Luxembourg",
    iso2: "LU",
    iso3: "LUX",
  },
  {
    name: "Macao",
    iso2: "MO",
    iso3: "MAC",
  },
  {
    name: "Madagascar",
    iso2: "MG",
    iso3: "MDG",
  },
  {
    name: "Malawi",
    iso2: "MW",
    iso3: "MWI",
  },
  {
    name: "Malaysia",
    iso2: "MY",
    iso3: "MYS",
  },
  {
    name: "Maldives",
    iso2: "MV",
    iso3: "MDV",
  },
  {
    name: "Mali",
    iso2: "ML",
    iso3: "MLI",
  },
  {
    name: "Malta",
    iso2: "MT",
    iso3: "MLT",
  },
  {
    name: "Marshall Islands",
    iso2: "MH",
    iso3: "MHL",
  },
  {
    name: "Martinique",
    iso2: "MQ",
    iso3: "MTQ",
  },
  {
    name: "Mauritania",
    iso2: "MR",
    iso3: "MRT",
  },
  {
    name: "Mauritius",
    iso2: "MU",
    iso3: "MUS",
  },
  {
    name: "Mayotte",
    iso2: "YT",
    iso3: "MYT",
  },
  {
    name: "Mexico",
    iso2: "MX",
    iso3: "MEX",
  },
  {
    name: "Micronesia (Federated States of)",
    iso2: "FM",
    iso3: "FSM",
  },
  {
    name: "Moldova (Republic of)",
    iso2: "MD",
    iso3: "MDA",
  },
  {
    name: "Monaco",
    iso2: "MC",
    iso3: "MCO",
  },
  {
    name: "Mongolia",
    iso2: "MN",
    iso3: "MNG",
  },
  {
    name: "Montenegro",
    iso2: "ME",
    iso3: "MNE",
  },
  {
    name: "Montserrat",
    iso2: "MS",
    iso3: "MSR",
  },
  {
    name: "Morocco",
    iso2: "MA",
    iso3: "MAR",
  },
  {
    name: "Mozambique",
    iso2: "MZ",
    iso3: "MOZ",
  },
  {
    name: "Myanmar",
    iso2: "MM",
    iso3: "MMR",
  },
  {
    name: "Namibia",
    iso2: "NA",
    iso3: "NAM",
  },
  {
    name: "Nauru",
    iso2: "NR",
    iso3: "NRU",
  },
  {
    name: "Nepal",
    iso2: "NP",
    iso3: "NPL",
  },
  {
    name: "Netherlands",
    iso2: "NL",
    iso3: "NLD",
  },
  {
    name: "New Caledonia",
    iso2: "NC",
    iso3: "NCL",
  },
  {
    name: "New Zealand",
    iso2: "NZ",
    iso3: "NZL",
  },
  {
    name: "Nicaragua",
    iso2: "NI",
    iso3: "NIC",
  },
  {
    name: "Niger",
    iso2: "NE",
    iso3: "NER",
  },
  {
    name: "Nigeria",
    iso2: "NG",
    iso3: "NGA",
  },
  {
    name: "Niue",
    iso2: "NU",
    iso3: "NIU",
  },
  {
    name: "Norfolk Island",
    iso2: "NF",
    iso3: "NFK",
  },
  {
    name: "North Macedonia",
    iso2: "MK",
    iso3: "MKD",
  },
  {
    name: "Northern Mariana Islands",
    iso2: "MP",
    iso3: "MNP",
  },
  {
    name: "Norway",
    iso2: "NO",
    iso3: "NOR",
  },
  {
    name: "Oman",
    iso2: "OM",
    iso3: "OMN",
  },
  {
    name: "Pakistan",
    iso2: "PK",
    iso3: "PAK",
  },
  {
    name: "Palau",
    iso2: "PW",
    iso3: "PLW",
  },
  {
    name: "Palestine (State of)",
    iso2: "PS",
    iso3: "PSE",
  },
  {
    name: "Panama",
    iso2: "PA",
    iso3: "PAN",
  },
  {
    name: "Papua New Guinea",
    iso2: "PG",
    iso3: "PNG",
  },
  {
    name: "Paraguay",
    iso2: "PY",
    iso3: "PRY",
  },
  {
    name: "Peru",
    iso2: "PE",
    iso3: "PER",
  },
  {
    name: "Philippines",
    iso2: "PH",
    iso3: "PHL",
  },
  {
    name: "Pitcairn",
    iso2: "PN",
    iso3: "PCN",
  },
  {
    name: "Poland",
    iso2: "PL",
    iso3: "POL",
  },
  {
    name: "Portugal",
    iso2: "PT",
    iso3: "PRT",
  },
  {
    name: "Puerto Rico",
    iso2: "PR",
    iso3: "PRI",
  },
  {
    name: "Qatar",
    iso2: "QA",
    iso3: "QAT",
  },
  {
    name: "Réunion",
    iso2: "RE",
    iso3: "REU",
  },
  {
    name: "Romania",
    iso2: "RO",
    iso3: "ROU",
  },
  {
    name: "Russian Federation",
    iso2: "RU",
    iso3: "RUS",
  },
  {
    name: "Rwanda",
    iso2: "RW",
    iso3: "RWA",
  },
  {
    name: "Saint Barthélemy",
    iso2: "BL",
    iso3: "BLM",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    iso2: "SH",
    iso3: "SHN",
  },
  {
    name: "Saint Kitts and Nevis",
    iso2: "KN",
    iso3: "KNA",
  },
  {
    name: "Saint Lucia",
    iso2: "LC",
    iso3: "LCA",
  },
  {
    name: "Saint Martin (French part)",
    iso2: "MF",
    iso3: "MAF",
  },
  {
    name: "Saint Pierre and Miquelon",
    iso2: "PM",
    iso3: "SPM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso2: "VC",
    iso3: "VCT",
  },
  {
    name: "Samoa",
    iso2: "WS",
    iso3: "WSM",
  },
  {
    name: "San Marino",
    iso2: "SM",
    iso3: "SMR",
  },
  {
    name: "Sao Tome and Principe",
    iso2: "ST",
    iso3: "STP",
  },
  {
    name: "Saudi Arabia",
    iso2: "SA",
    iso3: "SAU",
  },
  {
    name: "Senegal",
    iso2: "SN",
    iso3: "SEN",
  },
  {
    name: "Serbia",
    iso2: "RS",
    iso3: "SRB",
  },
  {
    name: "Seychelles",
    iso2: "SC",
    iso3: "SYC",
  },
  {
    name: "Sierra Leone",
    iso2: "SL",
    iso3: "SLE",
  },
  {
    name: "Singapore",
    iso2: "SG",
    iso3: "SGP",
  },
  {
    name: "Sint Maarten (Dutch part)",
    iso2: "SX",
    iso3: "SXM",
  },
  {
    name: "Slovakia",
    iso2: "SK",
    iso3: "SVK",
  },
  {
    name: "Slovenia",
    iso2: "SI",
    iso3: "SVN",
  },
  {
    name: "Solomon Islands",
    iso2: "SB",
    iso3: "SLB",
  },
  {
    name: "Somalia",
    iso2: "SO",
    iso3: "SOM",
  },
  {
    name: "South Africa",
    iso2: "ZA",
    iso3: "ZAF",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    iso2: "GS",
    iso3: "SGS",
  },
  {
    name: "South Sudan",
    iso2: "SS",
    iso3: "SSD",
  },
  {
    name: "Spain",
    iso2: "ES",
    iso3: "ESP",
  },
  {
    name: "Sri Lanka",
    iso2: "LK",
    iso3: "LKA",
  },
  {
    name: "Sudan",
    iso2: "SD",
    iso3: "SDN",
  },
  {
    name: "Suriname",
    iso2: "SR",
    iso3: "SUR",
  },
  {
    name: "Svalbard and Jan Mayen",
    iso2: "SJ",
    iso3: "SJM",
  },
  {
    name: "Sweden",
    iso2: "SE",
    iso3: "SWE",
  },
  {
    name: "Switzerland",
    iso2: "CH",
    iso3: "CHE",
  },
  {
    name: "Syrian Arab Republic",
    iso2: "SY",
    iso3: "SYR",
  },
  {
    name: "Taiwan",
    iso2: "TW",
    iso3: "TWN",
  },
  {
    name: "Tajikistan",
    iso2: "TJ",
    iso3: "TJK",
  },
  {
    name: "Tanzania (United Republic of)",
    iso2: "TZ",
    iso3: "TZA",
  },
  {
    name: "Thailand",
    iso2: "TH",
    iso3: "THA",
  },
  {
    name: "Timor-Leste",
    iso2: "TL",
    iso3: "TLS",
  },
  {
    name: "Togo",
    iso2: "TG",
    iso3: "TGO",
  },
  {
    name: "Tokelau",
    iso2: "TK",
    iso3: "TKL",
  },
  {
    name: "Tonga",
    iso2: "TO",
    iso3: "TON",
  },
  {
    name: "Trinidad and Tobago",
    iso2: "TT",
    iso3: "TTO",
  },
  {
    name: "Tunisia",
    iso2: "TN",
    iso3: "TUN",
  },
  {
    name: "Turkey",
    iso2: "TR",
    iso3: "TUR",
  },
  {
    name: "Turkmenistan",
    iso2: "TM",
    iso3: "TKM",
  },
  {
    name: "Turks and Caicos Islands",
    iso2: "TC",
    iso3: "TCA",
  },
  {
    name: "Tuvalu",
    iso2: "TV",
    iso3: "TUV",
  },
  {
    name: "Uganda",
    iso2: "UG",
    iso3: "UGA",
  },
  {
    name: "Ukraine",
    iso2: "UA",
    iso3: "UKR",
  },
  {
    name: "United Arab Emirates",
    iso2: "AE",
    iso3: "ARE",
  },
  {
    name: "United Kingdom (of Great Britain and Northern Ireland)",
    iso2: "GB",
    iso3: "GBR",
  },
  {
    name: "United States of America",
    iso2: "US",
    iso3: "USA",
  },
  {
    name: "United States Minor Outlying Islands",
    iso2: "UM",
    iso3: "UMI",
  },
  {
    name: "Uruguay",
    iso2: "UY",
    iso3: "URY",
  },
  {
    name: "Uzbekistan",
    iso2: "UZ",
    iso3: "UZB",
  },
  {
    name: "Vanuatu",
    iso2: "VU",
    iso3: "VUT",
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    iso2: "VE",
    iso3: "VEN",
  },
  {
    name: "Vietnam",
    iso2: "VN",
    iso3: "VNM",
  },
  {
    name: "Virgin Islands (British)",
    iso2: "VG",
    iso3: "VGB",
  },
  {
    name: "Virgin Islands (U.S.)",
    iso2: "VI",
    iso3: "VIR",
  },
  {
    name: "Wallis and Futuna",
    iso2: "WF",
    iso3: "WLF",
  },
  {
    name: "Western Sahara",
    iso2: "EH",
    iso3: "ESH",
  },
  {
    name: "Yemen",
    iso2: "YE",
    iso3: "YEM",
  },
  {
    name: "Zambia",
    iso2: "ZM",
    iso3: "ZMB",
  },
  {
    name: "Zimbabwe",
    iso2: "ZW",
    iso3: "ZWE",
  },
];

export const COUNTRIES_PHONE_AREA_CODE_MAP = {
  BD: "880",
  BE: "32",
  BF: "226",
  BG: "359",
  BA: "387",
  BB: "1",
  WF: "681",
  BL: "590",
  BM: "1441",
  BN: "673",
  BO: "591",
  BH: "973",
  BI: "257",
  BJ: "229",
  BT: "975",
  JM: "1876",
  BW: "267",
  WS: "685",
  BQ: "599",
  BR: "55",
  BS: "1",
  JE: "44",
  BY: "375",
  BZ: "501",
  RU: "7",
  RW: "250",
  RS: "381",
  TL: "670",
  RE: "262",
  TM: "993",
  TJ: "992",
  RO: "40",
  TK: "690",
  GW: "245",
  GU: "1",
  GT: "502",
  GR: "30",
  GQ: "240",
  GP: "590",
  JP: "81",
  GY: "592",
  GG: "44",
  GF: "594",
  GE: "995",
  GD: "1",
  GB: "44",
  GA: "241",
  SV: "503",
  GN: "224",
  GM: "220",
  GL: "299",
  GI: "350",
  GH: "233",
  OM: "968",
  TN: "216",
  JO: "962",
  HR: "385",
  HT: "509",
  HU: "36",
  HK: "852",
  HN: "504",
  VE: "58",
  PR: "1",
  PS: "970",
  PW: "680",
  PT: "351",
  SJ: "47",
  PY: "595",
  IQ: "964",
  PA: "507",
  PF: "689",
  PG: "675",
  PE: "51",
  PK: "92",
  PH: "63",
  PN: "870",
  PL: "48",
  PM: "508",
  ZM: "260",
  EH: "212",
  EE: "372",
  EG: "20",
  ZA: "27",
  EC: "593",
  IT: "39",
  VN: "84",
  SB: "677",
  ET: "251",
  SO: "252",
  ZW: "263",
  SA: "966",
  ES: "34",
  ER: "291",
  ME: "382",
  MD: "373",
  MG: "261",
  MF: "590",
  MA: "212",
  MC: "377",
  UZ: "998",
  MM: "95",
  ML: "223",
  MO: "853",
  MN: "976",
  MH: "692",
  MK: "389",
  MU: "230",
  MT: "356",
  MW: "265",
  MV: "960",
  MQ: "596",
  MP: "1",
  MS: "1",
  MR: "222",
  IM: "44",
  UG: "256",
  TZ: "255",
  MY: "60",
  MX: "52",
  IL: "972",
  FR: "33",
  IO: "246",
  SH: "290",
  FI: "358",
  FJ: "679",
  FK: "500",
  FM: "691",
  FO: "298",
  NI: "505",
  NL: "31",
  NO: "47",
  NA: "264",
  VU: "678",
  NC: "687",
  NE: "227",
  NF: "672",
  NG: "234",
  NZ: "64",
  NP: "977",
  NR: "674",
  NU: "683",
  CK: "682",
  CI: "225",
  CH: "41",
  CO: "57",
  CN: "86",
  CM: "237",
  CL: "56",
  CC: "61",
  CA: "1",
  CG: "242",
  CF: "236",
  CD: "243",
  CZ: "420",
  CY: "357",
  CX: "61",
  CR: "506",
  CW: "599",
  CV: "238",
  CU: "53",
  SZ: "268",
  SY: "963",
  SX: "599",
  KG: "996",
  KE: "254",
  SS: "211",
  SR: "597",
  KI: "686",
  KH: "855",
  KN: "1869",
  KM: "269",
  ST: "239",
  SK: "421",
  KR: "82",
  SI: "386",
  KP: "850",
  KW: "965",
  SN: "221",
  SM: "378",
  SL: "232",
  SC: "248",
  KZ: "7",
  KY: "1",
  SG: "65",
  SE: "46",
  SD: "249",
  DO: "1",
  DM: "1",
  DJ: "253",
  DK: "45",
  VG: "1",
  DE: "49",
  YE: "967",
  DZ: "213",
  US: "1",
  UY: "598",
  YT: "262",
  UM: "1",
  LB: "961",
  LC: "1",
  LA: "856",
  TV: "688",
  TW: "886",
  TT: "1",
  TR: "90",
  LK: "94",
  LI: "423",
  LV: "371",
  TO: "676",
  LT: "370",
  LU: "352",
  LR: "231",
  LS: "266",
  TH: "66",
  TG: "228",
  TD: "235",
  TC: "1",
  LY: "218",
  VA: "379",
  VC: "1",
  AE: "971",
  AD: "376",
  AG: "1",
  AF: "93",
  AI: "1",
  VI: "1",
  IS: "354",
  IR: "98",
  AM: "374",
  AL: "355",
  AO: "244",
  AS: "1",
  AR: "54",
  AU: "61",
  AT: "43",
  AW: "297",
  IN: "91",
  AX: "358",
  AZ: "994",
  IE: "353",
  ID: "62",
  UA: "380",
  QA: "974",
  MZ: "258",
};

export const COUNTRIES_PHONE_AREA_CODE_REGEX = new RegExp(
  `^(${Object.values(COUNTRIES_PHONE_AREA_CODE_MAP).join("|")})$`,
);

export const VALID_POSTAL_CODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const YES_NO_OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
