import { startsWith } from "lodash";

/**
 * Frontend deployments change the name of the static JS files. Browsers that have
 * the site loaded may dynamically request files that no longer exist. When this happens
 * the page throws an error and will not render. This function checks for this error and
 * reloads the page if it occurs.
 */
function checkImportError(e: ErrorEvent) {
  if (startsWith(e.message, "Uncaught TypeError: Failed to fetch dynamically imported module:")) {
    window.location.reload();
  }
}

export const registerGlobalErrorHandler = () => {
  // Make sure this is never added more than once
  window.removeEventListener("error", checkImportError);
  window.addEventListener("error", checkImportError);
};
