import crud from "libs/api/client";
import { EntityCalcData, EntityDetail, EntityUpdate, HomePageDashboardData, MembershipEntity } from "./types";

const EntityAPI = {
  create: async (data: any) => crud("create", "/entity/", data), // ToDo: TS Add proper types when we merge new funding application

  update: async (data: EntityUpdate): Promise<EntityUpdate> => crud("update", `/entity/`, data),

  detail: async (id: number): Promise<EntityDetail> => crud("detail", `/entity/${id}/`),

  dashboard: async (id: number): Promise<EntityCalcData> => crud("list", `/entity/${id}/dashboard/`),

  home_dashboard: async (id: number): Promise<HomePageDashboardData> => crud("list", `/entity/${id}/home_dashboard/`),

  config_data: async (id: number, data: { config_type?: "embed" | "filters" | "document_settings" }): Promise<any> =>
    crud("detail", `/entity/${id}/config_data/`, data),

  enable_banco: async (id: number): Promise<void> => crud("update", `/entity/${id}/banco_setup/`),

  user_membership_entities: async (): Promise<Array<MembershipEntity>> =>
    crud("list", `/entity/user_membership_entities/`),
};

export default EntityAPI;
