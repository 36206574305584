import { ForwardedRef, forwardRef } from "react";
import { Table as MUITable } from "@mui/material";

import TableContext from "./Table.context";
import { TableProps, TableSortConfig } from "./Table.types";

const Table = forwardRef(({ sort, onSortChange, ...props }: TableProps, ref: ForwardedRef<HTMLTableElement>) => {
  const handleSortChange = (newSort: TableSortConfig) => onSortChange?.(newSort);

  return (
    <TableContext.Provider value={{ sort, isSortable: !!onSortChange, handleSortChange }}>
      <MUITable ref={ref} {...props} />
    </TableContext.Provider>
  );
});

export default Table;
