import { lazy } from "react";

import { EnabledProducts, FeatureFlags, ProductState } from "@ampla/api";

import { PORTAL_MENU_TRANSACTIONS_OPTION_CLICKED } from "constants/eventKeys";
import { trackEvent } from "helpers/analytics";
import BANCO_HOME_ROUTES from "pages/BancoHome/routing";
import { BANKING_RECIPIENTS_ROUTES } from "pages/Recipients/routing";
import { SEND_PAYMENT_BASE_URL } from "pages/SendPayment/constants";
import {
  activeEntityHasExpensesInActiveState,
  activeEntityHasInsightsV1Enabled,
  activeEntityHasInsightsV2Enabled,
  activeEntityHasInsightsV3Enabled,
  activeEntityHasProductBankingInActiveState,
  activeEntityHasProductBankingInApplyingState,
  activeEntityHasProductBankingInDisabledState,
  activeEntityHasProductBankingInOnboardingState,
  activeEntityHasProductBankingInReviewingState,
  activeEntityHasProductCashbackRewardsInActiveState,
  activeEntityHasProductCashbackRewardsInLockedState,
  activeEntityHasProductChargeCardsInActiveState,
  activeEntityHasProductChargeCardsInApplyingState,
  activeEntityHasProductChargeCardsInLockedState,
  activeEntityHasProductChargeCardsInOnboardingState,
  activeEntityHasProductChargeCardsInPreApprovedState,
  activeEntityHasProductDebitCardsInActiveState,
  activeEntityHasProductFundingInActiveState,
  activeEntityHasProductInSomeState,
  activeEntityHasProductInternationalPayoutsInActiveState,
  everyValidator,
  featureFlagValidator,
  negateValidator,
  oneOfValidator,
  userBancoApplicationHasBeenDenied,
  userCanSendInternationalWires,
  userChargeCardsApplicationHasBeenDenied,
  userHasAddAgreementConsentPermission,
  userHasAddBancoInstructionPermission,
  userHasAddBillPermission,
  userHasChangeAutomatedProductsPermission,
  userHasCorporateCardsV2Enabled,
  userHasViewBancoAccountPermission,
  userHasViewBancoTransactionPermission,
  userHasViewBasicInsightsPermission,
  userHasViewProductRepaymentBancoAccountPermission,
} from "routes/helpers/validators";
import AIRWALLEX_ONBOARDING_ROUTES from "../pages/AirwallexOnboarding/routing";
import { CARD_CONFIRM_STEP, CARD_CONFIRM_STEP_SUCCESS, CARD_CREATE, CARD_DETAIL } from "./common";
import { RouteDeclaration } from "./types";

const AddBill = lazy(() => import("pages/AddBill"));
const SendMoney = lazy(() => import("pages/SendMoney"));
const SendPayment = lazy(() => import("pages/SendPayment"));
const BancoTransactions = lazy(() => import("pages/BancoTransactions"));
const Analytics = lazy(() => import("pages/Analytics"));
const AnalyticsInsightsEmbed = lazy(() => import("pages/AnalyticsInsightsEmbed"));
const ThreadAgreements = lazy(() => import("pages/ThreadAgreements"));
const BankingAdditionalDocumentation = lazy(() => import("pages/BankingAdditionalDocumentation"));

const ChargeCardDashboard = lazy(() => import("pages/ChargeCardDashboard"));
const ChargeCardMakePayment = lazy(() => import("pages/ChargeCardMakePayment"));
const ChargeCardRecurringPayment = lazy(() => import("pages/ChargeCardRecurringPayment"));
const ChargeCardOnboarding = lazy(() => import("pages/ChargeCardOnboarding"));
const ChargeCardOnboardingSuccess = lazy(() => import("pages/ChargeCardOnboardingSuccess"));
const ChargeCardLearnMore = lazy(() => import("pages/ChargeCardLearnMore"));
const CashbackRewardsDashboard = lazy(() => import("pages/CashbackRewardsDashboard"));
const ChargeCardPreApprovedDashboard = lazy(() => import("pages/ChargeCardPreApprovedDashboard"));
const CorporateCardVerification = lazy(() => import("pages/CorporateCardVerification"));
const ExpensesOnboarding = lazy(() => import("pages/ExpensesOnboarding"));
const CorporateCardDeprecatedCards = lazy(
  () => import("pages/ChargeCardDashboard/components/CorporateCardDeprecatedCards"),
);
const CorporateCardDeprecatedTransactions = lazy(
  () => import("pages/ChargeCardDashboard/components/CorporateCardDeprecatedTransactions"),
);

const LinkAccountTransfer = lazy(() => import("pages/LinkAccountTransfer"));
const DepositMoneyTransfer = lazy(() => import("pages/DepositMoneyTransfer"));

const CreatePlaidCounterpartySuccess = lazy(
  () => import("pages/ChargeCardMakePayment/components/CreatePlaidCounterpartySuccess"),
);
const DocumentsRequest = lazy(() => import("pages/DocumentsRequest"));
const RedeemCashback = lazy(() => import("pages/RedeemCashback"));
const Insights = lazy(() => import("pages/Insights"));
const InsightsV3 = lazy(() => import("pages/InsightsV3"));
const InternationalWire = lazy(() => import("pages/InternationalWire"));
const TransferBetweenAccounts = lazy(() => import("pages/TransferBetweenAccounts"));

export const INSIGHTS: RouteDeclaration = {
  key: "insights",
  name: "Insights",
  path: "/insights",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [
    oneOfValidator(
      activeEntityHasInsightsV1Enabled,
      activeEntityHasInsightsV2Enabled,
      activeEntityHasInsightsV3Enabled,
    ),
  ],
  PageComponent: Insights,
};

export const INSIGHTS_V1: RouteDeclaration = {
  key: "insights-v1",
  name: "Insights",
  path: "/insights-v1",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasInsightsV1Enabled, userHasViewBasicInsightsPermission],
  PageComponent: Analytics,
};

export const INSIGHTS_V2: RouteDeclaration = {
  key: "insights",
  name: "Summary",
  title: "Insights",
  path: "/insights-v2",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasInsightsV2Enabled, userHasViewBasicInsightsPermission],
  renderWithHelmet: false,
  PageComponent: AnalyticsInsightsEmbed,
};

export const INSIGHTS_V2_FINANCIALS: RouteDeclaration = {
  ...INSIGHTS_V2,
  name: "Financial Insights",
  path: "/insights-v2/financials",
};

export const INSIGHTS_V2_CASHFLOW: RouteDeclaration = {
  ...INSIGHTS_V2,
  name: "Cashflow Insights",
  path: "/insights-v2/cashflow",
};

export const INSIGHTS_V2_AP_AR: RouteDeclaration = {
  ...INSIGHTS_V2,
  name: "AP / AR Insights",
  path: "/insights-v2/ap-ar",
};

export const INSIGHTS_V2_MARKETING: RouteDeclaration = {
  ...INSIGHTS_V2,
  name: "Marketing Insights",
  path: "/insights-v2/marketing",
};

export const INSIGHTS_V2_ORDERS: RouteDeclaration = {
  ...INSIGHTS_V2,
  name: "Order Insights",
  path: "/insights-v2/orders",
};

export const INSIGHTS_V2_COHORTS: RouteDeclaration = {
  ...INSIGHTS_V2,
  name: "Customer Behavior Insights",
  path: "/insights-v2/cohorts",
};

export const INSIGHTS_V2_BENCHMARKS: RouteDeclaration = {
  ...INSIGHTS_V2,
  name: "Benchmarks",
  path: "/insights-v2/benchmarks",
};

export const INSIGHTS_V3: RouteDeclaration = {
  key: "insights-v3",
  name: "Summary",
  title: "Insights",
  path: "/insights-v3/:product?",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasInsightsV3Enabled, userHasViewBasicInsightsPermission],
  renderWithHelmet: false,
  PageComponent: InsightsV3,
};

export const BANKING_TRANSACTIONS: RouteDeclaration = {
  name: "Transactions",
  path: "/transactions",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    oneOfValidator(
      everyValidator(userHasViewBancoTransactionPermission, activeEntityHasProductBankingInActiveState),
      everyValidator(activeEntityHasProductBankingInDisabledState, negateValidator(userBancoApplicationHasBeenDenied)),
      activeEntityHasProductBankingInApplyingState,
      activeEntityHasProductBankingInOnboardingState,
      everyValidator(
        oneOfValidator(activeEntityHasProductBankingInActiveState, activeEntityHasProductBankingInReviewingState),
        negateValidator(userBancoApplicationHasBeenDenied),
      ),
    ),
  ],
  PageComponent: BancoTransactions,
  onClick() {
    trackEvent(PORTAL_MENU_TRANSACTIONS_OPTION_CLICKED);
  },
};

export const BANKING_ADD_BILL: RouteDeclaration = {
  path: "/add-a-bill",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [activeEntityHasProductBankingInActiveState, userHasAddBillPermission],
  PageComponent: AddBill,
};

export const BANKING_SEND_MONEY: RouteDeclaration = {
  name: "Send Money",
  path: "/send-money",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [activeEntityHasProductBankingInActiveState, userHasAddBancoInstructionPermission],
  PageComponent: SendMoney,
};

export const BANKING_SEND_PAYMENT: RouteDeclaration = {
  name: "Send Payment",
  path: SEND_PAYMENT_BASE_URL,
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [activeEntityHasProductBankingInActiveState, userHasAddBancoInstructionPermission],
  PageComponent: SendPayment,
};

export const BANKING_INTERNATIONAL_WIRE: RouteDeclaration = {
  name: "International wire",
  path: "/international-wire",
  pathIsExact: false,
  pathIsPrivate: true,
  PageComponent: InternationalWire,
  validators: [
    activeEntityHasProductInternationalPayoutsInActiveState,
    activeEntityHasProductFundingInActiveState,
    userCanSendInternationalWires,
  ],
};

export const BANKING_LINK_ACH_TRANSFER: RouteDeclaration = {
  name: "Link Account | Transfer",
  path: "/ach-transfer",
  pathIsExact: false,
  pathIsPrivate: true,
  PageComponent: LinkAccountTransfer,
};

export const BANKING_DEPOSIT_MONEY: RouteDeclaration = {
  name: "Deposit Money | Transfer",
  path: "/deposit-money",
  pathIsExact: false,
  pathIsPrivate: true,
  PageComponent: DepositMoneyTransfer,
};

export const BANKING_CASHBACK_REWARDS: RouteDeclaration = {
  name: "Rewards",
  path: "/rewards",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    oneOfValidator(
      activeEntityHasProductCashbackRewardsInActiveState,
      activeEntityHasProductCashbackRewardsInLockedState,
    ),
  ],
  PageComponent: CashbackRewardsDashboard,
};

export const BANKING_REDEEM_CASHBACK: RouteDeclaration = {
  name: "Redeem Cashback",
  path: "/rewards/redeem-cashback",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [
    oneOfValidator(
      activeEntityHasProductCashbackRewardsInActiveState,
      activeEntityHasProductCashbackRewardsInLockedState,
    ),
  ],
  PageComponent: RedeemCashback,
};

export const CHARGE_CARD_RECURRING_PAYMENTS: RouteDeclaration = {
  name: "Corporate Card | Recurring Payment",
  path: "/corporate-card/recurring-payment",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [
    oneOfValidator(activeEntityHasProductChargeCardsInOnboardingState, activeEntityHasProductChargeCardsInActiveState),
    userHasViewProductRepaymentBancoAccountPermission,
  ],
  PageComponent: ChargeCardRecurringPayment,
};

export const CHARGE_CARD_PRE_APPROVED: RouteDeclaration = {
  name: "Pre Approved Corporate Card",
  path: "/corporate-card/pre-approved",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasProductChargeCardsInPreApprovedState, userHasViewBancoAccountPermission],
  PageComponent: ChargeCardPreApprovedDashboard,
};

export const CHARGE_CARD_APPROVED: RouteDeclaration = {
  name: "Approved Corporate Card",
  path: "/corporate-card/approved",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasProductChargeCardsInOnboardingState, userHasViewBancoAccountPermission],
  PageComponent: ChargeCardPreApprovedDashboard,
};

export const CHARGE_CARD_ONBOARDING: RouteDeclaration = {
  name: "Corporate Card | Apply",
  path: "/corporate-card/onboarding",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasProductChargeCardsInApplyingState, userHasAddAgreementConsentPermission],
  PageComponent: ChargeCardOnboarding,
};

export const CHARGE_CARD_ONBOARDING_SUCCESS: RouteDeclaration = {
  name: "Corporate Card | Thank you!",
  path: "/corporate-card/onboarding/success",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    activeEntityHasProductInSomeState(EnabledProducts.ChargeCards, [ProductState.Applying, ProductState.Reviewing]),
    userHasAddAgreementConsentPermission,
  ],
  PageComponent: ChargeCardOnboardingSuccess,
};

export const CHARGE_CARD_LEARN_MORE: RouteDeclaration = {
  name: "Corporate Card | About",
  path: "/corporate-card/about",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasProductChargeCardsInApplyingState, userHasAddAgreementConsentPermission],
  PageComponent: ChargeCardLearnMore,
};

export const CHARGE_CARD_EXPENSES_ONBOARDING: RouteDeclaration = {
  name: "Expenses Onboarding",
  path: "/corporate-card/expenses-onboarding",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    activeEntityHasProductChargeCardsInActiveState,
    activeEntityHasExpensesInActiveState,
    userHasChangeAutomatedProductsPermission,
  ],
  PageComponent: ExpensesOnboarding,
};

export const BANKING_DEBIT_CARD_DETAIL: RouteDeclaration = {
  ...CARD_DETAIL,
  path: "/banking/:accountId?/cards/:cardId",
  validators: [...(CARD_DETAIL.validators || []), activeEntityHasProductDebitCardsInActiveState],
};

export const BANKING_DEBIT_CARDS_CREATE: RouteDeclaration = {
  ...CARD_CREATE,
  path: "/banking/:accountId?/cards/create",
  validators: [...(CARD_DETAIL.validators || []), activeEntityHasProductDebitCardsInActiveState],
};

export const BANKING_DEBIT_CARD_CONFIRM_STEP: RouteDeclaration = {
  ...CARD_CONFIRM_STEP,
  path: "/banking/:accountId?/cards/:cardId/confirm",
  validators: [...(CARD_DETAIL.validators || []), activeEntityHasProductDebitCardsInActiveState],
};

export const BANKING_DEBIT_CARD_CONFIRM_STEP_SUCCESS: RouteDeclaration = {
  ...CARD_CONFIRM_STEP_SUCCESS,
  path: "/banking/:accountId?/cards/:cardId/confirm/success",
};

export const CHARGE_CARD_CREATE_PAYMENT_SOURCE_SUCCESS: RouteDeclaration = {
  name: "Corporate Card | Payment",
  path: "/corporate-card/make-payment/banking-verification",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    oneOfValidator(activeEntityHasProductChargeCardsInActiveState, activeEntityHasProductChargeCardsInLockedState),
    userHasAddBancoInstructionPermission,
  ],
  PageComponent: CreatePlaidCounterpartySuccess,
};

export const CHARGE_CARD_MAKE_PAYMENT: RouteDeclaration = {
  name: "Corporate Card | Payment",
  path: "/corporate-card/make-payment",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [
    oneOfValidator(activeEntityHasProductChargeCardsInActiveState, activeEntityHasProductChargeCardsInLockedState),
    userHasAddBancoInstructionPermission,
  ],
  PageComponent: ChargeCardMakePayment,
};

export const CHARGE_CARD_CREATE: RouteDeclaration = {
  ...CARD_CREATE,
  name: "Corporate Card | Create Card",
  path: "/corporate-card/cards/create",
  validators: [
    ...(CARD_DETAIL.validators || []),
    oneOfValidator(activeEntityHasProductChargeCardsInOnboardingState, activeEntityHasProductChargeCardsInActiveState),
  ],
};

export const CORPORATE_CARD_DEPRECATED_CARDS: RouteDeclaration = {
  PageComponent: CorporateCardDeprecatedCards,
  name: "Corporate Card | Deprecated Cards",
  path: "/corporate-card/cards/deprecated",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasProductChargeCardsInActiveState, userHasCorporateCardsV2Enabled],
};

export const CHARGE_CARD_DETAIL: RouteDeclaration = {
  ...CARD_DETAIL,
  name: "Corporate Card | Card Detail",
  path: "/corporate-card/cards/:cardId",
  validators: [
    ...(CARD_DETAIL.validators || []),
    oneOfValidator(activeEntityHasProductChargeCardsInOnboardingState, activeEntityHasProductChargeCardsInActiveState),
  ],
};

export const CHARGE_CARD_CONFIRM_STEP: RouteDeclaration = {
  ...CARD_CONFIRM_STEP,
  name: "Corporate Card | Confirm card",
  path: "/corporate-card/cards/:cardId/confirm",
  validators: [
    ...(CARD_DETAIL.validators || []),
    oneOfValidator(activeEntityHasProductChargeCardsInOnboardingState, activeEntityHasProductChargeCardsInActiveState),
  ],
};

export const CHARGE_CARD_CONFIRM_STEP_SUCCESS: RouteDeclaration = {
  ...CARD_CONFIRM_STEP_SUCCESS,
  name: "Corporate Card | Confirm Card",
  path: "/corporate-card/cards/:cardId/confirm/success",
  validators: [
    ...(CARD_DETAIL.validators || []),
    oneOfValidator(activeEntityHasProductChargeCardsInOnboardingState, activeEntityHasProductChargeCardsInActiveState),
  ],
};

export const CORPORATE_CARD_DEPRECATED_TRANSACTIONS: RouteDeclaration = {
  name: "Corporate Card | Deprecated Transactions",
  path: "/corporate-card/transactions/deprecated",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasProductChargeCardsInActiveState, userHasCorporateCardsV2Enabled],
  PageComponent: CorporateCardDeprecatedTransactions,
};

// Keep validators in sync with the ones in frontend/src/helpers/banco-card.ts
export const CHARGE_CARD: RouteDeclaration = {
  name: "Corporate Card",
  path: "/corporate-card/:tab?/:expenseId?",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: ChargeCardDashboard,
  validators: [
    negateValidator(userChargeCardsApplicationHasBeenDenied),
    negateValidator(userBancoApplicationHasBeenDenied),
  ],
  redirections: [
    {
      key: "charge-card-pre-approved",
      to: "/corporate-card/pre-approved",
      conditions: [activeEntityHasProductChargeCardsInPreApprovedState],
    },
    {
      key: "charge-card-approved",
      to: "/corporate-card/approved",
      conditions: [activeEntityHasProductChargeCardsInOnboardingState],
    },
  ],
};

const BANKING_ADDITIONAL_DOCUMENTATION: RouteDeclaration = {
  name: "Additional Documentation",
  path: "/banking/additional-documentation/:bancoApplicationId/:unitDocumentId",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: BankingAdditionalDocumentation,
};

export const BRAND_REPRESENTATIVE_INVITEE_DOCUMENTS_REQUEST: RouteDeclaration = {
  name: "Documents Request",
  path: "/banking/documents-request/:bancoApplicationId/:brandRepresentativeEmail",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: DocumentsRequest,
};

export const BANKING_DOCUMENTS_REQUEST: RouteDeclaration = {
  name: "Documents Request",
  path: "/banking/documents-request",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: DocumentsRequest,
};

export const CHARGE_CARD_DOCUMENTS_REQUEST: RouteDeclaration = {
  name: "Documents Request",
  path: "/corporate-card/documents-request",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: DocumentsRequest,
};

export const CHARGE_CARD_VERIFICATION: RouteDeclaration = {
  name: "Verification",
  path: "/corporate-card/verification",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: CorporateCardVerification,
};

export const THREAD_MIGRATION_AGREEMENTS: RouteDeclaration = {
  name: "Thread Agreements",
  path: "/thread-agreements",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [userHasAddAgreementConsentPermission],
  PageComponent: ThreadAgreements,
};

export const TRANSFER_BETWEEN_ACCOUNTS: RouteDeclaration = {
  name: "Transfer between Ampla accounts",
  path: "/transfer-between-accounts",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: TransferBetweenAccounts,
  validators: [featureFlagValidator(FeatureFlags.TransferBetweenAmplaAccounts)],
};

const BANKING_ROUTES = [
  BANKING_DOCUMENTS_REQUEST, // this needs to be before BANKING_ACCOUNTS
  BANKING_ADDITIONAL_DOCUMENTATION,
  ...BANCO_HOME_ROUTES,
  BANKING_TRANSACTIONS,
  BANKING_ADD_BILL,
  BANKING_SEND_MONEY,
  BANKING_SEND_PAYMENT,
  BANKING_INTERNATIONAL_WIRE,
  ...BANKING_RECIPIENTS_ROUTES,
  BANKING_DEBIT_CARDS_CREATE,
  BANKING_LINK_ACH_TRANSFER,
  BANKING_DEBIT_CARD_DETAIL,
  BANKING_DEBIT_CARD_CONFIRM_STEP,
  BANKING_DEBIT_CARD_CONFIRM_STEP_SUCCESS,
  BANKING_CASHBACK_REWARDS,
  BANKING_REDEEM_CASHBACK,
  CORPORATE_CARD_DEPRECATED_CARDS,
  CORPORATE_CARD_DEPRECATED_TRANSACTIONS,
  CHARGE_CARD_DOCUMENTS_REQUEST,
  CHARGE_CARD_VERIFICATION,
  CHARGE_CARD_CREATE_PAYMENT_SOURCE_SUCCESS,
  CHARGE_CARD_MAKE_PAYMENT,
  CHARGE_CARD_RECURRING_PAYMENTS,
  CHARGE_CARD_APPROVED,
  CHARGE_CARD_PRE_APPROVED,
  CHARGE_CARD_LEARN_MORE,
  CHARGE_CARD_EXPENSES_ONBOARDING,
  CHARGE_CARD_ONBOARDING,
  CHARGE_CARD_ONBOARDING_SUCCESS,
  CHARGE_CARD_CREATE,
  CHARGE_CARD_DETAIL,
  CHARGE_CARD_CONFIRM_STEP,
  CHARGE_CARD_CONFIRM_STEP_SUCCESS,
  CHARGE_CARD,
  INSIGHTS,
  INSIGHTS_V1,
  INSIGHTS_V2,
  INSIGHTS_V2_FINANCIALS,
  INSIGHTS_V2_CASHFLOW,
  INSIGHTS_V2_AP_AR,
  INSIGHTS_V2_MARKETING,
  INSIGHTS_V2_ORDERS,
  INSIGHTS_V2_COHORTS,
  INSIGHTS_V2_BENCHMARKS,
  INSIGHTS_V3,
  BANKING_DEBIT_CARDS_CREATE,
  BANKING_LINK_ACH_TRANSFER,
  BANKING_DEPOSIT_MONEY,
  BRAND_REPRESENTATIVE_INVITEE_DOCUMENTS_REQUEST,
  THREAD_MIGRATION_AGREEMENTS,
  TRANSFER_BETWEEN_ACCOUNTS,
  ...AIRWALLEX_ONBOARDING_ROUTES,
];

export default BANKING_ROUTES;
