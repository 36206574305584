import { QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";

import { SnackbarProvider, theme, ThemeProvider } from "@ampla/ui-components";

import browserHistory from "../../browserHistory";
import queryClient from "../../queryClient";
import AuthContextProvider from "./AuthContextProvider";
import { LayoutContextProvider } from "./LayoutContextProvider";
import LeadContextProvider from "./LeadContextProvider/LeadContextProvider";
import { OverlaysContextProvider } from "./OverlaysContextProvider";

export const Providers = ({ children }: any) => {
  return (
    <Router history={browserHistory}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <AuthContextProvider>
              <LeadContextProvider>
                <OverlaysContextProvider>
                  <LayoutContextProvider>{children}</LayoutContextProvider>
                </OverlaysContextProvider>
              </LeadContextProvider>
            </AuthContextProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Router>
  );
};
