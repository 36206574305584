import { createContext, useContext } from "react";

import { TableSortConfig } from "./Table.types";

const TableContext = createContext<{
  sort?: TableSortConfig;
  isSortable: boolean;
  handleSortChange: (sort: TableSortConfig) => void;
}>({
  sort: undefined,
  isSortable: false,
  handleSortChange: () => {},
});

export const useTableContext = () => {
  const { sort, isSortable, handleSortChange } = useContext(TableContext);
  return {
    sort,
    isSortable,
    handleSortChange,
  };
};

export default TableContext;
