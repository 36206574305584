import { forwardRef } from "react";
import { Button } from "@mui/material";
import classNames from "classnames";

// FIXME: Move the utility functions from the component library
// to a shared `@ampla/utils` lib to avoid the circular dependency
// eslint-disable-next-line @nx/enforce-module-boundaries
import { KeyboardArrowDown } from "@ampla/icons";

import { useStyles } from "./FilterButton.styles";
import { FilterButtonProps } from "./FilterButton.types";

const FilterButton = forwardRef(({ label, open, active, className, ...props }: FilterButtonProps, ref: any) => {
  const classes = useStyles();

  return (
    <Button
      ref={ref}
      className={classNames(classes.root, className, {
        [classes.active]: active,
      })}
      endIcon={<KeyboardArrowDown className={classNames(classes.icon, { [classes.iconOpen]: open })} />}
      {...props}
    >
      {label}
    </Button>
  );
});

export default FilterButton;
