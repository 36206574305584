import { isDemoEnv, isStagingEnv } from "@ampla/utils";

export const getPageTitle = (name: string) => {
  let pageTitle = `${name} | Ampla`;

  if (isDemoEnv()) pageTitle += " - Demo";
  else if (isStagingEnv()) pageTitle += " - Staging";

  return pageTitle;
};
