import { Components } from "@mui/material/styles";

import colors from "../palette/colors";

const MuiChip: Components["MuiChip"] = {
  styleOverrides: {
    root: {
      backgroundColor: colors.teal[200],
      color: colors.teal[700],
    },
    outlined: {
      backgroundColor: "unset",
      "&&:focus": {
        backgroundColor: "unset",
      },
    },
    clickable: {
      "&&:hover": {
        backgroundColor: colors.teal[300],
        color: colors.teal[700],
      },
      "&&:focus": {
        backgroundColor: colors.teal[300],
        color: colors.teal[700],
        boxShadow: "none",
      },
    },
    deleteIcon: {
      color: colors.teal[700],
      height: 18,
      "&&:hover": {
        color: colors.teal[700],
      },
      "&&:focus": {
        color: colors.teal[900],
      },
    },
    avatar: {
      backgroundColor: "transparent",
      color: colors.teal[400],
    },
  },
};

export default MuiChip;
