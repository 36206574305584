import { makeStyles } from "libs/components/styles";

const useStyles = makeStyles({
  small: {
    fontSize: 16,
  },
  medium: {
    fontSize: 20,
  },
  large: {
    fontSize: 24,
  },
});

export default useStyles;
