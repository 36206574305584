import { Theme, makeStyles } from "@ampla/ui-components";

export const useStyles = makeStyles((theme: Theme) => ({
  productIconContainer: {
    width: 41,
    height: 41,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
