import React from "react";
import { Box } from "@mui/material";
import { TransitionGroup } from "react-transition-group";

import { AppNotification, AppNotificationActionCode } from "@ampla/api";
import { Collapse, List, Typography } from "@ampla/ui-components";

import NotificationItem from "libs/components/components/AppHeader/components/NotificationDrawer/components/NotificationItem";

interface NotificationListProps {
  title: string;
  notifications: AppNotification[];
  onAction(action: AppNotificationActionCode): void;
  onDismiss?(notificationId: string): void;
}

const NotificationList = (props: NotificationListProps) => {
  const { title, notifications, onAction, onDismiss } = props;

  return (
    <Box>
      <Typography variant="h5" sx={{ margin: "16px 32px" }}>
        {title}
      </Typography>
      <List>
        <TransitionGroup>
          {notifications.map((notification) => (
            <Collapse key={notification.id}>
              <NotificationItem notification={notification} onAction={onAction} onDismiss={onDismiss} />
            </Collapse>
          ))}
        </TransitionGroup>
      </List>
    </Box>
  );
};

export default NotificationList;
