export enum ProductApplicationState {
  NotSubmitted = "not_submitted",
  UnderReview = "under_review",
  Approved = "approved",
  Onboarded = "onboarded",
  Denied = "denied",
  PreApproved = "preapproved",
  PreApprovalAccepted = "preapproval_accepted",
}

export enum ChargeCardsProductApplicationState {
  NotSubmitted = "not_submitted",
  UnderReview = "under_review",
  AwaitingDocuments = "awaiting_documents",
  Approved = "approved",
  Denied = "denied",
  PreApproved = "preapproved",
  PreApprovalAccepted = "preapproval_accepted",
  Withdrawn = "withdrawn",
}

export enum CorporateCardTermLength {
  ThirtyPlusOne = "30+1", // -> Cash back
  ThirtyPlusThirty = "30+30", // -> Float
}
