import { ForwardedRef, forwardRef } from "react";
import { Link as MUILink } from "@mui/material";
import classNames from "classnames";

import { useStyles } from "./Link.styles";
import { LinkProps } from "./Link.types";

const Link = forwardRef(
  (
    {
      children,
      size = "medium",
      backgroundColor = "light",
      disabled = false,
      inline = false,
      href = "",
      onClick,
      startIcon,
      endIcon,
      className,
      underline,
      ...props
    }: LinkProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    const classes = useStyles();

    return (
      <MUILink
        {...props}
        ref={ref}
        href={href}
        onClick={onClick}
        underline={underline ?? (inline ? "always" : "hover")}
        className={classNames(
          classes.link,
          classes.clearButton,
          {
            [classes.backgroundLight]: backgroundColor === "light",
            [classes.backgroundDark]: backgroundColor === "dark",
            [classes.backgroundColorful]: backgroundColor === "colorful",
            [classes.linkSizeSmall]: size === "small",
            [classes.linkSizeMedium]: size === "medium",
            [classes.linkSizeLarge]: size === "large",
            [classes.disabled]: disabled,
            [classes.inline]: inline,
          },
          className,
        )}
      >
        {!inline && !endIcon && <span className={classes.icon}>{startIcon}</span>}
        {children}
        {!inline && !startIcon && <span className={classes.icon}>{endIcon}</span>}
      </MUILink>
    );
  },
);

export default Link;
