import fedHolidays from "@18f/us-federal-holidays";
import moment, { Moment } from "moment";
import momentTimezone from "moment-timezone";

import { FUNDING_POLICY_ADVANCE_DAYS } from "constants/gloc";

const months: { [key: number]: string } = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
export const MAX_SCHEDULED_DATE = 90;

export const dateStrToHumanReadable = (d: string) => {
  // Expects format of YYYY-MM-DD
  const pieces = d.split("-");

  const year = parseInt(pieces[0], 10);
  const monthName = months[parseInt(pieces[1], 10)];
  const day = parseInt(pieces[2], 10);

  return `${monthName} ${day}, ${year}`;
};

export const parseDateToBillFormat = (date: string) => moment(date).format("MM/DD/YYYY");

export const isPastFundingRequestDeadline = () =>
  momentTimezone().tz("America/New_York").isAfter(momentTimezone().tz("America/New_York").hour(15));

export const getFundingRequestMinDate = (startDate?: Date) => {
  const minDate = momentTimezone(startDate).tz("America/New_York").startOf("day");

  if (isPastFundingRequestDeadline()) minDate.add(1, "days");

  const holidaysStr = fedHolidays
    .inRange(minDate.toDate(), new Date(minDate.year() + 3, 0, 1))
    .map(({ dateString }) => dateString);

  const skipWeekendsAndHolidays = (date: moment.Moment): moment.Moment => {
    const dateStr = date.format("YYYY-MM-DD");

    if ([0, 6].includes(date.day()) || holidaysStr.includes(dateStr))
      return skipWeekendsAndHolidays(date.add(1, "days"));
    return date;
  };

  return skipWeekendsAndHolidays(minDate);
};

// Determine if a specific date would be a valid funding day. This is based on
// the funding policy and federal holidays and does NOT take into account the current date.
export const isValidFundingRequestDate = (date: Moment) => {
  const todayIsHoliday = fedHolidays.isAHoliday(date.toDate());
  const yesterdayWasHoliday = fedHolidays.isAHoliday(date.clone().subtract(1, "day").toDate());

  return (
    (date.day() === 3 && !todayIsHoliday) || // Allow funding on Wednesdays that are not holidays
    (date.day() === 4 && !todayIsHoliday && yesterdayWasHoliday) // If Wednesday is a holiday, allow funding on Thursday.
  );
};

// If a scheduled date is on a Monday, it can only be valid if today is Monday or Tuesday
const isValidMinFundingDate = (scheduledDate: Moment) => {
  return scheduledDate.day() !== 1 || [1, 2].includes(moment().day());
};

// Min scheduled funding date is a minimum amount of days in the future and
// only on a Wednesday or Friday UNLESS the previous valid day is a holiday
export const getScheduledFundingRequestMinDate = () => {
  const min = moment().add(FUNDING_POLICY_ADVANCE_DAYS, "days");
  while (!isValidFundingRequestDate(min) || !isValidMinFundingDate(min)) {
    min.add(1, "day");
  }
  return min;
};

export const getScheduledFundingRequestMaxDate = () => {
  return moment().add(MAX_SCHEDULED_DATE, "days");
};

// Only allow funding requests according to funding days and holidays and a minimum date
export const shouldDisableScheduledFundingDate = (minRequestDate: Moment) => (date: Moment) => {
  return date.isBefore(minRequestDate, "day") || !isValidFundingRequestDate(date);
};

export const getUTCDateTimeString = (date: string) => {
  // Expects format of YYYY-MM-DD
  const isCurrentOrPastDay = moment(date).isSameOrBefore(new Date(), "day");
  const scheduledDate = isCurrentOrPastDay ? new Date() : new Date(date);

  return scheduledDate.toISOString();
};

export const getTimezoneAbbr = () => momentTimezone.tz(momentTimezone.tz.guess()).zoneAbbr();

export const getTimeFromDateTime = (dateTime: string) =>
  `${moment(dateTime).format("hh:mm:ss A")} ${getTimezoneAbbr()}`;

export const getDaysBetweenNowAndDate = (date: string) => moment().diff(date, "days");

export const isBusinessDay = (date: Moment) => {
  const momentDate = moment(date);

  // Check if the date is a weekend (Saturday or Sunday)
  if (momentDate.day() === 0 || momentDate.day() === 6) {
    return false;
  }

  // Check if the date is a federal holiday
  if (fedHolidays.isAHoliday(momentDate.toDate())) {
    return false;
  }

  // If the date is not a weekend or federal holiday, it's a business day
  return true;
};

export const getNextBusinessDay = (date: Moment) => {
  let nextDay = moment(date).add(1, "days");

  // Check if the next day is a weekend or federal holiday
  while (!isBusinessDay(nextDay)) {
    nextDay = nextDay.add(1, "days");
  }

  return nextDay;
};

export const isMoreThan90Days = (date?: string) =>
  date ? new Date().getTime() - new Date(date).getTime() > 90 * 1000 * 60 * 60 * 24 : false;
