const styles = {
  option: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    py: 0.75,
    pl: 5,
    pr: 3,
    color: "ampla.gray.500",

    "@media (max-width: 768px)": {
      px: 3,
    },
  },
  optionSmall: {
    py: 1,
    pl: 6,
    pr: 3,
  },
  optionIndicator: {
    position: "absolute",
    backgroundColor: "ampla.teal.700",
    width: "6px",
    top: 0,
    bottom: 0,
    left: -6,
    transition: "left 0.2s ease-in-out",
  },
  optionIndicatorActive: {
    left: 0,
  },
  optionTypography: {
    color: "inherit",
    fontWeight: "inherit",
  },
  optionActive: {
    color: "ampla.teal.700",
    fontWeight: 600,
  },
  optionCollapseIcon: {
    width: 16,
    height: 16,
    color: "ampla.gray.300",
    transition: "transform 0.2s ease-in-out",
  },
  optionCollapseIconActive: {
    transform: "rotate(-180deg)",
  },
  collapse: {
    mb: -1.5,
  },
};

export default styles;
