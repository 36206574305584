import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { FormControl, FormControlLabel, Checkbox as MUICheckbox } from "@mui/material";
import classNames from "classnames";

import { Link } from "@ampla/ui-components";

import { CheckboxSelectState } from "libs/components/components/Checkbox/Checkbox.types";
import { useStyles } from "./Checkbox.styles";
import { CheckboxProps } from "./Checkbox.types";

const Checkbox = forwardRef(
  (
    {
      label,
      value,
      checked,
      size = "small",
      error,
      disabled,
      className,
      onToggleClick,
      displayAll,
      FormControlProps = {},
      FormControlLabelProps = {},
      ...props
    }: CheckboxProps,
    ref: ForwardedRef<any>,
  ) => {
    const classes = useStyles();

    const withWrapper = !!label;

    const [isOnly, setIsOnly] = useState(false);

    // Inside the Checkbox component
    useEffect(() => {
      // If `displayAll` is true, ensure we're in the "Only" state, otherwise revert
      setIsOnly(displayAll || false);
    }, [displayAll]); // Listen for changes in `displayAll`

    const handleToggleClick = (e: React.MouseEvent) => {
      e.preventDefault();
      setIsOnly(!isOnly); // Toggle the internal state
      if (onToggleClick) {
        onToggleClick(!isOnly ? CheckboxSelectState.Only : CheckboxSelectState.Reset); // Call with 'reset' to clear the filter
      }
    };

    const enhancedLabel = (
      <span>
        {label}
        {onToggleClick && (
          <span style={{ paddingLeft: "8px" }}>
            <Link onClick={handleToggleClick} size="small" inline={true} underline={"hover"}>
              {isOnly ? "(All)" : "(Only)"}
            </Link>{" "}
          </span>
        )}
      </span>
    );

    const checkbox = (
      <MUICheckbox
        {...props}
        ref={withWrapper ? undefined : ref}
        value={value}
        checked={checked}
        size={size}
        classes={{
          root: classes.root,
          checked: classes.checked,
          indeterminate: classes.indeterminate,
        }}
        className={classNames(className, {
          [classes.error]: error,
          [classes.sizeSmall]: size === "small",
          [classes.sizeMedium]: size === "medium",
        })}
        disabled={disabled}
        style={{
          ...props.style,
        }}
      />
    );

    return withWrapper ? (
      <FormControl ref={ref} disabled={disabled} {...FormControlProps}>
        <FormControlLabel
          {...FormControlLabelProps}
          value={value}
          checked={checked}
          label={enhancedLabel}
          control={checkbox}
          style={{ ...FormControlLabelProps.style }}
        />
      </FormControl>
    ) : (
      checkbox
    );
  },
);

export default Checkbox;
