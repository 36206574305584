import { lazy } from "react";

import {
  activeEntityHasProductBankingInActiveState,
  activeEntityHasProductBankingInDisabledState,
  activeEntityHasProductChargeCardsInActiveState,
  activeEntityHasProductChargeCardsInOnboardingState,
  activeEntityHasProductDebitCardsInActiveState,
  activeEntityHasProductFundingInActiveState,
  activeEntityHasProductFundingInDisabledState,
  activeEntityHasProductGrowthCardsInActiveState,
  activeEntityIsNotAmpla,
  negateValidator,
  oneOfValidator,
  userHasAddCardPermission,
  userHasOnlyOwnCardAccess,
  userHasRequestFundingPermission,
  userHasUseCardPermission,
  userHasViewAllCardPermission,
  userHasViewOwnCardPermission,
} from "./helpers/validators";

import { RouteDeclaration } from "./types";

const ErrorDisabledBrand = lazy(() => import("pages/ErrorDisabledBrand"));
const RequestFunding = lazy(() => import("pages/RequestFunding"));
const Cards = lazy(() => import("pages/Cards"));
const CardConfirmStep = lazy(() => import("pages/CardConfirmStep"));
const CardConfirmStepSuccess = lazy(() => import("pages/CardConfirmStep/components/CardConfirmStepSuccess"));
const CardDetail = lazy(() => import("pages/CardDetail"));
const CreateCard = lazy(() => import("pages/CreateCard"));

export const REQUEST_FUNDING: RouteDeclaration = {
  name: "Request funding",
  path: "/request-funding",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [activeEntityIsNotAmpla, activeEntityHasProductFundingInActiveState, userHasRequestFundingPermission],
  PageComponent: RequestFunding,
};

export const ERROR_NO_ENABLED_PRODUCTS = {
  path: "/disabled-brand",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: ErrorDisabledBrand,
  validators: [activeEntityHasProductFundingInDisabledState, activeEntityHasProductBankingInDisabledState],
};

export const CARDS: RouteDeclaration = {
  name: "Cards",
  title: "Cards",
  path: "/cards",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: Cards,
  validators: [
    oneOfValidator(
      activeEntityHasProductBankingInActiveState,
      activeEntityHasProductDebitCardsInActiveState,
      activeEntityHasProductGrowthCardsInActiveState,
      activeEntityHasProductChargeCardsInActiveState,
      activeEntityHasProductChargeCardsInOnboardingState,
    ),
    userHasOnlyOwnCardAccess,
  ],
};

export const CARD_DETAIL: RouteDeclaration = {
  name: "Cards",
  path: "/cards/:cardId",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: CardDetail,
  validators: [
    oneOfValidator(
      activeEntityHasProductDebitCardsInActiveState,
      activeEntityHasProductChargeCardsInOnboardingState,
      activeEntityHasProductChargeCardsInActiveState,
      activeEntityHasProductGrowthCardsInActiveState,
    ),
    oneOfValidator(userHasViewOwnCardPermission, userHasViewAllCardPermission),
  ],
};

export const CARD_CREATE: RouteDeclaration = {
  name: "Create card",
  path: "/cards/create",
  pathIsExact: false,
  pathIsPrivate: true,
  PageComponent: CreateCard,
  validators: [
    oneOfValidator(
      activeEntityHasProductDebitCardsInActiveState,
      activeEntityHasProductChargeCardsInOnboardingState,
      activeEntityHasProductChargeCardsInActiveState,
      activeEntityHasProductGrowthCardsInActiveState,
    ),
    userHasAddCardPermission,
  ],
};

export const CARD_CONFIRM_STEP: RouteDeclaration = {
  name: "Cards",
  path: "/cards/:cardId/confirm",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: CardConfirmStep,
  validators: [
    oneOfValidator(
      activeEntityHasProductDebitCardsInActiveState,
      activeEntityHasProductChargeCardsInOnboardingState,
      activeEntityHasProductChargeCardsInActiveState,
    ),
    userHasUseCardPermission,
  ],
};

export const CARD_CONFIRM_STEP_SUCCESS: RouteDeclaration = {
  name: "Cards",
  path: "/cards/:cardId/confirm/success",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: CardConfirmStepSuccess,
  validators: [
    oneOfValidator(
      activeEntityHasProductDebitCardsInActiveState,
      activeEntityHasProductChargeCardsInOnboardingState,
      activeEntityHasProductChargeCardsInActiveState,
    ),
    userHasUseCardPermission,
  ],
};

const COMMON_ROUTES = [
  REQUEST_FUNDING,
  ERROR_NO_ENABLED_PRODUCTS,
  CARDS,
  CARD_CREATE,
  CARD_DETAIL,
  CARD_CONFIRM_STEP,
  CARD_CONFIRM_STEP_SUCCESS,
];

export default COMMON_ROUTES;
