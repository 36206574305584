import { TypographyOptions } from "@mui/material/styles/createTypography";

const typography: TypographyOptions = {
  overline: {
    fontSize: "0.88rem",
  },
  fontFamily: [
    '"noto-sans"',
    '"Noto Sans"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
};

export default typography;
