import { useRef } from "react";
import { SettingsOutlined as SettingsOutlinedIcon } from "@mui/icons-material";
import { Popover } from "@mui/material";

import { IconButton } from "libs/components/components/IconButton";
import styles from "./SettingsDropdown.styles";
import { SettingsDropdownProps } from "./SettingsDropdown.types";

const SettingsDropdown = ({ isOpen, setIsOpen, children }: SettingsDropdownProps) => {
  const anchorEl = useRef<HTMLButtonElement>(null);

  const handleButtonClick = () => setIsOpen?.(true);

  const handlePopoverClose = () => setIsOpen?.(false);

  return (
    <>
      <IconButton ref={anchorEl} sx={styles.button} onClick={handleButtonClick}>
        <SettingsOutlinedIcon />
      </IconButton>
      <Popover
        open={!!isOpen}
        anchorEl={anchorEl.current}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            sx: styles.popover,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </Popover>
    </>
  );
};

export default SettingsDropdown;
