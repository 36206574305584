import { ForwardedRef, forwardRef } from "react";
import { Chip, Tab as MuiTab } from "@mui/material";
import classnames from "classnames";

import { Typography } from "libs/components/components/Typography";
import { TabProps } from "./Tab.types";

const Tab = forwardRef(
  (
    {
      className,
      variant = "primary",
      label,
      labelText,
      badge,
      startIcon,
      endIcon,
      color = "dark",
      ...tabProps
    }: TabProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const labelContent = label || (
      <>
        {startIcon}
        <Typography variant="label2">{labelText}</Typography>
        {endIcon}
        {badge && !tabProps?.disabled && <Chip label={badge} size="small" />}
      </>
    );

    return <MuiTab {...tabProps} ref={ref} className={classnames(variant, color, className)} label={labelContent} />;
  },
);

export default Tab;
