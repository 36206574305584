import { Components } from "@mui/material/styles";

const MuiMenu: Components["MuiMenu"] = {
  defaultProps: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  },
};

export default MuiMenu;
