import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { filter, orderBy, partition } from "lodash";
import { useQueryClient } from "react-query";

import { AppNotificationActionCode, AppNotificationSeverity } from "@ampla/api";
import { CircularProgress } from "@ampla/ui-components";

import Panel from "../../../Panel/Panel";
import { Typography } from "../../../Typography";
import NoNotificationsImage from "./components/NoNotificationsImage";
import NotificationList from "./components/NotificationList";
import { NotificationDrawerProps } from "./NotificationDrawer.types";

const NotificationDrawer = (props: NotificationDrawerProps) => {
  const {
    onClose,
    notifications,
    isLoading,
    open,
    onAction,
    onDismiss,
    refreshNotifications,
    markAllNotificationsRead,
    ...panelProps
  } = props;
  const queryClient = useQueryClient();

  // Keep a local list of notifications, so they can be removed immediately when dismissed
  const [localNotifications, setLocalNotifications] = useState(notifications);
  const sorted = orderBy(localNotifications, "date", "desc");
  const [urgent, regular] = partition(sorted, (n) => n.severity === AppNotificationSeverity.URGENT);
  const [read, unread] = partition(regular, (n) => n.read);

  useEffect(() => {
    setLocalNotifications(notifications);
  }, [notifications]);

  useEffect(() => {
    // Mark all notifications as read when the drawer is opened
    if (open) {
      markAllNotificationsRead();
    } else {
      // Refresh the notifications when the drawer is closed
      refreshNotifications();
    }
  }, [open]);

  const handleAction = (code: AppNotificationActionCode) => {
    onClose?.();
    onAction(code);
  };

  const handleDismiss = (notificationId: string) => {
    setLocalNotifications(filter(localNotifications, (n) => n.id !== notificationId));
    onDismiss(notificationId);
  };

  return (
    <Panel
      anchor="right"
      onClose={onClose}
      PanelHeaderProps={{ title: "Notifications", sx: { p: "32px 32px 0 32px" } }}
      sx={{ padding: 0 }}
      open={open}
      {...panelProps}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" marginTop="64px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!sorted.length && (
            <Box display="flex" flexDirection="column" gap="8px" alignItems="center">
              <NoNotificationsImage />
              <Typography variant="h5" sx={{ mt: "16px" }}>
                No notifications
              </Typography>
              <Typography variant="body2" color="gray-600">
                You’ll get updates about your Ampla products here.
              </Typography>
            </Box>
          )}
          {!!urgent.length && (
            <NotificationList title="Urgent" notifications={urgent} onAction={handleAction} onDismiss={handleDismiss} />
          )}
          {!!unread.length && (
            <NotificationList title="New" notifications={unread} onAction={handleAction} onDismiss={handleDismiss} />
          )}
          {!!read.length && (
            <NotificationList title="Earlier" notifications={read} onAction={handleAction} onDismiss={handleDismiss} />
          )}
        </>
      )}
    </Panel>
  );
};

export default NotificationDrawer;
