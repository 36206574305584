import { makeStyles } from "libs/components/styles";
import colors from "libs/components/theme/ampla-theme/palette/colors";

export const useStyles = makeStyles(() => ({
  root: {
    cursor: "pointer",
    userSelect: "none",
    msUserSelect: "none",
    WebkitUserSelect: "none",
    overflow: "hidden",

    "&:hover, &:active": {
      "& $sortIcon": {
        color: colors.gray[500],
      },
    },
  },
  content: {
    display: "inline-flex",
    gap: 4,
    transition: "all .3s ease",
  },
  sortIcon: {
    width: `16px !important`,
    height: `16px !important`,
    color: colors.gray[300],
    transition: "all .3s ease !important",
    marginBlock: -1,
  },
  sortIconDesc: {
    transform: "rotate(180deg)",
  },
  sortIconSorted: {
    color: `${colors.teal[900]} !important`,
  },
}));
