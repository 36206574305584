import { Suspense, useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import ErrorNotFound from "components/Common/ErrorNotFound";
import LoadingContainer from "components/Common/LoadingContainer";

import ACCOUNT_ROUTES from "./account";
import BANKING_ROUTES from "./banking";
import PORTAL_ROUTES from "./portal";
import COMMON_ROUTES from "./common";
import DISCLOSURE_ROUTES from "./disclosure";
import PUBLIC_ROUTES from "./public";
import BILL_PAY_ROUTES from "./billpay";
import NET_TERMS_ROUTES from "./netTerms";
import PrivateRoute from "./components/PrivateRoute";
import { RouteDeclaration } from "./types";
import { getPageTitle } from "./helpers/common";
import { generateBancoHomePagePath } from "../pages/BancoHome/routing";

const ROUTES = [
  ...PUBLIC_ROUTES,
  ...ACCOUNT_ROUTES,
  ...PORTAL_ROUTES,
  ...BANKING_ROUTES,
  ...COMMON_ROUTES,
  ...DISCLOSURE_ROUTES,
  ...BILL_PAY_ROUTES,
  ...NET_TERMS_ROUTES,
];

export default function Routes() {
  const renderRoute = useCallback(
    ({
      key,
      path,
      name,
      title,
      pathIsExact,
      pathIsPrivate,
      validators,
      notAuthorizedMessage,
      PageComponent,
      renderWithHelmet = true,
    }: RouteDeclaration) => {
      const ComponentWithHead = () => (
        <>
          {(name || title) && (
            <Helmet defer={false}>
              <title>{title ? getPageTitle(title) : name ? getPageTitle(name) : null}</title>
            </Helmet>
          )}
          <Suspense fallback={<LoadingContainer fullscreen />}>
            <PageComponent />
          </Suspense>
        </>
      );

      return pathIsPrivate ? (
        <PrivateRoute
          key={key ?? path}
          component={renderWithHelmet ? ComponentWithHead : PageComponent}
          path={path}
          validators={validators}
          exact={pathIsExact}
          notAuthorizedMessage={notAuthorizedMessage}
        />
      ) : (
        <Route
          key={key ?? path}
          component={renderWithHelmet ? ComponentWithHead : PageComponent}
          path={path}
          exact={pathIsExact}
        />
      );
    },
    [],
  );

  return (
    <Switch>
      {ROUTES.map(renderRoute)}

      {/* TODO: Remove once we remove all the send money old flow */}
      {/* Banco Redirects */}
      <Redirect from="/send-money/*" to="/send-payment/recipient" />

      {/* TODO: remove these redirects from banking once we replace all references in the code to these url paths */}
      {/* Note: Redirects must be sorted according to nesting otherwise they will not work correctly */}
      {/* Note: redirecting /settings to /settings/team in case anyone goes straight there */}
      <Redirect from="/charge-cards/*" to="/corporate-card/*" />
      <Redirect from="/settings" to="/settings/team" />
      <Redirect from="/add-funds" to="/add-funds/wire" />

      {/* Account Redirects */}
      <Redirect from="/account/team" to="/settings/team" />
      <Redirect from="/account/integrations" to="/settings" />
      <Redirect from="/account/vendor" to="/settings/vendors" />
      <Redirect from="/account" to="/settings/team" />

      {/* Legacy Redirects */}
      <Redirect from="/transactions/new" to="/request-funding" />
      <Redirect from="/production_run/new" to="/request-funding" />
      <Redirect from="/production_run" to="/funding-events" />
      <Redirect from="/self_reporting" to="/self-reporting" />
      <Redirect from="/deductions_analysis" to="/deductions-analysis" />
      <Redirect from="/accounts/checking" to={generateBancoHomePagePath()} />
      <Redirect from="/funding-events" to="/line-of-credit/events" />
      <Redirect from="/growth-card" to="/line-of-credit/cards" />
      <Redirect from="/legacy-growth-card" to="/line-of-credit/cards" />

      <Redirect from="/accounts/*" to={generateBancoHomePagePath()} />
      <Redirect from="/banking" to={generateBancoHomePagePath()} />
      <Redirect from="/dashboard" to="/" />
      <Route component={ErrorNotFound} />
    </Switch>
  );
}
