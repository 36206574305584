export enum FundingApplicationStatus {
  ApplicationPending = "application_pending",
  ApplicationStarted = "application_started",
  ApplicationComplete = "application_complete",
  ContractSent = "contact_sent",
  ContractSigned = "contact_signed",
  Funded = "funded",
  Passed = "passed",
  Lost = "lost",
  SuspectedFraud = "suspected_fraud",
  Ignore = "ignore",
}
