import { ForwardedRef, forwardRef, MouseEvent } from "react";
import { ArrowUpward as ArrowUpwardIcon } from "@mui/icons-material";
import { TableCell as MUITableCell } from "@mui/material";
import classNames from "classnames";

import { useTableContext } from "libs/components/components/Table";
import { useStyles } from "./TableCell.styles";
import { TableCellProps } from "./TableCell.types";

const TableCell = forwardRef(
  (
    { name, className, children, onClick, align, ...props }: TableCellProps,
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const classes = useStyles();

    const { sort, isSortable, handleSortChange } = useTableContext();

    if (!name || !isSortable)
      return (
        <MUITableCell className={className} align={align} onClick={onClick} {...props}>
          {children}
        </MUITableCell>
      );

    const handleTableCellClick = (event: MouseEvent<HTMLTableCellElement>) => {
      if (name) {
        if (name === sort?.column) {
          handleSortChange({ column: name, direction: sort?.direction === "asc" ? "desc" : "asc" });
        } else {
          handleSortChange({ column: name, direction: "asc" });
        }
      }

      onClick?.(event);
    };

    return (
      <MUITableCell
        ref={ref}
        className={classNames(classes.root, className)}
        onClick={handleTableCellClick}
        align={align}
        {...props}
      >
        <div className={classes.content}>
          {children}
          <ArrowUpwardIcon
            className={classNames("sortIcon", classes.sortIcon, {
              [classes.sortIconDesc]: name === sort?.column && sort?.direction === "desc",
              [classes.sortIconSorted]: name === sort?.column,
            })}
          />
        </div>
      </MUITableCell>
    );
  },
);

export default TableCell;
