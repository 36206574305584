import { lazy } from "react";

import { RouteDeclaration } from "./types";

const Home = lazy(() => import("pages/Home"));
const Login = lazy(() => import("pages/Login"));
const AgreementView = lazy(() => import("pages/AgreementView"));
const ConnectCodatIntegration = lazy(() => import("pages/ConnectCodatIntegration"));
const ErrorNotAuthorized = lazy(() => import("pages/ErrorNotAuthorized"));
const ConnectFivetranIntegration = lazy(() => import("pages/ConnectFivetranIntegration"));
const PersonaCreation = lazy(() => import("pages/PersonaCreation"));
const ConnectShopifyIntegration = lazy(() => import("pages/ConnectShopifyIntegration"));

export const PUBLIC_HOME: RouteDeclaration = {
  name: "Home",
  path: "/",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: Home,
};

export const PUBLIC_LOGIN: RouteDeclaration = {
  name: "Login",
  path: "/login",
  pathIsExact: false,
  pathIsPrivate: false,
  PageComponent: Login,
};

export const PUBLIC_NOT_AUTHORIZED: RouteDeclaration = {
  path: "/not-authorized",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: ErrorNotAuthorized,
};

// agreement is deprecated, but cannot be redirected because of slugs
export const PUBLIC_AGREEMENT_VIEW: RouteDeclaration = {
  path: "/agreement/:slug/:action?",
  pathIsExact: false,
  pathIsPrivate: false,
  PageComponent: AgreementView,
};

export const PUBLIC_LEGAL_VIEW: RouteDeclaration = {
  path: "/legal/:slug/:action?",
  pathIsExact: false,
  pathIsPrivate: false,
  PageComponent: AgreementView,
};

export const CONNECT_INTEGRATION: RouteDeclaration = {
  path: "/connect-integration/:companyId?",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: ConnectCodatIntegration,
};

export const CONNECT_INTEGRATION_CUSTOM = {
  path: "/connect-integration/:platformKey/:data",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: ConnectCodatIntegration,
};

export const CONNECT_ADS_INTEGRATION = {
  path: "/connect-ads-integration/:integrationType?",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: ConnectFivetranIntegration,
};

export const CONNECT_SHOPIFY_INTEGRATION = {
  path: "/connect-shopify-integration/:status",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: ConnectShopifyIntegration,
};

export const PERSONA_CREATION: RouteDeclaration = {
  name: "New Profile",
  path: "/new-profile/:tab?",
  pathIsExact: false,
  pathIsPrivate: false,
  PageComponent: PersonaCreation,
};

const PUBLIC_ROUTES = [
  PUBLIC_HOME,
  PUBLIC_LOGIN,
  PUBLIC_NOT_AUTHORIZED,
  PUBLIC_AGREEMENT_VIEW,
  PUBLIC_LEGAL_VIEW,
  CONNECT_INTEGRATION,
  CONNECT_INTEGRATION_CUSTOM,
  CONNECT_ADS_INTEGRATION,
  PERSONA_CREATION,
  CONNECT_SHOPIFY_INTEGRATION,
];

export default PUBLIC_ROUTES;
