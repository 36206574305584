import { TransactionEventSubtype } from "@ampla/api";

export const TRANSACTION_EVENT_SUBTYPE_DESCRIPTION = {
  [TransactionEventSubtype.Accounting]: "Accounting",
  [TransactionEventSubtype.Advance]: "Advance",
  [TransactionEventSubtype.AdvanceRepayment]: "Advance Repayment",
  [TransactionEventSubtype.Amortization]: "Amortization",
  [TransactionEventSubtype.AuditOffset]: "Audit Offset",
  [TransactionEventSubtype.BuyerChargeback]: "Buyer Chargeback",
  [TransactionEventSubtype.BuyerPayment]: "Buyer Payment",
  [TransactionEventSubtype.BuyerPaymentToBrand]: "Buyer Payment to Brand",
  [TransactionEventSubtype.ChargeBack]: "Charge Back",
  [TransactionEventSubtype.Complete]: "Complete",
  [TransactionEventSubtype.FeePayment]: "Fee Payment",
  [TransactionEventSubtype.FeePaymentFromBrand]: "Fee Payment from Brand",
  [TransactionEventSubtype.FundingApplication]: "Funding Application",
  [TransactionEventSubtype.FundingApproval]: "Funding Approval",
  [TransactionEventSubtype.GhostInvoice]: "Ghost Invoice",
  [TransactionEventSubtype.Invoice]: "Invoice",
  [TransactionEventSubtype.InvoiceFromSupplier]: "Invoice from Supplier",
  [TransactionEventSubtype.InvoiceOffset]: "Invoice Offset",
  [TransactionEventSubtype.PassthroughPayment]: "Passthrough Payment",
  [TransactionEventSubtype.PaydownPayment]: "Paydown",
  [TransactionEventSubtype.Promotion]: "Promotion",
  [TransactionEventSubtype.PurchaseOrder]: "Purchase Order",
  [TransactionEventSubtype.Repayment]: "Repayment",
  [TransactionEventSubtype.ScheduledBillPay]: "Scheduled Bill Pay",
  [TransactionEventSubtype.ShipmentToBrand]: "Shipment to Brand",
  [TransactionEventSubtype.ShipmentToBuyer]: "Shipment to Buyer",
  [TransactionEventSubtype.ShipmentToWarehouse]: "Shipment to Warehouse",
  [TransactionEventSubtype.ThirPartyProcessingFee]: "Third-Party Processing Fee",
  [TransactionEventSubtype.WriteOff]: "Writeoff",
  [TransactionEventSubtype.Adjustment]: "Adjustment",
};

export const FUNDING_POLICY_ADVANCE_DAYS = 3;
