import { AvatarProps, Avatar } from "@ampla/ui-components";
import classNames from "classnames";
import { Color, Size } from "images/enums";
import AmplaIconLogo from "images/logos/AmplaIconLogo";
import { useAvatarAmplaStyles } from "./AvatarAmpla.styles";

const AvatarAmpla = ({ className, ...props }: AvatarProps) => {
  const classes = useAvatarAmplaStyles();

  return (
    <Avatar className={classNames(classes.avatarRoot, className)} {...props}>
      <AmplaIconLogo size={Size.Small} color={Color.Teal} />
    </Avatar>
  );
};

export default AvatarAmpla;
