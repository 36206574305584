import { AmplaColors, ColorShades } from "./types";

const BLUE: ColorShades = {
  50: "#F4FBFF",
  100: "#DDF0FD",
  200: "#B1DBFB",
  300: "#81C4F8",
  400: "#3DAFF7",
  500: "#0097F6",
  600: "#0978CE",
  700: "#1A649E",
  800: "#004085",
  900: "#0D3859",
};

const TEAL: ColorShades = {
  50: "#F6FFFC",
  100: "#E8FFF7",
  200: "#C4EFE5",
  300: "#84DBCA",
  400: "#2EBFA5",
  500: "#21A598",
  600: "#108286",
  700: "#085D6A",
  800: "#084247",
  900: "#021315",
};

const GREEN: ColorShades = {
  50: "#F0FDF4",
  100: "#E1F6DA",
  200: "#9BEFB3",
  300: "#00DC72",
  400: "#03B25E",
  500: "#00845B",
  600: "#00714E",
  700: "#005D40",
  800: "#00432E",
  900: "#002419",
};

const ORANGE: ColorShades = {
  50: "#FFFDEB",
  100: "#FFF5CC",
  200: "#FFEA97",
  300: "#FFD77B",
  400: "#FFC160",
  500: "#FF9D20",
  600: "#E76E04",
  700: "#CA5F01",
  800: "#AC4901",
  900: "#743702",
};

const RED: ColorShades = {
  50: "#FFF8F8",
  100: "#FFE0E0",
  200: "#FFBDBB",
  300: "#FF807B",
  400: "#E0554D",
  500: "#D64039",
  600: "#B90B02",
  700: "#9C2019",
  800: "#761C16",
  900: "#541410",
};

const PURPLE: ColorShades = {
  50: "#FFF9FF",
  100: "#FAE8FC",
  200: "#F2D9F4",
  300: "#D890D8",
  400: "#C054BF",
  500: "#B13FAF",
  600: "#91338F",
  700: "#7A2B78",
  800: "#60215F",
  900: "#441643",
};

const GRAY: ColorShades = {
  50: "#FAFAFA",
  100: "#EEEDEC",
  200: "#DADBDD",
  300: "#BEC2C4",
  400: "#858E8E",
  500: "#6F787A",
  600: "#4E5A5B",
  700: "#384547",
  800: "#1E2D2F",
  900: "#161616",
};

const colors: AmplaColors = {
  blue: BLUE,
  teal: TEAL,
  green: GREEN,
  orange: ORANGE,
  red: RED,
  purple: PURPLE,
  gray: GRAY,
  black: "#000000",
  white: "#FFFFFF",
};

export default colors;
