const styles = {
  root: {
    py: 1,
  },
  profile: {
    display: "flex",
    alignItems: "flex-start",
    gap: 1.5,
    px: 3,
    py: 1.5,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "ampla.gray.100",
  },
  avatar: {
    width: 48,
    height: 48,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "0.32px",
    textTransform: "uppercase",
  },
  profileInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  accounts: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    px: 3,
    py: 1.5,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "ampla.gray.100",
  },
  options: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    px: 3,
    py: 1.5,
  },
  optionsList: {
    paddingTop: 0,
    paddingBottom: 0,
    marginInline: "-24px",

    "& li:last-child": {
      marginBottom: "-6px",
    },
  },
  optionsListItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingInline: 3,
  },
};

export default styles;
