import { useTheme } from "@ampla/ui-components";
import React from "react";
import { Color, Size } from "images/enums";
import { LogoProps } from "images/types";

const LargeAmplaIconLogo = ({ color }: React.SVGProps<SVGPathElement>): JSX.Element => (
  <svg width="60" height="auto" viewBox="0 0 132 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M81.1999 8H50.8799L0.409912 140.87H25.5499L87.3699 95.1V95.21L100.08 85.8L90.8999 105.72L102.7 140.87H131.62L81.1999 8ZM36.9999 107.82L65.9999 30.36L84.7699 87.25L36.9999 107.82Z"
      fill={color}
    />
  </svg>
);

const SmallAmplaIconLogo = ({ color }: React.SVGProps<SVGPathElement>): JSX.Element => (
  <svg width="40" height="auto" viewBox="0 0 132 133" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M81.2001 0H50.8801L0.410156 132.85H25.5502L87.3701 87.08V87.19L100.08 77.78L90.9001 97.7L102.7 132.85H131.62L81.2001 0ZM37.0001 99.82L66.0001 22.36L84.7701 79.25L37.0001 99.82Z"
      fill={color}
    />
  </svg>
);

const AmplaIconLogo = ({
  className,
  size = Size.Small,
  color = Color.Primary,
}: LogoProps<Size> & React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const theme = useTheme();

  const logoSrcMap = {
    large: LargeAmplaIconLogo,
    small: SmallAmplaIconLogo,
  };

  const LogoSVG = logoSrcMap[size];

  const logoColor = {
    primary: theme.palette.ampla.orange[50],
    teal: theme.palette.ampla.teal[700],
    white: "#FFFFFF", // confirm looks okay in components/Common/Header
    black: theme.palette.ampla.teal[900],
  }[color];

  return (
    <div className={className}>
      <LogoSVG color={logoColor} />
    </div>
  );
};

export default AmplaIconLogo;
