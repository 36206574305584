import { createStyles, Theme, CircularProgress, WithStyles, withStyles } from "@ampla/ui-components";
import classNames from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(4)}`,
      textAlign: "center",
      width: "100%",
    },
    fullscreen: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "#FFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdrop: {
      opacity: "0.7",
      zIndex: 10000,
    },
  });

type LoadingContainerProps = {
  fullscreen?: boolean;
  backdrop?: boolean;
  size?: number | string;
};

const LoadingContainer = ({
  fullscreen = false,
  backdrop = false,
  classes,
  size,
}: LoadingContainerProps & WithStyles<typeof styles>) => (
  <div
    className={classNames(classes.root, { [classes.fullscreen]: fullscreen, [classes.backdrop]: backdrop })}
    data-testid="loading-spinner"
  >
    <CircularProgress size={size} />
  </div>
);

export default withStyles(styles)(LoadingContainer);
