import { createContext, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

import LoadingContainer from "components/Common/LoadingContainer";
import { SESSION_USER_LOGGED_IN_KEY } from "constants/storageKeys";
import { setupUTMSession } from "helpers/heap";
import { setupPendoSession } from "helpers/pendo";
import usePersistentState from "hooks/utils/usePersistentState";
import { getUserGroupMap, setupDatadogRum, setupTracking, setupWidgetSession } from "./helpers";
import { useAutoRedirect, useCurrentUser, useLogin, useLogout, useTokenRefresh } from "./hooks";
import useApplicationStatuses from "./hooks/useApplicationStatuses";
import { AuthContextProviderProps, AuthContextType } from "./types";

export const AuthContext = createContext<AuthContextType | null>(null);

const AuthContextProvider = ({ children, ...props }: AuthContextProviderProps) => {
  const [showLoader, setShowLoader] = useState(true);

  const [userIsLoggedIn, setUserIsLoggedIn] = usePersistentState(SESSION_USER_LOGGED_IN_KEY, false, sessionStorage);
  const [pendoSession, pendoSessionStart] = useMemo(() => {
    const sessionId = v4();
    const sessionStart = new Date().getTime();
    return [sessionId, sessionStart];
  }, []);

  const { user, userQuery } = useCurrentUser({ userIsLoggedIn, setShowLoader });
  const { applicationStatuses, applicationStatusesQuery } = useApplicationStatuses({ userIsLoggedIn });
  const groups = user ? getUserGroupMap(user) : {};

  useAutoRedirect({ user, userIsLoggedIn, applicationStatuses, groups });
  useTokenRefresh({ userIsLoggedIn, setUserIsLoggedIn, setShowLoader });

  const { login, loginMutation } = useLogin({ user, setUserIsLoggedIn });

  const { logout, logoutMutation } = useLogout({ setUserIsLoggedIn });

  // Set the logout mutation on the window object, it gets used
  // by our rest client to logout when receiving a 401, once we
  // move to using axios we should use an interceptor instead
  useEffect(() => {
    window.ampla = window.ampla || {};
    window.ampla.logout = logout;
  }, []);

  useEffect(() => {
    setupUTMSession();
    setupWidgetSession();
  }, []);

  useEffect(() => {
    setupPendoSession(user, pendoSession, pendoSessionStart);
    setupTracking(user);
    setupDatadogRum(user);
  }, [user]);

  return showLoader ? (
    <LoadingContainer fullscreen />
  ) : (
    <AuthContext.Provider
      value={{
        user,
        applicationStatuses,
        refetchApplicationStatuses: applicationStatusesQuery.refetch,
        userIsLoggedIn,
        groups,
        isLoadingUser: userQuery.isLoading,
        refetchUser: userQuery.refetch,
        login,
        loginMutation: {
          ...loginMutation,
          isLoading: loginMutation.isLoading || userQuery.isLoading,
        },
        logout,
        logoutMutation,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
