import { ForwardedRef, forwardRef } from "react";
import classnames from "classnames";

import { palette } from "libs/components/theme/ampla-theme/palette";
import { AMPLA_COMPONENT_NAMESPACE } from "libs/components/theme/constants";
import { useStyles } from "./AmplaLogo.styles";
import { AmplaLogoProps } from "./AmplaLogo.types";

const AmplaLogo = forwardRef(
  ({ color = "primary", size = "medium", ...props }: AmplaLogoProps, ref: ForwardedRef<SVGSVGElement>) => {
    const classes = useStyles();
    const logoClasses = classnames(AMPLA_COMPONENT_NAMESPACE, classes.logo, size, props.className);
    let logoColor;

    switch (color) {
      case "primary":
        logoColor = palette.primary.main;
        break;
      case "black":
        logoColor = palette.secondary.dark;
        break;
      case "white":
        logoColor = palette.primary.contrastText;
        break;
      default:
        logoColor = palette.primary.main;
        break;
    }

    return (
      <svg
        ref={ref}
        fill={logoColor}
        viewBox="0 0 532 182"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        className={logoClasses}
      >
        <path d="M81.1999 8H50.8799L0.409912 140.87H25.5499L87.3699 95.1V95.21L100.08 85.8L90.8999 105.72L102.7 140.87H131.62L81.1999 8ZM36.9999 107.82L65.9999 30.36L84.7699 87.25L36.9999 107.82Z" />
        <path d="M242 63.47C238.011 63.3831 234.109 64.6484 230.93 67.06C228.066 69.1919 225.544 71.7479 223.45 74.64C223.85 77.04 224.183 79.6 224.45 82.32C224.717 85.04 224.85 87.7334 224.85 90.4V140.87H197.49V90.2C197.49 81.16 196.227 74.4467 193.7 70.06C191.173 65.6733 186.917 63.4767 180.93 63.47C177.301 63.3345 173.718 64.3116 170.66 66.27C168.008 68.0546 165.681 70.28 163.78 72.85V140.85H136.45V43.73H162.78V56.89C165.681 51.9834 169.92 48.0048 175 45.42C180.018 42.9357 185.551 41.6714 191.15 41.73C197.93 41.73 203.517 43.26 207.91 46.32C212.369 49.458 215.997 53.6351 218.48 58.49C222.867 52.37 227.787 48.0367 233.24 45.49C239.006 42.9003 245.27 41.6066 251.59 41.7C257.843 41.7 263.13 42.93 267.45 45.39C271.779 47.8785 275.408 51.4218 278 55.69C280.877 60.4584 282.907 65.689 284 71.15C285.301 77.483 285.935 83.935 285.89 90.4V140.87H258.52V90.2C258.52 81.16 257.257 74.4467 254.73 70.06C252.203 65.6733 247.96 63.4767 242 63.47Z" />
        <path d="M296.29 43.73H322.42V56.09C325.574 51.7353 329.678 48.155 334.42 45.62C339.207 43.0267 345.057 41.73 351.97 41.73C357.511 41.6523 362.993 42.8823 367.97 45.32C372.761 47.7177 376.954 51.1587 380.24 55.39C383.778 60.0709 386.425 65.3621 388.05 71C389.983 77.5734 390.924 84.3988 390.84 91.25C390.979 98.854 389.62 106.411 386.84 113.49C384.41 119.595 380.743 125.131 376.07 129.75C371.549 134.135 366.141 137.5 360.21 139.62C353.969 141.845 347.386 142.958 340.76 142.91C337.556 142.941 334.355 142.707 331.19 142.21C328.632 141.799 326.101 141.235 323.61 140.52V169.39L296.29 182V43.73ZM342.77 63.07C338.767 62.9777 334.81 63.9435 331.3 65.87C328.387 67.4479 325.785 69.5419 323.62 72.05V120.12C325.558 120.843 327.57 121.346 329.62 121.62C331.998 121.96 334.398 122.127 336.8 122.12C346.133 122.12 352.917 119.26 357.15 113.54C361.383 107.82 363.51 100.44 363.53 91.4C363.53 83.2867 361.837 76.5367 358.45 71.15C355.063 65.7634 349.837 63.07 342.77 63.07Z" />
        <path d="M426.67 112.74C426.67 115.8 427.3 118.1 428.57 119.62C429.84 121.14 431.99 121.92 435.05 121.92C436.25 121.92 437.51 121.85 438.84 121.72C440.114 121.598 441.38 121.398 442.63 121.12L443.63 140.27C440.881 141.033 438.08 141.598 435.25 141.96C432.014 142.422 428.749 142.656 425.48 142.66C421.24 142.794 417.021 142.013 413.11 140.37C409.962 138.98 407.191 136.858 405.03 134.18C402.973 131.594 401.477 128.607 400.64 125.41C399.777 122.123 399.343 118.738 399.35 115.34V12.66L426.67 0V112.74Z" />
        <path d="M520.52 52.2999C513.14 45.2599 503 41.7366 490.1 41.7299C483.447 41.6848 476.814 42.4572 470.35 44.0299C464.48 45.4572 458.841 47.706 453.6 50.7099L459 69.8599C462.807 67.8672 466.835 66.3267 471 65.2699C475.688 64.0487 480.515 63.4436 485.36 63.4699C491.88 63.4699 496.667 65.0999 499.72 68.3599C502.773 71.6199 504.303 76.3732 504.31 82.6199V85.9999C500.85 86.1399 497 86.2699 492.74 86.3999C488.48 86.5299 484.37 86.7399 480.38 86.9999C470 87.6665 461.82 90.3332 455.84 94.9999C449.86 99.6665 446.86 106.45 446.84 115.35C446.795 119.111 447.474 122.845 448.84 126.35C450.104 129.572 451.994 132.512 454.4 135C456.882 137.527 459.871 139.5 463.17 140.79C466.856 142.224 470.785 142.934 474.74 142.88C479.603 142.987 484.445 142.225 489.04 140.63C497.47 137.45 504.04 131.45 511.13 126.17L517.83 121.17L508.76 140.85H531.59V83.6199C531.59 69.7865 527.9 59.3465 520.52 52.2999ZM504.26 117.3C501.591 119.378 498.637 121.061 495.49 122.3C492.058 123.651 488.398 124.33 484.71 124.3C481.764 124.451 478.852 123.609 476.44 121.91C474.367 120.31 473.333 117.917 473.34 114.73C473.34 111.143 474.537 108.583 476.93 107.05C479.872 105.326 483.129 104.207 486.51 103.76C489.17 103.36 492.09 103.03 495.29 102.76C498.49 102.49 501.47 102.23 504.29 101.96L504.26 117.3Z" />
      </svg>
    );
  },
);

export default AmplaLogo;
