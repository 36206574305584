import { ReactNode } from "react";
import { FormControlLabelProps, FormControlProps, CheckboxProps as MUICheckboxProps } from "@mui/material";

enum CheckboxSelectState {
  Only = "only",
  Reset = "reset",
}

export { CheckboxSelectState };

export interface CheckboxProps
  extends Omit<
    MUICheckboxProps,
    "color" | "icon" | "checkedIcon" | "disableRipple" | "disableTouchRipple" | "disableFocusRipple"
  > {
  /**
   * If `true`, the component is highlighted in red.
   */
  error?: boolean;

  label?: ReactNode;

  FormControlProps?: Partial<FormControlProps>;

  FormControlLabelProps?: Partial<FormControlLabelProps>;

  onToggleClick?: (action: CheckboxSelectState) => void;

  displayAll?: boolean | undefined;
}
