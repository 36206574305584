/* eslint-disable func-names */
import * as Yup from "yup";
import postalCodes from "postal-codes-js";

Yup.addMethod<Yup.StringSchema>(Yup.string, "isValidPostalCode", function (countryFieldRef) {
  return this.test("isValidPostalCode", countryFieldRef, function (value) {
    const { path, resolve, createError } = this;

    if (!value) return createError({ path, message: "Postal code is required" });

    const countryCode = resolve(countryFieldRef);

    let result = postalCodes.validate(countryCode, value);

    if (typeof result === "string") {
      if (["US", "USA"].includes(countryCode)) {
        result = result.replace("postal", "zip");
        result = result.replace("Postal", "Zip");
        const cutIndex = result.lastIndexOf("for");
        if (cutIndex > 0) {
          result = result.substring(0, cutIndex);
        }
      }

      return createError({ path, message: result });
    }

    return true;
  });
});
