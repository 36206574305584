import { ForwardedRef, forwardRef } from "react";
import { FormControlLabel, Radio as MUIRadio } from "@mui/material";

import { useStyles } from "./Radio.styles";
import { RadioProps } from "./Radio.types";

const Radio = forwardRef(({ label, value, checked, size = "small", ...props }: RadioProps, ref: ForwardedRef<any>) => {
  const classes = useStyles();

  const withWrapper = !!label;

  const radio = (
    <MUIRadio
      {...props}
      ref={withWrapper ? undefined : ref}
      value={value}
      checked={checked}
      size={size}
      disableRipple
      disableFocusRipple
      disableTouchRipple
    />
  );

  return withWrapper ? (
    <FormControlLabel
      ref={ref}
      value={value}
      checked={checked}
      label={label}
      componentsProps={{ typography: { className: classes.formControlLabel } }}
      control={radio}
    />
  ) : (
    radio
  );
});

export default Radio;
