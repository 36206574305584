import { makeStylesX } from "libs/components/styles";

export const useStyles = makeStylesX((theme) => ({
  option: {
    display: "flex",
    minHeight: 56,
    paddingBlock: 1,
    paddingLeft: 1,
    paddingRight: 3,
  },
  separator: {
    height: "1px",
    border: "none",
    backgroundColor: theme.palette.ampla.gray[100],
  },
  checkbox: {
    svg: {
      fill: theme.palette.ampla.teal[400],
    },
  },
  bottomOptions: {
    display: "flex",
    paddingBlock: 2,
    paddingInline: 2,
  },
  emptyContainer: {
    paddingBlock: 2,
    paddingInline: 4,
  },
}));
