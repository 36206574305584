import { ChangeEvent, FocusEvent, ForwardedRef, forwardRef } from "react";
import { useField, useFormikContext } from "formik";
import { OnValueChange } from "react-number-format";

import { TextField } from "../TextField";
import { TextFormFieldProps } from "./TextFormField.types";

const TextFormField = forwardRef(
  ({ onNumericValueChange, ...props }: TextFormFieldProps, ref: ForwardedRef<HTMLDivElement>) => {
    // This component should only be used in a Formik context. If it is not, return a plain TextField
    // before the Formik context is needed. (a warning will be logged)
    const context = useFormikContext();
    if (!context) {
      return <TextField {...props} />;
    }

    const [field, meta, helpers] = useField(props.name || "");
    const { error = "", touched, value } = meta;
    const { onChange: onChangeFormik, onBlur: onBlurFormik } = field;

    const handleNumericValueChange: OnValueChange = (numberFormatValues) => {
      onChangeFormik({ target: { name: props.name, value: numberFormatValues.value } });
      if (onNumericValueChange) onNumericValueChange(numberFormatValues, helpers);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChangeFormik(event);
      if (props.onChange) props?.onChange?.(event, helpers);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      onBlurFormik(event);
      if (props.onBlur) props?.onBlur?.(event, helpers);
    };

    return (
      <TextField
        ref={ref}
        error={!props.disabled && touched && !!error}
        helperText={!props.disabled && touched && error ? error : undefined}
        {...props}
        onNumericValueChange={handleNumericValueChange}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      />
    );
  },
);

export default TextFormField;
