import { useTheme } from "@mui/material";

import { MakeStylesX } from "./types";

/**
 * Creates a React hook for generating styles with access to the theme object.
 *
 * @param callback A function that takes the theme as an argument and returns the styles object.
 * @returns A React hook that returns the styles object.
 */
export const makeStylesX: MakeStylesX = (callback) => () => {
  const theme = useTheme();
  return callback(theme);
};
