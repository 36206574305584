import { Components } from "@mui/material/styles";

import { getButtonColorVariantDefinition } from "libs/components/theme/ampla-theme/helpers/buttons";
import { AMPLA_COMPONENT_NAMESPACE_SELECTOR } from "libs/components/theme/constants";
import palette from "../palette";
import colors from "../palette/colors";

const BUTTON_SMALL_ICON_PADDING = 2;
const BUTTON_MEDIUM_ICON_PADDING = 4;
const BUTTON_LARGE_ICON_PADDING = 4;

const MuiIconButton: Components["MuiIconButton"] = {
  defaultProps: {
    size: "large",
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      color: colors.teal[900],

      "&:hover": {
        color: palette.primary?.main,
        backgroundColor: "none",
      },

      [AMPLA_COMPONENT_NAMESPACE_SELECTOR]: {
        "&.primary": getButtonColorVariantDefinition({
          defaultColor: colors.teal[700],
          defaultBorderColor: "transparent",
          defaultBackgroundColor: "transparent",
          hoverColor: colors.teal[700],
          hoverBorderColor: colors.gray[100],
          hoverBackgroundColor: colors.gray[100],
          activeColor: colors.teal[700],
          activeBorderColor: colors.gray[200],
          activeBackgroundColor: colors.gray[200],
          disabledColor: colors.gray[200],
          disabledBorderColor: "transparent",
          disabledBackgroundColor: "transparent",
        }),

        "&.primary-contrast": getButtonColorVariantDefinition({
          defaultColor: colors.white,
          defaultBorderColor: "transparent",
          defaultBackgroundColor: "transparent",
          hoverColor: colors.white,
          hoverBorderColor: colors.teal[600],
          hoverBackgroundColor: colors.teal[600],
          activeColor: colors.white,
          activeBorderColor: colors.teal[500],
          activeBackgroundColor: colors.teal[500],
          disabledColor: colors.gray[400],
          disabledBorderColor: "transparent",
          disabledBackgroundColor: "transparent",
        }),

        "&.secondary": getButtonColorVariantDefinition({
          defaultColor: colors.teal[900],
          defaultBorderColor: "transparent",
          defaultBackgroundColor: "transparent",
          hoverColor: colors.teal[900],
          hoverBorderColor: colors.gray[100],
          hoverBackgroundColor: colors.gray[100],
          activeColor: colors.teal[900],
          activeBorderColor: colors.gray[200],
          activeBackgroundColor: colors.gray[200],
          disabledColor: colors.gray[200],
          disabledBorderColor: "transparent",
          disabledBackgroundColor: "transparent",
        }),

        "&.small": {
          padding: BUTTON_SMALL_ICON_PADDING,
        },

        "&.medium": {
          padding: BUTTON_MEDIUM_ICON_PADDING,
        },

        "&.large": {
          padding: BUTTON_LARGE_ICON_PADDING,
        },
      },
    },
  },
};

export default MuiIconButton;
