import { Search as SearchIcon, Close as CloseIcon } from "@ampla/icons";
import { makeStyles, Theme, InputAdornment, MUIIconButton, TextField } from "@ampla/ui-components";
import { KeyboardEvent, ReactNode } from "react";
import useDebounce from "hooks/utils/useDebounce";

const useStyles = makeStyles((theme: Theme) => ({
  searchField: {
    width: 300,
  },
  searchFieldIcon: {
    color: theme.palette.ampla.gray[400],
  },
}));

interface SearchFilterProps {
  dataTestId?: string;
  placeholder?: string;
  filterValue?: string;
  setFilterValue: (arg0: string) => void;
  classes?: {
    searchField?: string;
    searchFieldIcon?: string;
    searchFieldInput?: string;
  };
  autoFocus?: boolean;
  endAdornment?: ReactNode;
  onEnterPressed?: () => void;
  debounceWaitMs?: number;
  disabled?: boolean;
}

const SearchFilter = ({
  dataTestId = "search-filter-input",
  placeholder = "Search",
  filterValue = "",
  setFilterValue,
  classes,
  autoFocus = false,
  endAdornment,
  onEnterPressed,
  debounceWaitMs = 300,
  disabled = false,
}: SearchFilterProps) => {
  const { inputValue, setInputValue } = useDebounce(filterValue, setFilterValue, debounceWaitMs);
  const privateClasses = useStyles();

  const handleInputChange = ({ target: { value } }: any) => setInputValue(value);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) onEnterPressed?.();
  };

  const handleClearClick = () => setFilterValue("");

  return (
    <TextField
      placeholder={placeholder}
      value={inputValue}
      onChange={handleInputChange}
      className={classes?.searchField || privateClasses.searchField}
      onKeyDown={handleKeyDown}
      InputProps={{
        autoFocus,
        inputProps: {
          className: classes?.searchFieldInput,
        },
        endAdornment: endAdornment || (
          <InputAdornment position="end">
            {filterValue ? (
              <MUIIconButton onClick={handleClearClick} size="small">
                <CloseIcon fontSize="small" className={classes?.searchFieldIcon || privateClasses.searchFieldIcon} />
              </MUIIconButton>
            ) : (
              <SearchIcon fontSize="small" className={classes?.searchFieldIcon || privateClasses.searchFieldIcon} />
            )}
          </InputAdornment>
        ),
      }}
      disabled={disabled}
      data-testid={dataTestId}
    />
  );
};

export default SearchFilter;
