import { makeStyles } from "libs/components/styles";
import colors from "libs/components/theme/ampla-theme/palette/colors";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    textTransform: "uppercase",
  },
  asterisk: {
    width: 8,
    height: 8,
    fill: colors.red[500],
    marginLeft: 2,
  },
  infoIconContainer: {
    cursor: "pointer",
    display: "flex",
    marginLeft: 8,
  },
  infoICon: {
    width: `16px !important`,
    height: `16px !important`,
    fill: colors.gray[300],
  },
}));
