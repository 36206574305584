import camelcaseKeys from "camelcase-keys";
import { matchPath } from "react-router";
import { generatePath } from "react-router-dom";

import { BancoApplicationStatus, BrandRoles, EnabledProducts, ProductState, SignupSource } from "@ampla/api";
import { WithRequired } from "@ampla/utils";

import { filterRoute } from "apps/portal/components/AppShell/helpers";
import { BANCO_HOME_PAGE } from "apps/portal/pages/BancoHome/routing";
import {
  BANKING_DOCUMENTS_REQUEST,
  CHARGE_CARD,
  CHARGE_CARD_DOCUMENTS_REQUEST,
  INSIGHTS,
} from "apps/portal/routes/banking";
import { NET_TERMS_VENDOR_CUSTOMERS_SUMMARY } from "apps/portal/routes/netTerms";
import {
  APPLICATION,
  HOMEPAGE_V2,
  LINE_OF_CREDIT_DASHBOARD,
  PAY_LATER_BETA_LANDING,
  PHONE_VERIFICATION,
  SIGNUP,
  SIGNUP_ADDRESS,
  SIGNUP_BUSINESS,
  SIGNUP_PRODUCT,
  SIGNUP_USER,
} from "apps/portal/routes/portal";
import { RouteDeclaration } from "apps/portal/routes/types";
import { SESSION_PRODUCT_PRE_SELECTED } from "constants/storageKeys";
import { isPathMatchOfAny } from "helpers/url";
import {
  activeEntityHasInsightsEmbeddedInDisabledState,
  activeEntityHasNetTermsPayerInReviewingState,
  activeEntityHasNetTermsSellerInActiveState,
  activeEntityHasNetTermsSellerInDisabledState,
  activeEntityHasNetTermsSellerInEnabledState,
  activeEntityHasProductBankingInActiveState,
  activeEntityHasProductBankingInApplyingState,
  activeEntityHasProductBankingInReviewingState,
  activeEntityHasProductChargeCardsInActiveState,
  activeEntityHasProductChargeCardsInApplyingState,
  activeEntityHasProductChargeCardsInReviewingState,
  activeEntityHasProductFundingInActiveState,
  activeEntityHasProductFundingInApplyingState,
  activeEntityHasProductFundingInDisabledState,
  activeEntityHasProductFundingInReviewingState,
  activeEntityHasSomeProductInState,
  userHasRequestFundingPermission,
  userHasVendorRelationship,
  userHasVerifiedPhone,
  userHasViewBancoAccountPermission,
  userHasViewBasicInsightsPermission,
  userHasViewFullHomePagePermission,
  userIsStaff,
} from "routes/helpers/validators";
import { NetTermsPaths } from "routes/paths";
import { AuthContextType } from "../../types";
import { CHECK_FREE_ROUTES_APPLICATION } from "./constants";

const BASE_PATH = "/";

type GetNexUrlParams = WithRequired<Pick<AuthContextType, "user" | "groups" | "applicationStatuses">, "user">;

export const getNextRedirectUrl = (params: GetNexUrlParams): string | null => {
  const { user, applicationStatuses } = params;

  // Do not run any checks if the pathname is a check free path
  if (isPathMatchOfAny(window.location.pathname, CHECK_FREE_ROUTES_APPLICATION)) return null;

  let nextRoute: RouteDeclaration;
  const canVisitRoute = filterRoute(params);
  const { pathname } = window.location;

  const { activeEntity, activeEntityAddress } = camelcaseKeys(user, { deep: true });

  const userIsBrandOwner = user.brand_role === BrandRoles.BrandOwner;

  // Prevent authenticated users from seeing the Net Terms signup pages
  if (
    user &&
    !matchPath(pathname, { path: NetTermsPaths.NET_TERMS_BRAND_PAYMENT_SETUP, exact: true }) &&
    (matchPath(pathname, { path: NetTermsPaths.NET_TERMS_BRAND_SIGNUP_UNSECURED, exact: true }) ||
      matchPath(pathname, { path: NetTermsPaths.NET_TERMS_BRAND_PAYMENT_SIGNUP, exact: true }) ||
      matchPath(pathname, { path: NetTermsPaths.NET_TERMS_VENDOR_UNSECURED_SIGNUP, exact: true }))
  ) {
    return BASE_PATH;
  }

  // Should prevent the user from seeing the user step of the signup if they
  // already have an user
  if (pathname === SIGNUP_USER.path) {
    nextRoute = SIGNUP_BUSINESS;
    if (userHasVerifiedPhone({ user }) && canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
    return null;
  }

  // Should prevent the user from seeing the company setup step of the signup if they
  // already have an active entity
  if (pathname === SIGNUP_BUSINESS.path) {
    nextRoute = SIGNUP_ADDRESS;
    if (activeEntity && canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
    return null;
  }

  // Should prevent the user from seeing the address setup step of the signup if they
  // already have their address setup or have an active product
  if (pathname === SIGNUP_ADDRESS.path) {
    if (activeEntityAddress) return BASE_PATH;
    return null;
  }

  // Should allow staff users to navigate to any URL
  if (!userIsStaff({ user })) {
    // TODO: Remove on Pay Later launch
    if (pathname === PAY_LATER_BETA_LANDING.path) return null;

    // Brands signing up under a Vendor's account have a separate onboarding experience
    if (userHasVendorRelationship({ user })) {
      // Brands signing up to make payments to their Vendor
      if (user.signup_source === SignupSource.NET_TERMS_BRAND_PAYMENT_INVITATION) {
        if (!user.active_entity_address) {
          return NetTermsPaths.NET_TERMS_BRAND_PAYMENT_SETUP;
        }
      } else {
        if (!user.connected_plaid || !user.active_entity_address) {
          return generatePath(NetTermsPaths.NET_TERMS_BRAND_SIGNUP_SECURED, {
            vendorId: user.referral_entity,
            stepId: "profile",
          });
        }

        // If the user has completed setup, but is still in review, show them the pending page
        if (activeEntityHasNetTermsPayerInReviewingState({ user })) {
          return NetTermsPaths.NET_TERMS_BRAND_PENDING;
        }
      }

      // If the user is a Net Terms Brand default them to their invoices page
      if (pathname === BASE_PATH) return NetTermsPaths.NET_TERMS_BRAND_INVOICES_OPEN;

      return null;
    }

    // If the user is onboarding as a vendor and has not completed registration
    if (user.signup_source === SignupSource.NET_TERMS_VENDOR) {
      if (!user.contact?.verified_phone_number) {
        return generatePath(NetTermsPaths.NET_TERMS_VENDOR_SECURED_SIGNUP, { stepId: "verify" });
      }
      if (!activeEntity || !activeEntityAddress) {
        return generatePath(NetTermsPaths.NET_TERMS_VENDOR_SECURED_SIGNUP, { stepId: "business" });
      }
    }

    // Net Terms vendor entity
    if (activeEntityHasNetTermsSellerInEnabledState({ user })) {
      if (pathname === BASE_PATH) {
        return NetTermsPaths.NET_TERMS_VENDOR_CUSTOMERS_SUMMARY;
      }
      return null;
    }

    // Prevent a lead converted to reach the product selection page
    if (pathname === SIGNUP_PRODUCT.path && user.lead_converted) return BASE_PATH;

    // If a user logs out during the product selection, redirect the user
    if (pathname === BASE_PATH && !user.lead_converted && !activeEntity) return SIGNUP_PRODUCT.path;

    if ([SIGNUP_BUSINESS.path, SIGNUP_ADDRESS.path, APPLICATION.path].includes(pathname) && !user.lead_converted) {
      const product = sessionStorage.getItem(SESSION_PRODUCT_PRE_SELECTED);
      if (product && JSON.parse(product)) return SIGNUP_PRODUCT.path;
    }

    // If the user has no active entity or memberships then they just signed up
    // and still need to create their company
    if (![SIGNUP_USER.path, SIGNUP_PRODUCT.path].includes(pathname) && !activeEntity) return SIGNUP_BUSINESS.path;

    // If the active entity has no address and they have no active products we need to
    // redirect the user to the signup address page
    if (!activeEntityAddress && pathname === APPLICATION.path) return SIGNUP_ADDRESS.path;

    // Always prompt phone verification before application
    if (pathname.includes(APPLICATION.path) && !userHasVerifiedPhone({ user })) {
      nextRoute = PHONE_VERIFICATION;
      if (canVisitRoute(nextRoute)) return nextRoute.path;
    }

    // should only show the product applications to brand owners
    if (pathname === APPLICATION.path && !userIsBrandOwner) return BASE_PATH;

    if (!userHasVerifiedPhone({ user }) && activeEntityHasProductBankingInActiveState({ user })) {
      nextRoute = PHONE_VERIFICATION;
      if (canVisitRoute(nextRoute)) return nextRoute.path;
    }

    if (!pathname.includes(SIGNUP.path) && user.lead_converted === false && userIsBrandOwner) {
      nextRoute = APPLICATION;
      if (canVisitRoute(nextRoute)) return nextRoute.path;
    }

    if (!pathname.includes(APPLICATION.path) && userIsBrandOwner) {
      const productsToApply = [EnabledProducts.Banking, EnabledProducts.ProductionFunding, EnabledProducts.ChargeCards];
      if (
        activeEntityHasSomeProductInState(productsToApply, ProductState.Applying) &&
        !activeEntityHasSomeProductInState(productsToApply, ProductState.Reviewing) &&
        !activeEntityHasSomeProductInState(productsToApply, ProductState.Active) &&
        !activeEntityHasSomeProductInState(productsToApply, ProductState.Onboarding)
      ) {
        nextRoute = APPLICATION;
        if (canVisitRoute(nextRoute)) return nextRoute.path;
      }
    }

    // Should only see the documents request page if charge cards or banking is reviewing
    if (
      (pathname === CHARGE_CARD_DOCUMENTS_REQUEST.path || pathname === BANKING_DOCUMENTS_REQUEST.path) &&
      userIsBrandOwner &&
      (activeEntityHasProductChargeCardsInReviewingState({ user }) ||
        activeEntityHasProductBankingInReviewingState({ user }))
    )
      return null;
  }

  // The home url BASE_PATH acts as our catch all
  if (pathname === BASE_PATH) {
    if (canVisitRoute(HOMEPAGE_V2)) return HOMEPAGE_V2.path;

    if (applicationStatuses?.banco_application?.status !== BancoApplicationStatus.Denied) {
      // If charge cards and banking are in review, user should see the corporate card page
      if (
        activeEntityHasProductFundingInDisabledState({ user }) &&
        ((activeEntityHasProductChargeCardsInReviewingState({ user }) &&
          activeEntityHasProductBankingInReviewingState({ user })) ||
          (activeEntityHasProductChargeCardsInApplyingState({ user }) &&
            activeEntityHasProductBankingInApplyingState({ user })))
      ) {
        nextRoute = CHARGE_CARD;
        if (canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
      }

      // If line of credit and banking are in review, user should see the line of credit page
      if (
        activeEntityHasProductFundingInReviewingState({ user }) ||
        activeEntityHasProductFundingInApplyingState({ user })
      ) {
        nextRoute = LINE_OF_CREDIT_DASHBOARD;
        if (canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
      }

      // If banking is in review, user should see the banking page
      if (
        (activeEntityHasProductBankingInReviewingState({ user }) ||
          activeEntityHasProductBankingInApplyingState({ user })) &&
        userHasViewBancoAccountPermission({ user })
      ) {
        nextRoute = BANCO_HOME_PAGE;
        if (canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
      }
    }

    // If funding is in active state redirect the user to the Line of Credit page
    if (activeEntityHasProductFundingInActiveState({ user }) && userHasRequestFundingPermission({ user })) {
      nextRoute = LINE_OF_CREDIT_DASHBOARD;
      if (canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
    }

    if (activeEntityHasProductChargeCardsInActiveState({ user })) {
      nextRoute = CHARGE_CARD;
      if (canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
    }

    // If banking is in active state redirect the user to the Banking page
    if (activeEntityHasProductBankingInActiveState({ user }) && userHasViewBancoAccountPermission({ user })) {
      nextRoute = BANCO_HOME_PAGE;
      if (canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
    }

    // If a active Net Terms vendor, redirect to the customers page
    if (activeEntityHasNetTermsSellerInActiveState({ user })) {
      nextRoute = NET_TERMS_VENDOR_CUSTOMERS_SUMMARY;
      if (canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
    }

    // If a non-active Net Terms vendor, redirect to the setup page
    if (!activeEntityHasNetTermsSellerInDisabledState({ user })) {
      nextRoute = NET_TERMS_VENDOR_CUSTOMERS_SUMMARY;
      if (canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
    }

    if (!activeEntityHasInsightsEmbeddedInDisabledState({ user }) && userHasViewBasicInsightsPermission({ user })) {
      nextRoute = INSIGHTS;
      if (canVisitRoute(nextRoute)) return generatePath(nextRoute.path);
    }
  }

  return null;
};
