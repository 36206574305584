import { isDemoEnv, isStagingEnv } from "@ampla/utils";
import { ComponentType } from "react";

const withDemoWrapper =
  <P extends {}>(Component: ComponentType<P>) =>
  (props: P) => {
    return isDemoEnv() || isStagingEnv() ? <Component {...props} /> : null;
  };

export default withDemoWrapper;
