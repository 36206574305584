import { QueryClient } from "react-query";
import { APIError } from "@ampla/api";

const INVALID_API_STATUS_CODES = [
  401, // Unauthorized
  403, // Forbidden
  404, // Not Found
];

// Create the react-query client to use on the provider
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // If the error is an APIError, then check the statusCode, if it's not, just check the failureCount
      retry: (failureCount: number, error: unknown) =>
        failureCount < 3 && !(error instanceof APIError && INVALID_API_STATUS_CODES.includes(error.statusCode)),
    },
  },
});

export default queryClient;
