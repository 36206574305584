import { lazy } from "react";
import { generatePath } from "react-router";
import { RouteDeclaration } from "routes/types";

import { FeatureFlags } from "@ampla/api";

import {
  activeEntityHasProductBankingInActiveState,
  activeEntityHasProductInternationalPayoutsInActiveState,
  featureFlagValidator,
} from "routes/helpers/validators";

const AirwallexKYC = lazy(() => import("./pages/KYC"));
const AirwallexRFI = lazy(() => import("./pages/RFI"));

const KYC_ROOT = "/international-payments-onboarding";
const RFI_ROOT = "/international-payments-onboarding-rfi";

export const KYC_PAGE: RouteDeclaration = {
  name: "International Payments Onboarding",
  path: KYC_ROOT,
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: AirwallexKYC,
  validators: [
    activeEntityHasProductBankingInActiveState,
    activeEntityHasProductInternationalPayoutsInActiveState,
    featureFlagValidator(FeatureFlags.AirwallexOnboarding),
  ],
};

export const RFI_PAGE: RouteDeclaration = {
  name: "International Payments Onboarding",
  path: RFI_ROOT,
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: AirwallexRFI,
  validators: [
    activeEntityHasProductBankingInActiveState,
    activeEntityHasProductInternationalPayoutsInActiveState,
    featureFlagValidator(FeatureFlags.AirwallexOnboarding),
  ],
};

type GenerateAirwallexKYCPathParams = {
  backUrl?: string;
};

export const generateAirwallexKYCPath = ({ backUrl }: GenerateAirwallexKYCPathParams = {}) =>
  `${generatePath(KYC_PAGE.path)}${backUrl ? `?backUrl=${backUrl}` : ""}`;

type GenerateAirwallexRFIPathParams = {
  backUrl?: string;
};

export const generateAirwallexRFIPath = ({ backUrl }: GenerateAirwallexRFIPathParams = {}) =>
  `${generatePath(RFI_PAGE.path)}${backUrl ? `?backUrl=${backUrl}` : ""}`;

const AIRWALLEX_ONBOARDING_ROUTES = [KYC_PAGE, RFI_PAGE] as RouteDeclaration[];

export default AIRWALLEX_ONBOARDING_ROUTES;
