import { SESSION_USER_LOGGED_IN_KEY } from "storage-keys.json";

/**
 * @deprecated We should avoid using this function and instead use the
 * `userIsLoggedIn` flag returned by the `useAuthState` hook.
 */
export const isLoggedIn = () => {
  const sessionFlag = sessionStorage.getItem(SESSION_USER_LOGGED_IN_KEY);
  return sessionFlag ? JSON.parse(sessionFlag) : false;
};
