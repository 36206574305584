import { ForwardedRef, forwardRef } from "react";

// FIXME: Move the utility functions from the component library
// to a shared `@ampla/utils` lib to avoid the circular dependency
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AsteriskIcon, InfoIcon } from "@ampla/icons";

import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";
import { useStyles } from "./TextFieldLabel.styles";
import { TextFieldLabelProps } from "./TextFieldLabel.types";

const TextFieldLabel = forwardRef(
  (
    { label, required, helperTooltipTitle, helperTooltipProps, error, disabled }: TextFieldLabelProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const classes = useStyles();

    return (
      <div ref={ref} className={classes.container}>
        <Typography
          component="span"
          variant="overline2"
          color={error ? "red-500" : disabled ? "gray-300" : "gray-600"}
          className={classes.label}
        >
          {label}
        </Typography>
        {required ? <AsteriskIcon className={classes.asterisk} /> : null}
        {helperTooltipTitle ? (
          <Tooltip title={helperTooltipTitle} placement="top" {...helperTooltipProps}>
            <div className={classes.infoIconContainer}>
              <InfoIcon className={classes.infoICon} />
            </div>
          </Tooltip>
        ) : null}
      </div>
    );
  },
);

export default TextFieldLabel;
