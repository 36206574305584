import { useEffect, useState } from "react";
import { startsWith } from "lodash";
import { useHistory, useLocation } from "react-router";

import { PORTAL_AUTOMATIC_REDIRECTION } from "constants/eventKeys";
import { trackEvent } from "helpers/analytics";
import { AuthContextType } from "../../types";
import { getNextRedirectUrl } from "./helpers";

type UseAutoRedirect = Pick<AuthContextType, "user" | "userIsLoggedIn" | "groups" | "applicationStatuses">;

const useAutoRedirect = ({ user, userIsLoggedIn, applicationStatuses, groups }: UseAutoRedirect) => {
  const [autoRedirectIsEnabled, setAutoRedirectIsEnabled] = useState(false);

  const history = useHistory();
  const location = useLocation();

  // Disable auto redirects when user is not logged in
  useEffect(() => {
    if (userIsLoggedIn && user) setAutoRedirectIsEnabled(true);
    else setAutoRedirectIsEnabled(false);
  }, [userIsLoggedIn, user]);

  useEffect(() => {
    if (!user || !autoRedirectIsEnabled) return;

    const nextRedirectUrl = getNextRedirectUrl({ user, applicationStatuses, groups });

    if (nextRedirectUrl && nextRedirectUrl !== window.location.pathname) {
      console.log("Redirecting to:", nextRedirectUrl);

      trackEvent(PORTAL_AUTOMATIC_REDIRECTION, {
        next_redirect_url: nextRedirectUrl,
      });

      if (startsWith(nextRedirectUrl, "http")) {
        window.location.href = nextRedirectUrl;
      } else {
        history.replace(nextRedirectUrl);
      }
    }
  }, [user, location, autoRedirectIsEnabled]); // We want this to run when the user first loads and on every route change
};

export default useAutoRedirect;
