export enum Size {
  Large = "large",
  Small = "small",
}

export enum Color {
  Primary = "primary",
  White = "white",
  Black = "black",
  Teal = "teal",
}

export enum IconSize {
  Large = "large",
  Medium = "medium",
  Small = "small",
  Xs = "xs",
}
