import { createContext } from "react";

import useLead from "components/context/LeadContextProvider/useLead";
import { LeadReturn } from "./types";

export const LeadContext = createContext<LeadReturn>({} as LeadReturn);

const LeadContextProvider = ({ children, ...props }: any) => {
  const lead = useLead();

  return (
    <LeadContext.Provider value={lead} {...props}>
      {children}
    </LeadContext.Provider>
  );
};

export default LeadContextProvider;
