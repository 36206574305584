import { BoxProps } from "@mui/material";

export type StatusChipColor = "default" | "info" | "success" | "warning" | "error";

export const statusChipColorMap: Record<StatusChipColor, string> = {
  default: "ampla.gray.100",
  info: "ampla.blue.50",
  success: "ampla.green.100",
  warning: "ampla.orange.100",
  error: "ampla.red.100",
};

export interface StatusChipProps extends BoxProps {
  label: string;
  color?: StatusChipColor;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}
