import { Components } from "@mui/material/styles";

import colors from "../palette/colors";

const MuiRadio: Components["MuiRadio"] = {
  styleOverrides: {
    root: {
      height: "38px",
      width: "38px",

      "&.Mui-checked": {
        color: colors.teal[400],
        "&.Mui-disabled": {
          color: colors.gray[200],
        },
      },
      "&.Mui-disabled": {
        color: colors.gray[200],
      },
      "&:hover": {
        backgroundColor: colors.gray[100],
      },
      "&:active": {
        backgroundColor: colors.gray[200],
      },

      "&.MuiRadio-sizeSmall": {
        height: "32px",
        width: "32px",
      },
    },
  },
};

export default MuiRadio;
