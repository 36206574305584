import { ForwardedRef, forwardRef, useMemo } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";

import { TextField, TextFieldProps } from "libs/components/components/TextField";
import { getDateIsHoliday, getDateIsWeekend } from "libs/components/helpers/date";
import { DatePickerProps } from "./DatePicker.types";

const DatePicker = forwardRef(
  (
    {
      slots,
      disableWeekends,
      disableHolidays,
      minDate,
      maxDate,
      momentLibInstance,
      slotProps = {},
      uneditable,
      UneditableTextFieldProps,
      ...props
    }: DatePickerProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const shouldDisableDate = (date: Moment) => {
      if (disableWeekends && getDateIsWeekend(date)) return true;
      if (disableHolidays && getDateIsHoliday(date)) return true;
      return false;
    };

    const parsedMinDate = useMemo(() => {
      if (!minDate) return minDate;
      return moment.isMoment(minDate) ? minDate : moment(minDate);
    }, [minDate]);

    const parsedMaxDate = useMemo(() => {
      if (!maxDate) return maxDate;
      return moment.isMoment(maxDate) ? maxDate : moment(maxDate);
    }, [maxDate]);

    return (
      <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={momentLibInstance}>
        <MUIDatePicker
          ref={ref}
          slots={{ ...slots, textField: TextField as any }}
          shouldDisableDate={shouldDisableDate || props.shouldDisableDate}
          minDate={parsedMinDate}
          maxDate={parsedMaxDate}
          slotProps={{
            ...slotProps,
            textField: {
              ...slotProps.textField,
              uneditable,
              UneditableTextFieldProps,
            } as TextFieldProps,
          }}
          {...props}
        />
      </LocalizationProvider>
    );
  },
);

export default DatePicker;
