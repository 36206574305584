import camelcaseKeys from "camelcase-keys";
import { map, pick } from "lodash";

import crud from "libs/api/client/client";
import { APIList } from "libs/api/lib";
import { AppNotification } from "./types";

const NotificationAPI = {
  listNotifications: async (): Promise<APIList<AppNotification>> =>
    camelcaseKeys(await crud("list", "/app_notifications/notification_center/"), { deep: true }),

  updateNotifications: async (notifications: Partial<AppNotification>[]): Promise<void> => {
    const data = notifications.map((notification) => pick(notification, ["id", "read", "dismissed"]));
    return camelcaseKeys(await crud("update", "/app_notifications/notification_center/bulk_update/", data), {
      deep: true,
    });
  },

  markNotificationsRead: (notificationIds: string[]): Promise<void> =>
    NotificationAPI.updateNotifications(map(notificationIds, (id) => ({ id, read: true }))),

  dismissNotification: (notificationId: string): Promise<void> =>
    NotificationAPI.updateNotifications([{ id: notificationId, dismissed: true }]),
};

export default NotificationAPI;
