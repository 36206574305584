import { ForwardedRef, forwardRef, useCallback, useEffect, useState } from "react";

import { Box, Filter, Link, MenuItem, MUICheckbox, MUIRadio, Typography } from "@ampla/ui-components";

import { useStyles } from "./SelectFilter.styles";
import { MultiSelectOption, SelectFilterProps } from "./SelectFilter.types";

const getFilterLabel = (
  value: Array<MultiSelectOption>,
  defaultLabel = "Filter",
  options: Array<MultiSelectOption>,
  multiple?: boolean,
) => {
  if (!value.length) return defaultLabel;

  if (!multiple) return options?.find((option) => option.value === value[0])?.label || defaultLabel;

  return `${defaultLabel} (${value.length})`;
};

const SelectFilter = forwardRef(
  (
    {
      label,
      value,
      setFilterValue,
      options,
      minimizedOptions,
      defaultLabel,
      multiple,
      selectAll,
      clearable,
      keepOpen,
      filterProps,
    }: SelectFilterProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const styles = useStyles();

    const [open, setOpen] = useState(false);
    const [minimized, setMinimized] = useState(() => !!minimizedOptions);

    useEffect(() => {
      if (!open && !!minimizedOptions) setTimeout(() => setMinimized(true), 200);
    }, [open]);

    const handleFilterClick = () => setOpen(true);

    const handleFilterClose = () => setOpen(false);

    const handleSelectAllClick = () => {
      if (value.length === options.length) {
        setFilterValue([]);
      } else {
        setFilterValue(options.map((option) => option.value));
      }
      setOpen(false);
    };

    const handleOptionClick = useCallback(
      (option: MultiSelectOption) => () => {
        if (!multiple) setFilterValue([option.value]);
        else {
          if (value.includes(option.value)) {
            setFilterValue(value.filter((v) => v !== option.value));
          } else {
            setFilterValue([...value, option.value]);
          }
        }
        if (!keepOpen) setOpen(false);
      },
      [value, setFilterValue],
    );

    const handleSeeMoreClick = () => setMinimized(false);

    const handleClearClick = () => {
      setFilterValue([]);
      setOpen(false);
    };

    const selectAllChecked = value.length === options.length;
    const selectAllIndeterminate = value.length > 0 && value.length < options.length;

    return (
      <Filter
        {...filterProps}
        ref={ref}
        label={label ?? getFilterLabel(value, defaultLabel, options, multiple)}
        open={open}
        active={!!value.length}
        onClick={handleFilterClick}
        onClose={handleFilterClose}
      >
        <Box>
          {multiple && selectAll && !!options.length && (
            <>
              <MenuItem sx={styles.option} onClick={handleSelectAllClick}>
                <MUICheckbox
                  size="small"
                  checked={selectAllChecked}
                  indeterminate={selectAllIndeterminate}
                  sx={selectAllChecked || selectAllIndeterminate ? styles.checkbox : null}
                  disableRipple
                />
                <Typography variant="label2">Select all</Typography>
              </MenuItem>
              <Box sx={styles.separator} />
            </>
          )}
          {options.length ? (
            options.map((option, index) => {
              if (minimizedOptions && minimized && index >= minimizedOptions) return null;

              const checked = value.includes(option.value);

              return (
                <MenuItem key={option.value} sx={styles.option} onClick={handleOptionClick(option)}>
                  {multiple ? (
                    <MUICheckbox size="small" checked={checked} sx={checked ? styles.checkbox : null} disableRipple />
                  ) : (
                    <MUIRadio size="small" checked={checked} sx={checked ? styles.checkbox : null} disableRipple />
                  )}
                  <Typography variant="label2">{option.label}</Typography>
                </MenuItem>
              );
            })
          ) : (
            <Box sx={styles.emptyContainer}>
              <Typography color="gray" variant="label2">
                No options available
              </Typography>
            </Box>
          )}
          {minimizedOptions && minimized && options.length > minimizedOptions && (
            <Box sx={styles.bottomOptions}>
              <Link component={"button" as any} onClick={handleSeeMoreClick}>
                See more
              </Link>
            </Box>
          )}
          {!!value.length && clearable && (
            <Box sx={styles.bottomOptions}>
              <Link component={"button" as any} onClick={handleClearClick}>
                Clear
              </Link>
            </Box>
          )}
        </Box>
      </Filter>
    );
  },
);

export default SelectFilter;
