import { ForwardedRef, forwardRef } from "react";

import { TextFormField } from "libs/components/components/TextFormField";
import { SelectFormFieldProps } from "./SelectFormField.types";

const SelectFormField = forwardRef(
  ({ children, ...props }: SelectFormFieldProps, ref: ForwardedRef<HTMLDivElement>) => (
    <TextFormField {...props} ref={ref} select>
      {children}
    </TextFormField>
  ),
);

export default SelectFormField;
