export enum EntityType {
  ApplyingBrand = "brand_applying",
  NetTermsVendor = "net_terms_vendor",
  NetTermsBrand = "net_terms_brand",
  NetTermsSeller = "net_terms_seller",
  NetTermsPayer = "net_terms_payer",
  ApprovedVendor = "approved_vendor",
  Bank = "bank",
  Brand = "brand",
  Buyer = "buyer",
  Copacker = "copacker",
  DisabledBrand = "brand_disabled",
  GourmetGrowth = "gg",
  InactiveBrand = "brand_inactive",
  Manufacturer = "manufacturer",
  PaymentProcessor = "payment_processor",
  Shipper = "shipper",
  UnapprovedVendor = "unapproved_vendor",
  Warehouse = "warehouse",
}
