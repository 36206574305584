import { APP_HEADER_HEIGHT } from "../AppHeader/AppHeader.styles";

export const APP_MENU_WIDTH = 200;
export const APP_MENU_WIDTH_MOBILE = 296;

const styles = {
  drawer: {
    top: APP_HEADER_HEIGHT,
    width: APP_MENU_WIDTH,
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor: "ampla.gray.100",
    pt: "33px",

    "@media (max-width: 767px)": {
      top: 0,
      width: APP_MENU_WIDTH_MOBILE,
    },
  },
};

export default styles;
