import colors from "libs/components/theme/ampla-theme/palette/colors";
import { AmplaColors, ColorShades, ColorString } from "libs/components/theme/ampla-theme/palette/types";

export const getColorValuesFromColorString = (
  color?: ColorString,
): [colorName?: string, colorShade?: number, colorValue?: string] => {
  const parts = color?.split("-");

  const colorName = parts?.[0];

  let colorShade = colorName ? parseInt(parts?.[1] || "") || undefined : undefined;
  let colorValue = colorName ? colors[colorName as keyof AmplaColors] : undefined;

  // If the color has shades pick the correct one
  if (colorValue && typeof colorValue !== "string") {
    colorShade = colorShade || 500;
    colorValue = colorValue[colorShade as keyof ColorShades] || undefined;
  }

  return [colorName, colorShade, colorValue];
};
