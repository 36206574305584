export const APP_HEADER_HEIGHT = 57;

const styles = {
  root: {
    px: 2,
    py: 2,
    height: APP_HEADER_HEIGHT,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "ampla.gray.100",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "ampla.white",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1200,

    "@media (min-width:768px)": {
      pl: 5,
      pr: 3,
    },

    "@media (min-width:1280px)": {
      pl: 5,
      pr: 5,
    },
  },
  logo: {
    marginTop: "3px",
    height: 24,
  },
  contentRight: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  },
  iconButton: {
    svg: { color: "ampla.gray.400" },

    "@media (max-width:767px)": {
      display: "none",
    },
  },
  menuButton: {
    display: "none",
    "@media (max-width:767px)": {
      display: "flex",
    },
  },
};

export default styles;
