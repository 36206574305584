import { makeStyles as makeStylesTSS } from "tss-react/mui";

import { StylesObject, UseStylesReturnType } from "./types";

// Wrapper around TSS-react's `makeStyles` that returns typed enhanced component
// and the returned `useStyles` hook maintains the call signature of MUI V4's `makeStyles`.
export const makeStyles = <Styles extends StylesObject>(styles: Styles) => {
  const useStylesTSS = makeStylesTSS()(styles);

  const useStyles = () => {
    const { classes } = useStylesTSS();
    return classes as UseStylesReturnType<Styles>;
  };

  return useStyles;
};
