import { isEmpty, map } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { AppNotificationActionCode, FeatureFlags, NotificationAPI } from "@ampla/api";

import { useAuthState } from "components/context/AuthContextProvider";
import { NOTIFICATIONS_LIST } from "constants/queryKeys";
import { userHasFeatureFlag } from "routes/helpers/validators";

const useNotifications = () => {
  const { user } = useAuthState();
  const { active_entity } = user ?? {};
  const history = useHistory();
  const queryClient = useQueryClient();
  const hasAppNotification = userHasFeatureFlag(user ?? {}, FeatureFlags.ShowAppNotifications) && !!active_entity;
  const hasFinancialReporting = userHasFeatureFlag(user ?? {}, FeatureFlags.FinancialReporting);

  const { data: notificationsResult, isLoading: loadingNotifications } = useQuery({
    queryKey: [NOTIFICATIONS_LIST, user?.id, active_entity],
    queryFn: NotificationAPI.listNotifications,
    enabled: hasAppNotification,
  });

  const dismissNotification = (notificationId: string) => {
    NotificationAPI.dismissNotification(notificationId);
  };

  const markAllNotificationsRead = () => {
    const ids = map(notificationsResult?.results, "id");
    if (!isEmpty(ids)) {
      NotificationAPI.markNotificationsRead(ids);
    }
  };

  const refreshNotifications = () => {
    queryClient.invalidateQueries(NOTIFICATIONS_LIST);
  };

  const actionClick = (code: AppNotificationActionCode) => {
    switch (code) {
      case AppNotificationActionCode.GO_TO_GLOC:
        history.push("/line-of-credit");
        break;
      // These may change, but for now they both go to reporting
      case AppNotificationActionCode.PROVIDE_ADDITIONAL_INFORMATION:
      case AppNotificationActionCode.MANAGE_FINANCIAL_REPORTING:
        if (hasFinancialReporting) {
          history.push("/financial-reporting");
        } else {
          history.push("/self-reporting");
        }
        break;
    }
  };

  return {
    notifications: notificationsResult?.results,
    loadingNotifications,
    actionClick,
    dismissNotification,
    markAllNotificationsRead,
    refreshNotifications,
  };
};

export default useNotifications;
