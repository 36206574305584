import { EntityType } from "@ampla/api";
import { IntegrationProducts, IntegrationStatus, IntegrationType } from "@ampla/api";
import { CamelCasedBankConnection, CamelCasedIntegration, Integration } from "@ampla/api";
import { CamelCasedPlaidItem } from "@ampla/api";
import { ENTITY_TYPES } from "constants/common";
import {
  userHasAddIntegrationPermission,
  userHasAddPlaidItemPermission,
  userHasChangeIntegrationPermission,
  userHasChangePlaidItemPermission,
  userIsStaff,
} from "routes/helpers/validators";
import { User } from "@ampla/api";

type CodatIntegrationValidatorParams = {
  user?: User;
  integration: Integration;
};

type BankIntegrationValidatorParams = {
  user?: User;
  integration: CamelCasedPlaidItem | CamelCasedBankConnection;
  provider: IntegrationProducts;
};

type FivetranIntegrationValidatorParams = {
  user?: User;
  integration: Integration;
};

export const hasHealthyPlaidIntegration = (integrations: Array<CamelCasedPlaidItem>) =>
  !!integrations.find((integration) => integration.status === IntegrationStatus.Ok);

export const hasHealthyBankIntegration = (integrations: Array<CamelCasedBankConnection>) =>
  !!integrations.find(
    (integration) => !!integration.bankAccounts.find((account) => account.status === IntegrationStatus.Ok),
  );

export const hasHealthyCodatIntegration = (integrations: Array<Integration>) =>
  !!integrations.find((integration) => integration.status === IntegrationStatus.Ok);

export const canResumeCodatIntegration = ({ user, integration }: CodatIntegrationValidatorParams) => {
  if (!user) return false;

  if (integration.status !== IntegrationStatus.PendingAuth) return false;

  if (!userHasAddIntegrationPermission({ user }) && !userHasChangeIntegrationPermission({ user })) return false;

  return true;
};

export const canRelinkCodatIntegration = ({ user, integration }: CodatIntegrationValidatorParams) => {
  if (!user) return false;

  if (integration.status === IntegrationStatus.Ok) return false;

  if (integration.status === IntegrationStatus.PendingAuth) return false;

  if (!userHasAddIntegrationPermission({ user }) && !userHasChangeIntegrationPermission({ user })) return false;

  return true;
};

export const canConfigureBankIntegration = ({ user, integration, provider }: BankIntegrationValidatorParams) => {
  if (!user) return false;

  if (user?.active_entity_type === EntityType.Brand && !userIsStaff({ user })) return false;

  if (provider === IntegrationProducts.Plaid && (integration as CamelCasedPlaidItem).status !== IntegrationStatus.Ok)
    return false;

  if (
    provider === IntegrationProducts.Stripe &&
    (integration as CamelCasedBankConnection).bankAccounts.find((account) => account.status !== IntegrationStatus.Ok)
  )
    return false;

  if (!userHasAddPlaidItemPermission({ user }) && !userHasChangePlaidItemPermission({ user })) return false;

  return true;
};

export const canVerifyBankIntegration = ({ user, integration, provider }: BankIntegrationValidatorParams) => {
  if (!user) return false;

  if (provider !== IntegrationProducts.Plaid) return false;

  if (!userHasAddPlaidItemPermission({ user }) && !userHasChangePlaidItemPermission({ user })) return false;

  if ((integration as CamelCasedPlaidItem).status !== IntegrationStatus.PendingAuth) return false;

  return true;
};

export const canRelinkBankIntegration = ({ user, integration, provider }: BankIntegrationValidatorParams) => {
  if (!user) return false;

  if (!userHasAddPlaidItemPermission({ user }) && !userHasChangePlaidItemPermission({ user })) return false;

  if (
    provider === IntegrationProducts.Plaid &&
    [IntegrationStatus.Ok, IntegrationStatus.PendingAuth].includes(
      (integration as CamelCasedPlaidItem).status as IntegrationStatus,
    )
  )
    return false;

  if (
    [IntegrationProducts.Stripe, IntegrationProducts.Finicity].includes(provider) &&
    !getUnhealthyBankAccount(integration as CamelCasedBankConnection)
  )
    return false;

  return true;
};

export const getUnhealthyBankAccount = (integration: CamelCasedBankConnection) =>
  integration.bankAccounts.find((account) => account.status !== IntegrationStatus.Ok);

export const canRemoveFivetranIntegration = ({ user }: FivetranIntegrationValidatorParams) => {
  if (!user) return false;

  if (!userHasAddIntegrationPermission({ user }) && !userHasChangeIntegrationPermission({ user })) return false;

  return true;
};

export const canRemoveCodatIntegration = ({ user }: CodatIntegrationValidatorParams) => {
  if (!user) return false;

  if (user.active_entity_type !== ENTITY_TYPES.ApplyingBrand && user.active_entity_type !== ENTITY_TYPES.NetTermsVendor)
    return false;

  if (!userHasAddIntegrationPermission({ user }) && !userHasChangeIntegrationPermission({ user })) return false;

  return true;
};

export const isLegacyShopifyIntegration = (integration: CamelCasedIntegration) =>
  integration.type === IntegrationType.CodatEcommerce &&
  integration.data?.platformName === "Shopify" &&
  !integration.data?.isMigrated;

export const getIntegrationDisplayName = (integration: CamelCasedIntegration) =>
  `${integration.data.platformName}${
    integration.data?.shopifyShopName ? " - " + integration.data?.shopifyShopName : ""
  }` || "";
