import { ForwardedRef, forwardRef } from "react";
import { Typography as MuiTypography } from "@mui/material";
import classnames from "classnames";

import { getColorValuesFromColorString } from "libs/components/helpers/colors";
import { AMPLA_COMPONENT_NAMESPACE } from "libs/components/theme/constants";
import { TypographyProps } from "./Typography.types";

const Typography = forwardRef(
  <C extends React.ElementType = "div">(props: TypographyProps<C>, ref: ForwardedRef<HTMLSpanElement>) => {
    const { variant, className, weight, underline, altFont, color, component = "div", ...baseProps } = props;

    const [colorName, colorShade] = getColorValuesFromColorString(color);

    const colorClassName = color ? [colorName, colorShade].filter((value) => value).join("-") : "";

    return (
      <MuiTypography
        {...baseProps}
        ref={ref}
        component={component}
        className={classnames(
          AMPLA_COMPONENT_NAMESPACE,
          colorClassName,
          variant,
          weight,
          { underline, altfont: altFont },
          className,
        )}
      />
    );
  },
);

export default Typography;
