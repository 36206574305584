import { Components } from "@mui/material/styles";

import colors from "../palette/colors";

const MuiTableRow: Components["MuiTableRow"] = {
  styleOverrides: {
    root: {
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: colors.gray[100],
      "&:last-child": {
        borderBottomWidth: 0,
      },

      "&.MuiTableRow-hover:hover": {
        backgroundColor: colors.gray[50],
      },
    },
    head: {
      "&:last-child": {
        borderBottomWidth: 1,
      },
    },
  },
};

export default MuiTableRow;
