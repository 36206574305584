import { Components } from "@mui/material/styles";

const MuiCheckbox: Components["MuiCheckbox"] = {
  styleOverrides: {
    root: {
      flexShrink: 0,
    },
  },
};

export default MuiCheckbox;
