import { ForwardedRef, forwardRef, useMemo } from "react";
import { Avatar } from "@mui/material";

import { UserAvatarProps } from "./UserAvatar.types";

const UserAvatar = forwardRef(
  ({ firstName, lastName, email, ...props }: UserAvatarProps, ref: ForwardedRef<HTMLDivElement>) => {
    const initials = useMemo(() => {
      const parts = [];

      if (firstName) parts.push(firstName[0]);
      if (lastName) parts.push(lastName[0]);

      if (!parts.length) parts.push(email ? email[0] : "?");

      return parts.join("");
    }, [firstName, lastName, email]);

    return (
      <Avatar
        {...props}
        ref={ref}
        sx={{
          ...(props.sx || {}),
          color: "ampla.teal.800",
          backgroundColor: "ampla.teal.200",
          textTransform: "uppercase",
        }}
      >
        {initials}
      </Avatar>
    );
  },
);

export default UserAvatar;
