const styles = {
  mobileOptions: {
    display: "flex",
    flexDirection: "column",

    "@media (min-width: 768px)": {
      display: "none",
    },
  },
  mobileDivider: {
    my: 1,
  },
  mobileSubtitle: {
    mt: 1.5,
    mb: 0.5,
    px: 3,
  },
};

export default styles;
