import { Theme, makeStyles } from "@ampla/ui-components";

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: 0,
    padding: 0,
    margin: 0,
    position: "fixed",
    bottom: "150px",
    right: 0,
    color: "white",
    backgroundColor: theme.palette.ampla.teal[700],
    width: "100px",
    height: "50px",
    zIndex: 2,
    borderRadius: "5px 5px 0px 0px !important",
    transform: "rotate(-90deg)",
    transformOrigin: "bottom right",
    transition: "all linear 100ms",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.ampla.teal[800],
    },
  },
  buttonTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
  buttonHide: {
    visibility: "hidden",
  },
}));
