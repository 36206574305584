import { Components } from "@mui/material/styles";

import { AMPLA_COMPONENT_NAMESPACE_SELECTOR } from "libs/components/theme/constants";
import colors from "../palette/colors";

const MuiTextField: Components["MuiTextField"] = {
  styleOverrides: {
    root: {
      [AMPLA_COMPONENT_NAMESPACE_SELECTOR]: {
        "& fieldset": {
          visibility: "hidden",
        },
        "& .MuiFormLabel-root": {
          display: "flex",
          alignItems: "center",
          position: "unset",
          transform: "none",
        },
        "& .MuiInputBaseLabel-root": {
          textTransform: "uppercase",
          color: colors.gray[500],
          "&.Mui-error": {
            color: colors.red[500],
          },
        },
        "& .MuiInputBase-root": {
          border: `1px solid ${colors.gray[200]}`,
          borderRadius: 4,
          backgroundColor: colors.gray[50],
          "&.Mui-focused": {
            borderColor: colors.teal[400],
            backgroundColor: colors.teal[50],
          },
          "&.Mui-error": {
            color: colors.red[500],
            borderColor: colors.red[500],
            backgroundColor: colors.red[50],
          },
          "&:before, &:after": {
            content: "unset",
          },
        },
        "& .MuiInputBase-input": {
          height: "28px",
          color: colors.teal[900],
          transition: "all .25s ease",
          padding: "10px 12px 10px 16px",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",

          "&::placeholder": {
            color: colors.gray[500],
            opacity: 1,
          },
          "&.Mui-disabled": {
            cursor: "auto",
            "&::placeholder": {
              color: colors.gray[300],
              opacity: 1,
            },
          },
        },
        "& .MuiInputBase-inputAdornedStart": {
          paddingLeft: 12,
        },
        "& .MuiFormHelperText-root": {
          marginTop: "8px",
          marginLeft: "unset",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "18px",
          color: colors.gray[400],

          "&.Mui-error": {
            color: colors.red[500],
          },
        },
        "& .MuiSelect-select": {
          display: "flex",
          alignItems: "center",
        },
        "& .MuiSelect-icon": {
          right: "10px",
        },
      },
    },
  },
};

export default MuiTextField;
