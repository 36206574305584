import { SESSION_USER_KEY } from "storage-keys.json";

import { User } from "./types";

/**
 * Sets the current user and stores it in the session storage.
 * @param user The user object to set as the current user.
 * @returns The updated user object.
 */
export const setCurrentUser = (user: User): User => {
  const newUser = {
    ...user,
    groups: user.groups || [],
    user_groups: user?.groups?.reduce((obj, d) => Object.assign(obj, { [d]: true }), {}) || {},
  };
  sessionStorage.setItem(SESSION_USER_KEY, JSON.stringify(newUser));
  return newUser;
};
