import { USER_SIGNUP_FROM_PAID_CHANNEL } from "constants/eventKeys";
import { SESSION_UTM_CAMPAIGN_KEY, SESSION_UTM_MEDIUM_KEY, SESSION_UTM_SOURCE_KEY } from "constants/storageKeys";
import { User } from "@ampla/api";
import { getEnvironmentFromAPIURL } from "./url";
import { reduce } from "lodash";
import { FeatureFlags } from "@ampla/api";

const UTM_SOURCE_PARAM = "utm_source";
const UTM_MEDIUM_PARAM = "utm_medium";
const UTM_CAMPAIGN_PARAM = "utm_campaign";
const UTM_MEDIUM_VALUE = "cpc";
const UTM_GOOGLE_PARAM = "gclid";
const UTM_GOOGLE_VALUE = "google_ads";

// ⚠️ We're tracking events in Heap from both the frontend and backend, they both
// ⚠️ send this same set of properties when identifying the user, if we need to add or
// ⚠️ remove a property from here we should also apply the same changes in the backend.

type HeapUserFFProperties = {
  [feature_flag in FeatureFlags]?: boolean;
};

type HeapUserProperties = HeapUserFFProperties & {
  entity_id?: number;
  entity_name?: string;
  user_name?: string;
  user_email?: string;
  user_is_staff?: string;
  env_name?: string;
};

export function getTrackingUserProperties(user: User): HeapUserProperties {
  return {
    entity_id: user.active_entity,
    entity_name: user.active_entity_name,
    user_name: [user.contact?.first_name, user.contact?.last_name].join(" "),
    user_email: user.email ?? user.username,
    user_is_staff: user?.is_staff?.toString(),
    env_name: getEnvironmentFromAPIURL(),
    ...reduce(
      user.active_entity_feature_flags,
      (result, value) => {
        result[value] = true;
        return result;
      },
      {} as HeapUserFFProperties,
    ),
  };
}

export function getHeapInstance() {
  const { heap } = window;
  if (typeof heap === "object" && window.env.REACT_APP_HEAP_KEY) return heap;
  return null;
}

export function identifyUserInHeap(user: User) {
  const heap = getHeapInstance();

  if (heap && user.id) {
    heap.identify(user.id.toString());
    heap.addUserProperties(getTrackingUserProperties(user));
  }
}

export function identifyLeadInHeap(email: string) {
  const heap = getHeapInstance();

  if (heap) {
    heap.addUserProperties({ lead_email: email });
  }
}

export function setupUTMSession() {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get(UTM_SOURCE_PARAM);
  const utmMedium = urlParams.get(UTM_MEDIUM_PARAM);
  const utmCampaign = urlParams.get(UTM_CAMPAIGN_PARAM);
  const utmGoogle = urlParams.get(UTM_GOOGLE_PARAM);

  if (utmSource) sessionStorage.setItem(SESSION_UTM_SOURCE_KEY, utmSource);
  if (utmMedium) sessionStorage.setItem(SESSION_UTM_MEDIUM_KEY, utmMedium);
  if (utmCampaign) sessionStorage.setItem(SESSION_UTM_CAMPAIGN_KEY, utmCampaign);
  if (utmGoogle) sessionStorage.setItem(SESSION_UTM_SOURCE_KEY, UTM_GOOGLE_VALUE);
}

export function trackUserLeadGen(user: User) {
  const utmSource = sessionStorage.getItem(SESSION_UTM_SOURCE_KEY);
  const utmMedium = sessionStorage.getItem(SESSION_UTM_MEDIUM_KEY);
  const heap = getHeapInstance();

  if (heap && utmSource && utmMedium === UTM_MEDIUM_VALUE) {
    identifyUserInHeap(user);
    heap.track(USER_SIGNUP_FROM_PAID_CHANNEL, { source: utmSource });
    sessionStorage.removeItem(SESSION_UTM_SOURCE_KEY);
  }
}
