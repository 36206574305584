import { ForwardedRef, forwardRef } from "react";
import { TextField as MuiTextField } from "@mui/material";
import classnames from "classnames";
import { isNil } from "lodash";
import { NumericFormat, PatternFormat } from "react-number-format";

import { TextFieldLabel } from "libs/components/components/TextFieldLabel";
import { AMPLA_COMPONENT_NAMESPACE } from "libs/components/theme/constants";
import { renderSelectValue } from "./helpers";
import { TextFieldProps } from "./TextField.types";
import UneditableTextField from "./UneditableTextField";

const TextField = forwardRef(
  (
    {
      className,
      format,
      label,
      type,
      labelHelperTooltipTitle,
      labelHelperTooltipProps,
      numericFormatProps,
      patternFormatProps,
      onNumericValueChange,
      uneditable,
      UneditableTextFieldProps = {},
      ...props
    }: TextFieldProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const muiTextFieldProps = {
      ...props,
      label: label ? (
        <TextFieldLabel
          label={label}
          helperTooltipProps={labelHelperTooltipProps}
          helperTooltipTitle={labelHelperTooltipTitle}
          required={props.required}
          error={props.error}
          disabled={props.disabled}
        />
      ) : undefined,
      InputLabelProps: {
        shrink: true,
        required: false,
        style: labelHelperTooltipTitle ? { marginTop: -1, marginBottom: 7 } : undefined,
      },
      className: classnames(AMPLA_COMPONENT_NAMESPACE, className),
    };

    if (format === "number") {
      // NumericFormat for Number based formatting like currency inputs.
      return (
        <NumericFormat
          ref={ref}
          customInput={uneditable ? UneditableTextField : MuiTextField}
          decimalScale={2}
          thousandSeparator=","
          {...numericFormatProps}
          {...muiTextFieldProps}
          // if the onNumericValueChange prop is provided, use it for the onValueChange event
          // and set onChange to undefined to avoid conflicts and ensure the correct event handling.
          {...(onNumericValueChange ? { onValueChange: onNumericValueChange, onChange: undefined } : {})}
          // pass extra props to UneditableTextField when this TextField is uneditable
          {...(uneditable ? UneditableTextFieldProps : {})}
        />
      );
    } else if (format === "pattern") {
      // PatternFormat for Pattern based formatting like card numbers, phone number inputs.
      return (
        <PatternFormat
          ref={ref}
          customInput={uneditable ? UneditableTextField : MuiTextField}
          format={patternFormatProps?.format || ""}
          {...patternFormatProps}
          {...muiTextFieldProps}
          // pass extra props to UneditableTextField when this TextField is uneditable
          {...(uneditable ? UneditableTextFieldProps : {})}
        />
      );
    } else if (uneditable) {
      return <UneditableTextField {...muiTextFieldProps} {...UneditableTextFieldProps} />;
    } else {
      return (
        <MuiTextField
          ref={ref}
          {...muiTextFieldProps}
          type={type}
          SelectProps={{
            displayEmpty: true,
            renderValue:
              muiTextFieldProps.SelectProps?.renderValue || muiTextFieldProps.placeholder
                ? (value) =>
                    isNil(value) || value === ""
                      ? muiTextFieldProps.placeholder
                      : renderSelectValue(value, muiTextFieldProps.children, !!muiTextFieldProps.SelectProps?.multiple)
                : undefined,
            ...(muiTextFieldProps as any).SelectProps,
          }}
        />
      );
    }
  },
);

export default TextField;
