import { generatePath } from "react-router";

import { RouteDeclaration } from "apps/portal/routes/types";
import { ValidatorParams } from "apps/portal/types";
import { AppMenuRouteDeclaration, RouteDeclarationGroup } from "./AppShell.types";

/**
 * Filter a single route based on the user's permissions
 * @param validatorParams
 * @returns A function that takes a route declaration and returns a boolean
 */
export const filterRoute = (validatorParams: ValidatorParams) => (route: RouteDeclaration) =>
  route.validators?.length ? route.validators.every((validator) => validator(validatorParams)) : true;

/**
 * Filter a route group based on the user's permissions
 * @param validatorParams
 * @returns A function that takes a route and route group declarations array and returns a boolean
 */
export const filterGroupRoute = (validatorParams: ValidatorParams) => (route: RouteDeclarationGroup) =>
  route.routes.some(filterRoute(validatorParams));

/**
 * Filter routes based on the user's permissions
 * @param routes
 * @param validatorParams
 * @returns An array of route declaration objects
 */
export const filterRoutes = (routes: RouteDeclaration[], validatorParams: ValidatorParams) =>
  routes.filter(filterRoute(validatorParams));

/**
 * Filter route and route groups based on the user's permissions
 * if the route group has no allowed child routes it will be filtered out
 * @param routes
 * @param validatorParams
 * @returns An array of route and route group declaration objects
 */
export const filterGroupRoutes = (routes: AppMenuRouteDeclaration[], validatorParams: ValidatorParams) => {
  const filteredRoutes = routes.filter((route) => {
    if (route.path) return filterRoute(validatorParams)(route);
    return filterGroupRoute(validatorParams)(route as RouteDeclarationGroup);
  });

  return filteredRoutes.map((route) => {
    if (route.path) return route;
    return {
      ...route,
      routes: route.routes?.filter(filterRoute(validatorParams)) || [],
    };
  });
};

/**
 * Flatten a route declaration group into a single array of routes
 * @param routes
 * @returns
 */
export const flattenRouteDeclarationGroups = (routes: AppMenuRouteDeclaration[]): RouteDeclaration[] => {
  const flattenedRoutes: RouteDeclaration[] = [];

  routes.forEach((route) => {
    if (route.path) {
      flattenedRoutes.push(route);
    } else {
      flattenedRoutes.push(...(route.routes || []));
    }
  });

  return flattenedRoutes;
};

/**
 * Find the active route based on the current path
 * @param routes
 * @param currentPath
 * @returns
 */
export const findActiveRoute = (routes: AppMenuRouteDeclaration[], currentPath: string) => {
  const flattenedRoutes = flattenRouteDeclarationGroups(routes);

  let activeRoute;

  // Try to find an exact match first
  activeRoute = flattenedRoutes.find((route) => currentPath === generatePath(route.path));

  // If no exact match, try to find a partial match
  if (!activeRoute) activeRoute = flattenedRoutes.find((route) => currentPath.startsWith(generatePath(route.path)));

  return activeRoute;
};
