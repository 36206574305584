import { useMemo, useState } from "react";
import { generatePath, Link, useLocation } from "react-router-dom";

import { FeatureFlags } from "@ampla/api";
import {
  APP_HEADER_HEIGHT,
  APP_MENU_WIDTH,
  AppHeader,
  AppMenu,
  AppMenuOption,
  Box,
  Divider,
  Typography,
  useMediaQuery,
} from "@ampla/ui-components";

import { getUserFullName } from "apps/portal/helpers/user";
import useNotifications from "hooks/notifications/useNotifications";
import { userHasFeatureFlag } from "routes/helpers/validators";
import AppBrandSwitcher from "../AppLayout/components/AppBrandSwitcher";
import AppFooter from "../AppLayout/components/AppFooter";
import { useAuthState } from "../context/AuthContextProvider";
import { useLayoutState } from "../context/LayoutContextProvider";
import Zendesk from "../Zendesk";
import styles from "./AppShell.styles";
import { AppShellProps } from "./AppShell.types";
import AppBanners from "./components/AppBanners";
import { ProfilePanel } from "./components/ProfilePanel";
import { SettingsPanel } from "./components/SettingsPanel";
import { MAIN_MENU_ROUTES, SETTINGS_MENU_ROUTES } from "./constants";
import { filterGroupRoutes, filterRoutes, findActiveRoute } from "./helpers";

const AppShell = ({ children }: AppShellProps) => {
  const {
    isMenuOpen,
    setIsMenuOpen,
    isSettingsMenuOpen,
    setIsSettingsMenuOpen,
    isProfileMenuOpen,
    setIsProfileMenuOpen,
    isNotificationMenuOpen,
    setIsNotificationMenuOpen,
    showAppHeader,
    showAppMenu,
    showAppFooter,
    showBrandSwitcher,
  } = useLayoutState();

  const [expandedGroupKeys, setExpandedGroupKeys] = useState<string[]>([]);

  const location = useLocation();
  const authContext = useAuthState();

  const { user, logout } = authContext;

  const {
    notifications,
    loadingNotifications,
    actionClick,
    dismissNotification,
    markAllNotificationsRead,
    refreshNotifications,
  } = useNotifications();

  const hasAppNotification = userHasFeatureFlag(user ?? {}, FeatureFlags.ShowAppNotifications);

  const handleMenuButtonClick = () => setIsMenuOpen(!isMenuOpen);

  const handleMenuOptionClick = () => setIsMenuOpen(false);

  const handleMenuGroupClick = (key: string) => () =>
    setExpandedGroupKeys(
      expandedGroupKeys.includes(key)
        ? expandedGroupKeys.filter((expandedGroupKey) => expandedGroupKey !== key)
        : [...expandedGroupKeys, key],
    );

  const handleSettingsOptionClick = () => setIsSettingsMenuOpen(false);

  const handleProfileOptionClick = () => setIsProfileMenuOpen(false);

  const handleNotificationClick = () => setIsNotificationMenuOpen(!isNotificationMenuOpen);

  const handleSignOutClick = () => {
    logout();
    setIsMenuOpen(false);
  };

  const isSmallViewport = useMediaQuery("(max-width: 767px)");

  const filteredMainMenuRoutes = filterGroupRoutes(MAIN_MENU_ROUTES, authContext);
  const filteredSettingsMenuRoutes = filterRoutes(SETTINGS_MENU_ROUTES, authContext);

  const activeRoute = useMemo(
    () => findActiveRoute([...MAIN_MENU_ROUTES, ...SETTINGS_MENU_ROUTES], location.pathname),
    [MAIN_MENU_ROUTES, SETTINGS_MENU_ROUTES, location],
  );

  return (
    <Box
      sx={{
        paddingTop: showAppHeader ? `${APP_HEADER_HEIGHT}px` : undefined,
        paddingLeft: showAppMenu && !isSmallViewport ? `${APP_MENU_WIDTH}px` : undefined,
      }}
    >
      {showAppHeader && (
        <AppHeader
          user={{
            name: getUserFullName(user),
            email: user?.email || "",
            brand: user?.active_entity_name || "",
          }}
          SettingsDropdownProps={
            filteredSettingsMenuRoutes.length
              ? {
                  isOpen: isSettingsMenuOpen,
                  setIsOpen: setIsSettingsMenuOpen,
                  children: (
                    <SettingsPanel routes={filteredSettingsMenuRoutes} onOptionClick={handleSettingsOptionClick} />
                  ),
                }
              : undefined
          }
          ProfileDropdownProps={{
            isOpen: isProfileMenuOpen,
            setIsOpen: setIsProfileMenuOpen,
            children: <ProfilePanel onOptionClick={handleProfileOptionClick} />,
          }}
          NotificationDrawerProps={{
            open: isNotificationMenuOpen,
            onClose: () => setIsNotificationMenuOpen(false),
            notifications,
            isLoading: loadingNotifications,
            onAction: actionClick,
            onDismiss: dismissNotification,
            markAllNotificationsRead,
            refreshNotifications,
          }}
          onMenuButtonClick={handleMenuButtonClick}
          onNotificationsButtonClick={hasAppNotification ? handleNotificationClick : undefined}
        />
      )}
      {showAppMenu && (
        <AppMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}>
          {filteredMainMenuRoutes.map((option) =>
            option.routes ? (
              <AppMenuOption
                key={option.key}
                label={option.title || option.name || ""}
                onClick={handleMenuGroupClick(option.key as string)}
                isOpen={expandedGroupKeys.includes(option.key as string)}
                isActive={option.routes.some((route) => route.path === activeRoute?.path)}
                sx={{ mb: 1.5 }}
              >
                {option.routes.map((route) => (
                  <AppMenuOption
                    key={route.key || route.path}
                    component={Link}
                    to={generatePath(route.path)}
                    label={route.name || ""}
                    isActive={route.path === activeRoute?.path}
                    isExpanded={expandedGroupKeys.includes(option.key as string)}
                    size="small"
                  />
                ))}
              </AppMenuOption>
            ) : (
              <AppMenuOption
                key={option.key || option.path}
                component={Link}
                to={generatePath(option.path)}
                onClick={handleMenuOptionClick}
                label={option.title || option.name || ""}
                isActive={option.path === activeRoute?.path}
                sx={{ mb: 1.5 }}
              />
            ),
          )}
          {isSmallViewport && (
            <Box sx={styles.mobileOptions}>
              {!!filteredSettingsMenuRoutes.length && (
                <>
                  <Divider sx={styles.mobileDivider} />
                  <Typography variant="overline2" color="gray-400" sx={styles.mobileSubtitle}>
                    Settings
                  </Typography>
                  <Box sx={{ mb: 1 }}>
                    {filteredSettingsMenuRoutes.map((option) => (
                      <AppMenuOption
                        key={option.key || option.path}
                        component={Link}
                        to={generatePath(option.path)}
                        onClick={handleMenuOptionClick}
                        label={option.title || option.name || ""}
                        isActive={option.path === activeRoute?.path}
                      />
                    ))}
                  </Box>
                </>
              )}
              <Divider sx={styles.mobileDivider} />
              <AppMenuOption label="Sign out" onClick={handleSignOutClick} />
            </Box>
          )}
        </AppMenu>
      )}
      <AppBanners />
      {children}
      {showAppFooter && <AppFooter />}
      {showBrandSwitcher && <AppBrandSwitcher />}
      <Zendesk />
    </Box>
  );
};

export default AppShell;
