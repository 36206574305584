const styles = {
  root: {
    py: 1,
  },
  list: {
    pt: 0.5,
    pb: 0,
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    py: 1.5,
    px: 3,
  },
};

export default styles;
