import { BancoCardFormat, BancoCardStatus, BancoCardType } from "../banco-card/enums";
import { CreditCardStatus, CreditCardType } from "../credit-card";
import { CardStatus, CardType } from "./types";

// We probably only need one of SHORT or LONG version
export const CARD_TYPE_SHORT_DESCRIPTION: Record<CardType, string> = {
  [BancoCardType.UnitDebitCard]: "Debit",
  [BancoCardType.UnitGrowthCard]: "Growth",
  [BancoCardType.UnitChargeCard]: "Corporate",
  [CreditCardType.I2CCorporateCard]: "Corporate",
};

export const CARD_TYPE_FULL_DESCRIPTION: Record<CardType, string> = {
  [BancoCardType.UnitDebitCard]: "Debit Card",
  [BancoCardType.UnitGrowthCard]: "Growth Card",
  [BancoCardType.UnitChargeCard]: "Corporate Card",
  [CreditCardType.I2CCorporateCard]: "Corporate Card",
};

export const CARD_FORMAT_DESCRIPTION = {
  [BancoCardFormat.Virtual]: "Virtual",
  [BancoCardFormat.Physical]: "Physical",
};

export const CARD_STATUS_DESCRIPTION: Record<CardStatus, string> = {
  [BancoCardStatus.Active]: "Active",
  [BancoCardStatus.Frozen]: "Frozen",
  [BancoCardStatus.Lost]: "Lost",
  [BancoCardStatus.Stolen]: "Stolen",
  [BancoCardStatus.ClosedByCustomer]: "Closed",
  [BancoCardStatus.SuspectedFraud]: "Suspected Fraud",
  [BancoCardStatus.Inactive]: "Pending",
  [BancoCardStatus.Applying]: "Pending",
  [CreditCardStatus.PendingConfirmation]: "Pending",
  [CreditCardStatus.PendingActivation]: "Pending Activation",
};

export const CARD_STATUS_DESCRIPTION_TOOLTIP: Partial<Record<BancoCardStatus, string>> = {
  [BancoCardStatus.Inactive]: "Pending Card Activation",
  [BancoCardStatus.Applying]: "Pending Email Verification",
};
