import { forwardRef } from "react";
import { CircularProgress, Button as MUIButton } from "@mui/material";
import classNames from "classnames";

import { AMPLA_COMPONENT_NAMESPACE } from "libs/components/theme/constants";
import { ButtonProps } from "./Button.types";

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, isLoading, color = "primary", size = "large", variant = "contained", ...props }: ButtonProps, ref) => (
    <MUIButton
      {...props}
      ref={ref}
      size={size}
      variant={variant}
      className={classNames(
        AMPLA_COMPONENT_NAMESPACE,
        color,
        {
          loading: isLoading,
          withStartIcon: !!props.startIcon,
          withEndIcon: !!props.endIcon,
        },
        props.className,
      )}
      disabled={isLoading || props.disabled}
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
    >
      <span>{children}</span>
      {isLoading && (
        <div className="loading-container">
          <CircularProgress size={20} color="inherit" />
        </div>
      )}
    </MUIButton>
  ),
);

export default Button;
