import { ComponentType, SVGProps } from "react";

import { getColorValuesFromColorString } from "@ampla/ui-components";

import { WithIconWrapperProps } from "./types";

const withIconWrapper =
  (Component: ComponentType<SVGProps<SVGSVGElement>>) =>
  ({ color, style = {}, ...props }: WithIconWrapperProps) => {
    const [, , colorValue] = getColorValuesFromColorString(color);

    return <Component {...props} style={{ fill: colorValue, ...style }} />;
  };

export default withIconWrapper;
