import { User } from "@ampla/api";

export function getPendoInstance() {
  const { pendo } = window;
  if (pendo && window.env.REACT_APP_PENDO_KEY) return pendo;
  return null;
}

export function setupPendoSession(user?: User, sessionId?: string, sessionTimestamp?: number) {
  const pendo = getPendoInstance();

  if (pendo) {
    if (user?.id) {
      pendo.identify({
        visitor: {
          id: user?.email,
          email: user?.email,
          full_name: `${user?.contact?.first_name} ${user?.contact?.last_name}`,
          role: user?.brand_role,
          internal_id: user?.id,
          session_id: sessionId,
          session_start_timestamp: sessionTimestamp,
        },
        account: {
          id: user?.active_entity,
          brand: user?.active_entity_name,
        },
      });
    } else {
      pendo.initialize({
        visitor: {
          id: "VISITOR-UNIQUE-ID",
          session_id: sessionId,
          session_start_timestamp: sessionTimestamp,
        },
      });
    }
  }
}

export function identifyLeadInPendo(email: string) {
  const pendo = getPendoInstance();

  if (pendo) {
    pendo.updateOptions({
      visitor: { lead_email: email },
    });
  }
}
