import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

const useDebounce = (value: string, setValue: (arg0: string) => void, debouceWaitMs = 300) => {
  const [inputValue, setInputValue] = useState(value);

  const debouncedSetValue = useCallback(debounce(setValue, debouceWaitMs), [setValue]);

  useEffect(() => {
    debouncedSetValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (!value) setInputValue(value);
  }, [value]);

  return {
    inputValue,
    setInputValue,
  };
};

export default useDebounce;
