import { AuthAPI } from "@ampla/api";
import { useMutation, useQueryClient } from "react-query";
import { AuthInternalContext } from "../types";
import { CURRENT_USER_QUERY_KEY } from "constants/common";
import { useHistory } from "react-router";
import { getWidgetReturnUrl } from "helpers/widget";

const useLogout = ({ setUserIsLoggedIn }: Pick<AuthInternalContext, "setUserIsLoggedIn">) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: logout, ...logoutMutation } = useMutation<void, any, { nextUrl?: string } | void>(
    () => AuthAPI.logout(),
    {
      onError: (error) => console.error(error, "Failed to logout"),
      onSettled: (data, error, variables) => {
        // Set the logged in flag to false
        setUserIsLoggedIn(false);

        // Clear session storage
        sessionStorage.clear();

        // Clear local storage
        localStorage.clear();

        // Remove the current user data query from the cache
        queryClient.removeQueries(CURRENT_USER_QUERY_KEY);

        let nextUrl = variables?.nextUrl;

        // Try to get it from session storage in case portal was
        // opened from the Ampla embedable widget
        if (!nextUrl) nextUrl = getWidgetReturnUrl();

        history.push(nextUrl || "/");
      },
      retry: false,
    },
  );

  return { logout, logoutMutation };
};

export default useLogout;
