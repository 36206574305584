import { BrandRole, BrandRoleAction, BrandRoles, UserPermissions } from "@ampla/api";

const BRAND_ROLE_PERM_MAP: Record<BrandRole, Partial<Record<BrandRoleAction, UserPermissions>>> = {
  [BrandRoles.BrandOwner]: {
    [BrandRoleAction.SendActivation]: UserPermissions.SendActivationBrandOwner,
  },
  [BrandRoles.BrandAdmin]: {
    [BrandRoleAction.Add]: UserPermissions.AddBrandAdmin,
    [BrandRoleAction.Change]: UserPermissions.ChangeBrandAdmin,
    [BrandRoleAction.Delete]: UserPermissions.DeleteBrandAdmin,
    [BrandRoleAction.SendActivation]: UserPermissions.SendActivationBrandAdmin,
  },
  [BrandRoles.BrandAccountingManager]: {
    [BrandRoleAction.Add]: UserPermissions.AddBrandManager,
    [BrandRoleAction.Change]: UserPermissions.ChangeBrandManager,
    [BrandRoleAction.Delete]: UserPermissions.DeleteBrandManager,
    [BrandRoleAction.SendActivation]: UserPermissions.SendActivationBrandManager,
  },
  [BrandRoles.BrandEmployee]: {
    [BrandRoleAction.Add]: UserPermissions.AddBrandEmployee,
    [BrandRoleAction.Change]: UserPermissions.ChangeBrandEmployee,
    [BrandRoleAction.Delete]: UserPermissions.DeleteBrandEmployee,
    [BrandRoleAction.SendActivation]: UserPermissions.SendActivationBrandEmployee,
  },
  [BrandRoles.BrandBookkeeper]: {
    [BrandRoleAction.Add]: UserPermissions.AddBrandBookkeeper,
    [BrandRoleAction.Change]: UserPermissions.ChangeBrandBookkeeper,
    [BrandRoleAction.Delete]: UserPermissions.DeleteBrandBookkeeper,
    [BrandRoleAction.SendActivation]: UserPermissions.SendActivationBrandBookkeeper,
  },
  [BrandRoles.BrandCardholder]: {
    [BrandRoleAction.Add]: UserPermissions.AddBrandCardholder,
    [BrandRoleAction.Change]: UserPermissions.ChangeBrandCardholder,
    [BrandRoleAction.Delete]: UserPermissions.DeleteBrandCardholder,
    [BrandRoleAction.SendActivation]: UserPermissions.SendActivationBrandCardholder,
  },
};

export const getBrandRolePerm = (brandRole: BrandRole, action: BrandRoleAction): UserPermissions | undefined =>
  BRAND_ROLE_PERM_MAP[brandRole]?.[action];

export const getAllBrandRolePerms = (action: BrandRoleAction): UserPermissions[] =>
  Object.values(BRAND_ROLE_PERM_MAP)
    .map((actions) => actions?.[action])
    .filter(Boolean) as UserPermissions[];
