import { lazy } from "react";
import { RouteDeclaration } from "./types";
import {
  activeEntityHasProductChargeCardsInActiveState,
  activeEntityHasProductDebitCardsInActiveState,
  activeEntityHasProductFundingInActiveState,
  activeEntityHasProductFundingInApplyingState,
  activeEntityHasProductFundingInDisabledState,
  activeEntityHasProductFundingInReviewingState,
  activeEntityHasProductGrowthCardsInActiveState,
  activeEntityHasProductGrowthCardsInOnboardingState,
  activeEntityIsNotAmpla,
  everyValidator,
  featureFlagValidator,
  negateValidator,
  oneOfValidator,
  userBancoApplicationHasBeenDenied,
  userFundingApplicationHasBeenDenied,
  userHasAccessSelfReportingPermission,
  userHasRequestFundingPermission,
  userHasViewFullHomePagePermission,
  userHasViewTransactionEventPermission,
} from "./helpers/validators";
import { CARD_CONFIRM_STEP, CARD_CONFIRM_STEP_SUCCESS, CARD_CREATE, CARD_DETAIL } from "./common";
import { PORTAL_RECIPIENTS_ROUTES } from "pages/Recipients/routing";
import { FeatureFlags } from "@ampla/api";

const SelfReportingDashboard = lazy(() => import("pages/SelfReportingDashboard"));
const FinancialReporting = lazy(() => import("pages/FinancialReporting"));
const FinancialReportingDetails = lazy(() => import("pages/FinancialReporting/components/Details"));
const Signup = lazy(() => import("pages/Signup"));
const Application = lazy(() => import("pages/Application"));
const PhoneVerification = lazy(() => import("pages/PhoneVerification"));
const HomePageV2 = lazy(() => import("pages/HomePageV2"));
const LineOfCreditDashboard = lazy(() => import("pages/LineOfCreditDashboard"));
const PayLaterLandingPage = lazy(() => import("pages/PayLaterLandingPage"));

// Application routes

export const SIGNUP: RouteDeclaration = {
  name: "Signup",
  path: "/signup",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: Signup,
};

export const SIGNUP_FOR_PRODUCT: RouteDeclaration = {
  name: "Signup",
  path: "/signup/:product",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: Signup,
};

export const SIGNUP_USER: RouteDeclaration = {
  name: "Signup",
  path: "/signup/user",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: Signup,
};

export const SIGNUP_PRODUCT: RouteDeclaration = {
  name: "Signup",
  path: "/signup/product",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: Signup,
};

export const SIGNUP_BUSINESS: RouteDeclaration = {
  name: "Signup",
  path: "/signup/company",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: Signup,
};

export const SIGNUP_ADDRESS: RouteDeclaration = {
  name: "Signup",
  path: "/signup/address",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: Signup,
};

export const PHONE_VERIFICATION: RouteDeclaration = {
  name: "Phone verification",
  path: "/phone-verification",
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: PhoneVerification,
};

export const PAY_LATER_BETA_LANDING: RouteDeclaration = {
  name: "Welcome to Ampla's Pay Later Beta",
  path: "/pay-later/beta",
  pathIsExact: true,
  pathIsPrivate: false,
  PageComponent: PayLaterLandingPage,
};

export const APPLICATION: RouteDeclaration = {
  name: "Application",
  path: "/application",
  pathIsExact: false,
  pathIsPrivate: true,
  PageComponent: Application,
};

// Portal routes

export const LINE_OF_CREDIT_CHARGE_CARD_CREATE: RouteDeclaration = {
  ...CARD_CREATE,
  name: "Growth Line of Credit | Create card",
  path: "/line-of-credit/cards/create",
  validators: [
    ...(CARD_DETAIL.validators || []),
    oneOfValidator(activeEntityHasProductGrowthCardsInOnboardingState, activeEntityHasProductGrowthCardsInActiveState),
  ],
};

export const LINE_OF_CREDIT_CHARGE_CARD_DETAIL: RouteDeclaration = {
  ...CARD_DETAIL,
  name: "Growth Line of Credit | Card detail",
  path: "/line-of-credit/cards/:cardId",
  validators: [
    ...(CARD_DETAIL.validators || []),
    oneOfValidator(activeEntityHasProductGrowthCardsInOnboardingState, activeEntityHasProductGrowthCardsInActiveState),
  ],
};

export const LINE_OF_CREDIT_CHARGE_CARD_CONFIRM_STEP: RouteDeclaration = {
  ...CARD_CONFIRM_STEP,
  name: "Growth Line of Credit | Confirm card",
  path: "/line-of-credit/cards/:cardId/confirm",
  validators: [
    ...(CARD_DETAIL.validators || []),
    oneOfValidator(activeEntityHasProductGrowthCardsInOnboardingState, activeEntityHasProductGrowthCardsInActiveState),
  ],
};

export const LINE_OF_CREDIT_CHARGE_CARD_CONFIRM_STEP_SUCCESS: RouteDeclaration = {
  ...CARD_CONFIRM_STEP_SUCCESS,
  name: "Growth Line of Credit | Confirm card",
  path: "/line-of-credit/cards/:cardId/confirm/success",
  validators: [
    ...(CARD_DETAIL.validators || []),
    oneOfValidator(activeEntityHasProductGrowthCardsInOnboardingState, activeEntityHasProductGrowthCardsInActiveState),
  ],
};

export const LINE_OF_CREDIT_RECURRING_TARGET_UTILIZATION: RouteDeclaration = {
  name: "Growth Line of Credit",
  title: "Line of Credit",
  path: "/line-of-credit/setup-recurring-advance",
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [
    // TODO: Figure actual permissions for this
    activeEntityIsNotAmpla,
    activeEntityHasProductFundingInActiveState,
    featureFlagValidator(FeatureFlags.RecurringAdvance),
  ],
  PageComponent: lazy(() => import("pages/LineOfCreditRecurringAdvance")),
};

// Keep validators in sync with the ones in frontend/src/helpers/banco-card.ts
export const LINE_OF_CREDIT_DASHBOARD: RouteDeclaration = {
  name: "Growth Line of Credit",
  title: "Line of Credit",
  path: "/line-of-credit/:tab?/:id?",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    activeEntityIsNotAmpla,
    oneOfValidator(
      everyValidator(activeEntityHasProductFundingInActiveState, userHasRequestFundingPermission),
      everyValidator(activeEntityHasProductFundingInActiveState, userHasViewTransactionEventPermission),
      everyValidator(
        oneOfValidator(
          activeEntityHasProductFundingInApplyingState,
          activeEntityHasProductFundingInReviewingState,
          everyValidator(
            activeEntityHasProductFundingInDisabledState,
            negateValidator(userFundingApplicationHasBeenDenied),
          ),
        ),
        negateValidator(userBancoApplicationHasBeenDenied),
      ),
    ),
  ],
  PageComponent: LineOfCreditDashboard,
};

export const LEGACY_SELF_REPORTING: RouteDeclaration = {
  name: "Self-reporting",
  path: "/self-reporting",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    activeEntityIsNotAmpla,
    userHasAccessSelfReportingPermission,
    negateValidator(featureFlagValidator(FeatureFlags.FinancialReporting)),
    oneOfValidator(
      activeEntityHasProductFundingInActiveState,
      activeEntityHasProductGrowthCardsInActiveState,
      activeEntityHasProductChargeCardsInActiveState,
      activeEntityHasProductDebitCardsInActiveState,
    ),
  ],
  PageComponent: SelfReportingDashboard,
};

export const FINANCIAL_REPORTING: RouteDeclaration = {
  name: "Financial reporting",
  path: "/financial-reporting",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    activeEntityIsNotAmpla,
    userHasAccessSelfReportingPermission,
    featureFlagValidator(FeatureFlags.FinancialReporting),
    oneOfValidator(
      activeEntityHasProductFundingInActiveState,
      activeEntityHasProductGrowthCardsInActiveState,
      activeEntityHasProductChargeCardsInActiveState,
      activeEntityHasProductDebitCardsInActiveState,
    ),
  ],
  PageComponent: FinancialReporting,
};

// TODO Limit :category frontend/apps/portal/src/pages/FinancialReporting/constants.ts
export const FINANCIAL_REPORTING_DETAILS: RouteDeclaration = {
  name: "Financial reporting details",
  path: "/financial-reporting/:category",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    activeEntityIsNotAmpla,
    userHasAccessSelfReportingPermission,
    featureFlagValidator(FeatureFlags.FinancialReporting),
    oneOfValidator(
      activeEntityHasProductFundingInActiveState,
      activeEntityHasProductGrowthCardsInActiveState,
      activeEntityHasProductChargeCardsInActiveState,
      activeEntityHasProductDebitCardsInActiveState,
    ),
  ],
  PageComponent: FinancialReportingDetails,
};

export const HOMEPAGE_V2: RouteDeclaration = {
  name: "Home",
  path: "/homepage",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [userHasViewFullHomePagePermission],
  PageComponent: HomePageV2,
};

const PORTAL_ROUTES = [
  SIGNUP,
  SIGNUP_FOR_PRODUCT,
  SIGNUP_USER,
  SIGNUP_PRODUCT,
  SIGNUP_BUSINESS,
  SIGNUP_ADDRESS,
  PHONE_VERIFICATION,
  PAY_LATER_BETA_LANDING,
  APPLICATION,
  LEGACY_SELF_REPORTING,
  FINANCIAL_REPORTING,
  FINANCIAL_REPORTING_DETAILS,
  ...PORTAL_RECIPIENTS_ROUTES,
  LINE_OF_CREDIT_CHARGE_CARD_CREATE,
  LINE_OF_CREDIT_CHARGE_CARD_DETAIL,
  LINE_OF_CREDIT_CHARGE_CARD_CONFIRM_STEP,
  LINE_OF_CREDIT_CHARGE_CARD_CONFIRM_STEP_SUCCESS,
  LINE_OF_CREDIT_RECURRING_TARGET_UTILIZATION,
  LINE_OF_CREDIT_DASHBOARD,
  HOMEPAGE_V2,
];

export default PORTAL_ROUTES;
