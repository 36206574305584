import { getHeapInstance } from "./heap";

export const trackEvent = (
  eventName: string,
  eventProperties?: {
    [key: string]: EventProperties | string | number | boolean | undefined | null;
  },
): boolean => {
  const heap = getHeapInstance();
  const { dataLayer: gtm } = window;
  let tracked = false;

  if (heap) {
    heap?.track(eventName, eventProperties);
    tracked = true;
  }

  if (gtm && typeof gtm === "object") {
    gtm.push({ event: eventName, ...eventProperties });
    tracked = true;
  }

  return tracked;
};
