import { CamelCasedPropertiesDeep } from "type-fest";

type UnitFullName = {
  first: string;
  last: string;
};

type UnitAddress = {
  street: string;
  street2?: string;
  city: string;
  state?: string;
  postal_code: string;
  country?: string;
};

type UnitPhone = {
  country_code?: string;
  number: string;
};

type UnitBusinessContact = {
  full_name: UnitFullName;
  email: string;
  phone: UnitPhone;
};

type UnitVIPBase = {
  full_name?: UnitFullName;
  ssn?: string;
  passport?: string;
  ssnPresent?: boolean;
  passportPresent?: boolean;
  nationality?: string;
  date_of_birth: string;
  address?: UnitAddress;
  phone: UnitPhone;
  email: string;
  occupation?: "";
  annual_income?: "";
  source_of_income?: "";
};

type UnitOfficer = UnitVIPBase & {
  percentage?: number;
  isOwner?: boolean;
  title: string;
};

export enum BancoApplicationStatus {
  Incomplete = "incomplete",
  AwaitingDocuments = "awaiting_documents",
  PendingPartnerReview = "pending_partner_review",
  PendingAmplaReview = "pending_ampla_review",
  Approved = "approved",
  Denied = "denied",
}

export type CamelCasedUnitOfficer = CamelCasedPropertiesDeep<UnitOfficer>;

export type UnitBeneficialOwner = UnitVIPBase & {
  percentage: number;
};

export type CamelCasedUnitBeneficialOwner = CamelCasedPropertiesDeep<UnitBeneficialOwner>;

export type BancoApplicationDetails = {
  entity: number;
  id: string;
  status: any;
};

export type BancoApplicationList = {
  results: BancoApplicationDetails[];
};

type BancoApplicationBase = {
  name?: string;
  address?: UnitAddress;
  phone?: UnitPhone;
  state_of_incorporation?: string;
  ein?: string;
  entity_type?: string;
  officer?: UnitOfficer;
  beneficial_owners?: Array<UnitBeneficialOwner>;
  document_id?: string;
  website?: string;
  business_vertical?: string;
  year_of_incorporation?: string;
  annual_revenue?: string;
  number_of_employees?: string;
  countries_of_operation?: string[];
  cash_flow?: string;
};

export type BancoApplicationSubmit = BancoApplicationBase & {
  id?: string;
  contact?: UnitBusinessContact;
  reuseOfficerInformation?: boolean;
};

export type CamelCasedBancoApplicationSubmit = CamelCasedPropertiesDeep<BancoApplicationSubmit>;

export type BancoApplicationResponse = BancoApplicationBase & {
  id: string;
  contact: UnitBusinessContact;
};

export type CamelCasedBancoApplicationResponse = CamelCasedPropertiesDeep<BancoApplicationResponse>;

export enum BancoApplicationDocumentStatus {
  Required = "Required",
  ReceivedBack = "ReceivedBack",
  ReceivedFront = "ReceivedFront",
  Invalid = "Invalid",
  Approved = "Approved",
  PendingReview = "PendingReview",
}

export enum BancoApplicationDocumentTypes {
  IdDocument = "IdDocument",
  Passport = "Passport",
  AddressVerification = "AddressVerification",
  SocialSecurityCard = "SocialSecurityCard",
  CertificateOfIncorporation = "CertificateOfIncorporation",
  EmployerIdentificationNumberConfirmation = "EmployerIdentificationNumberConfirmation",
  PowerOfAttorney = "PowerOfAttorney",
}

type BancoApplicationDocumentBase = {
  id: string;
  description: string;
  documentType: BancoApplicationDocumentTypes;
  status: BancoApplicationDocumentStatus;
};

export type BancoRejectedApplicationDocument = BancoApplicationDocumentBase & {
  status: BancoApplicationDocumentStatus.Invalid;
  reasonCode: string;
  reason: string;
};

export type BancoApplicationDocument = BancoApplicationDocumentBase | BancoRejectedApplicationDocument;

export type BancoApplicationDetailedDocuments = {
  brand: Array<BancoApplicationDocument>;
  beneficialOwners: Array<{
    name: string;
    email: string;
    documents: Array<BancoApplicationDocument>;
    percentage: number;
  }>;
};
