export const NetTermsPaths = {
  NET_TERMS_BRAND_PAYMENT_SIGNUP: "/ars/brand/signup/payment/invite/:inviteId",
  NET_TERMS_BRAND_PAYMENT_SETUP: "/ars/brand/signup/payment/invite/setup",
  NET_TERMS_BRAND_SIGNUP_UNSECURED: "/ars/brand/signup/terms/:vendorId",
  NET_TERMS_BRAND_SIGNUP_SECURED: "/ars/brand/signup/terms/:vendorId/:stepId",
  NET_TERMS_BRAND_INVITATION_SIGNUP: "/ars/brand/signup/terms/invite/:inviteId",
  NET_TERMS_BRAND_INVOICES_OPEN: "/ars/brand/invoices",
  NET_TERMS_BRAND_INVOICES_PAID: "/ars/brand/invoices/paid",
  NET_TERMS_BRAND_INVOICE_DETAIL: "/ars/brand/invoices/:invoiceId",
  NET_TERMS_BRAND_SCHEDULE_PAYMENT: "/ars/brand/invoices/:invoiceId/payment",
  NET_TERMS_BRAND_SETUP: "/ars/brand/setup",
  NET_TERMS_BRAND_PENDING: "/ars/brand/pending",
  NET_TERMS_VENDOR_CUSTOMERS_SUMMARY: "/ars/vendor/invoices",
  NET_TERMS_VENDOR_INVOICES: "/ars/vendor/invoices/all",
  NET_TERMS_VENDOR_INVOICE_DETAIL: "/ars/vendor/invoices/:invoiceId",
  NET_TERMS_VENDOR_CUSTOMERS: "/ars/vendor/invoices/customers",
  NET_TERMS_VENDOR_CUSTOMER_DETAIL: "/ars/vendor/invoices/customers/:customerId",
  NET_TERMS_VENDOR_UNSECURED_SIGNUP: "/ars/vendor/signup",
  NET_TERMS_VENDOR_SECURED_SIGNUP: "/ars/vendor/signup/:stepId",
  NET_TERMS_VENDOR_PROFILE: "/ars/vendor/profile",
  NET_TERMS_VENDOR_ONBOARDING: "/ars/vendor/onboarding",
  NET_TERMS_VENDOR_SETUP_AR_SYNC: "/ars/vendor/setup/arsync",
};
