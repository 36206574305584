import styles from "./SettingsPanel.styles";
import { Typography } from "libs/components/components/Typography";
import { Box, MenuItem, MenuList } from "@ampla/ui-components";
import { Link } from "react-router-dom";
import { SettingsPanelProps } from "./SettingsPanel.types";

const SettingsPanel = ({ routes, onOptionClick }: SettingsPanelProps) => {
  return (
    <Box sx={styles.root}>
      <MenuList sx={styles.list}>
        {routes.map((option) => (
          <MenuItem
            key={option.key || option.path}
            component={Link}
            to={option.path}
            sx={styles.listItem}
            onClick={onOptionClick}
          >
            <Typography variant="label2">{option.name}</Typography>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};

export default SettingsPanel;
