import { makeStyles } from "libs/components/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "unset",
    minWidth: "unset",
    height: 28,
    paddingBlock: 4,
    paddingInline: 12,
    color: theme.palette.ampla.gray[600],
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.ampla.gray[300],
    borderRadius: 4,
    backgroundColor: theme.palette.ampla.white,

    "&:hover": {
      color: theme.palette.ampla.gray[600],
      backgroundColor: theme.palette.ampla.gray[50],
    },

    "&:active": {
      color: theme.palette.ampla.gray[600],
      borderColor: theme.palette.ampla.teal[700],
      backgroundColor: theme.palette.ampla.teal[50],
    },

    "& .MuiButton-endIcon": {
      marginLeft: 4,
    },
  },

  active: {
    color: theme.palette.ampla.gray[600],
    borderColor: theme.palette.ampla.teal[700],
    backgroundColor: theme.palette.ampla.teal[50],
  },

  icon: {
    transition: ".3s all",
  },

  iconOpen: {
    transform: "rotate(-180deg)",
  },
}));
