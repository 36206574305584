import { Components } from "@mui/material/styles";

import { getButtonColorVariantDefinition } from "libs/components/theme/ampla-theme/helpers/buttons";
import colors from "libs/components/theme/ampla-theme/palette/colors";
import { AMPLA_COMPONENT_NAMESPACE_SELECTOR } from "libs/components/theme/constants";
import palette from "../palette";

// Small button sizes
const BUTTON_SMALL_ICON_MARGIN = 7.33;
const BUTTON_SMALL_PADDING_BLOCK = 6;
const BUTTON_SMALL_PADDING_INLINE_ICON = 16;
const BUTTON_SMALL_PADDING_INLINE_NO_ICON = 20;

// Medium button sizes
const BUTTON_MEDIUM_ICON_MARGIN = 7.33;
const BUTTON_MEDIUM_PADDING_BLOCK = 9;
const BUTTON_MEDIUM_PADDING_INLINE_ICON = 16;
const BUTTON_MEDIUM_PADDING_INLINE_NO_ICON = 20;

// Large button sizes
const BUTTON_LARGE_ICON_MARGIN = 7.67;
const BUTTON_LARGE_PADDING_BLOCK = 13;
const BUTTON_LARGE_PADDING_INLINE_ICON = 16;
const BUTTON_LARGE_PADDING_INLINE_NO_ICON = 20;

const MuiButton: Components["MuiButton"] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      borderRadius: 8,
      textTransform: "none",
      fontWeight: 500,
      padding: "13px 16px",
      height: "48px",
      fontSize: "1rem",
      lineHeight: "1.375rem",

      [AMPLA_COMPONENT_NAMESPACE_SELECTOR]: {
        height: 40,
        minHeight: "unset",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "22.4px",
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: "solid",
        paddingBlock: BUTTON_MEDIUM_PADDING_BLOCK,
        paddingLeft: BUTTON_MEDIUM_PADDING_INLINE_NO_ICON,
        paddingRight: BUTTON_MEDIUM_PADDING_INLINE_NO_ICON,

        "&.withStartIcon": {
          paddingLeft: BUTTON_MEDIUM_PADDING_INLINE_ICON,
        },

        "&.withEndIcon": {
          paddingRight: BUTTON_MEDIUM_PADDING_INLINE_ICON,
        },

        "&.primary": getButtonColorVariantDefinition({
          defaultColor: colors.white,
          defaultBorderColor: colors.teal[700],
          defaultBackgroundColor: colors.teal[700],
          hoverColor: colors.white,
          hoverBorderColor: colors.teal[800],
          hoverBackgroundColor: colors.teal[800],
          activeColor: colors.white,
          activeBorderColor: colors.teal[600],
          activeBackgroundColor: colors.teal[600],
        }),

        "&.primary-contrast": getButtonColorVariantDefinition({
          defaultColor: colors.teal[700],
          defaultBorderColor: colors.white,
          defaultBackgroundColor: colors.white,
          hoverColor: colors.white,
          hoverBorderColor: colors.white,
          hoverBackgroundColor: colors.teal[800],
          activeColor: colors.white,
          activeBorderColor: colors.teal[600],
          activeBackgroundColor: colors.teal[600],
        }),

        "&.secondary": getButtonColorVariantDefinition({
          defaultColor: colors.teal[700],
          defaultBorderColor: colors.teal[200],
          defaultBackgroundColor: colors.teal[200],
          hoverColor: colors.white,
          hoverBorderColor: colors.teal[800],
          hoverBackgroundColor: colors.teal[800],
          activeColor: colors.white,
          activeBorderColor: colors.teal[600],
          activeBackgroundColor: colors.teal[600],
        }),

        "&.secondary-contrast": getButtonColorVariantDefinition({
          defaultColor: colors.teal[700],
          defaultBorderColor: colors.teal[200],
          defaultBackgroundColor: colors.teal[200],
          hoverColor: colors.white,
          hoverBorderColor: colors.white,
          hoverBackgroundColor: colors.teal[800],
          activeColor: colors.white,
          activeBorderColor: colors.teal[600],
          activeBackgroundColor: colors.teal[600],
        }),

        "&.destructive-secondary": getButtonColorVariantDefinition({
          defaultColor: colors.red[600],
          defaultBorderColor: colors.red[100],
          defaultBackgroundColor: colors.red[100],
          hoverColor: colors.white,
          hoverBorderColor: colors.red[600],
          hoverBackgroundColor: colors.red[600],
          activeColor: colors.red[700],
          activeBorderColor: colors.red[200],
          activeBackgroundColor: colors.red[200],
        }),

        "&.destructive-secondary-contrast": getButtonColorVariantDefinition({
          defaultColor: colors.red[600],
          defaultBorderColor: colors.red[100],
          defaultBackgroundColor: colors.red[100],
          hoverColor: colors.white,
          hoverBorderColor: colors.red[600],
          hoverBackgroundColor: colors.red[600],
          activeColor: colors.red[700],
          activeBorderColor: colors.red[200],
          activeBackgroundColor: colors.red[200],
        }),

        "&.destructive-primary": getButtonColorVariantDefinition({
          defaultColor: colors.white,
          defaultBorderColor: colors.red[500],
          defaultBackgroundColor: colors.red[500],
          hoverColor: colors.white,
          hoverBorderColor: colors.red[600],
          hoverBackgroundColor: colors.red[600],
          activeColor: colors.red[700],
          activeBorderColor: colors.red[200],
          activeBackgroundColor: colors.red[200],
        }),

        "&.destructive-primary-contrast": getButtonColorVariantDefinition({
          defaultColor: colors.white,
          defaultBorderColor: colors.red[500],
          defaultBackgroundColor: colors.red[500],
          hoverColor: colors.white,
          hoverBorderColor: colors.red[600],
          hoverBackgroundColor: colors.red[600],
          activeColor: colors.red[700],
          activeBorderColor: colors.red[200],
          activeBackgroundColor: colors.red[200],
        }),

        "&.destructive-ghost, &.destructive-ghost-contrast": getButtonColorVariantDefinition({
          defaultColor: colors.red[500],
          defaultBorderColor: "transparent",
          defaultBackgroundColor: "transparent",
          hoverColor: colors.red[500],
          hoverBorderColor: "transparent",
          hoverBackgroundColor: colors.red[100],
          activeColor: colors.red[700],
          activeBorderColor: "transparent",
          activeBackgroundColor: colors.red[200],
          disabledBorderColor: "transparent",
          disabledBackgroundColor: "transparent",
        }),

        "&.ghost": getButtonColorVariantDefinition({
          defaultColor: colors.teal[700],
          defaultBorderColor: "transparent",
          defaultBackgroundColor: "transparent",
          hoverColor: colors.teal[700],
          hoverBorderColor: colors.gray[100],
          hoverBackgroundColor: colors.gray[100],
          activeColor: colors.teal[700],
          activeBorderColor: colors.gray[200],
          activeBackgroundColor: colors.gray[200],
          disabledColor: colors.gray[300],
          disabledBorderColor: "transparent",
          disabledBackgroundColor: "transparent",
        }),

        "&.ghost-contrast": getButtonColorVariantDefinition({
          defaultColor: colors.white,
          defaultBorderColor: "transparent",
          defaultBackgroundColor: "transparent",
          hoverColor: colors.white,
          hoverBorderColor: colors.teal[600],
          hoverBackgroundColor: colors.teal[600],
          activeColor: colors.white,
          activeBorderColor: colors.teal[500],
          activeBackgroundColor: colors.teal[500],
          disabledColor: colors.gray[400],
          disabledBorderColor: "transparent",
          disabledBackgroundColor: "transparent",
        }),

        "&.tertiary": getButtonColorVariantDefinition({
          defaultColor: colors.teal[700],
          defaultBorderColor: colors.teal[700],
          defaultBackgroundColor: colors.white,
          hoverColor: colors.white,
          hoverBorderColor: colors.teal[800],
          hoverBackgroundColor: colors.teal[800],
          activeColor: colors.white,
          activeBorderColor: colors.teal[600],
          activeBackgroundColor: colors.teal[600],
        }),

        "&.tertiary-contrast": getButtonColorVariantDefinition({
          defaultColor: colors.white,
          defaultBorderColor: colors.white,
          defaultBackgroundColor: colors.teal[700],
          hoverColor: colors.white,
          hoverBorderColor: colors.white,
          hoverBackgroundColor: colors.teal[800],
          activeColor: colors.white,
          activeBorderColor: colors.white,
          activeBackgroundColor: colors.teal[600],
        }),

        "&.MuiButton-text": {
          padding: 0,
          height: "unset",
          backgroundColor: "transparent",
          color: colors.teal[700],
          border: "none",
          "&.loading": {
            backgroundColor: "transparent",
          },
          "&:hover": {
            color: colors.teal[800],
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
          "&:active": {
            color: colors.teal[600],
            borderColor: "transparent",
            backgroundColor: "transparent !important",
          },
          "&:focus": {
            color: colors.teal[600],
            backgroundColor: "transparent",
          },
          "&:disabled": {
            backgroundColor: "transparent",
          },

          "&.destructive-primary, &.destructive-secondary, &.destructive-primary-contrast, &.destructive-secondary-contrast, &.destructive-ghost, &.destructive-ghost-contrast":
            {
              color: colors.red[500],
              "&:hover": {
                color: colors.red[700],
                backgroundColor: "transparent !important",
              },
              "&:active": {
                color: colors.red[600],
                backgroundColor: "transparent",
              },
              "&:focus": {
                color: colors.red[600],
              },
              "&:disabled": {
                color: colors.gray[300],
              },
            },

          "&.primary-contrast, &.secondary-contrast, &.tertiary-contrast, &.ghost-contrast": {
            color: colors.white,
            "&:hover": {
              color: colors.teal[300],
              backgroundColor: "transparent !important",
            },
            "&:active": {
              color: colors.teal[200],
              backgroundColor: "transparent",
            },
          },
        },

        "&.loading": {
          position: "relative",
          "& > span": {
            color: "transparent !important",
          },
          "& > .loading-container": {
            position: "absolute",
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
          },
        },

        "& .MuiButton-iconSizeSmall": {
          "& .MuiSvgIcon-root": {
            fontSize: 14,
          },
          "&.MuiButton-startIcon": {
            marginLeft: "unset",
            marginRight: BUTTON_SMALL_ICON_MARGIN,
          },
          "&.MuiButton-endIcon": {
            marginLeft: BUTTON_SMALL_ICON_MARGIN,
            marginRight: "unset",
          },
        },

        "& .MuiButton-iconSizeMedium": {
          "& .MuiSvgIcon-root": {
            fontSize: 16,
          },
          "&.MuiButton-startIcon": {
            marginLeft: "unset",
            marginRight: BUTTON_MEDIUM_ICON_MARGIN,
          },
          "&.MuiButton-endIcon": {
            marginLeft: BUTTON_MEDIUM_ICON_MARGIN,
            marginRight: "unset",
          },
        },

        "& .MuiButton-iconSizeLarge": {
          "& .MuiSvgIcon-root": {
            fontSize: 16,
          },
          "&.MuiButton-startIcon": {
            marginLeft: "unset",
            marginRight: BUTTON_LARGE_ICON_MARGIN,
          },
          "&.MuiButton-endIcon": {
            marginLeft: BUTTON_LARGE_ICON_MARGIN,
            marginRight: "unset",
          },
        },
      },
    },

    sizeSmall: {
      padding: "0px 14px",
      height: "38px",
      fontSize: "0.8rem",

      [AMPLA_COMPONENT_NAMESPACE_SELECTOR]: {
        height: 32,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: "19.6px",
        paddingBlock: BUTTON_SMALL_PADDING_BLOCK,
        paddingLeft: BUTTON_SMALL_PADDING_INLINE_NO_ICON,
        paddingRight: BUTTON_SMALL_PADDING_INLINE_NO_ICON,
        borderRadius: 4,

        "&.withStartIcon": {
          paddingLeft: BUTTON_SMALL_PADDING_INLINE_ICON,
        },

        "&.withEndIcon": {
          paddingRight: BUTTON_SMALL_PADDING_INLINE_ICON,
        },
      },
    },

    sizeLarge: {
      padding: "20px 32px",
      minHeight: "64px",
      fontSize: "1.125rem",
      lineHeight: "1.5rem",

      [AMPLA_COMPONENT_NAMESPACE_SELECTOR]: {
        height: 48,
        minHeight: "unset",
        paddingBlock: BUTTON_LARGE_PADDING_BLOCK,
        paddingLeft: BUTTON_LARGE_PADDING_INLINE_NO_ICON,
        paddingRight: BUTTON_LARGE_PADDING_INLINE_NO_ICON,
        borderRadius: 6,

        "&.withStartIcon": {
          paddingLeft: BUTTON_LARGE_PADDING_INLINE_ICON,
        },

        "&.withEndIcon": {
          paddingRight: BUTTON_LARGE_PADDING_INLINE_ICON,
        },
      },
    },

    // Contained, outlined and text variant definitions for legacy button
    contained: {
      boxShadow: "none",
      color: colors.white,
      backgroundColor: colors.teal[700],
      disableRipple: true,
      "&:hover": {
        boxShadow: "none",
        color: colors.white,
        backgroundColor: colors.teal[800],
      },
      "&:active": {
        boxShadow: "none",
        color: colors.white,
        backgroundColor: `${colors.teal[600]} !important`,
      },
      "&:focus": {
        boxShadow: "none",
        color: colors.white,
        backgroundColor: colors.teal[800],
      },
    },
    containedPrimary: {
      disableRipple: true,
      "&:hover": {
        boxShadow: "none",
        color: colors.white,
        backgroundColor: colors.teal[800],
      },
      "&:active": {
        boxShadow: "none",
        color: colors.white,
        backgroundColor: `${colors.teal[600]} !important`,
      },
      "&:focus": {
        boxShadow: "none",
        color: colors.white,
        backgroundColor: colors.teal[800],
      },
    },
    containedSecondary: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: colors.teal[200],
      backgroundColor: colors.teal[200],
      color: colors.green[700],
      disableRipple: true,
      "&:hover": {
        color: colors.green[700],
        borderColor: colors.green[700],
        backgroundColor: colors.teal[200],
      },
      "&:active": {
        color: `${colors.white} !important`,
        borderColor: `${colors.teal[600]} !important`,
        backgroundColor: colors.teal[600],
      },
      "&:focus": {
        color: colors.green[700],
        borderColor: colors.teal[200],
        backgroundColor: colors.teal[200],
      },
      "&:disabled": {
        borderWidth: 0,
      },
    },
    outlined: {
      borderWidth: 2,
      padding: "8px 32px",
      color: palette.primary?.main,
      backgroundColor: colors.white,
      borderColor: palette.primary?.main,
      "&:hover": {
        color: colors.white,
        backgroundColor: palette.primary?.main,
      },
      "&$disabled": {
        borderWidth: 2,
      },
    },
    outlinedPrimary: {
      borderWidth: 2,
      borderColor: palette.primary?.main,
      "&:hover": {
        color: colors.white,
        backgroundColor: palette.primary?.main,
        borderWidth: 2,
      },
    },
    outlinedSecondary: {
      color: palette.secondary?.main,
      borderWidth: 2,
      borderColor: palette.secondary?.main,
      "&:hover": {
        backgroundColor: palette.primary?.main,
        borderWidth: 2,
        borderColor: palette.primary?.main,
      },
    },
    text: {
      color: palette.primary?.main,
      padding: "8px 32px",
      "&:hover": {
        color: colors.teal[900],
        backgroundColor: "transparent",
      },
    },
    textPrimary: {
      color: palette.primary?.main,
      "&:hover": {
        color: colors.teal[900],
        backgroundColor: "transparent",
      },
    },
    textSecondary: {
      color: palette.secondary?.main,
      "&:hover": {
        color: palette.primary?.main,
        backgroundColor: "transparent",
      },
    },
  },
};

export default MuiButton;
