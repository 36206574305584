import { SVGProps } from "react";

import withIconWrapper from "libs/icons/components/withIconWrapper";

const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="mask0_531_14860"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="inherit" />
    </mask>
    <g mask="url(#mask0_531_14860)">
      <path
        d="M2.72534 21C2.54201 21 2.37534 20.9543 2.22534 20.863C2.07534 20.771 1.95867 20.65 1.87534 20.5C1.79201 20.35 1.74634 20.1873 1.73834 20.012C1.72967 19.8373 1.77534 19.6667 1.87534 19.5L11.1253 3.5C11.2253 3.33333 11.3543 3.20833 11.5123 3.125C11.671 3.04167 11.8337 3 12.0003 3C12.167 3 12.3297 3.04167 12.4883 3.125C12.6463 3.20833 12.7753 3.33333 12.8753 3.5L22.1253 19.5C22.2253 19.6667 22.2713 19.8373 22.2633 20.012C22.2547 20.1873 22.2087 20.35 22.1253 20.5C22.042 20.65 21.9253 20.771 21.7753 20.863C21.6253 20.9543 21.4587 21 21.2753 21H2.72534ZM12.0003 18C12.2837 18 12.5213 17.904 12.7133 17.712C12.9047 17.5207 13.0003 17.2833 13.0003 17C13.0003 16.7167 12.9047 16.4793 12.7133 16.288C12.5213 16.096 12.2837 16 12.0003 16C11.717 16 11.4797 16.096 11.2883 16.288C11.0963 16.4793 11.0003 16.7167 11.0003 17C11.0003 17.2833 11.0963 17.5207 11.2883 17.712C11.4797 17.904 11.717 18 12.0003 18ZM12.0003 15C12.2837 15 12.5213 14.904 12.7133 14.712C12.9047 14.5207 13.0003 14.2833 13.0003 14V11C13.0003 10.7167 12.9047 10.479 12.7133 10.287C12.5213 10.0957 12.2837 10 12.0003 10C11.717 10 11.4797 10.0957 11.2883 10.287C11.0963 10.479 11.0003 10.7167 11.0003 11V14C11.0003 14.2833 11.0963 14.5207 11.2883 14.712C11.4797 14.904 11.717 15 12.0003 15Z"
        fill="inherit"
      />
    </g>
  </svg>
);

export default withIconWrapper(ErrorIcon);
