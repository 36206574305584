import * as React from "react";
import { SVGProps } from "react";

const NoNotificationsImage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={312} height={268} viewBox="0 0 312 268" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_4210_1000)">
      <path
        d="M84.1693 245.91C51.8818 227.68 -17.5338 176.441 4.1012 105.795C27.6527 28.9003 146.792 69.4336 158.532 38.5367C158.532 38.5367 181.585 -26.2702 250.831 12.2039C320.077 50.6779 292.22 150.714 225.47 224.694C194.412 259.112 131.879 272.849 84.1693 245.91Z"
        fill="#DDF0FD"
      />
      <path
        d="M257.577 136.13C257.577 136.13 293.474 142.816 311.998 98.5206C311.998 98.5206 262.8 98.3512 257.577 136.13Z"
        fill="#84DBCA"
      />
      <path
        d="M230.843 183.117C230.843 183.117 244.294 149.172 204.376 122.491C204.376 122.491 194.767 170.744 230.843 183.117Z"
        fill="#84DBCA"
      />
      <path
        d="M237.671 167.776C237.671 167.776 270.806 183.117 299.697 144.777C299.697 144.777 252.068 132.457 237.671 167.776Z"
        fill="#84DBCA"
      />
      <path
        d="M212.006 202.88C212.006 202.88 242.841 222.447 276.528 188.234C276.528 188.234 230.922 169.764 212.006 202.88Z"
        fill="#84DBCA"
      />
      <path
        d="M242.877 160.038C242.877 160.038 268.8 132.074 240.033 91.0148C240.033 91.0148 212.444 134.356 242.877 160.038Z"
        fill="#84DBCA"
      />
      <path
        d="M225.129 198.245C225.129 198.245 225.039 198.218 224.995 198.209C224.255 197.942 223.872 197.13 224.139 196.391C235.353 165.119 245.631 145.33 273.729 117.463C274.282 116.911 275.182 116.911 275.735 117.463C276.288 118.016 276.279 118.917 275.735 119.469C248.029 146.952 237.885 166.474 226.813 197.335C226.564 198.031 225.833 198.414 225.129 198.236V198.245Z"
        fill="#84DBCA"
      />
      <path
        d="M163.917 263.453C210.111 263.453 247.559 260.468 247.559 256.785C247.559 253.102 210.111 250.117 163.917 250.117C117.722 250.117 80.2739 253.102 80.2739 256.785C80.2739 260.468 117.722 263.453 163.917 263.453Z"
        fill="#E8FFF7"
      />
      <path
        d="M214.719 257.543H117.402C113.453 257.543 110.226 254.307 110.226 250.358L110.395 67.1335C110.395 63.1845 113.631 59.9486 117.58 59.9486H214.897C218.846 59.9486 222.073 63.1845 222.073 67.1335L221.904 250.358C221.904 254.307 218.668 257.543 214.719 257.543Z"
        fill="#085D6A"
      />
      <path
        d="M208.567 257.543H111.25C107.301 257.543 104.074 254.307 104.074 250.358L104.244 67.1335C104.244 63.1845 107.479 59.9486 111.429 59.9486H208.746C212.695 59.9486 215.922 63.1845 215.922 67.1335L215.752 250.358C215.752 254.307 212.517 257.543 208.567 257.543Z"
        fill="#21A598"
      />
      <path
        d="M108.621 68.6935H210.547C211.126 68.6935 211.598 69.1659 211.598 69.7454V246.632C211.598 247.211 211.126 247.692 210.538 247.692H108.612C108.032 247.692 107.56 247.22 107.56 246.64V69.7543C107.56 69.1749 108.032 68.6935 108.621 68.6935Z"
        fill="#E8FFF7"
      />
      <path
        d="M162.24 232.458C181.105 232.458 196.399 217.164 196.399 198.298C196.399 179.432 181.105 164.139 162.24 164.139C143.374 164.139 128.08 179.432 128.08 198.298C128.08 217.164 143.374 232.458 162.24 232.458Z"
        fill="#C4EFE5"
      />
      <path
        d="M139.66 183.715H184.811L171.252 207.177C171.252 207.177 159.334 207.07 159.084 207.177C158.835 207.284 139.66 183.715 139.66 183.715Z"
        fill="#1A649E"
      />
      <path d="M184.811 212.873H139.66V183.715L162.258 202.871L184.82 183.715L184.811 212.873Z" fill="#0978CE" />
      <path
        d="M162.258 203.058C162.258 203.058 162.178 203.041 162.142 203.014L141.417 185.168C141.337 185.105 141.328 184.989 141.399 184.909C141.461 184.829 141.577 184.829 141.657 184.891L162.267 202.64L183.67 184.312C183.751 184.249 183.858 184.258 183.929 184.33C183.991 184.41 183.982 184.517 183.911 184.588L162.383 203.014C162.383 203.014 162.303 203.058 162.267 203.058H162.258Z"
        fill="#0D3859"
      />
      <path
        d="M162.24 157.088C181.105 157.088 196.399 141.794 196.399 122.928C196.399 104.062 181.105 88.7684 162.24 88.7684C143.374 88.7684 128.08 104.062 128.08 122.928C128.08 141.794 143.374 157.088 162.24 157.088Z"
        fill="#C4EFE5"
      />
      <path
        d="M187.698 114.397L162.712 135.631L137.725 114.397L158.237 94.741C160.733 92.343 164.673 92.343 167.178 94.741L187.689 114.397H187.698Z"
        fill="#004085"
      />
      <path d="M183.803 104.154H141.496V128.731H183.803V104.154Z" fill="white" />
      <path d="M187.698 138.76H137.734V114.397L162.73 127.661L187.698 114.397V138.76Z" fill="#0978CE" />
      <path
        d="M153.343 132.252C153.28 132.252 153.218 132.217 153.182 132.154C153.138 132.065 153.182 131.967 153.262 131.922L162.649 127.51C162.738 127.465 162.836 127.51 162.881 127.59C162.926 127.679 162.881 127.777 162.801 127.822L153.414 132.234C153.414 132.234 153.361 132.252 153.343 132.252Z"
        fill="#0D3859"
      />
      <path d="M167.061 109.182H146.077V111.91H167.061V109.182Z" fill="#B1DBFB" />
      <path d="M178.106 114.807H146.077V117.535H178.106V114.807Z" fill="#B1DBFB" />
      <path
        d="M129.533 114.29C135.864 114.29 140.997 109.158 140.997 102.826C140.997 96.495 135.864 91.3625 129.533 91.3625C123.202 91.3625 118.069 96.495 118.069 102.826C118.069 109.158 123.202 114.29 129.533 114.29Z"
        fill="#E0554D"
      />
      <path
        d="M129.044 107.381C128.928 107.381 128.821 107.328 128.759 107.23L126.637 104.208C126.53 104.048 126.566 103.834 126.726 103.718C126.887 103.611 127.101 103.646 127.217 103.807L129.035 106.401L134.241 98.1017C134.348 97.9413 134.562 97.8878 134.722 97.9947C134.883 98.1017 134.936 98.3157 134.829 98.4761L129.347 107.221C129.285 107.319 129.178 107.381 129.062 107.381H129.044Z"
        fill="white"
      />
      <path
        d="M132.162 189.758C138.324 188.304 142.14 182.13 140.686 175.968C139.231 169.806 133.057 165.989 126.895 167.444C120.733 168.898 116.917 175.072 118.371 181.234C119.825 187.396 126 191.212 132.162 189.758Z"
        fill="#81C4F8"
      />
      <path
        d="M125.896 182.654C125.807 182.654 125.726 182.618 125.655 182.556C125.512 182.422 125.512 182.199 125.646 182.065L132.706 174.64C132.84 174.497 133.063 174.497 133.196 174.631C133.339 174.765 133.339 174.987 133.205 175.121L126.145 182.547C126.074 182.618 125.985 182.654 125.896 182.654Z"
        fill="white"
      />
      <path
        d="M132.6 182.654C132.511 182.654 132.422 182.618 132.35 182.547L125.513 175.522C125.379 175.38 125.379 175.166 125.513 175.032C125.656 174.898 125.87 174.898 126.003 175.032L132.841 182.056C132.974 182.199 132.974 182.413 132.841 182.547C132.769 182.609 132.689 182.645 132.6 182.645V182.654Z"
        fill="white"
      />
      <path
        d="M70.7612 267.518C85.7918 267.518 97.9765 266.121 97.9765 264.398C97.9765 262.675 85.7918 261.278 70.7612 261.278C55.7306 261.278 43.5459 262.675 43.5459 264.398C43.5459 266.121 55.7306 267.518 70.7612 267.518Z"
        fill="#E8FFF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.7627 259.682C80.7627 259.682 72.6686 231.504 78.1865 220.201L72.6775 182.395L91.5579 181.192L84.8811 259.914L80.7627 259.691V259.682Z"
        fill="#E0554D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.0161 258.559L88.5996 261.171C89.3038 261.813 93.1459 263.07 93.1459 263.07C93.5292 263.872 92.7804 264.291 92.1475 264.398L80.7996 264.157C80.7996 264.157 78.1788 263.613 80.5054 258.559C82.5735 259.468 84.4723 260.128 85.0161 258.559Z"
        fill="#541410"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.5856 163.247L88.3406 163.408C92.4501 164.602 91.4606 187.432 91.4606 187.432L76.3152 189.678C68.5865 190.097 59.3781 191.898 58.4867 184.098C56.8553 169.79 63.5856 163.247 63.5856 163.247Z"
        fill="#84DBCA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.5667 185.462L89.5609 221.199L88.2327 252.711H75.0217C75.0217 252.711 76.2519 246.988 76.1093 245.179C75.9666 243.36 72.918 226.958 75.4496 219.434L72.2405 185.462H91.5756H91.5667Z"
        fill="#84DBCA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.6131 259.887C52.6131 259.887 49.288 231.656 56.7226 220.334L61.9018 189.509L72.7237 190.249L56.6958 260.11L52.6131 259.887Z"
        fill="#E0554D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.0598 258.764L58.6822 260.841C59.2795 261.492 61.2852 262.847 61.2852 262.847C61.2852 262.847 63.1839 264.745 60.0015 264.514L51.8984 264.371C51.8984 264.371 49.3668 263.827 52.5492 258.764C54.4568 259.673 56.2575 260.333 57.0598 258.764Z"
        fill="#541410"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.6549 185.997L67.1077 220.611L60.0921 253.103L49.8853 252.675C49.8853 252.675 50.2775 247.933 50.4379 246.115C50.5984 244.296 49.5198 227.733 54.8862 219.8L59.3522 184L76.6549 186.006V185.997Z"
        fill="#84DBCA"
      />
      <path
        d="M73.7569 202.105C73.6321 202.105 73.5162 202.016 73.4984 201.882L71.3768 187.2L68.0963 186.255C67.9537 186.219 67.8824 186.068 67.918 185.934C67.9626 185.792 68.1052 185.72 68.2389 185.756L71.6799 186.754C71.7779 186.781 71.8492 186.87 71.8671 186.968L74.0065 201.819C74.0243 201.962 73.9262 202.096 73.7836 202.114C73.7747 202.114 73.7569 202.114 73.748 202.114L73.7569 202.105Z"
        fill="#108286"
      />
      <path
        d="M98.476 144.483C103.566 139.384 105.269 137.2 110.261 131.967L114.468 132.912C106.018 144.911 106.133 149.974 100.517 152.728C94.4289 150.081 97.5578 145.918 98.476 144.483Z"
        fill="#E0554D"
      />
      <path
        d="M111.357 134.231L114.985 134.249C115.707 134.249 116.411 133.999 116.964 133.527L119.201 131.619C119.977 130.951 119.852 129.712 118.961 129.221L114.396 129.462C113.389 129.516 112.373 129.97 111.553 130.737L110.26 131.976L111.357 134.24V134.231Z"
        fill="#E0554D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.301 136.415L108.764 132.76L98.9846 142.637L84.4544 131.628C84.4544 131.628 82.8854 130.469 81.4502 130.175C80.015 129.881 85.0962 143.672 85.0962 143.672L95.9449 152.907C95.9449 152.907 100.821 156.464 103.834 151.668C106.838 146.872 113.301 136.424 113.301 136.424V136.415Z"
        fill="#3DAFF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.6079 117.847C77.6257 117.891 78.3923 118.587 79.569 119.496C80.1574 119.942 79.2481 121.323 78.5528 121.19C77.8575 121.056 76.6541 119.077 76.6541 119.077L77.6079 117.856V117.847Z"
        fill="#E0554D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.7238 152.951C52.1759 152.782 49.1539 154.681 48.4854 158.746L49.3411 178.847L52.8088 177.983L54.9571 162.106L55.7238 152.96V152.951Z"
        fill="#E0554D"
      />
      <path
        d="M52.6291 176.628L53.1194 180.256C53.2175 180.978 53.057 181.718 52.6559 182.333L51.0424 184.82C50.4808 185.685 49.2239 185.729 48.6088 184.891L48.2433 180.291C48.1631 179.275 48.484 178.206 49.1436 177.278L50.249 175.745L52.6202 176.619L52.6291 176.628Z"
        fill="#E0554D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.2248 176.075L47.9848 174.934C47.9848 174.934 47.2181 160.208 47.3518 158.594C47.4945 156.981 47.3964 155.75 48.9653 152.907C50.2846 150.509 56.7208 134.722 56.7208 134.722C56.7208 134.722 65.3498 135.087 63.4154 142.7C61.8286 148.976 56.177 159.922 56.177 159.922L54.2248 176.075Z"
        fill="#3DAFF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.4034 122.607C76.4034 122.607 76.0201 126.271 75.6992 129.302C75.619 130.113 75.1019 130.808 74.371 131.129C73.6311 131.45 72.7842 131.343 72.1424 130.853C71.9374 130.692 71.7323 130.532 71.5362 130.389C70.6626 129.712 70.2436 128.589 70.4754 127.501C71.0638 124.711 72.1335 119.621 72.1335 119.621L76.4034 122.616V122.607Z"
        fill="#E0554D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.3593 124.622L75.9582 126.797L74.0862 124.684L76.3593 124.622Z"
        fill="#743702"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.2535 118.863C69.8791 117.847 70.3873 116.724 71.3857 116.358C72.4554 115.957 73.8014 115.458 74.8711 115.057C75.8695 114.682 76.8323 115.271 77.3582 116.216C77.9733 117.33 78.6954 119.844 78.9985 121.733C79.1678 122.803 78.731 124.007 77.7326 124.381C76.6629 124.782 73.0883 124.943 72.0631 123.124C71.2876 121.742 70.7973 120.352 70.2535 118.863Z"
        fill="#E0554D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.3652 123.944C67.3652 123.944 67.2404 118.408 68.5062 116.572C70.2178 114.103 72.8386 112.409 76.4667 114.415C76.4667 114.415 76.8946 119.246 72.4374 124.167L67.3652 123.944Z"
        fill="#743702"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.271 127.76L72.3933 127.474L69.8527 127.572L60.1896 130.942C56.9359 132.555 55.4205 136.335 56.6685 139.758L57.3905 147.433C56.8824 153.887 56.4456 165.182 56.8824 173.766C70.5837 178.856 88.1983 178.428 92.5217 172.144C91.9601 158.986 89.2324 147.924 85.8182 138.056C85.3279 136.647 86.4154 133.367 84.7128 131.949C82.4842 130.104 76.2799 127.751 76.2799 127.751L76.271 127.76Z"
        fill="#3DAFF7"
      />
      <path
        d="M88.974 147.835C88.9116 147.835 88.8403 147.808 88.7868 147.763L82.7161 142.005C82.6092 141.907 82.6092 141.737 82.7072 141.63C82.8053 141.523 82.9747 141.523 83.0816 141.621L89.1523 147.38C89.2592 147.478 89.2592 147.647 89.1612 147.754C89.1077 147.808 89.0364 147.835 88.9651 147.835H88.974Z"
        fill="#0978CE"
      />
      <path
        d="M56.9081 157.105C56.7654 157.105 56.6406 156.981 56.6406 156.838L56.7476 143.484C56.7476 143.342 56.8635 143.217 57.015 143.217C57.1577 143.217 57.2825 143.342 57.2825 143.484L57.1755 156.838C57.1755 156.981 57.0596 157.105 56.9081 157.105Z"
        fill="#0978CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.1615 115.03C73.2134 114.727 72.2328 118.007 72.839 119.327C73.4273 120.601 73.0619 121.448 73.0886 122.901C73.1688 126.342 76.1195 128.589 76.3601 131.976C76.476 133.661 75.7718 135.363 74.087 135.756C72.5003 136.121 71.3147 135.194 69.7814 134.855C68.7028 134.615 67.5707 134.784 66.4385 135.078C64.0139 135.711 61.3485 134.508 60.6086 132.119C59.628 128.963 62.3825 125.665 63.5414 124.417C64.8251 123.026 66.4207 122.259 66.9021 119.772C67.847 114.887 73.7126 112.195 72.1526 115.048L72.1615 115.03Z"
        fill="#743702"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.5963 121.627C74.1668 122.304 75.0761 122.464 75.6288 121.992C76.1814 121.52 76.1725 120.584 75.602 119.906C75.0315 119.229 74.1222 119.068 73.5696 119.541C73.0169 120.013 73.0258 120.949 73.5963 121.627Z"
        fill="#E0554D"
      />
    </g>
    <defs>
      <clipPath id="clip0_4210_1000">
        <rect width={312} height={267.518} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default NoNotificationsImage;
