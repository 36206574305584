import { makeStyles } from "@ampla/ui-components";

const DRAWER_WIDTH = 330;

export const useStyles = makeStyles((theme) => ({
  switcherButton: {
    border: 0,
    padding: 0,
    margin: 0,
    position: "fixed",
    bottom: "260px",
    right: 0,
    color: theme.palette.ampla.teal[700],
    backgroundColor: theme.palette.ampla.teal[200],
    width: "100px",
    height: "50px",
    zIndex: 2,
    borderRadius: "5px 5px 0px 0px !important",
    transform: "rotate(-90deg)",
    transformOrigin: "bottom right",
    transition: "all linear 100ms",
    cursor: "pointer",

    "&:hover": {
      color: "white",
      backgroundColor: theme.palette.ampla.teal[800],
    },
  },
  buttonTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
  drawerContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  drawerSearch: {
    padding: "16px",
  },
  drawerList: {
    width: "100%",
    maxWidth: "none",
    flex: 1,
  },
  paper: {
    width: DRAWER_WIDTH,
    border: 0,
    borderRadius: 0,
    overflow: "hidden",
  },
  avatarAmpla: {
    width: 30,
    height: 30,
    "& svg": {
      width: "14px !important",
    },
  },
  avatarBusiness: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.ampla.teal[200],
    "& svg": {
      width: "16px !important",
      fill: theme.palette.ampla.teal[700],
    },
  },
  listItemIcon: {
    marginRight: theme.spacing(1.5),
  },
  listItemTextPrimary: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "0.9rem",
  },
  listItemTextSecondary: {
    color: theme.palette.ampla.gray[700],
  },
  loadingContainerRoot: {
    zIndex: 9999,
  },
  arrowIcon: {
    color: theme.palette.ampla.gray[400],
  },
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.ampla.gray[50],
    },
  },
}));
