import snakecaseKeys from "snakecase-keys";
import { SESSION_USER_KEY } from "storage-keys.json";

import { get, post } from "../../client";
import { PhoneNumber } from "../types";
import { setCurrentUser } from "./helpers";
import {
  LoginRequest,
  LoginResponse,
  MagicAuthCreateToken,
  MagicAuthLoginRequest,
  User,
  UserRegistration,
} from "./types";

const AuthAPI = {
  login: ({ email, password }: LoginRequest): Promise<LoginResponse> =>
    post("/auth/token-auth/", { username: email, password }),

  login_as: (userId: number) => post("/auth/login-as/", { user: userId }),

  logout: () => post("/auth/logout/"),

  magic_auth_create_token: async (): Promise<MagicAuthCreateToken> => post("/auth/magic-auth/token/"),

  magic_auth_login: ({ token }: MagicAuthLoginRequest): Promise<LoginResponse> =>
    post("/auth/magic-auth/login/", { token }),

  refresh_token: (): Promise<LoginResponse> => post("/auth/token-refresh/"),

  register: (data: UserRegistration): Promise<{ id: number; username: string }> => post("/auth/register/", data),

  get_current_user: async (force = false, deviceFingerprint?: string): Promise<User> => {
    // FIXME: We should not be setting the user object here,
    // this should just make the request and return the response
    if (force) {
      const response: User = await get("/auth/current_user/", snakecaseKeys({ deviceFingerprint }));
      return setCurrentUser(response);
    }

    try {
      const userStr = sessionStorage.getItem(SESSION_USER_KEY) || "";
      const user = JSON.parse(userStr);
      return user;
    } catch (error) {
      return AuthAPI.get_current_user(true);
    }
  },

  send_reset_password: async (email: string) => post("/auth/password_reset/", { email }),

  reset_password_confirm: async (data: { password: string; token: string; uid: string }) =>
    post("/auth/password_reset/confirm/", data),

  reset_password_validate: async (token: string) => post("/auth/password_reset/validate_token/", { token }),

  get_activation_user: (data: { uid: string }) => post("/auth/activation_user/", data),

  switch_active_entity: (data: { entity_id: number }) => get("/auth/switch_active_entity/", data),

  send_token: (phone: PhoneNumber) => post("/auth/send_token/", phone),

  verify_token: (phone: PhoneNumber, token: string) => post("/auth/verify_token/", { ...phone, token }),
};

export default AuthAPI;
