import { datadogRum } from "@datadog/browser-rum";
import { pick } from "lodash";

import { User } from "@ampla/api";

import { SESSION_WIDGET_RETURN_URL_KEY, SESSION_WIDGET_SOURCE_KEY } from "constants/storageKeys";
import { identifyTrackedUser } from "helpers/auth";
import { arrayToMap } from "helpers/objects";

export const setupWidgetSession = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const source: string | null = urlParams.get("source");
  const returnUrl: string | null = urlParams.get("returnUrl");

  if (source) sessionStorage.setItem(SESSION_WIDGET_SOURCE_KEY, source);
  if (returnUrl) sessionStorage.setItem(SESSION_WIDGET_RETURN_URL_KEY, returnUrl);
};

export const setupTracking = (user?: User) => {
  if (user) identifyTrackedUser(user);
};

const _getUserKeyMap = (user: User, key: keyof User) => (user?.[key] ? arrayToMap(user[key] as string[]) : {});

export const getUserGroupMap = (user: User) => _getUserKeyMap(user, "groups");

export const setupDatadogRum = (user?: User) => {
  if (user) {
    datadogRum.setUser(pick(user, ["id", "username", "email", "active_entity", "active_entity_name"]) as any);
  } else {
    datadogRum.clearUser();
  }
};
