import { Avatar, Box } from "@mui/material";
import { map } from "lodash";
import moment from "moment";

import { AppNotification, AppNotificationActionCode, AppNotificationSeverity } from "@ampla/api";
// FIXME: Move the utility functions from the component library
// to a shared `@ampla/utils` lib to avoid the circular dependency
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AmplaIcon, ChevronRight } from "@ampla/icons";

import Button from "../../../../Button/Button";
import Typography from "../../../../Typography/Typography";
import styles from "./NotificationItem.styles";

interface NotificationItemProps {
  notification: AppNotification;
  onAction(action?: AppNotificationActionCode): void;
  onDismiss?(notificationId: string): void;
}

function getColors(notification: AppNotification) {
  const { severity, read } = notification;
  if (severity === AppNotificationSeverity.URGENT) {
    return {
      backgroundColor: "ampla.red.100",
      borderColor: "ampla.red.200",
    };
  } else if (!read) {
    return {
      backgroundColor: "ampla.blue.50",
      borderColor: "ampla.gray.200",
    };
  }
  return {
    backgroundColor: "transparent",
    borderColor: "ampla.gray.200",
  };
}

const NotificationItem = (props: NotificationItemProps) => {
  const { notification, onAction, onDismiss } = props;
  const {
    id,
    title,
    description,
    createdAt,
    read,
    userDismissible,
    action1Label,
    action1Code,
    action2Label,
    action2Code,
    action3Label,
    action3Code,
  } = notification;
  const timeLabel = moment(createdAt).fromNow();
  const actions = [
    { label: action1Label, code: action1Code },
    { label: action2Label, code: action2Code },
    { label: action3Label, code: action3Code },
  ].filter(({ code }) => !!code);

  const handleDismiss = () => {
    onDismiss?.(id);
  };

  return (
    <Box display="flex" padding="16px" gap="12px" borderBottom="1px solid" sx={getColors(notification)}>
      <Box>
        <Box display="flex" gap="12px" alignItems="center">
          <Box
            sx={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              backgroundColor: "ampla.red.600",
              visibility: read ? "hidden" : "visible",
            }}
          />
          <Avatar sx={{ backgroundColor: "ampla.teal.700", width: "32px", height: "32px" }}>
            <AmplaIcon color="white" width="18px" height="18px" />
          </Avatar>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="8px" flex="1">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="overline2" color="gray-600">
            {title}
          </Typography>
          <Typography variant="overline2" color="gray-600">
            {timeLabel}
          </Typography>
        </Box>
        <Typography variant="body2">{description}</Typography>
        <Box display="flex" gap="8px" alignItems="center" sx={styles.actions}>
          {userDismissible && (
            <Button variant="text" size="small" onClick={handleDismiss}>
              Dismiss
            </Button>
          )}
          {map(actions, ({ label, code }) => (
            <Button key={label} variant="text" size="small" endIcon={<ChevronRight />} onClick={() => onAction(code)}>
              {label}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationItem;
