import { Components } from "@mui/material/styles";

import palette from "../palette";
import colors from "../palette/colors";

const MuiFab: Components["MuiFab"] = {
  styleOverrides: {
    root: {
      disableRipple: true,
      boxShadow: "none",
      borderRadius: 30,
      fontWeight: 700,
      textTransform: "none",
      backgroundColor: palette.primary?.main,
      color: colors.white,
      "&:hover": {
        boxShadow: "none",
        color: colors.white,
        backgroundColor: colors.teal[700],
      },
      "&:focus": {
        boxShadow: "none",
        color: colors.white,
        backgroundColor: colors.teal[900],
      },
    },
    extended: {
      padding: "8px 32px",
    },
    primary: {
      disableRipple: true,
      "&:hover": {
        color: colors.white,
        backgroundColor: colors.teal[700],
      },
      "&:focus": {
        boxShadow: "none",
        color: colors.white,
        backgroundColor: colors.teal[900],
      },
    },
    secondary: {
      disableRipple: true,
      "&:hover": {
        color: colors.white,
        backgroundColor: colors.teal[700],
      },
      "&:focus": {
        boxShadow: "none",
        color: colors.white,
        backgroundColor: colors.teal[900],
      },
    },
  },
};

export default MuiFab;
