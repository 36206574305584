const styles = {
  button: {
    margin: "-8px",
    padding: "8px !important",
    height: "unset",
    minWidth: "unset",
    minHeight: "unset",

    span: {
      color: "ampla.gray.500",
      display: "flex",
      alignItems: "center",
      gap: "6px",
      maxWidth: 147,
    },

    "&:hover": {
      span: {
        color: "ampla.teal.900",
        transition: "color 0.25s ease-in-out",
      },
    },

    "@media (max-width:767px)": {
      display: "none",
    },
  },
  buttonContent: {
    display: "inline-flex",
    alignItems: "center",
    gap: "3px",
    overflow: "hidden",
  },
  buttonContentText: {
    color: "inherit",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.32px",
    textTransform: "uppercase",
  },
  arrowIcon: {
    width: 14,
    height: 14,
    color: "inherit",
    transition: "transform 0.2s ease-in-out",
  },
  arrowIconOpen: {
    transform: "rotate(-180deg)",
  },
  popover: {
    borderRadius: "4px",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "ampla.gray.100",
  },
};

export default styles;
