import { createTheme } from "@mui/material/styles";

import overrides from "./overrides";
import palette from "./palette";
import typography from "./typography";

const amplaTheme = createTheme({
  typography,
  palette,
  components: overrides,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      mid: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default amplaTheme;
