import { useState, useEffect } from "react";

function usePersistentState<S>(
  key: string,
  initialValue?: S | (() => S),
  backend: Storage = localStorage,
): [S, (val: S | ((prevState: S) => S), cb?: () => void) => void, () => void] {
  const [callback, setCallback] = useState<undefined | (() => void)>(undefined);
  const [value, setValue] = useState<S>(() => {
    const persistedValueStr = backend.getItem(key);

    if (initialValue !== undefined && !persistedValueStr) return initialValue;

    // This is to guard against a case such as when the value is undefined
    try {
      if (persistedValueStr) return JSON.parse(persistedValueStr);
    } catch (e) {
      console.error(e);
      return null;
    }

    return null;
  });

  const removeValue = () => backend.removeItem(key);

  const _setValue = (val: S | ((prevState: S) => S), cb?: () => void) => {
    setCallback(cb);
    setValue(val);
  };

  useEffect(() => {
    backend.setItem(key, JSON.stringify(value));
    if (callback) {
      callback();
    }
  }, [value]);

  return [value, _setValue, removeValue];
}

export default usePersistentState;
