export const CHECK_FREE_ROUTES_APPLICATION = [
  /^\/banking\/additional-documentation\/*/,
  /^\/phone-verification/,
  /^\/connect-integration*/,
  /^\/connect-ads-integration*/,
  /^\/connect-shopify-integration*/,
  /^\/account\/activate*/,
  /^\/account\/\w*_result\b/,
  "/not-authorized",
  /^\/(banking|corporate-card)\/documents-request\/?.*/,
  /^\/agreement*/,
];
