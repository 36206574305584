import { Components } from "@mui/material/styles";

import { getVariantBaseStyleDefinition } from "../helpers/typography";
import colors from "../palette/colors";

const MuiTableCell: Components["MuiTableCell"] = {
  styleOverrides: {
    head: {
      ...getVariantBaseStyleDefinition({ variant: "overline2" }),
      color: colors.gray[500],
      verticalAlign: "top",
      textTransform: "uppercase",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: colors.gray[300],
    },
    body: {
      ...getVariantBaseStyleDefinition({ variant: "body2" }),
      color: colors.teal[900],
      borderBottom: "none",
      textTransform: "none",
    },
    sizeSmall: {
      fontSize: "0.8125rem",
    },
  },
};

export default MuiTableCell;
