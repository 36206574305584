import { TypographyVariant } from "libs/components/components/Typography/Typography.types";

type GetVariantBaseStyleDefinitionParams = {
  variant: TypographyVariant;
};

export const getVariantBaseStyleDefinition = ({ variant }: GetVariantBaseStyleDefinitionParams) => {
  // Label variants
  if (variant === "label1")
    return {
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 500,
    };
  if (variant === "label2")
    return {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 500,
    };
  if (variant === "label3")
    return {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 500,
    };

  // Overline variants
  if (variant === "overline1")
    return {
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.32px",
      textTransform: "uppercase",
    };
  if (variant === "overline2")
    return {
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0.32px",
      textTransform: "uppercase",
    };

  // Body variants
  if (variant === "body1")
    return {
      fontSize: "16px",
      lineHeight: "24px",
    };
  if (variant === "body2")
    return {
      fontSize: "14px",
      lineHeight: "20px",
    };
  if (variant === "body3")
    return {
      fontSize: "12px",
      lineHeight: "18px",
    };

  // Header variants
  if (variant === "h1")
    return {
      fontSize: "32px",
      lineHeight: "42px",
      fontWeight: 500,
    };
  if (variant === "h2")
    return {
      fontSize: "28px",
      lineHeight: "36px",
      fontWeight: 500,
    };
  if (variant === "h3")
    return {
      fontSize: "24px",
      lineHeight: "32px",
    };
  if (variant === "h4")
    return {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: 600,
    };
  if (variant === "h5")
    return {
      fontSize: "18px",
      lineHeight: "26px",
      fontWeight: 600,
    };
  if (variant === "h6")
    return {
      fontSize: "18px",
      lineHeight: "26px",
    };
  if (variant === "h7")
    return {
      fontSize: "16px",
      lineHeight: "22px",
    };
};

type GetVariantWeightStyleDefinition = {
  variant: TypographyVariant;
  weight: "medium" | "bold";
};

export const getVariantWeightStyleDefinition = ({ variant, weight }: GetVariantWeightStyleDefinition) => {
  // Body variants
  if (["body1", "body2", "body3"].includes(variant))
    return {
      fontWeight: weight === "medium" ? 500 : weight === "bold" ? 700 : undefined,
    };

  return undefined;
};

type GetVariantAltFontStyleDefinition = {
  variant: TypographyVariant;
};

export const getVariantAltFontStyleDefinition = ({ variant }: GetVariantAltFontStyleDefinition) => {
  // Header variants
  if (["h1", "h2", "h3", "h4", "h6", "h7"].includes(variant))
    return {
      fontWeight: 400,
      fontFamily: "Merriweather",
      fontVariantNumeric: "lining-nums",
    };
  if (variant === "h5")
    return {
      fontWeight: 700,
      fontFamily: "Merriweather",
      fontVariantNumeric: "lining-nums",
    };

  return undefined;
};
