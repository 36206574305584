import "core-js";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import browserHistory from "./browserHistory";
import { datadogRum } from "@datadog/browser-rum";
import { registerGlobalErrorHandler } from "@ampla/utils";

registerGlobalErrorHandler();

const env = window.env;
const SENTRY_DSN = env.REACT_APP_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    enabled: env.NODE_ENV === "production",
    environment: env.REACT_APP_ENV_ROLE,
    dsn: SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
  });
}

if (env.REACT_APP_DATADOG_CLIENT_TOKEN) {
  const environment = env.REACT_APP_ENV_NAME === "production" ? "prod" : env.REACT_APP_ENV_NAME;
  datadogRum.init({
    applicationId: env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: env.REACT_APP_DATADOG_SITE,
    service: "portal",
    env: environment,
    version: "1.0.0",
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    allowedTracingUrls: [/https:\/\/apiv2.*.getampla.com/, "https://api.getampla.com"],
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
