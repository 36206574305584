import React, { forwardRef } from "react";
import { Box } from "@mui/material";

import { ModalFooterProps } from "@ampla/ui-components";

const ModalFooter = forwardRef(({ children, size, __TYPE, sx, ...props }: ModalFooterProps, ref) => {
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      padding="24px 32px"
      gap={2}
      borderTop={size !== "small" ? "1px solid" : "none"}
      sx={{ borderTopColor: "ampla.gray.100", ...sx }}
      {...props}
      ref={ref}
    >
      {children}
    </Box>
  );
});

ModalFooter.defaultProps = {
  __TYPE: "ModalFooter",
};

export default ModalFooter;
