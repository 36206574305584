import { makeStyles } from "libs/components/styles";
import colors from "libs/components/theme/ampla-theme/palette/colors";

export const useStyles = makeStyles(() => ({
  iconButton: {
    "&:hover, &:active": {
      color: colors.teal[700],
    },
    "& svg": {
      width: 20,
      height: 20,
    },
  },
}));
