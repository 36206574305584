import { makeStyles } from "libs/components/styles";

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    display: "flex",

    "& .Tooltip-content": {
      overflowY: "auto",
      maxHeight: "400px",
    },
    "& .Tooltip-close": {
      width: "16px",
      height: "16px",
      color: theme.palette.ampla.teal[900],
      cursor: "pointer",
    },
  },
}));
