import { AttachMoney as AttachMoneyIcon, ChevronRight, AccountBalance as AccountBalanceIcon } from "@ampla/icons";
import { WithStyles, withStyles, Theme, MUILink, Hidden, createStyles, Banner } from "@ampla/ui-components";
import { Link as RouterLink } from "react-router-dom";
import { PRODUCT_NAMES } from "pages/Signup/constants";
import { EnabledProducts } from "@ampla/api";
import { SignupAccess } from "apps/portal/enums";

const styles = (theme: Theme) =>
  createStyles({
    productIconContainer: {
      width: 41,
      height: 41,
      padding: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgb(217 146 224 / 35%)",
      borderRadius: "100%",
    },
    continueLink: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "rgb(217 146 224 / 35%)",
      paddingBlock: theme.spacing(1),
      paddingInline: theme.spacing(2),
      borderRadius: 6,
    },
    continueLinkIcon: {
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(1),
        marginRight: -5,
      },
    },
  });

type BannerProps = {
  product:
    | EnabledProducts.Banking
    | EnabledProducts.ProductionFunding
    | EnabledProducts.PayLater
    | EnabledProducts.ChargeCards
    | SignupAccess.CorporateCard;
  applicationPath?: string;
};

const ProductApplicationBanner = ({
  product,
  classes,
  applicationPath = "/application",
}: BannerProps & WithStyles<typeof styles>) => (
  <Banner
    variant="info"
    startIcon={
      <div className={classes.productIconContainer}>
        {product === EnabledProducts.ProductionFunding ? (
          <AttachMoneyIcon fontSize="small" />
        ) : (
          <AccountBalanceIcon fontSize="small" />
        )}
      </div>
    }
  >
    You have an open {PRODUCT_NAMES[product]} application.
    <MUILink to={applicationPath} component={RouterLink} className={classes.continueLink}>
      <Hidden smDown>Continue application</Hidden>
      <ChevronRight fontSize="small" className={classes.continueLinkIcon} />
    </MUILink>
  </Banner>
);

export default withStyles(styles)(ProductApplicationBanner);
