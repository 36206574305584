import { Theme, makeStyles } from "@ampla/ui-components";

export const useAvatarAmplaStyles = makeStyles((theme: Theme) => ({
  avatarRoot: {
    paddingTop: 3,
    backgroundColor: theme.palette.ampla.teal[200],
    "& svg": {
      height: 20,
      marginTop: -6,
    },
  },
}));
