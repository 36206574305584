import { UserAvatar } from "libs/components/components/UserAvatar";
import { ProfilePanelProps } from "./ProfilePanel.types";
import styles from "./ProfilePanel.styles";
import { Typography } from "libs/components/components/Typography";
import { Box, MenuItem, MenuList } from "@ampla/ui-components";
import { useAuthState } from "apps/portal/components/context/AuthContextProvider";
import { getUserFullName } from "apps/portal/helpers/user";

const ProfilePanel = ({ onOptionClick }: ProfilePanelProps) => {
  const { user, logout } = useAuthState();

  const handleSignOutClick = () => {
    onOptionClick?.();
    logout();
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.profile}>
        <UserAvatar
          firstName={user?.contact?.first_name}
          lastName={user?.contact?.last_name}
          email={user?.email}
          sx={styles.avatar}
        />
        <Box sx={styles.profileInfo}>
          <Typography variant="label2">{getUserFullName(user)}</Typography>
          <Typography variant="label3">{user?.email}</Typography>
          {/* <Link size="small" sx={{ display: "inline !important" }}>
            Preferences
          </Link> */}
        </Box>
      </Box>
      <Box sx={styles.accounts}>
        <Typography variant="overline2" color="gray-400">
          Ampla Account
        </Typography>
        <Typography variant="label2">{user?.active_entity_name}</Typography>
      </Box>
      <Box sx={styles.options}>
        <MenuList sx={styles.optionsList}>
          <MenuItem sx={styles.optionsListItem} onClick={handleSignOutClick}>
            <Typography variant="label2">Sign out</Typography>
          </MenuItem>
        </MenuList>
      </Box>
    </Box>
  );
};

export default ProfilePanel;
