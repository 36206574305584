import { makeStyles } from "libs/components/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.ampla.gray[100],
    borderRadius: 4,
    boxShadow: "0px 2px 8px 0px rgba(8, 93, 106, 0.12)",
    marginTop: 8,
  },
}));
