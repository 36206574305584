import {
  FINANCIAL_REPORTING,
  HOMEPAGE_V2,
  LEGACY_SELF_REPORTING,
  LINE_OF_CREDIT_DASHBOARD,
} from "apps/portal/routes/portal";
import { BANCO_HOME_PAGE } from "apps/portal/pages/BancoHome/routing";
import {
  BANKING_CASHBACK_REWARDS,
  BANKING_TRANSACTIONS,
  CHARGE_CARD,
  INSIGHTS_V1,
  INSIGHTS_V2,
  INSIGHTS_V3,
} from "apps/portal/routes/banking";
import { BILL_PAY } from "apps/portal/routes/billpay";
import {
  NET_TERMS_BRAND_INVOICES_OPEN,
  NET_TERMS_VENDOR_CUSTOMERS_SUMMARY,
  NET_TERMS_VENDOR_PROFILE,
} from "apps/portal/routes/netTerms";
import { SETTINGS_INTEGRATIONS, SETTINGS_TEAM, SETTINGS_VENDORS } from "apps/portal/routes/account";
import { RECIPIENTS } from "apps/portal/pages/Recipients/routing";
import { CARDS } from "apps/portal/routes/common";

export const MAIN_MENU_ROUTES = [
  HOMEPAGE_V2,
  BANCO_HOME_PAGE,
  LINE_OF_CREDIT_DASHBOARD,
  CHARGE_CARD,
  BILL_PAY,
  BANKING_TRANSACTIONS,
  BANKING_CASHBACK_REWARDS,
  CARDS,
  INSIGHTS_V1,
  INSIGHTS_V2,
  INSIGHTS_V3,
  NET_TERMS_BRAND_INVOICES_OPEN,
  NET_TERMS_VENDOR_CUSTOMERS_SUMMARY,
];

export const SETTINGS_MENU_ROUTES = [
  SETTINGS_INTEGRATIONS,
  SETTINGS_TEAM,
  LEGACY_SELF_REPORTING,
  FINANCIAL_REPORTING,
  SETTINGS_VENDORS,
  RECIPIENTS,
  NET_TERMS_VENDOR_PROFILE,
];
