import { makeStyles } from "libs/components/styles";
import colors from "libs/components/theme/ampla-theme/palette/colors";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  container: {
    display: "flex",
    alignItems: "center",

    "&.secondary": {
      padding: "4px",
      border: "1px solid",
      borderColor: colors.gray[100],
      borderRadius: "4px",
      backgroundColor: colors.gray[50],
    },
  },
}));
