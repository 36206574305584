export enum CreditCardType {
  I2CCorporateCard = "i2c_corporate_card",
}

export enum CreditCardFormat {
  Physical = "physical",
  Virtual = "virtual",
}

export enum CreditCardStatus {
  Inactive = "inactive",
  Active = "active",
  Stolen = "stolen",
  Lost = "lost",
  Frozen = "frozen",
  ClosedByCustomer = "closed_by_customer",
  SuspectedFraud = "suspected_fraud",
  PendingConfirmation = "pending_confirmation",
  PendingActivation = "pending_activation",
}

export enum CreditCardLimitPeriod {
  Day = "day",
  Month = "month",
}
