import { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { InputAdornment } from "@mui/material";
import classNames from "classnames";

import { TextField } from "libs/components/components/TextField";
import useStyles from "./CurrencyTextField.styles";
import { CurrencyTextFieldProps } from "./CurrencyTextField.types";

const getStringValue = (value: number | null | undefined): string | null | undefined => {
  if (value === null || value === undefined) return value;

  const formatter = new Intl.NumberFormat("en-US", { currency: "USD" });
  return formatter.format(value);
};

/**
 * @deprecated This component exists only for compatibility reasons.
 * Use `TextField` with the `format="number"` prop instead, it provides additional functionality.
 */
const CurrencyTextField = forwardRef(
  (
    { value, onChange, decimalPlaces = 2, minimumValue, jumbo, ...props }: CurrencyTextFieldProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const classes = useStyles();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(event, parseFloat(event.target.value.replace(/,/g, "")));
    };

    return (
      <TextField
        {...props}
        ref={ref}
        format="number"
        value={getStringValue(value)}
        onChange={handleChange}
        numericFormatProps={{
          decimalScale: decimalPlaces,
          isAllowed: ({ floatValue }) => !minimumValue || (!!floatValue && floatValue > parseFloat(minimumValue)),
          fixedDecimalScale: true,
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          ...props.InputProps,
        }}
        className={classNames(props.className, {
          [classes.jumboCurrencyField]: jumbo,
        })}
      />
    );
  },
);

export default CurrencyTextField;
