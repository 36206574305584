import { LinkProps } from "@mui/material";
import { Components } from "@mui/material/styles";

import colors from "../palette/colors";

const MuiLink: Components["MuiLink"] = {
  styleOverrides: {
    root: {
      color: colors.teal[700],
      "&:hover": {
        color: colors.teal[800],
      },
      "&:active": {
        color: colors.teal[600],
      },
    },
  },
};

export default MuiLink;
