import { ReactNode } from "react";
import { Box as MuiBox, FormControl as MuiFormControl, TextFieldProps as MuiTextFieldProps } from "@mui/material";

import { Typography } from "../Typography";
import { UneditableTextFieldProps } from "./TextField.types";

const UneditableTextField = ({
  label,
  value,
  formatValue = (val: unknown) => val as ReactNode,
  TypographyProps = {},
}: UneditableTextFieldProps & MuiTextFieldProps) => (
  <MuiFormControl component={MuiBox} gap={1}>
    {label}
    <Typography {...TypographyProps}>{formatValue(value)}</Typography>
  </MuiFormControl>
);

export default UneditableTextField;
