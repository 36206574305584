export enum AppNotificationSeverity {
  DEFAULT = "DEFAULT",
  URGENT = "URGENT",
}

export enum AppNotificationActionCode {
  PROVIDE_ADDITIONAL_INFORMATION = "PROVIDE_ADDITIONAL_INFORMATION",
  GO_TO_GLOC = "GO_TO_GLOC",
  MANAGE_FINANCIAL_REPORTING = "MANAGE_FINANCIAL_REPORTING",
}

export interface AppNotification {
  id: string;
  recipientUserId?: number;
  recipientEntityId?: number;
  severity: AppNotificationSeverity;
  category: string;
  code: string;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  read: boolean;
  userDismissible: boolean;
  dismissed: boolean;
  action1Label?: string;
  action1Code?: AppNotificationActionCode;
  action2Label?: string;
  action2Code?: AppNotificationActionCode;
  action3Label?: string;
  action3Code?: AppNotificationActionCode;
}
