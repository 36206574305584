import { Components } from "@mui/material/styles";

import MuiAppBar from "./app-bar";
import MuiButton from "./button";
import MuiCheckbox from "./checkbox";
import MuiChip from "./chip";
import MuiCssBaseline from "./css-baseline";
import MuiFab from "./fab";
import MuiFormHelperText from "./form-helper-text";
import MuiFormLabel from "./form-label";
import MuiIconButton from "./icon-button";
import MuiLink from "./link";
import MuiListItemButton from "./list-item-button";
import MuiListItemIcon from "./list-item-icon";
import MuiMenu from "./menu";
import MuiMenuItem from "./menu-item";
import MuiModal from "./modal";
import MuiPaper from "./paper";
import MuiRadio from "./radio";
import MuiSelect from "./select";
import MuiStepper from "./stepper";
import MuiSvgIcon from "./svg-icon";
import MuiTab from "./tab";
import MuiTableCell from "./table-cell";
import MuiTablePagination from "./table-pagination";
import MuiTableRow from "./table-row";
import MuiTabs from "./tabs";
import MuiTextField from "./textfield";
import MuiTooltip from "./tooltip";
import MuiTypography from "./typography";

const overrides: Components = {
  MuiAppBar,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiCssBaseline,
  MuiFab,
  MuiFormHelperText,
  MuiFormLabel,
  MuiIconButton,
  MuiInput: MuiSelect,
  MuiLink,
  MuiListItemButton,
  MuiListItemIcon,
  MuiMenu,
  MuiMenuItem,
  MuiModal,
  MuiPaper,
  MuiRadio,
  MuiStepper,
  MuiSvgIcon,
  MuiTab,
  MuiTableCell,
  MuiTablePagination,
  MuiTableRow,
  MuiTabs,
  MuiTextField,
  MuiTooltip,
  MuiTypography,
};

export default overrides;
