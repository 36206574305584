import { useRef } from "react";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Box, Popover } from "@mui/material";

import { Button } from "libs/components/components/Button";
import { Typography } from "libs/components/components/Typography";
import { UserAvatar } from "libs/components/components/UserAvatar";
import styles from "./ProfileDropdown.styles";
import { ProfileDropdownProps } from "./ProfileDropdown.types";

const ProfileDropdown = ({ user, isOpen, setIsOpen, children }: ProfileDropdownProps) => {
  const anchorEl = useRef<HTMLButtonElement>(null);

  const handleButtonClick = () => setIsOpen?.(true);

  const handlePopoverClose = () => setIsOpen?.(false);

  const nameParts = user.name.split(" ");

  const firstName = nameParts.length > 0 ? nameParts[0] : "";
  const lastName = nameParts.length > 1 ? nameParts[1] : "";

  return (
    <>
      <Button ref={anchorEl} size="small" color="ghost" component="div" sx={styles.button} onClick={handleButtonClick}>
        <UserAvatar firstName={firstName} lastName={lastName} email={user.email} sx={styles.avatar} />
        <Box sx={styles.buttonContent}>
          <Typography variant="body3" sx={styles.buttonContentText}>
            {user.brand}
          </Typography>
          <KeyboardArrowDownIcon
            sx={{
              ...styles.arrowIcon,
              ...(isOpen ? styles.arrowIconOpen : {}),
            }}
          />
        </Box>
      </Button>
      <Popover
        open={!!isOpen}
        anchorEl={anchorEl.current}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            sx: styles.popover,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </Popover>
    </>
  );
};

export default ProfileDropdown;
