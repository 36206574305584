import { APP_MENU_WIDTH, createStyles, MUILink, Theme, Typography, withStyles, WithStyles } from "@ampla/ui-components";

import { useAuthState } from "components/context/AuthContextProvider";
import { COMPANY_NAME } from "constants/common";
import {
  activeEntityHasNetTermsSellerInDisabledState,
  activeEntityHasProductBankingInDisabledState,
} from "routes/helpers/validators";

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      height: 50,
      flexShrink: 0,
      textAlign: "center",
      width: "100%",
      paddingBottom: "2rem",
      maxWidth: 1600 - APP_MENU_WIDTH,
    },
    footerLink: {
      color: theme.palette.ampla.teal[700],
      textDecoration: "underline",
    },
  });

const AppFooter = ({ classes }: WithStyles<typeof styles>) => {
  const { user } = useAuthState();

  if (activeEntityHasProductBankingInDisabledState({ user }) && activeEntityHasNetTermsSellerInDisabledState({ user }))
    return null;

  return (
    <div className={classes.footer}>
      <Typography variant="body2" color="gray-500">
        {COMPANY_NAME} is a financial technology company, not a bank. Banking services provided by Blue Ridge Bank N.A.
        and Thread Bank; Members FDIC. |{" "}
        <MUILink href="https://getampla.com/legal" target="_blank" className={classes.footerLink}>
          Legal
        </MUILink>
        {" | "}
        <MUILink href="https://www.getampla.com/contact-us" target="_blank" className={classes.footerLink}>
          Contact Us
        </MUILink>
      </Typography>
    </div>
  );
};

export default withStyles(styles)(AppFooter);
