import { AuthAPI } from "@ampla/api";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { AuthInternalContext } from "../types";
import { AuthErrorCode } from "@ampla/api";

export const TOKEN_REFRESH_CHECK_INTERVAL_HOURS = 0.5;

const useTokenRefresh = ({
  userIsLoggedIn,
  setUserIsLoggedIn,
  setShowLoader,
}: Pick<AuthInternalContext, "userIsLoggedIn" | "setUserIsLoggedIn" | "setShowLoader">) => {
  const { mutate: refreshToken } = useMutation(AuthAPI.refresh_token, {
    onError: () => {
      console.error("Could not refresh authentication token");

      // On first render showLoader in `AuthContextProvider` is set to `true`
      // if the first call to validate the token fails the user is logged out
      // so we won't be fetching the current user and we should stop showing
      // the loader.
      setShowLoader(false);
      setUserIsLoggedIn(false);
    },
    onSuccess: () => setUserIsLoggedIn(true),
    retry: (count: number, error: any) => {
      if ([AuthErrorCode.MissingToken, AuthErrorCode.NotAuthenticated].includes(error?.code)) return false;
      return count <= 3;
    },
  });

  const setupTokenRefresh = () => {
    if (!window.ampla?.refreshTokenInterval) {
      window.ampla = window.ampla || {};
      window.ampla.refreshTokenInterval = setInterval(
        refreshToken,
        TOKEN_REFRESH_CHECK_INTERVAL_HOURS * 60 * 60 * 1000,
      );
    }
  };

  const tearDownTokenRefresh = () => {
    if (window.ampla?.refreshTokenInterval) {
      clearInterval(window.ampla.refreshTokenInterval);
      delete window.ampla.refreshTokenInterval;
    }
  };

  useEffect(() => {
    // Refresh the token as soon as the portal loads to determine
    // if the user is logged in or not
    refreshToken();
  }, []);

  useEffect(() => {
    if (userIsLoggedIn) setupTokenRefresh();
    else tearDownTokenRefresh();
  }, [userIsLoggedIn]);
};

export default useTokenRefresh;
