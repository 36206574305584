import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Box, Drawer, useTheme } from "@mui/material";

import { PanelHeader } from "../PanelHeader";
import styles from "./Panel.styles";
import { PanelProps } from "./Panel.types";

const Panel = forwardRef(
  (
    {
      size = "narrow",
      anchor = "right",
      hideBackdrop,
      transitionDuration,
      onClose,
      children,
      PanelHeaderProps,
      SlideProps = {},
      ...props
    }: PanelProps,
    ref,
  ) => {
    const theme = useTheme();
    const [open, setOpen] = useState(props.open);

    const panelRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => panelRef?.current);

    // Keep a local `open` state so the component will function correctly
    // with `useModals`
    useEffect(() => {
      setOpen(props.open);
    }, [props.open]);

    const handleClose = () => {
      if (onClose) {
        setOpen(false);

        // Wait for the transition to finish before calling onClose
        setTimeout(
          onClose,
          transitionDuration
            ? typeof transitionDuration === "number"
              ? transitionDuration
              : transitionDuration?.exit
            : theme.transitions.duration.leavingScreen,
        );
      }
    };

    const handlePageClick = useCallback((e: MouseEvent) => {
      // Don't close the panel if the click was inside the panel
      if (!panelRef?.current?.contains(e.target as Node)) {
        handleClose();
      }
    }, []);

    // If we are not using a backdrop for the panel, we need to handle clicks anywhere on the page
    useEffect(() => {
      if (hideBackdrop) {
        document.addEventListener("click", handlePageClick);
        return () => document.removeEventListener("click", handlePageClick);
      }
    }, []);

    return (
      <Drawer
        {...props}
        ref={panelRef}
        open={open}
        anchor={anchor}
        hideBackdrop={hideBackdrop}
        sx={
          {
            ...props.sx,
            ...(hideBackdrop ? styles.drawerWithoutBackdrop : {}),
          } as any
        }
        transitionDuration={transitionDuration}
        SlideProps={{ appear: true, ...SlideProps }}
        PaperProps={{ sx: styles.paper }}
        onClose={handleClose}
      >
        <Box
          sx={
            {
              ...styles.content,
              ...(size === "narrow" ? styles.narrow : {}),
              ...(size === "wide" ? styles.wide : {}),
              ...(props.sx || {}),
            } as any
          }
        >
          {PanelHeaderProps && <PanelHeader {...PanelHeaderProps} onClose={handleClose} />}
          {children}
        </Box>
      </Drawer>
    );
  },
);

export default Panel;
