import { EnabledProducts } from "@ampla/api";
import ProductApplicationBanner from "apps/portal/components/AppLayout/components/ProductApplicationBanner";
import ShopifyMigrationBanner from "apps/portal/components/AppLayout/components/ShopifyMigrationBanner";
import StaffAccountBanner from "apps/portal/components/AppLayout/components/StaffAccountBanner";
import OutdatedProfileBanner from "apps/portal/components/OutdatedProfileBanner";
import { useAuthState } from "apps/portal/components/context/AuthContextProvider";
import { useLayoutState } from "apps/portal/components/context/LayoutContextProvider";
import { activeEntityHasProductBankingInActiveState } from "apps/portal/routes/helpers/validators";

const AppBanners = () => {
  const { user } = useAuthState();

  const {
    showBankingApplicationBanner,
    showFundingApplicationBanner,
    showChargeCardApplicationBanner,
    showStaffAccountBanner,
    showOutdatedProfileBanner,
    showMigrateShopifyBanner,
  } = useLayoutState();

  const isBankingActive = activeEntityHasProductBankingInActiveState({ user });

  return (
    <>
      {showBankingApplicationBanner && <ProductApplicationBanner product={EnabledProducts.Banking} />}
      {showFundingApplicationBanner && <ProductApplicationBanner product={EnabledProducts.ProductionFunding} />}
      {showChargeCardApplicationBanner && (
        <ProductApplicationBanner
          product={EnabledProducts.ChargeCards}
          applicationPath={isBankingActive ? "/corporate-card/onboarding" : undefined}
        />
      )}
      {showStaffAccountBanner && <StaffAccountBanner />}
      {showOutdatedProfileBanner && <OutdatedProfileBanner />}
      {showMigrateShopifyBanner && <ShopifyMigrationBanner />}
    </>
  );
};

export default AppBanners;
