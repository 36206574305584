import React, { forwardRef } from "react";
import { IconButton as MUIIconButton } from "@mui/material";
import classNames from "classnames";

import { AMPLA_COMPONENT_NAMESPACE } from "libs/components/theme/constants";
import useStyles from "./IconButton.styles";
import { IconButtonProps } from "./IconButton.types";

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, color = "primary", size = "large", ...props }: IconButtonProps, ref) => {
    const classes = useStyles();

    return (
      <MUIIconButton
        {...props}
        ref={ref}
        size={size}
        className={classNames(AMPLA_COMPONENT_NAMESPACE, color, size, props.className)}
      >
        {React.cloneElement(children, {
          className: classNames({
            [classes.small]: size === "small",
            [classes.medium]: size === "medium",
            [classes.large]: size === "large",
          }),
        })}
      </MUIIconButton>
    );
  },
);

export default IconButton;
