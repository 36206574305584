import { lazy } from "react";

import {
  activeEntityHasProductBankingInActiveState,
  userHasAddBancoCounterpartyPermission,
  userHasChangeBancoCounterpartyPermission,
  userHasViewBancoCounterpartyPermission,
} from "routes/helpers/validators";
import { RouteDeclaration } from "routes/types";

const Recipients = lazy(() => import("pages/Recipients/pages/RecipientsList"));
const RecipientAdd = lazy(() => import("pages/Recipients/pages/AddRecipient"));
const RecipientEdit = lazy(() => import("pages/Recipients/pages/EditRecipient"));

export const RECIPIENTS_ROOT = "/recipients";

export const RECIPIENTS: RouteDeclaration = {
  name: "Recipients",
  path: RECIPIENTS_ROOT,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasProductBankingInActiveState, userHasViewBancoCounterpartyPermission],
  PageComponent: Recipients,
};

export const BANKING_ADD_RECIPIENT: RouteDeclaration = {
  name: "Add Recipient",
  path: `${RECIPIENTS_ROOT}/add`,
  pathIsExact: false,
  pathIsPrivate: true,
  validators: [activeEntityHasProductBankingInActiveState, userHasAddBancoCounterpartyPermission],
  PageComponent: RecipientAdd,
};

export const BANKING_EDIT_RECIPIENT: RouteDeclaration = {
  name: "Edit Recipient",
  path: `${RECIPIENTS_ROOT}/:bancoCounterpartyId`,
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [activeEntityHasProductBankingInActiveState, userHasChangeBancoCounterpartyPermission],
  PageComponent: RecipientEdit,
};

export const PORTAL_RECIPIENTS_ROUTES = [RECIPIENTS];

export const BANKING_RECIPIENTS_ROUTES = [BANKING_ADD_RECIPIENT, BANKING_EDIT_RECIPIENT];
