import { getEnvVariables } from "@ampla/utils";

export const ROOT_API_URL_FALLBACK = `${window.location.protocol}//${window.location.hostname.replace(
  "portal",
  "api",
)}/api`;

export const ROOT_API_URL = getEnvVariables().REACT_APP_API_URL
  ? getEnvVariables().REACT_APP_API_URL
  : ROOT_API_URL_FALLBACK;

export const ROOT_FILE_URL = `${ROOT_API_URL.replace(/\/api$/, "")}`;

export const ROOT_ADMIN_URL = `${ROOT_API_URL.replace(/\/api$/, "/ops")}`;

export const BASE_HEADERS: HeadersInit = {
  "content-type": "application/json",
  crossDomain: "true",
};

export const BASE_FETCH_OPTIONS: RequestInit & { crossDomain: boolean } = {
  referrer: "no-referrer",
  redirect: "error",
  mode: "cors",
  crossDomain: true,
  credentials: "include",
};

export const IDEMPOTENCY_KEY_HEADER = "Api-Idempotency-Key";
