import { Components } from "@mui/material/styles";

const MuiListItemIcon: Components["MuiListItemIcon"] = {
  styleOverrides: {
    root: {
      minWidth: "unset !important",
    },
  },
};

export default MuiListItemIcon;
