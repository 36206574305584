const styles = {
  actions: {
    ".MuiButton-root+.MuiButton-root:before": {
      content: "''",
      width: "6px",
      height: "6px",
      borderRadius: "50%",
      backgroundColor: "ampla.teal.700",
      marginRight: "8px",
    },
    "& .MuiButton-root": {
      minWidth: 0,
    },
  },
};

export default styles;
