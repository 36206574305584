import { SVGProps } from "react";

import withIconWrapper from "../components/withIconWrapper";

const AsteriskIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.50544 1.3335V1.80408V3.18153L2.25175 2.49036L1.82529 2.25506L1.33203 3.07368L1.7585 3.30898L3.00706 4.00016L1.7585 4.68644L1.33203 4.92173L1.82529 5.74035L2.25175 5.50505L3.50544 4.81389V6.19624V6.66683H4.49195V6.19624V4.81389L5.74564 5.50505L6.17211 5.74035L6.66536 4.92173L6.2389 4.68644L4.99034 4.00016L6.2389 3.30898L6.66536 3.07368L6.17211 2.25506L5.74564 2.49036L4.49195 3.18153V1.80408V1.3335H3.50544Z"
      fill="inherit"
    />
  </svg>
);

export default withIconWrapper(AsteriskIcon);
