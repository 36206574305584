import { Components } from "@mui/material/styles";

import colors from "../palette/colors";

const MuiPaper: Components["MuiPaper"] = {
  defaultProps: {
    elevation: 1,
  },
  styleOverrides: {
    rounded: {
      borderRadius: 12,
    },
    elevation0: {
      boxShadow: "none",
    },
    elevation: {
      boxShadow: "0px 4px 20px rgba(8, 100, 106, 0.1)",
    },
  },
};

export default MuiPaper;
