import { PaperProps, Paper } from "@ampla/ui-components";
import { ForwardedRef, forwardRef } from "react";

export type RoundedPaperProps = PaperProps & {
  borderless?: boolean;
};

const RoundedPaper = forwardRef(({ borderless, ...rest }: RoundedPaperProps, ref: ForwardedRef<HTMLDivElement>) => (
  <Paper ref={ref} {...rest} />
));

export default RoundedPaper;
