import { Components } from "@mui/material/styles";

import { getVariantBaseStyleDefinition } from "../helpers/typography";
import colors from "../palette/colors";

const MuiFormLabel: Components["MuiFormLabel"] = {
  styleOverrides: {
    root: {
      ...getVariantBaseStyleDefinition({ variant: "overline2" }),
      color: colors.gray[600],
      textTransform: "uppercase",
      marginBottom: 8,
    },
  },
};

export default MuiFormLabel;
