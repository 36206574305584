export enum BancoCardType {
  UnitGrowthCard = "unit_growth_card",
  UnitDebitCard = "unit_debit_card",
  UnitChargeCard = "unit_charge_card",
}

export enum BancoCardFormat {
  Physical = "physical",
  Virtual = "virtual",
}

export enum BancoCardStatus {
  Inactive = "inactive",
  Active = "active",
  Stolen = "stolen",
  Lost = "lost",
  Frozen = "frozen",
  ClosedByCustomer = "closed_by_customer",
  SuspectedFraud = "suspected_fraud",
  Applying = "applying",
}

export enum BancoCardLimitPeriod {
  Day = "day",
  Month = "month",
}

export enum BancoCardLimitType {
  DailyWithdrawal = "daily_withdrawal",
  DailyPurchase = "daily_purchase",
  MonthlyWithdrawal = "monthly_withdrawal",
  MonthlyPurchase = "monthly_purchase",
}
