export const BrandRoles = {
  BrandOwner: "brand_owner",
  BrandAdmin: "brand_admin",
  BrandAccountingManager: "brand_accounting_manager",
  BrandEmployee: "brand_employee",
  BrandCardholder: "brand_cardholder",
  BrandBookkeeper: "brand_bookkeeper",
} as const;

export enum BrandAccessGroups {
  BrandAccessEmailAdvances = "brand_access_email_advances",
  BrandAccessSelfReporting = "brand_access_self_reporting",
}

export enum InvestorGroups {
  Investor = "investor",
  PotentialInvestor = "potential_investor",
}

export enum InternalGroups {
  // no need to expose other internal groups to frontend
  AccessPerformanceDash = "access_performance_dash",
}

export enum SignupAccessGroups {
  SignupBankingAccess = "signup_banking_access",
  SignupFundingAccess = "signup_funding_access",
  SignupPayLaterAccess = "signup_pay_later_access",
  SignupFundingBankingAccess = "signup_funding_banking_access",
  SignupCorporateCardAccess = "signup_corporate_card_access",
}

export const UserGroups = {
  ...BrandRoles,
  ...BrandAccessGroups,
  ...InvestorGroups,
  ...InternalGroups,
  ...SignupAccessGroups,
};

export enum BrandRoleAction {
  Add,
  Change,
  Delete,
  SendActivation,
}

export type UserGroups = typeof UserGroups;

export enum UserPermissions {
  // User Management
  // Permissions to manage Brand Owners
  SendActivationBrandOwner = "iam.send_activation_brand_owner",
  // Permissions to manage Brand Admins
  AddBrandAdmin = "iam.add_brand_admin",
  ChangeBrandAdmin = "iam.change_brand_admin",
  DeleteBrandAdmin = "iam.delete_brand_admin",
  SendActivationBrandAdmin = "iam.send_activation_brand_admin",
  // Permissions to manage Brand Acounting Manager
  AddBrandManager = "iam.add_brand_accounting_manager",
  ChangeBrandManager = "iam.change_brand_accounting_manager",
  DeleteBrandManager = "iam.delete_brand_accounting_manager",
  SendActivationBrandManager = "iam.send_activation_brand_accounting_manager",
  // Permissions to manage Brand Employee
  AddBrandEmployee = "iam.add_brand_employee",
  ChangeBrandEmployee = "iam.change_brand_employee",
  DeleteBrandEmployee = "iam.delete_brand_employee",
  SendActivationBrandEmployee = "iam.send_activation_brand_employee",
  // Permissions to manage Brand Book Keeper
  AddBrandBookkeeper = "iam.add_brand_bookkeeper",
  ChangeBrandBookkeeper = "iam.change_brand_bookkeeper",
  DeleteBrandBookkeeper = "iam.delete_brand_bookkeeper",
  SendActivationBrandBookkeeper = "iam.send_activation_brand_bookkeeper",
  // Permissions to manage Brand Card Holder
  AddBrandCardholder = "iam.add_brand_cardholder",
  ChangeBrandCardholder = "iam.change_brand_cardholder",
  DeleteBrandCardholder = "iam.delete_brand_cardholder",
  SendActivationBrandCardholder = "iam.send_activation_brand_cardholder",

  // Home Page
  ViewFullHomePage = "account.view_full_home_page",

  // Credit Cards
  AddCreditCard = "credit.add_card",
  ChangeCreditCard = "credit.change_card",
  DeleteCreditCard = "credit.delete_card",
  ViewOwnCreditCards = "credit.view_own_cards",
  ViewAllCreditCards = "credit.view_all_cards",
  ViewAllCreditCardsSensitiveData = "credit.view_all_cards_sensitive_data",
  UseCreditCard = "credit.use_card",
  FreezeCreditCard = "credit.freeze_card",
  FreezeAllCreditCards = "credit.freeze_all_cards",
  UnfreezeAllCreditCards = "credit.unfreeze_all_cards",
  ViewCorporateCardCreditSummary = "credit.view_corporate_card_summary",
  CreateCreditAccountRepayment = "credit.create_credit_account_repayment",

  // Banco Cards
  AddBancoCard = "banco.add_bancocard",
  ChangeBancoCard = "banco.change_bancocard",
  DeleteBancoCard = "banco.delete_bancocard",
  FreezeAllBancoCards = "banco.freeze_all_bancocards",
  FreezeBancoCard = "banco.freeze_bancocard",
  UnfreezeAllBancoCards = "banco.unfreeze_all_bancocards",
  UseBancoCard = "banco.use_bancocard",
  ViewAllBancoCards = "banco.view_all_bancocards",
  ViewAllBancoCardsSensitiveData = "banco.view_all_bancocards_sensitive_data",
  ViewOwnBancoCards = "banco.view_own_bancocards",
  ViewOwnCardTransactions = "banco.view_own_card_transactions",
  ViewAllCardTransactions = "banco.view_all_card_transactions",
  ViewChargeCardSummary = "banco.view_charge_card_summary",
  ExportBancoInstructions = "banco.export_banco_instructions",
  // Charge Cards / Corporate Card
  AddChargeCardsProductApplication = "account.add_chargecardsproductapplication",
  SubmitChargeCardsProductApplication = "account.submit_chargecardsproductapplication",
  // Repayment Account
  AddProductRepaymentBancoAccount = "banco.add_productrepaymentbancoaccount",
  ViewProductRepaymentBancoAccount = "banco.view_productrepaymentbancoaccount",
  DeleteProductRepaymentBancoAccount = "banco.delete_productrepaymentbancoaccount",
  // Banco Account
  AddBancoAccount = "banco.add_bancoaccount",
  ViewBancoAccount = "banco.view_bancoaccount",
  ChangeBancoAccount = "banco.change_bancoaccount",
  DeletePaymentMethod = "banco.delete_paymentmethod",
  ViewSensitiveBankInfo = "banco.view_sensitive_bank_info",
  // Banco Instruction & Transaction
  AddBancoInstruction = "banco.add_bancoinstruction",
  ViewBancoInstruction = "banco.view_bancoinstruction",
  CancelBancoInstruction = "banco.cancel_bancoinstruction",
  ViewBancoTransaction = "banco.view_bancotransaction",
  // Banco Counterparty
  AddBancoCounterparty = "banco.add_bancocounterparty",
  ChangeBancoCounterparty = "banco.change_bancocounterparty",
  ViewBancoCounterparty = "banco.view_bancocounterparty",
  RequestPayLaterCounterpartyApproval = "banco.request_paylater_counterparty_approval",

  // Billpay
  AddBill = "billpay.add_bill", // includes tracking categories and chart of accounts
  AddBillPaySettings = "billpay.add_billpaysettings",
  AddPayment = "billpay.add_payment",
  AddGLOCPayment = "billpay.add_gloc_payment",
  AddPayLaterPayment = "billpay.add_paylater_payment",
  ChangeBill = "billpay.change_bill", // includes tracking categories and chart of accounts
  ChangeBillPaySettings = "billpay.change_billpaysettings",
  ChangePayment = "billpay.change_payment",
  DeleteBill = "billpay.delete_bill", // includes tracking categories and chart of accounts
  DeletePayment = "billpay.delete_payment",
  DeletePayLaterPayment = "billpay.delete_paylater_payment",
  EditBillPayAccountingIntegration = "billpay.edit_accounting_integration",
  ViewBill = "billpay.view_bill", // includes tracking categories and chart of accounts
  ViewBillPaySettings = "billpay.view_billpaysettings",
  ViewPayment = "billpay.view_payment",
  ViewBillPayCheckList = "billpay.view_billpaychecklist",

  // Event
  RequestFunding = "event.request_funding",
  ViewTransactionEvent = "event.view_transactionevent",
  ExportTransactionEvents = "event.export_transactionevents",

  // Integration
  AddBankConnection = "integration.add_bankconnection",
  AddIntegration = "integration.add_integration",
  ChangeBankConnection = "integration.change_bankconnection",
  ChangeIntegration = "integration.change_integration",
  ViewBankConnection = "integration.view_bankconnection",
  ViewIntegration = "integration.view_integration",

  // Money
  AddMoneyAccount = "money.add_moneyaccount",
  AddPlaidItem = "money.add_plaiditem",
  ChangeMoneyAccount = "money.change_moneyaccount",
  ChangePlaidItem = "money.change_plaiditem",
  ViewMoneyAccount = "money.view_moneyaccount",
  ViewPlaidAccount = "money.view_plaidaccount",
  ViewPlaidItem = "money.view_plaiditem",

  // Paylater
  AddPayLaterSettings = "paylater.add_paylatersettings",
  ViewPayLaterSettings = "paylater.view_paylatersettings", // includes advance id
  ChangePayLaterSettings = "paylater.change_paylatersettings",
  DeletePayLaterSettings = "paylater.delete_paylatersettings",

  // Agreement Consents
  AddAgreementConsent = "account.add_agreementconsent",

  // Self reporting
  AccessSelfReporting = "account.access_self_reporting",
  AddContractCovenantReportingFile = "account.add_contractcovenantreportingfile",
  DeleteContractCovenantReportingFile = "account.delete_contractcovenantreportingfile",
  // Rewards
  ViewRewardsSummary = "rewards.view_rewards_summary",
  RedeemRewards = "rewards.redeem_rewards",
  // Insights
  ViewBasicInsights = "insights.view_basic_insights",

  // AP Automation
  AddAutomatedProducts = "ap_automation.add_automatedproducts",
  ViewAutomatedProducts = "ap_automation.view_automatedproducts",
  ChangeAutomatedProducts = "ap_automation.change_automatedproducts",
  AddEntityAmplaCategory = "ap_automation.add_entityamplacategory",
  ViewEntityAmplaCategory = "ap_automation.view_entityamplacategory",
  ChangeEntityAmplaCategory = "ap_automation.change_entityamplacategory",
  ViewAPAutomationSettings = "ap_automation.view_apautomationsettings",
  ViewAmplaCategory = "ap_automation.view_amplacategory",

  // Expenses
  ViewExpense = "ap_automation.view_expense",
  ViewAllExpense = "ap_automation.view_all_expense",
  ViewUserNeedsReviewExpense = "ap_automation.view_user_needs_review_expense",
  ChangeExpense = "ap_automation.change_expense",
  ViewExpensesSettings = "ap_automation.view_expensessettings",
  ChangeExpensesSettings = "ap_automation.change_expensessettings",
  ExportExpenses = "ap_automation.export_expenses",

  // INTERNAL
  AccessAllBrands = "account.access_all_brands",
  LoginAsBrandUser = "account.login_as_brand_user",
}

export enum PermissionSet {
  // ManageOwner = "owner",
  ManageAdmins = "admin",
  ManageAccountingManagers = "accounting_manager",
  ManageEmployees = "employee",
  ManageCardholders = "cardholder",
  ManageBookkeepers = "bookkeeper",

  // TODO: all sets below are mocked

  // Ampla account management
  AcceptFinancialAgreements = "accept_financial_agreements",
  ManageRolesAndPermissions = "manage_roles_and_permissions",
  ManageIntegrations = "manage_integrations",
  MoveMoney = "move_money",

  // Banking
  ViewBankingHomepage = "view_banking_homepage",
  BankingViewExportTransactions = "banking_view_export_transactions",
  ManageDebitCards = "manage_debit_cards",
  CreateReserveAndReconciliationAccounts = "create_reserve_reconciliation_accounts",

  // GLOC
  ViewGLOCHomepage = "view_gloc_homepage",
  GLOCViewExportTransactions = "gloc_view_export_transactions",

  // Corporate Card
  ViewCorporateCardHomepage = "view_corporate_card_homepage",
  ViewExportCardTransactions = "view_export_card_transactions",
  ViewCardsByUser = "view_cards_by_user",
  FreezeCards = "freeze_cards",
  ReportCardsAsStolen = "report_cards_as_stolen",
  CloseCards = "close_cards",
  AccessRewards = "access_rewards",
  RedeemRewards = "redeem_rewards",

  // Bills
  ViewBillpayHomepage = "view_billpay_homepage",
  ViewExportBills = "view_export_bills",
  AddEditBills = "add_edit_bills",
  ManageRecipients = "manage_recipients",

  // Expenses
  ExpensesViewExportTransactions = "expenses_view_export_transactions",
  EditExpenses = "edit_expenses",

  // Insights
  ViewInsights = "view_insights",
}

export enum SignupSource {
  NET_TERMS_VENDOR = "net-terms-vendor",
  NET_TERMS_BRAND_REFERRAL = "net-terms-brand-referral",
  NET_TERMS_BRAND_INVITATION = "net-terms-brand-invitation",
  NET_TERMS_BRAND_PAYMENT_INVITATION = "net-terms-brand-payment-invitation",
}
