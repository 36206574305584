import { matchPath } from "react-router";
import { NetTermsPaths } from "routes/paths";

export const EXCLUDED_ROUTES = ["/bill-schedule/payment", NetTermsPaths.NET_TERMS_BRAND_SCHEDULE_PAYMENT];

export const startZendesk = (ref: any) => {
  if (!document.getElementById("ze-snippet")) {
    // Add ZenDesk Script Tag Programatically
    const script = document.createElement("script");
    script.src = "https://static.zdassets.com/ekr/snippet.js?key=64446992-cf68-45c2-b4bf-5df1b3405521";
    script.id = "ze-snippet";
    script.async = true;
    document.head.appendChild(script);

    // Once Loaded & On Close of Custom Launcher, Hide the Default Launcher
    script.onload = function () {
      window.zE("webWidget", "hide");
      window.zE("webWidget:on", "close", function () {
        window.zE("webWidget", "hide");
        const el = ref.current!;
        el.style.opacity = "1";
      });

      window.zESettings = {
        webWidget: {
          offset: { horizontal: "14px" },
        },
      };
    };
  }
};

export const isExcludedRoute = (urlPath: string) => {
  return EXCLUDED_ROUTES.some((path) => matchPath(urlPath, { path, exact: true, strict: false }));
};
