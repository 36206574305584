const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
  },
  headerIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "> *": {
      width: "20px !important",
      height: 20,
      color: "ampla.teal.900",
    },
  },
  leftAlign: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
};

export default styles;
