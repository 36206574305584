import { forwardRef } from "react";
import { Close } from "@mui/icons-material";
import { Box, Tooltip as MuiTooltip } from "@mui/material";
import classnames from "classnames";

import { AMPLA_COMPONENT_NAMESPACE } from "libs/components/theme/constants";
import { useStyles } from "./Tooltip.styles";
import { TooltipProps } from "./Tooltip.types";

const Tooltip = forwardRef((props: TooltipProps, ref) => {
  const {
    children,
    classes,
    color = "dark",
    error = false,
    closeable,
    onClickClose,
    disableHoverListener,
    title,
    ...tipProps
  } = props;
  const styleClasses = useStyles();
  const disableHover = closeable || disableHoverListener;

  if (!title) {
    return children;
  }

  return (
    <MuiTooltip
      {...tipProps}
      ref={ref}
      arrow
      disableHoverListener={disableHover}
      classes={{
        tooltip: classnames(styleClasses.tooltip, AMPLA_COMPONENT_NAMESPACE, color, { error }, classes?.tooltip),
        arrow: classnames(AMPLA_COMPONENT_NAMESPACE, color, classes?.arrow),
        ...classes,
      }}
      title={
        <>
          <Box className="Tooltip-content">{title}</Box>
          {closeable && <Close className="Tooltip-close" onClick={onClickClose} />}
        </>
      }
    >
      {children}
    </MuiTooltip>
  );
});

export default Tooltip;
