import colors from "../palette/colors";

export const getButtonColorVariantDefinition = ({
  defaultColor,
  defaultBorderColor,
  defaultBackgroundColor,
  hoverColor,
  hoverBorderColor,
  hoverBackgroundColor,
  activeColor,
  activeBorderColor,
  activeBackgroundColor,
  disabledColor,
  disabledBorderColor,
  disabledBackgroundColor,
}: {
  defaultColor: string;
  defaultBorderColor: string;
  defaultBackgroundColor: string;
  hoverColor: string;
  hoverBorderColor: string;
  hoverBackgroundColor: string;
  activeColor: string;
  activeBorderColor: string;
  activeBackgroundColor: string;
  disabledColor?: string;
  disabledBorderColor?: string;
  disabledBackgroundColor?: string;
}) => ({
  color: defaultColor,
  borderColor: defaultBorderColor,
  backgroundColor: defaultBackgroundColor,
  "&:focus": {
    color: defaultColor,
    borderColor: defaultBorderColor,
    backgroundColor: defaultBackgroundColor,
  },
  "&:hover": {
    color: hoverColor,
    borderColor: hoverBorderColor,
    backgroundColor: hoverBackgroundColor,
  },
  "&:active": {
    color: activeColor,
    borderColor: activeBorderColor,
    backgroundColor: `${activeBackgroundColor} !important`,
  },
  "&:disabled": {
    color: disabledColor || colors.gray[300],
    borderColor: disabledBorderColor || colors.gray[300],
    backgroundColor: disabledBackgroundColor || colors.gray[100],
  },
});
