import { Components } from "@mui/material/styles";

import { AMPLA_COMPONENT_NAMESPACE_SELECTOR } from "libs/components/theme/constants";
import colors from "../palette/colors";

const MuiTooltip: Components["MuiTooltip"] = {
  styleOverrides: {
    tooltip: {
      [AMPLA_COMPONENT_NAMESPACE_SELECTOR]: {
        color: colors.teal[900],
        backgroundColor: colors.white,
        fontSize: "14px",
        fontWeight: 400,
        padding: "8px 12px 8px 16px",
        border: "1px solid",
        borderColor: colors.gray[600],
        boxShadow: `0px 4px 20px rgba(8, 93, 106, 0.1)`,
        maxWidth: "280px",

        "&.error": {
          color: colors.red[600],
        },

        "& .Tooltip-close": {
          color: colors.teal[700],
        },
      },
    },
    arrow: {
      [AMPLA_COMPONENT_NAMESPACE_SELECTOR]: {
        "&.light": {
          color: colors.white,
        },
        "&.dark": {
          color: colors.gray[600],
        },
      },
    },
  },
};

export default MuiTooltip;
