import { ComponentType } from "react";
import { withStyles as withStylesTSS } from "tss-react/mui";

import { getCleanClassName } from "./helpers";
import { ComponentWithStyles, StylesObject } from "./types";

// Wrapper around TSS-react's `withStyles` that returns typed enhanced component
// and maintains the call signature of MUI V4's `withStyles`.
export const withStyles =
  <Styles extends StylesObject>(styles: Styles) =>
  <Props>(Component: ComponentType<Props>): ComponentWithStyles<Props, Styles> =>
    withStylesTSS(Component, styles as any, {
      name: Component.displayName
        ? getCleanClassName(Component.displayName)
        : Component.name
        ? getCleanClassName(Component.name)
        : undefined,
    }) as ComponentWithStyles<Props, Styles>;
