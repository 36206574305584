import { ComponentType, ReactNode, Suspense } from "react";
import { Redirect, Route, RouteProps } from "react-router";

import { ValidatorFunction } from "types";
import { useAuthState } from "components/context/AuthContextProvider";
import LoadingContainer from "components/Common/LoadingContainer";

type PrivateRouteProps = {
  component?: ComponentType;
  children?: ReactNode;
  notAuthorizedMessage?: string;
  validators?: Array<ValidatorFunction>;
};

const PrivateRoute = ({
  path,
  component: Component,
  children,
  validators = [],
  notAuthorizedMessage,
  ...rest
}: RouteProps & PrivateRouteProps) => {
  const { user, applicationStatuses, userIsLoggedIn, groups } = useAuthState();

  const renderRouteComponent = (props: any) => {
    const currentPath = `${window.location.pathname}${window.location.search}`;

    if (!userIsLoggedIn) return <Redirect to={`/login?next=${encodeURIComponent(currentPath)}`} />;

    const hasPermission = validators.every((validator) => validator({ user, groups, applicationStatuses }));

    if (!hasPermission) {
      let notAuthorizedUrl = `/not-authorized?path=${path}`;
      if (notAuthorizedMessage) {
        notAuthorizedUrl += `&message=${notAuthorizedMessage}`;
      }
      return <Redirect to={notAuthorizedUrl} />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return Component ? <Component {...props} /> : children;
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <Suspense fallback={<LoadingContainer fullscreen />}>
      <Route {...rest} path={path} render={renderRouteComponent} />
    </Suspense>
  );
};

export default PrivateRoute;
