import fedHolidays from "@18f/us-federal-holidays";
import { Moment } from "moment";

export const getDateIsWeekend = (day: Moment) => {
  if (day) {
    const dayOfWeek = day.day();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
    return isWeekend;
  }

  return false;
};

export const getDateIsHoliday = (day: Moment) => {
  if (day) {
    const isAHoliday = fedHolidays.isAHoliday(day.toDate(), {
      shiftSaturdayHolidays: true,
      shiftSundayHolidays: true,
      utc: false,
    });

    return isAHoliday;
  }

  return false;
};
