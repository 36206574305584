import { makeStyles } from "libs/components/styles";
import colors from "libs/components/theme/ampla-theme/palette/colors";

export const useStyles = makeStyles({
  root: {
    "& #notistack-snackbar": {
      padding: 0,
      gap: 10,
    },
    "& .notistack-MuiContent": {
      minHeight: 52,
      width: 420,

      paddingBlock: 14,
      paddingInline: 16,

      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 4,

      color: colors.black,

      boxShadow: "0px 2px 8px 0px rgba(8, 93, 106, 0.12)",
    },
    "& .notistack-MuiContent-success": {
      borderColor: colors.green[600],
      backgroundColor: colors.green[100],

      "& svg": {
        fill: colors.green[400],
        flexShrink: 0,
      },
    },
    "& .notistack-MuiContent-warning": {
      borderColor: colors.orange[600],
      backgroundColor: colors.orange[100],

      "& svg": {
        fill: colors.orange[500],
      },
    },
    "& .notistack-MuiContent-error": {
      borderColor: colors.red[600],
      backgroundColor: colors.red[100],

      "& svg": {
        fill: colors.red[600],
      },
    },
  },
});
