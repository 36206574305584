import { lazy } from "react";

import {
  activeEntityHasProductBankingInActiveState,
  activeEntityHasProductFundingInActiveState,
  activeEntityIsNotAmpla,
  featureFlagValidator,
  negateValidator,
  oneOfValidator,
  userHasAddBankConnectionPermission,
  userHasAddMoneyAccountPermission,
  userHasAddPlaidItemPermission,
  userHasChangeMoneyAccountPermission,
  userHasTeamPagePermission,
  userHasViewBankConnectionPermission,
  userHasViewIntegrationPermission,
  userHasViewMoneyAccountPermission,
  userHasViewPlaidItemPermission,
} from "routes/helpers/validators";

import { RouteDeclaration } from "./types";
import { FeatureFlags } from "@ampla/api";

const ActivateAccountPassword = lazy(() => import("pages/ActivateAccountPassword"));
const Settings = lazy(() => import("pages/Settings"));
const LegacyIntegrations = lazy(() => import("pages/LegacyIntegrations"));
const PlaidOAuthCallback = lazy(() => import("pages/PlaidOAuthCallback"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const SendResetPasswordEmail = lazy(() => import("pages/SendResetPasswordEmail"));
const VendorManagement = lazy(() => import("pages/VendorManagement"));
const FinancialIntegration = lazy(() => import("pages/FinancialIntegration"));

const UserManagement = lazy(() => import("pages/UserManagement"));
const UsersAndRoles = lazy(() => import("pages/UsersAndRoles"));

// Account Management

export const ACCOUNT_RESET_PASSWORD: RouteDeclaration = {
  name: "Reset password",
  path: "/account/reset/:uid/:token",
  pathIsExact: false,
  pathIsPrivate: false,
  PageComponent: ResetPassword,
};

export const ACCOUNT_SEND_PASSWORD_RESET: RouteDeclaration = {
  name: "Reset password",
  path: "/account/send-reset",
  pathIsExact: false,
  pathIsPrivate: false,
  PageComponent: SendResetPasswordEmail,
};

export const ACCOUNT_ACTIVATE_ACCOUNT_PASSWORD: RouteDeclaration = {
  name: "Account activation",
  path: "/account/activate/:uid/:token",
  pathIsExact: false,
  pathIsPrivate: false,
  PageComponent: ActivateAccountPassword,
};

// Integrations Management

export const ACCOUNT_PLAID_OAUTH_CALLBACK = {
  path: "/account/plaid/oauth_callback",
  pathIsExact: false,
  pathIsPrivate: false,
  PageComponent: PlaidOAuthCallback,
};

// Account Settings

export const SETTINGS_TEAM: RouteDeclaration = {
  name: "Users and roles",
  path: "/settings/team",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [userHasTeamPagePermission],
  PageComponent: UserManagement,
};

export const USERS_AND_ROLES: RouteDeclaration = {
  name: "Users and roles",
  path: "/settings/users-and-roles",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [userHasTeamPagePermission, featureFlagValidator(FeatureFlags.CustomizableRoles)],
  PageComponent: UsersAndRoles,
};

export const SETTINGS_INTEGRATIONS: RouteDeclaration = {
  name: "Settings",
  path: "/settings",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    oneOfValidator(
      userHasViewIntegrationPermission,
      userHasViewPlaidItemPermission,
      userHasViewBankConnectionPermission,
    ),
  ],
  PageComponent: Settings,
};

export const SETTINGS_LEGACY_INTEGRATIONS: RouteDeclaration = {
  name: "Settings",
  path: "/settings/legacy-integrations",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    oneOfValidator(
      userHasViewIntegrationPermission,
      userHasViewPlaidItemPermission,
      userHasViewBankConnectionPermission,
    ),
  ],
  PageComponent: LegacyIntegrations,
};

export const SETTINGS_VENDORS: RouteDeclaration = {
  name: "Vendors",
  path: "/settings/vendors",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [
    activeEntityIsNotAmpla,
    activeEntityHasProductFundingInActiveState,
    negateValidator(activeEntityHasProductBankingInActiveState),
    userHasAddMoneyAccountPermission,
    userHasChangeMoneyAccountPermission,
    userHasViewMoneyAccountPermission,
  ],
  PageComponent: VendorManagement,
};

export const FINANCIAL_INTEGRATION: RouteDeclaration = {
  name: "Financial Integration",
  path: "/settings/financial-integration",
  pathIsExact: true,
  pathIsPrivate: true,
  validators: [oneOfValidator(userHasAddPlaidItemPermission, userHasAddBankConnectionPermission)],
  PageComponent: FinancialIntegration,
};

const ACCOUNT_ROUTES = [
  ACCOUNT_RESET_PASSWORD,
  ACCOUNT_SEND_PASSWORD_RESET,
  ACCOUNT_ACTIVATE_ACCOUNT_PASSWORD,
  ACCOUNT_PLAID_OAUTH_CALLBACK,
  FINANCIAL_INTEGRATION,
  SETTINGS_TEAM,
  SETTINGS_INTEGRATIONS,
  SETTINGS_LEGACY_INTEGRATIONS,
  SETTINGS_VENDORS,
  USERS_AND_ROLES,
];

export default ACCOUNT_ROUTES;
