import { Components } from "@mui/material/styles";

import colors from "../palette/colors";

const MuiFormHelperText: Components["MuiFormHelperText"] = {
  styleOverrides: {
    root: {
      marginTop: "8px",
      marginLeft: "unset",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px",
      color: colors.gray[400],

      "&.Mui-error": {
        color: colors.red[500],
      },
    },
  },
};

export default MuiFormHelperText;
