import { Components } from "@mui/material/styles";

import { getVariantBaseStyleDefinition } from "libs/components/theme/ampla-theme/helpers/typography";
import colors from "libs/components/theme/ampla-theme/palette/colors";

const MuiTab: Components["MuiTab"] = {
  styleOverrides: {
    root: {
      textTransform: "none",
      color: colors.gray[600],
      fontSize: "14px",
      fontWeight: 500,
      opacity: 1,
      marginLeft: "2px",
      marginRight: "2px",
      borderBottom: "2px solid",
      borderBottomColor: colors.gray[100],
      padding: "6px 14px",
      minHeight: "34px",
      minWidth: "auto",
      whiteSpace: "nowrap",
      display: "inline-flex",
      flexDirection: "row",
      gap: "4px",

      "& .MuiSvgIcon-root": {
        height: "16px",
        width: "16px",
      },

      "& .MuiChip-root": {
        backgroundColor: colors.orange[300],
        color: colors.teal[900],
        textTransform: "uppercase",
        height: "auto",
        fontWeight: 500,
        ...getVariantBaseStyleDefinition({ variant: "overline2" }),

        "& .MuiChip-label": {
          padding: "0 4px",
        },
      },

      "@media (min-width: 600px)": {
        minWidth: "auto",
      },

      "&.secondary": {
        color: colors.gray[400],
        borderBottom: "none",
        borderRadius: "4px",

        "&.Mui-selected": {
          backgroundColor: colors.white,
          boxShadow: `0px 0px 4px ${colors.gray[100]}`,

          "&:hover": {
            color: colors.teal[700],
            backgroundColor: colors.white,
          },
        },

        "&:hover": {
          backgroundColor: colors.gray[100],
          color: colors.gray[500],
        },
        "&.Mui-disabled": {
          color: colors.gray[300],
        },
      },

      "&.primary.light": {
        color: colors.white,
        borderBottomColor: colors.gray[300],

        "&:hover": {
          borderBottomColor: colors.gray[400],
        },

        "&.Mui-selected": {
          color: colors.white,
          borderBottomColor: colors.white,
        },

        "&.Mui-disabled": {
          color: colors.gray[500],
          borderBottomColor: colors.gray[500],
        },
      },

      "&:hover": {
        borderBottomColor: colors.gray[300],
      },

      "&.Mui-selected": {
        color: colors.teal[700],
        borderBottomColor: colors.teal[700],

        "&:hover": {
          cursor: "default",
        },
      },

      "&.Mui-disabled": {
        color: colors.gray[100],
        borderBottomColor: colors.gray[100],
        "&.MuiTab-textColorInherit": {
          opacity: 1,
        },
      },
    },
  },
  defaultProps: {
    disableRipple: true,
  },
};

export default MuiTab;
