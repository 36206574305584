import { forwardRef } from "react";
import { Box } from "@mui/material";

import { statusChipColorMap, StatusChipProps } from "./StatusChip.types";

const StatusChip = forwardRef(({ label, color = "default", startIcon, endIcon, ...props }: StatusChipProps, ref) => (
  <Box
    ref={ref}
    display="inline-flex"
    alignItems="center"
    gap="3px"
    width="fit-content"
    px="8px"
    py="1px"
    borderRadius="16px"
    fontSize="12px"
    lineHeight="16px"
    color="ampla.teal.900"
    bgcolor={statusChipColorMap[color]}
    whiteSpace="nowrap"
    sx={{
      svg: {
        display: "flex",
        fontSize: "12px",
        width: "1em",
        height: "1em",
      },
    }}
    {...props}
  >
    {startIcon}
    {label}
    {endIcon}
  </Box>
));

export default StatusChip;
