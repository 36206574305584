import { SignupAccess } from "enums";
import { SignupDialogStep } from "./enums";
import { EnabledProducts } from "@ampla/api";

export const SIGNUP_STEP_URL_MAP = {
  [SignupDialogStep.Lead]: "/signup",
  [SignupDialogStep.Product]: "/signup/product",
  [SignupDialogStep.User]: "/signup/user",
  [SignupDialogStep.Company]: "/signup/company",
  [SignupDialogStep.Address]: "/signup/address",
};

export const SIGNUP_URL_STEP_MAP = {
  "/signup": SignupDialogStep.Lead,
  "/signup/product": SignupDialogStep.Product,
  "/signup/user": SignupDialogStep.User,
  "/signup/company": SignupDialogStep.Company,
  "/signup/address": SignupDialogStep.Address,
};

export type PRODUCT_NAME_KEYS =
  | SignupAccess.Banking
  | SignupAccess.PayLater
  | SignupAccess.ProductionFunding
  | SignupAccess.FundingAndBanking
  | SignupAccess.CorporateCard
  | EnabledProducts.ChargeCards;

export const PRODUCT_NAMES: Record<PRODUCT_NAME_KEYS, string> = {
  [SignupAccess.Banking]: "Ampla Banking",
  [SignupAccess.PayLater]: "Ampla Pay Later",
  [SignupAccess.ProductionFunding]: "Ampla Growth Line of Credit",
  [SignupAccess.FundingAndBanking]: "Ampla Growth Line of Credit",
  [SignupAccess.CorporateCard]: "Ampla Visa Corporate Card",
  [EnabledProducts.ChargeCards]: "Ampla Visa Corporate Card", // TODO: Remove when charge_card gets renamed to corporate_card
};

export const PRODUCT_TITLES: Record<SignupAccess, string> = {
  [SignupAccess.CorporateCard]: "Ampla Visa Corporate Card",
  [SignupAccess.ProductionFunding]: "Ampla Growth Line of Credit",
  [SignupAccess.Banking]: "Ampla Banking",
  [SignupAccess.PayLater]: "Ampla Pay Later",
  [SignupAccess.FundingAndBanking]: "Ampla Growth Line of Credit",
};

export const LOGOUT_MODAL_CONTENT: Record<string, any> = {
  [SignupDialogStep.User]: {
    title: "Cancel application?",
    description:
      "You are about to cancel your application without saving your information. To save your application, continue and create an account first before canceling.",
    cancelLabel: "No, continue",
    confirmLabel: "Yes, cancel",
  },
  default: {
    title: "Save and Log out?",
    description:
      "You are about to log out of your account. To resume your application log back in with the user and password you set during account creation.",
    confirmLabel: "Save & log out",
  },
};

export const SUFFIX_PRODUCT_MAP: Record<string, EnabledProducts> = {
  banking: EnabledProducts.Banking,
  funding: EnabledProducts.ProductionFunding,
  "corporate-card": EnabledProducts.ChargeCards,
  "bill-pay": EnabledProducts.BillPay,
  spins: EnabledProducts.Spins,
};
