export enum TransactionEventType {
  BankingTransfer = "banking_transfer",
  Confirmation = "confirmation",
  Document = "document",
  MoneyTransfer = "money_transfer",
  Offset = "offset",
  Shipment = "shipment",
}

export enum TransactionEventSubtype {
  Accounting = "accounting",
  Advance = "advance",
  AdvanceRepayment = "advance_repayment",
  Amortization = "amortization",
  AuditOffset = "audit_offset",
  BuyerChargeback = "buyer_chargeback",
  BuyerPayment = "buyer_payment",
  BuyerPaymentToBrand = "buyer_payment_to_brand",
  ChargeBack = "charge_back",
  Complete = "complete",
  FeePayment = "fee_payment",
  FeePaymentFromBrand = "fee_payment_from_brand",
  FundingApplication = "funding_application",
  FundingApproval = "funding_approval",
  GhostInvoice = "ghost_invoice",
  Invoice = "invoice",
  InvoiceFromSupplier = "invoice_from_supplier",
  InvoiceOffset = "invoice_offset",
  PassthroughPayment = "passthrough_payment",
  PaydownPayment = "paydown_payment",
  Promotion = "promotion",
  PurchaseOrder = "purchase_order",
  Repayment = "repayment",
  ScheduledBillPay = "scheduled_bill_pay",
  ShipmentToBrand = "shipment_to_brand",
  ShipmentToBuyer = "shipment_to_buyer",
  ShipmentToWarehouse = "shipment_to_warehouse",
  ThirPartyProcessingFee = "third_party_processing_fee",
  WriteOff = "writeoff",
  Adjustment = "adjustment",
}

export enum TransactionEventStatus {
  Approved = "approved",
  CanceledByUser = "canceled_by_user",
  Complete = "complete",
  Failed = "failed",
  PendingRecalculation = "pending_recalculation",
  PendingSettlement = "pending_settlement",
  PendingAction = "pending_action",
  PreApproved = "pre_approved",
  Processing = "processing",
  Rejected = "rejected",
  Requested = "requested",
  Scheduled = "scheduled",
  Waiting = "waiting",
}

export enum TransactionEventBankingSystem {
  ACB = "atlantic_capital_bank",
  Synapse = "synapse",
}
