import { ForwardedRef, forwardRef } from "react";

import { Typography } from "libs/components/components/Typography";
import { useStyles } from "./TableEmptyState.styles";
import { TableEmptyStateProps } from "./TableEmptyState.types";

const TableEmptyState = forwardRef(
  ({ message = "No items to display" }: TableEmptyStateProps, ref: ForwardedRef<HTMLTableRowElement>) => {
    const classes = useStyles();

    return (
      <tr ref={ref}>
        <td colSpan={999}>
          <div className={classes.textContainer}>
            <Typography variant="overline1" color="gray-500">
              {message}
            </Typography>
          </div>
        </td>
      </tr>
    );
  },
);

export default TableEmptyState;
