import { WithStyles, withStyles, MUITypography, createStyles, MUIButton } from "@ampla/ui-components";
import { Link as RouterLink } from "react-router-dom";
import ErrorPage from "components/ErrorPage";
import Spacer from "./Spacer";

const styles = () =>
  createStyles({
    optionsContainer: {
      display: "flex",
      justifyContent: "center",
    },
  });

const ErrorNotFound = ({ classes }: WithStyles<typeof styles>) => (
  <ErrorPage title="Whoops...">
    <MUITypography align="center">
      Seems like you lost your way. Path <strong>{window.location.pathname}</strong> does not exist.
    </MUITypography>
    <Spacer size={3} />
    <div className={classes.optionsContainer}>
      <MUIButton to="/" component={RouterLink} color="primary" variant="contained">
        Go to Home
      </MUIButton>
    </div>
  </ErrorPage>
);

export default withStyles(styles)(ErrorNotFound);
