export enum Brands {
  "default" = "default",
  "localhost" = "localhost",
  "local.getampla.com" = "local.getampla.com",
  "www.cart.com" = "cart.com",
  "shopcanal.com" = "shopcanal.com",
  "chord.co" = "chord.co",
  "runalloy.com" = "runalloy.com",
  "daasity.com" = "daasity.com",
  "anvyl.com" = "anvyl.com",
}

export type Brand = keyof typeof Brands;

export type BrandConfigItem = {
  name: string;
  url: string;
  logo?: string;
  color: string;
  backgroundColor: string;
  buttonColor: string;
  buttonBackground?: string;
  buttonBackgroundColor: string;
  fontFamily: string;
  fontSize: string;
  titleFontSize: string;
  linkColor: string;
  borderRadius?: string;
};

export type BrandConfig = {
  [key in Brand]: BrandConfigItem;
};

export const BRAND_CONFIG: BrandConfig = {
  default: {
    name: "Ampla",
    url: "https://getampla.com",
    color: "#021315",
    backgroundColor: "#FFFFFF",
    buttonColor: "#FFFFFF",
    buttonBackgroundColor: "#064646",
    fontFamily: "sans-serif",
    fontSize: "16px",
    titleFontSize: "24px",
    linkColor: "#064646",
  },
  localhost: {
    name: "Canal",
    url: "https://shopcanal.com",
    logo: "canal.svg",
    color: "#021315",
    backgroundColor: "#FFFFFF",
    buttonColor: "#FFFFFF",
    buttonBackgroundColor: "#168085",
    fontFamily: "sans-serif",
    fontSize: "16px",
    titleFontSize: "24px",
    linkColor: "#168085",
  },
  "local.getampla.com": {
    name: "Anvyl",
    url: "https://anvyl.com",
    logo: "anvyl.svg",
    color: "#313a49",
    backgroundColor: "#FFFFFF",
    buttonColor: "#FFFFFF",
    buttonBackgroundColor: "rgb(234, 235, 254)",
    buttonBackground: "linear-gradient(to left,#33f 0%,#36f 100%);",
    fontFamily: "sans-serif",
    fontSize: "16px",
    titleFontSize: "24px",
    linkColor: "rgb(51, 58, 71)",
    borderRadius: "25px",
  },
  "www.cart.com": {
    name: "Cart.com",
    url: "https://cart.com",
    logo: "cart.svg",
    color: "#021315",
    backgroundColor: "#FFFFFF",
    buttonColor: "#FFFFFF",
    buttonBackgroundColor: "#0B8AEE",
    fontFamily: "Gilroy,sans-serif",
    fontSize: "16px",
    titleFontSize: "24px",
    linkColor: "#0B8AEE",
  },
  "anvyl.com": {
    name: "Anvyl",
    url: "https://anvyl.com",
    logo: "anvyl.svg",
    color: "#313a49",
    backgroundColor: "#FFFFFF",
    buttonColor: "#FFFFFF",
    buttonBackgroundColor: "rgb(234, 235, 254)",
    buttonBackground: "linear-gradient(to left,#33f 0%,#36f 100%);",
    fontFamily: "sans-serif",
    fontSize: "16px",
    titleFontSize: "24px",
    linkColor: "rgb(51, 58, 71)",
    borderRadius: "25px",
  },
  "shopcanal.com": {
    name: "Canal",
    url: "https://shopcanal.com",
    logo: "canal.svg",
    color: "#021315",
    backgroundColor: "#FFFFFF",
    buttonColor: "#FFFFFF",
    buttonBackgroundColor: "#168085",
    fontFamily: "sans-serif",
    fontSize: "16px",
    titleFontSize: "24px",
    linkColor: "#168085",
  },
  "chord.co": {
    name: "Chord",
    url: "https://chord.co",
    logo: "chord.svg",
    color: "#0B8AEE",
    backgroundColor: "#FFFFFF",
    buttonColor: "#FFFFFF",
    buttonBackgroundColor: "#0B8AEE",
    fontFamily: "sans-serif",
    fontSize: "16px",
    titleFontSize: "24px",
    linkColor: "#0B8AEE",
  },
  "runalloy.com": {
    name: "Runalloy",
    url: "https://runalloy.com",
    logo: "runalloy.svg",
    color: "#0B8AEE",
    backgroundColor: "#FFFFFF",
    buttonColor: "#FFFFFF",
    buttonBackgroundColor: "#0B8AEE",
    fontFamily: "sans-serif",
    fontSize: "16px",
    titleFontSize: "24px",
    linkColor: "#0B8AEE",
  },
  "daasity.com": {
    name: "Daasity",
    url: "https://daasity.com",
    logo: "daasity.svg",
    color: "#0B8AEE",
    backgroundColor: "#FFFFFF",
    buttonColor: "#FFFFFF",
    buttonBackgroundColor: "#0B8AEE",
    fontFamily: "sans-serif",
    fontSize: "16px",
    titleFontSize: "24px",
    linkColor: "#0B8AEE",
  },
};
