import { IntegrationProducts, IntegrationType } from "../integration";

export enum ContractCovenantType {
  HealthyIntegration = "Healthy Integration",
  RequiredReporting = "Required Reporting",
}

export enum ContractCovenantLookbackPeriod {
  CurrentDate = "Current Date",
  PriorMonth = "Prior Month",
  PriorQuarter = "Prior Quarter",
  PriorHalfYear = "Prior Half Year",
  PriorYear = "Prior Year",
  Trailing3MonthAverage = "Trailing 3 Month Average",
  Trailing6MonthAverage = "Trailing 6 Month Average",
  Trailing12MonthAverage = "Trailing 12 Month Average",
  Past15Days = "Past 15 Days",
  Past30Days = "Past 30 Days",
  Past45Days = "Past 45 Days",
  Past60Days = "Past 60 Days",
}

export enum BackendReportingApprovalStatus {
  Open = "open",
  Submitted = "submitted",
  Declined = "declined",
  Approved = "approved",
}

export enum ContractCovenantReportingApprovalStatus {
  Open = BackendReportingApprovalStatus.Open,
  Submitted = BackendReportingApprovalStatus.Submitted,
  Declined = BackendReportingApprovalStatus.Declined,
  Approved = BackendReportingApprovalStatus.Approved,
  PastDue = "past_due", // when backend status is "open" and we've passed report_date + reporting_buffer_days
}

export enum ContractCovenantIntegrationType {
  CodatAccounting = IntegrationType.CodatAccounting,
  Plaid = IntegrationProducts.Plaid,
  Finicity = IntegrationProducts.Finicity,
  Stripe = IntegrationProducts.Stripe,
  Unit = "unit",
}
