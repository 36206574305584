import {
  CompareArrows as CompareArrowsIcon,
  Business as BusinessIcon,
  ArrowForward as ArrowForwardIcon,
} from "@ampla/icons";

import {
  Divider,
  MUITypography,
  SwipeableDrawer,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  InputAdornment,
  MUIIconButton,
  Avatar,
  Button,
} from "@ampla/ui-components";

import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as Mousetrap from "mousetrap";

import { FixedSizeList as VirtualizedList, ListChildComponentProps } from "react-window";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { useMutation } from "react-query";
import { noop } from "lodash";
import { EntityAPI } from "@ampla/api";
import { useAuthState } from "components/context/AuthContextProvider";
import SearchFilter from "components/Common/SearchFilter";
import RoundedPaper from "components/Common/RoundedPaper";
import { EntityType } from "@ampla/api";
import AvatarAmpla from "components/AvatarAmpla";
import { ENTITY_MEMBERSHIPS_QUERY_KEY } from "constants/queryKeys";
import { AuthAPI } from "@ampla/api";
import LoadingContainer from "components/Common/LoadingContainer";
import { useStyles } from "./AppBrandSwitcher.styles";
import { MembershipEntity } from "@ampla/api";
import { userHasAccessAllBrandsPermission } from "routes/helpers/validators";

const AppBrandSwitcher = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [switcherOpen, setSwitcherOpen] = useState(false);

  const { user } = useAuthState();

  const classes = useStyles();

  const handleKeyFind = () => {
    if (!switcherOpen) setSwitcherOpen(true);
  };

  const { isLoading: isLoadingUserMembershipEntities, data: userMembershipEntities } = useQuery({
    queryKey: [ENTITY_MEMBERSHIPS_QUERY_KEY],
    queryFn: () => EntityAPI.user_membership_entities(),
    enabled: Boolean(switcherOpen),
  });

  useEffect(() => {
    Mousetrap.bind(["command+shift+f", "ctrl+shift+f"], handleKeyFind);

    return () => {
      Mousetrap.unbind(["command+shift+f", "ctrl+shift+f"]);
    };
  }, []);

  const { mutate, isLoading, isSuccess } = useMutation(
    (entityId: number) => AuthAPI.switch_active_entity({ entity_id: entityId }),
    {
      onSuccess: () => {
        window.location.href = "/";
      },
    },
  );

  const brandsSorted = useMemo(
    () =>
      userMembershipEntities?.reverse().sort(({ type: typeA, name: nameA = "" }, { type: typeB, name: nameB = "" }) => {
        if (typeA === "brand") return -1;
        if (typeA === "gg") return -1;

        if (typeA === typeB) return nameA.toLowerCase() > nameB.toLowerCase() ? 0 : -1;

        return 0;
      }) || [],
    [userMembershipEntities],
  );

  const brandsFiltered = useMemo(() => {
    if (!searchQuery) return brandsSorted;

    return brandsSorted.filter(({ name }) => name?.toUpperCase().includes(searchQuery.toUpperCase()));
  }, [brandsSorted, searchQuery]);

  const switchBrands = (entityId: number) => {
    setSwitcherOpen(false);
    setTimeout(() => mutate(entityId), 300);
  };

  const handleOpenSwitcherFabClick = () => setSwitcherOpen(true);

  const handleSwitcherClose = () => {
    setSwitcherOpen(false);
    setSearchQuery("");
  };

  const handleBrandButtonClick = (entityId: number) => () => switchBrands(entityId);

  const handleSearchEnterPressed = () => {
    if (brandsFiltered.length === 1) switchBrands(brandsFiltered[0].id as number);
  };

  const itemKey = (index: number, data?: MembershipEntity[]): number => {
    if (data && data.length) {
      const item = data[index];
      return item.id ?? 0;
    }

    return 0;
  };

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    if (brandsFiltered.length == 0) {
      return (
        <MUITypography color="primary" align="center">
          No results.
        </MUITypography>
      );
    }

    const brand = brandsFiltered[index];
    return (
      <ListItem
        style={style}
        className={classes.listItem}
        onClick={handleBrandButtonClick(brand.id as number)}
        data-testId={`brand-switcher-${brand.id}`}
        button
      >
        <ListItemIcon className={classes.listItemIcon}>
          {brand.type === EntityType.GourmetGrowth ? (
            <AvatarAmpla className={classes.avatarAmpla} />
          ) : (
            <Avatar className={classes.avatarBusiness}>
              <BusinessIcon />
            </Avatar>
          )}
        </ListItemIcon>
        <ListItemText
          primary={brand.name}
          secondary={brand.type === EntityType.ApplyingBrand ? "Applying Brand" : undefined}
          primaryTypographyProps={{ className: classes.listItemTextPrimary }}
          secondaryTypographyProps={{ className: classes.listItemTextSecondary }}
        />
      </ListItem>
    );
  };

  const showBrandSwitcher = useMemo(() => user && userHasAccessAllBrandsPermission({ user }), [user]);

  if (!showBrandSwitcher) return null;

  return (
    <>
      <button
        onClick={handleOpenSwitcherFabClick}
        className={classes.switcherButton}
        data-testid="brand-switcher-button"
      >
        <div className={classes.buttonTextContainer}>
          <CompareArrowsIcon fontSize="small" />
          <span className={classes.buttonText}>Brand</span>
        </div>
      </button>
      {(isLoading || isSuccess) && <LoadingContainer fullscreen classes={{ root: classes.loadingContainerRoot }} />}
      <SwipeableDrawer
        anchor="right"
        open={switcherOpen}
        onClose={handleSwitcherClose}
        onOpen={noop}
        PaperProps={{ component: RoundedPaper, className: classes.paper }}
      >
        <div className={classes.drawerContainer}>
          <div className={classes.drawerSearch}>
            <SearchFilter
              dataTestId="brand-switcher-search-filter-input"
              placeholder="Search brands"
              filterValue={searchQuery}
              setFilterValue={setSearchQuery}
              autoFocus
              debounceWaitMs={0}
              endAdornment={
                brandsFiltered.length === 1 ? (
                  <InputAdornment position="end">
                    <MUIIconButton size="small">
                      <ArrowForwardIcon className={classes.arrowIcon} />
                    </MUIIconButton>
                  </InputAdornment>
                ) : undefined
              }
              onEnterPressed={handleSearchEnterPressed}
            />
          </div>
          <Divider />
          <div className={classes.drawerList}>
            <AutoSizer>
              {({ height, width }: Size) => (
                <List component="nav" style={{ height }}>
                  <VirtualizedList
                    height={height}
                    overscanCount={100}
                    itemCount={brandsFiltered.length}
                    itemSize={50}
                    width={width}
                    itemKey={itemKey}
                    itemData={brandsFiltered}
                  >
                    {renderRow}
                  </VirtualizedList>
                </List>
              )}
            </AutoSizer>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default AppBrandSwitcher;
