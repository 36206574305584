import { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { useLocation } from "react-router";
import { startZendesk, isExcludedRoute } from "./utils";
import { QuestionAnswerOutlined as QuestionAnswerOutlinedIcon } from "@ampla/icons";
import { useStyles } from "./Zendesk.styles";

export default function Zendesk() {
  const [isButtonHidden, setIsButtonHidden] = useState(true);
  const classes = useStyles();
  const location = useLocation();
  const launcherRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setIsButtonHidden(isExcludedRoute(location.pathname));
    startZendesk(launcherRef);
  }, [location.pathname]);

  const handleWidget = () => {
    window.zE("webWidget", "show");
    window.zE("webWidget", "open");
    const el = launcherRef.current!;
    el.style.opacity = "0";
  };

  return (
    <button
      id="zendesk-launcher"
      ref={launcherRef}
      className={classnames(classes.button, { [classes.buttonHide]: isButtonHidden })}
      onClick={handleWidget}
    >
      <div className={classes.buttonTextContainer}>
        <QuestionAnswerOutlinedIcon fontSize="small" />
        <span className={classes.buttonText}>Help</span>
      </div>
    </button>
  );
}
