import React, { forwardRef } from "react";
import { Box } from "@mui/material";

import { ModalBodyProps } from "@ampla/ui-components";

const ModalBody = forwardRef(({ children, __TYPE, ...props }: ModalBodyProps, ref) => {
  return (
    <Box px={4} pb={2} overflow="auto" height="100%" {...props} ref={ref}>
      {children}
    </Box>
  );
});

ModalBody.defaultProps = {
  __TYPE: "ModalBody",
};

export default ModalBody;
