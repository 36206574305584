// Key name to store in localStorage the Plaid environment value.
// This value gets loaded from the backend and it's stored
// when connecting to a bank that uses an OAuth flow.
export const PLAID_ENV_KEY = "plaid_env";

// Key name to store in localStorage the pathname of the page that initialized
// the OAuth flow.
// This value is used in our callback to know where to redirect the user
// after they complete an OAuth flow.
export const PLAID_CALLBACK_CALLER_URI_KEY = "plaid_callback_caller_uri";

// Key name to store the Plaid link token value to localStorage.
// This value gets loaded from the backend and it's stored
// when connecting to a bank that uses an OAuth flow.
export const PLAID_CALLBACK_LINK_TOKEN_KEY = "plaid_callback_link_token";

// Key name to store in localStorage the URL that Plaid redirects the user to after
// they complete an OAuth flow.
// This value is passed to Plaid when it gets initialized for the second time
// to finish an OAuth flow.
export const PLAID_CALLBACK_REDIRECT_URI_KEY = "plaid_callback_redirect_uri";

// Key name to store in localStorage the Plaid item ID in case the user is relinking Plaid
// from an existing account.
// This value is used to only initialize Plaid a second time after the OAuth flow on the
// correct item.
export const PLAID_CALLBACK_ITEM_ID_KEY = "plaid_callback_item_id";

// Key name for a flag we store in localStorage that holds the connection status for
// integrations and allows us to communicate between the tab that originated the
// OAuth call and the callback tab
export const INTEGRATION_CONNECTION_STATUS_KEY = "INTEGRATION_CONNECTION_STATUS";

// Key name for a flag we store in localStorage that holds the timestamp when user
// dismiss the PayLater banner in the BillPay home page
export const PAY_LATER_BANNER_TIMESTAMP = "pay_later_banner_timestamp";
export const PAY_LATER_REVIEWING_TIMESTAMP = "pay_later_reviewing_timestamp";
export const PAY_LATER_CURRENT_FLOW = "pay_later_current_flow";
export const PAY_LATER_BETA_AUTH = "pay_later_beta_auth";

// Key name used to store the user is logged in flag in sessionStorage
export const SESSION_USER_LOGGED_IN_KEY = "_ggUserLoggedIn";

// Key name used to store the user object in sessionStorage
export const SESSION_USER_KEY = "_ggUser";

// Key name used to store the paid channel source (facebook, google, etc)
export const SESSION_UTM_SOURCE_KEY = "_ggUtmSource";

// Key name used to store the utm medium (social, cpc, etc)
export const SESSION_UTM_MEDIUM_KEY = "_ggUtmMedium";

// Key name used to store the utm campaign
export const SESSION_UTM_CAMPAIGN_KEY = "_ggUtmCampaign";

// Key name used to store the source of the partner in sessionStorage
export const SESSION_WIDGET_SOURCE_KEY = "_ggWidgetSource";

// Key name used to store the return url from partner in sessionStorage
export const SESSION_WIDGET_RETURN_URL_KEY = "_ggWidgetReturnUrl";

// Key name used to store google's oauth token
export const SESSION_GOOGLE_OAUTH_KEY = "google_oauth_token";

// Key name used to store billIds in the schedule-payment flow
export const BILL_PAY_SCHEDULE_PAYMENT_BILL_IDS = "bill_pay_schedule_payment_bill_ids";

// Key name used to store the hide staff banner flag
export const LOCAL_STORAGE_HIDE_STAFF_BANNER_FLAG = "hide_staff_banner";

// Key name used to store the lead's email before account creation
export const SESSION_LEAD_EMAIL = "lead_email";

// Key name used to store the reciepient bulk upload process ides
export const BANCO_COUNTERPARTY_BULK_UPLOAD_PROCESS_KEYS = "banco_counterparty_bulk_upload_process_keys";

// Key name used to store which product the user is signing up for
export const SESSION_PRODUCT_PRE_SELECTED = "session_product_pre_selected";

// Key name used to store whether the corporate card reapply banner was dismissed
export const SESSION_CORPORATE_CARD_REAPPLY_DISMISSED = "session_corporate_card_reapply_dismissed";
