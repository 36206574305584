import { Components } from "@mui/material/styles";

import { AMPLA_COMPONENT_NAMESPACE_SELECTOR } from "libs/components/theme/constants";
import colors from "../palette/colors";

const MuiSelect: Components["MuiInput"] = {
  styleOverrides: {
    root: {
      [AMPLA_COMPONENT_NAMESPACE_SELECTOR]: {
        "&.AmplaSelect": {
          "&:before, &:after": {
            display: "none",
          },
        },
        "& .MuiSelect-root": {
          padding: "10px 16px",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: colors.gray[200],
          borderRadius: 4,
          backgroundColor: colors.gray[50],
        },
        "&.Mui-focused .MuiSelect-root": {
          color: colors.teal[500],
          borderColor: colors.teal[400],
          backgroundColor: colors.teal[50],
        },
        "&.Mui-error .MuiSelect-root": {
          color: colors.red[600],
          borderColor: colors.red[600],
        },
        "& .MuiSelect-icon": {
          right: 10,
        },
      },
    },
  },
};

export default MuiSelect;
