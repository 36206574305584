import { lazy } from "react";
import { generatePath } from "react-router";

import { BancoAccountDetailDeposit, BancoAccountDetailReconciliation, BancoAccountDetailReserve } from "@ampla/api";

import { PORTAL_MENU_ACCOUNTS_OPTION_CLICKED } from "constants/eventKeys";
import { trackEvent } from "helpers/analytics";
import {
  activeEntityHasProductBankingInActiveState,
  activeEntityHasProductBankingInApplyingState,
  activeEntityHasProductBankingInDisabledState,
  activeEntityHasProductBankingInOnboardingState,
  activeEntityHasProductBankingInReviewingState,
  activeEntityHasReconciliationAccountsInActiveState,
  activeEntityHasReserveAccountInActiveState,
  everyValidator,
  negateValidator,
  oneOfValidator,
  userBancoApplicationHasBeenDenied,
  userHasViewBancoAccountPermission,
} from "routes/helpers/validators";
import { RouteDeclaration } from "routes/types";
import { TabOptions as CheckingReserveAccountTabOptions } from "./pages/CheckingReserveAccountsDetail/CheckingReserveAccountsDetailPage";
import { TabOptions as ReconciliationAccountTabOptions } from "./pages/ReconciliationAccountsDetail/ReconciliationAccountsDetailPage";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const ReconciliationAccountsDetail = lazy(() => import("./pages/ReconciliationAccountsDetail"));
const CheckingReserveAccountsDetail = lazy(() => import("./pages/CheckingReserveAccountsDetail"));

const BANKING_ROOT = "/banking-dashboard";
const ACCOUNT_DETAILS_ROOT = "/account-details";
const RECONCILIATION_ACCOUNTS_DETAILS_ROOT = "/reconciliation-accounts-details";

export const BANCO_HOME_PAGE: RouteDeclaration = {
  name: "Banking",
  path: BANKING_ROOT,
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: Dashboard,
  validators: [
    oneOfValidator(
      everyValidator(
        oneOfValidator(activeEntityHasProductBankingInActiveState, activeEntityHasProductBankingInReviewingState),
        userHasViewBancoAccountPermission,
        negateValidator(userBancoApplicationHasBeenDenied),
      ),
      everyValidator(activeEntityHasProductBankingInDisabledState, negateValidator(userBancoApplicationHasBeenDenied)),
      activeEntityHasProductBankingInApplyingState,
      activeEntityHasProductBankingInOnboardingState,
    ),
  ],
  onClick() {
    trackEvent(PORTAL_MENU_ACCOUNTS_OPTION_CLICKED);
  },
};

export const RECONCILIATION_ACCOUNTS_DETAIL_PAGE: RouteDeclaration = {
  name: "Reconciliation accounts",
  path: `${RECONCILIATION_ACCOUNTS_DETAILS_ROOT}/:bancoAccountId?`,
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: ReconciliationAccountsDetail,
  validators: [
    activeEntityHasProductBankingInActiveState,
    activeEntityHasReconciliationAccountsInActiveState,
    userHasViewBancoAccountPermission,
  ],
};

export const CHECKING_RESERVE_ACCOUNTS_DETAIL_PAGE: RouteDeclaration = {
  name: "Account details",
  path: `${ACCOUNT_DETAILS_ROOT}/:bancoAccountId?`,
  pathIsExact: true,
  pathIsPrivate: true,
  PageComponent: CheckingReserveAccountsDetail,
  validators: [
    activeEntityHasProductBankingInActiveState,
    activeEntityHasReserveAccountInActiveState,
    userHasViewBancoAccountPermission,
  ],
};

export const generateBancoHomePagePath = () => generatePath(BANCO_HOME_PAGE.path);

type GenerateReconciliationPathParams = {
  bancoAccountId?: BancoAccountDetailReconciliation["id"];
  tab?: ReconciliationAccountTabOptions;
};
export const generateReconciliationAccountDetailPagePath = ({
  bancoAccountId,
  tab,
}: GenerateReconciliationPathParams = {}) => {
  const path = generatePath(RECONCILIATION_ACCOUNTS_DETAIL_PAGE.path, { bancoAccountId });

  if (tab) return `${path}?tab=${tab}`;
  return path;
};

type GenerateCheckingReservePathParams = {
  bancoAccountId: (BancoAccountDetailDeposit | BancoAccountDetailReserve)["id"];
  tab?: CheckingReserveAccountTabOptions;
};
export const generateCheckingReserveAccountDetailPagePath = ({
  bancoAccountId,
  tab,
}: GenerateCheckingReservePathParams) => {
  const path = generatePath(CHECKING_RESERVE_ACCOUNTS_DETAIL_PAGE.path, { bancoAccountId });

  if (tab) return `${path}?tab=${tab}`;
  return path;
};

const BANCO_HOME_ROUTES = [BANCO_HOME_PAGE, RECONCILIATION_ACCOUNTS_DETAIL_PAGE, CHECKING_RESERVE_ACCOUNTS_DETAIL_PAGE];

export default BANCO_HOME_ROUTES;
