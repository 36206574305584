const styles = {
  root: {
    padding: "2px 0",
    height: "24px",
    width: "40px",
    marginRight: "12px",

    "&.MuiSwitch-sizeSmall": {
      height: "20px",
      width: "32px",
      "& .MuiSwitch-thumb": {
        width: "12px",
        height: "12px",
      },
    },

    "&:not(.Mui-disabled)": {
      "&:hover": {
        "& .Mui-checked+.MuiSwitch-track": {
          backgroundColor: "ampla.teal.300",
        },
        "& .MuiSwitch-track": {
          backgroundColor: "ampla.gray.300",
        },
      },
      "&:active": {
        "&.MuiSwitch-sizeSmall": {
          "& .MuiSwitch-thumb": {
            width: "16px",
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "matrix(1, 0, 0, 1, 12, 0)",
          },
        },
        "&.MuiSwitch-sizeMedium": {
          "& .MuiSwitch-thumb": {
            width: "20px",
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "matrix(1, 0, 0, 1, 16, 0)",
          },
        },
      },
    },
    "& .MuiSwitch-switchBase": {
      left: "2px",
      top: "4px",
      padding: 0,
      opacity: 1,
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.Mui-checked": {
        "&+.MuiSwitch-track": {
          backgroundColor: "ampla.teal.400",
          opacity: 1,
        },
      },
      "&.Mui-disabled": {
        "&+.MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "ampla.gray.100",
        },
        "& .MuiSwitch-thumb": {
          backgroundColor: "ampla.gray.200",
        },
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "ampla.gray.200",
      borderRadius: "12px",
      transition: "background-color 0.25s ease-in-out",
    },
    "& .MuiSwitch-thumb": {
      transition: ".15s ease all",
      width: "16px",
      height: "16px",
      backgroundColor: "white",
      boxShadow: "none",
      borderRadius: "8px",
    },
  },
  formControlLabel: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
  },
};

export default styles;
