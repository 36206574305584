import { ForwardedRef, forwardRef } from "react";
import { Drawer, useMediaQuery } from "@mui/material";

import styles from "./AppMenu.styles";
import { AppMenuProps } from "./AppMenu.types";

const AppMenu = forwardRef(
  ({ isMenuOpen, setIsMenuOpen, children, ...props }: AppMenuProps, ref: ForwardedRef<HTMLDivElement>) => {
    const handleClose = () => setIsMenuOpen(false);

    const isSmallViewport = useMediaQuery("(max-width: 767px)");

    return (
      <Drawer
        ref={ref}
        {...props}
        open={isMenuOpen}
        variant={isSmallViewport ? "temporary" : "permanent"}
        anchor={isSmallViewport ? "right" : "left"}
        PaperProps={{
          sx: {
            ...styles.drawer,
            ...(props.PaperProps?.sx || {}),
          } as any,
        }}
        onClose={handleClose}
      >
        {children}
      </Drawer>
    );
  },
);

export default AppMenu;
