import "whatwg-fetch";

import { serializeToParams } from "@ampla/utils";

import { BASE_FETCH_OPTIONS, ROOT_API_URL } from "./constants";
import { APIError } from "./exceptions";
import { RestFunctionType } from "./types";
import { b64toBlob, getHeaders } from "./utils";

export const get: RestFunctionType<any> = async (path, body, extraData) => {
  let paramString = serializeToParams(body);

  // If path already has a query parameter
  if (path.indexOf("?") > -1) paramString = `&${paramString.substring(1)}`;

  const url = `${ROOT_API_URL}${path}${paramString}`;

  const response = await fetch(url, {
    headers: {
      ...getHeaders(),
      ...(extraData ? extraData.headers : {}),
    },
    ...BASE_FETCH_OPTIONS,
  });

  if (response.ok) {
    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") > -1) return response.json();
    return response.text();
  }

  if (response.status === 401) window.ampla?.logout?.();

  if (typeof response.json === "function") {
    throw await response.json();
  }

  throw new APIError(response.status, response.statusText);
};

export const post: RestFunctionType<any> = async (path, body, options: RequestInit = {}) => {
  const payload = body instanceof FormData ? body : JSON.stringify(body);
  const headers = body instanceof FormData ? undefined : getHeaders();

  const response = await fetch(`${ROOT_API_URL}${path}`, {
    body: payload,
    method: "POST",
    ...BASE_FETCH_OPTIONS,
    ...options,
    headers: {
      ...headers,
      ...(options.headers || {}),
    },
  });

  if (response.ok) {
    const contentType = response.headers.get("content-type") || "";

    if (response.status === 204) return null; // Handle 204 No Content

    if (contentType.indexOf("application/json") > -1) return response.json();

    return response.text();
  }

  if (response.status === 401 && !path.includes("/logout")) window.ampla?.logout?.();

  if (typeof response.json === "function") {
    throw await response.json();
  }
  throw new Error(response.statusText);
};

export const upload: RestFunctionType<any> = async (path, data) => {
  const formData = new FormData();
  Object.getOwnPropertyNames(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  const response = await fetch(`${ROOT_API_URL}${path}`, {
    body: formData,
    method: "POST",
    ...BASE_FETCH_OPTIONS,
  });
  if (response.ok) {
    return response.json();
  }

  if (response.status === 401) window.ampla?.logout?.();

  if (typeof response.json === "function") {
    throw await response.json();
  }
  throw new Error(response.statusText);
};

export const put: RestFunctionType<string | any> = async (path, body) => {
  const response = await fetch(`${ROOT_API_URL}${path}`, {
    body: JSON.stringify(body),
    method: "PUT",
    headers: getHeaders(),
    ...BASE_FETCH_OPTIONS,
  });
  const contentType = response.headers.get("content-type") || "";

  if (response.ok) {
    if (response.status === 204) return null; // Handle 204 No Content

    if (contentType.indexOf("application/json") > -1) return response.json();

    return response.text();
  }

  if (response.status === 401) window.ampla?.logout?.();

  if (typeof response.json === "function") {
    throw await response.json();
  }
  throw new Error(response.statusText);
};

export const patch: RestFunctionType<any> = async (path, body) => {
  const headers = getHeaders();
  const response = await fetch(`${ROOT_API_URL}${path}`, {
    body: JSON.stringify(body),
    method: "PATCH",
    headers,
    ...BASE_FETCH_OPTIONS,
  });
  const contentType = response.headers.get("content-type") || "";

  if (response.ok) {
    if (response.status === 204) return null; // Handle 204 No Content

    if (contentType.indexOf("application/json") > -1) return response.json();

    return response.text();
  }

  if (response.status === 401) window.ampla?.logout?.();

  if (typeof response.json === "function") {
    throw await response.json();
  }
  throw new Error(response.statusText);
};

export const del: RestFunctionType<void> = async (path, body) => {
  const url = `${ROOT_API_URL}${path}${serializeToParams(body)}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: getHeaders(),
    ...BASE_FETCH_OPTIONS,
  });
  if (response.ok) {
    return;
  }

  if (response.status === 401) window.ampla?.logout?.();

  if (typeof response.json === "function") {
    throw await response.json();
  }
  throw new Error(response.statusText);
};

export const convertBlobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (reader.result && typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject();
      }
    };
    reader.readAsDataURL(blob);
  });

export const file: RestFunctionType<Blob> = async (path, body, extraData) => {
  const url = extraData.fullUrl
    ? `${path}${serializeToParams(body)}`
    : `${ROOT_API_URL}${path}${serializeToParams(body)}`;
  const response = await fetch(url, {
    headers: getHeaders(),
    ...BASE_FETCH_OPTIONS,
  });

  if (response.ok) {
    if (extraData.pdfFile) {
      const resultBlob = await response.blob();
      return resultBlob;
    }
    if (extraData.arrayBuffer) {
      const resultBlob = await response.blob();
      const base64 = await convertBlobToBase64(resultBlob);
      return b64toBlob(base64.replace("data:application/vnd.ms-excel;base64,", ""), extraData.type);
    }
    const resultText = await response.text();
    return new Blob([resultText], { type: extraData.type || "text/csv;charset=utf-8" });
  }

  if (response.status === 401) window.ampla?.logout?.();

  throw new Error(response.statusText);
};

const restFunctions: { [key: string]: RestFunctionType<any> } = {
  update: patch,
  create: post,
  delete: del,
  list: get,
  detail: get,
  upload,
  file,
};

const client = async (method: string, path: string, body?: any, extraData?: any) => {
  const func = restFunctions[method];
  if (!func) {
    throw new Error(`${method} is not a valid method`);
  }

  let result;

  if (body && extraData) {
    result = await func(path, body, extraData);
  } else if (body) {
    result = await func(path, body);
  } else {
    result = await func(path);
  }

  return result;
};

export default client;
