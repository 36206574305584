import { ForwardedRef, forwardRef, useMemo } from "react";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Box, Collapse, ListItemButton } from "@mui/material";

import { Typography } from "libs/components/components/Typography";
import styles from "./AppMenuOption.styles";
import { AppMenuOptionProps } from "./AppMenuOption.types";

const AppMenuOption = forwardRef(
  <C extends React.ElementType>(
    { label, size = "medium", isOpen, isActive, children, ...props }: AppMenuOptionProps<C>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const isParentOption = useMemo(() => !!children && size !== "small", [children, size]);

    if (size === "small")
      return (
        <ListItemButton ref={ref} {...props} sx={{ ...styles.optionSmall, ...(props.sx || {}) }}>
          <Typography variant="body3" weight={isActive ? "bold" : undefined} color="gray-500">
            {label}
          </Typography>
        </ListItemButton>
      );

    return (
      <Box ref={ref} sx={props.sx}>
        <ListItemButton {...props} sx={{ ...styles.option, ...(isActive ? styles.optionActive : {}) }}>
          <Box sx={{ ...styles.optionIndicator, ...(isActive ? styles.optionIndicatorActive : {}) }} />
          <Typography variant="body1" sx={styles.optionTypography}>
            {label}
          </Typography>
          {isParentOption && (
            <KeyboardArrowDownIcon
              sx={{ ...styles.optionCollapseIcon, ...(isOpen ? styles.optionCollapseIconActive : {}) }}
            />
          )}
        </ListItemButton>
        {isParentOption && (
          <Collapse in={isOpen} sx={styles.collapse} unmountOnExit>
            {children}
          </Collapse>
        )}
      </Box>
    );
  },
);

export default AppMenuOption;
